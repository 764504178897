import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import {
  IndoorWidget,
  Air2Widget,
  SolarWidget,
  UvWidget,
  RainWidget,
  WindWidget,
  RelaysWidget,
  SoilWidget,
  SunMoonWidget, 
  Widget,
  TempWidget,
  DevicePopup,
  ForecastWidget,
  HumidityWidget
} from '../features/device'
import bindAllActions from '../common/bindAllActions';
import { deparam } from '../common/ambient'


class StandAloneLoader extends PureComponent {
  static propTypes = {
    className: PropTypes.string
  };

  render() {
    const query = deparam(window.location.search)
    const props = JSON.parse(query.props)
    console.log(props);

    const widgets = {
      HumidityWidget: {
        component: HumidityWidget,
        type: 'humidity'
      },
      ForecastWidget: {
        component: ForecastWidget,
        type: 'forecast'
      },
      TempWidget: {
        component: TempWidget,
        type: 'temp'
      },
      IndoorWidget: {
        component: IndoorWidget,
        type: 'indoor'
      },
      Air2Widget: {
        component: Air2Widget,
        type: 'air'
      },
      AirWidget: {
        component: Air2Widget,
        type: 'air'
      },
      SolarWidget: {
        component: SolarWidget,
        type: 'solar'
      },
      UvWidget: {
        component: UvWidget,
        type: 'uv'
      },
      RainWidget: {
        component: RainWidget,
        type: 'rain'
      },
      WindWidget: {
        component: WindWidget,
        type: 'wind'
      },
      RelaysWidget: {
        component: RelaysWidget,
        type: 'relays'
      },
      SoilWidget: {
        component: SoilWidget,
        widgetProps: {
          type: 'soiltemp' + props.sensor,
          className: 'soil'
        }
      }, 
      SunMoonWidget: {
        component: SunMoonWidget,
        type: 'sunMoon'
      }
    }
    const components = {
      DevicePopup: {
        component: DevicePopup 
      }
    }
    let show
    const childProps = Object.assign({
      device: { devices: [{}], fetchedDevices: {} },
      user: {},
      onChange: {}
    }, props)
    if (/Widget$/.test(query.component)) {
      const widget = widgets[query.component]
      const widgetProps = Object.assign({
        user: {},
        type: widget.type
      }, widget.widgetProps, props)
      widgetProps.device = props.currentDevice || {} // this prop is named wrong
      show = React.createElement(Widget, widgetProps, React.createElement(widget.component, childProps))
    } else {
      show = React.createElement(components[query.component].component, childProps)
    }

    return <div className="component-stand-alone-loader">{show}</div> 
  }
}

export default bindAllActions(StandAloneLoader);
  
StandAloneLoader.displayName = 'StandAloneLoader'

StandAloneLoader.displayName = 'StandAloneLoader'

StandAloneLoader.displayName = 'StandAloneLoader'

StandAloneLoader.displayName = 'StandAloneLoader'

StandAloneLoader.displayName = 'StandAloneLoader'

StandAloneLoader.displayName = 'StandAloneLoader'

StandAloneLoader.displayName = 'StandAloneLoader'
