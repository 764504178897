import React, { Component } from 'react';
import bindAllActions from '../../common/bindAllActions'
import PropTypes from 'prop-types';
import { not, nth, equals, toPairs, concat, propEq, find, map, identity, values, filter, mapObjIndexed, pipe, path } from 'ramda'
import { capitalizeFirstLetter, formatDate } from '../../common/ambient'
import Avatar from './Avatar'

const lineToDiv = line => <div key={line} className="line">{line}</div>

class AdminAlexa extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    userActions: PropTypes.object.isRequired,
  };
  constructor(props) {
    super(props) 
    this.fetchData = ::this.fetchData
  }
  fetchData() {
    const { userActions, user } = this.props
    const { fetchUsersPending, users } = user
    if (!user.fetchAlexaDataPending && user.alexaData.length < 1) {
      userActions.fetchAlexaData()
    }
    if (!fetchUsersPending && !users) {
      userActions.fetchUsers({
        $limit: 4000
      })
    }
  }
  componentDidMount() {
    this.fetchData()
  }
  componentDidUpdate() {
    this.fetchData()
  }
  userAvatar(data) {
    const { user } = this.props
    const { users } = user
    const theUser = users && find(propEq('_id', data.userId), users)
    if (theUser) {
      return <Avatar u={theUser} />
    }
    return ''
  }
  alexaCard(data) {
    const { user } = this.props
    const { users } = user
    const items = {}
    items.intent = path(['request', 'intent', 'name'], data)
    ;['deviceParam', 'device', 'timeRange'].forEach((param) => {
      items[param] = path(['request', 'intent', 'slots', capitalizeFirstLetter(param), 'value'], data)
    })
    const lines = pipe(
      mapObjIndexed((val, key) => {
        return (val) ? key + ': ' + val : false
      }),
      filter(identity),
      values,
      map(lineToDiv)
    )(items)
    const response = path(['response', 'response', 'outputSpeech', 'ssml'], data) || ''
    return (
      <div className="intent" key={data._id}>
        {this.userAvatar(data)}
        {formatDate(false, new Date(data.request.timestamp))}
        <i className="alexa" />
        <br/>
        {lines}
        <div className="output">
          {response.replace(/<\/?speak>/g, '')}
        </div>
      </div>
    )
  }
  googleCard(data) {
    let lines = ['intent: ' + data.result.metadata.intentName, 'utterance: ' + data.result.resolvedQuery]
    lines = pipe(
      toPairs,
      filter(pipe(nth(1), equals(''), not)),
      map(pair => pair[0] + ': ' + pair[1]),
      concat(lines)
    )(data.result.parameters)
    return (
      <div className="intent" key={data._id}>
        {this.userAvatar(data)}
        {formatDate(false, new Date(data.request.timestamp))}
        <i className="google-assistant" />
        <br/>
        {lines.map(lineToDiv)}
        <div className="output">
          {data.response.replace(/<\/?speak>/g, '')}
        </div>
      </div>
    )
    
  }

  render() {
    const { user } = this.props
    const { alexaData } = user
    const cards = alexaData.map((data) => {
      return data.originalRequest ? this.googleCard(data) : this.alexaCard(data)
    })
    return (
      <div className="user-admin-alexa">
        <header className="main">
          <h1>
            Alexa 
          </h1>
        </header>
        <div className="page-body">
          <div className="block">
            <h3>Recent Commands</h3>
            {cards}
          </div>
        </div>
      </div>
    );
  }
}
export default bindAllActions(AdminAlexa)

AdminAlexa.displayName = 'AdminAlexa'

AdminAlexa.displayName = 'AdminAlexa'

AdminAlexa.displayName = 'AdminAlexa'

AdminAlexa.displayName = 'AdminAlexa'

AdminAlexa.displayName = 'AdminAlexa'

AdminAlexa.displayName = 'AdminAlexa'

AdminAlexa.displayName = 'AdminAlexa'
