import React, { useState } from 'react'
import classNames from 'classnames'
import { path, range } from 'ramda'
import astro from '../../common/lib/astro'
import {
  DayForecast,
  Loader
} from '../../components/'
import {
  FormattedDataPoint
} from '../device/'
import {
  SimpleLineGraph
} from './'
import { isSomething, convertUnitInverse, getUserUnitI, getForecastParams, getAstroValue, timeFormatForUser } from '../../common/ambient'
import { useDoModal } from '../user/redux/doModal'

const WIND_GUST_HIGH_MPH = 18

const OtherDays = ({ f, selected, hourlies = [], user }) => {
  let more
  const { doModal } = useDoModal()
  if (selected) {
    let show = (
      <Hourlies hoursToShow={hourlies} user={user} width={hourlies.length < 24 && `${hourlies.length * 78.3}px`} />
    )
    if (hourlies.length === 0) {
      show = (
        <div className='no-hourlies'>
          {user.plus ? (
            <span>You are a <i className='awn-plus' />. In production you will see hourly forecasts here.</span>
          ) : (
            <a onClick={evt => {
              doModal({
                type: 'plus-subscription',
                data: {}
              })
              evt.preventDefault()
            }}>
              Get all 10 DAYS of hourly forecasts<br/>with a <i className='awn-plus' /> subscription.
            </a>
          )}
        </div>
      )
    }
    more = (
      <div className='device-forecast-widget'>
        {show}
      </div>
    )
  }
  return (
    <div className={classNames('other-days', { selected })}>
      <DayForecast
        title={<><span className='day'>{moment(f.time * 1000).format('ddd')}</span> <span className='date'>{moment(f.time * 1000).format('M/D')}</span></>}
        forecast={{ ...f, int: true }}
      />
      {more}
    </div>
  )
}

const Hourlies = ({ hoursToShow, user, width }) => {
  let lastDay = moment().dayOfYear()
  const forecastParams = getForecastParams(user)
  const humidity = hoursToShow.map(f => ({
    value: Math.round(f.humidity * 100),
    time: f.time
  }))
  const pressure = hoursToShow.map(f => ({
    value: f.pressure,
    time: f.time
  }))
  const todayTime = moment(hoursToShow[0].time * 1000).startOf('day')
  const getAlt = (time) => {
    const thisTime = moment(time * 1000)
    if (thisTime.dayOfYear() === todayTime.dayOfYear()) {
      return
    }
    if (Math.floor(moment.duration(thisTime.diff(todayTime)).asDays()) > 1) {
      return
    }
    return 'alt'
  }
  return (
    <div className='hourly-wrap'>
      <div className='scrollable-wrap' style={width ? { width } : {}}>
        <div className='hourly'>
          {range(0, hoursToShow.length).map(i => {
            const hour = hoursToShow[i]
            let title = 'Now'
            const m = moment(hour.time * 1000)
            if (i > 0) {
              title = m.format(getUserUnitI('hour24', user) === 1 ? 'H' : 'ha')
            }
            if (lastDay !== m.dayOfYear()) {
              lastDay = m.dayOfYear()
              title = m.format('M/D')
            }
            return (
              <div key={`hour-${i}`} className={classNames('hour', getAlt(hour.time))}>
                <div className='title'>{title}</div>
                {forecastParams.includes('Cloud Cover') && <div className={classNames('weather-icon', hour.icon)} />}
                {forecastParams.includes('Temperature') && <FormattedDataPoint type='tempf' value={hour.temperature} after='°' valueTransform={Math.round} />}
                {forecastParams.includes('Precipitation') && <div className={classNames('precip', hour.icon)}>
                  <span className='drop'>{parseInt(hour.precipProbability * 100, 10)}%</span>
                  <FormattedDataPoint type='dailyrainin' value={hour.precipIntensity} />
                </div>}
              </div>
            )
          })}
        </div>
        {forecastParams.includes('Wind') && <div className='wind labels'>
          {hoursToShow.map((f, i) => <div className={classNames('label', getAlt(f.time), { 'high-winds': f.windGust && f.windGust > WIND_GUST_HIGH_MPH })} key={`wind-${i}`}>
            {f.windBearing ? <FormattedDataPoint type='winddir' value={f.windBearing} /> : <span className='no-wind'>--</span>}
            <div className='arrow-wrap'>
              <div className={classNames('arrow', { 'no-wind': !isSomething(f.windBearing) })} style={isSomething(f.windBearing) ? { transform: `rotate(${f.windBearing + 180}deg)` } : {}} />
            </div>
            <FormattedDataPoint type='windspeedmph' value={f.windSpeed} valueTransform={Math.round} />
            {f.windGust && f.windGust > WIND_GUST_HIGH_MPH && <FormattedDataPoint type='windgustmph' value={f.windGust} valueTransform={Math.round} />}
          </div>)}
        </div>}
        {forecastParams.includes('UV Index') && <div className='uv-index labels'>
          {hoursToShow.map((f, i) => <div className={classNames('label', getAlt(f.time))}key={`uv-index-${i}`}>
            <div className={`bar uv-${f.uvIndex > 11 ? 11 : f.uvIndex}`} />
            <b>{f.uvIndex}</b>
            <span className='suff'>UV</span>
          </div>)}
        </div>}
        {forecastParams.includes('Humidity') && <div className='line-graph-wrap'>
          <SimpleLineGraph
            data={humidity}
            margin={{ left: 37, right: 40, top: 5, bottom: 5 }}
            dot={info => {
              if (getAlt(info.payload.time)) {
                return <circle {...info} className='alt' />
              }
            }}
          />
          <div className='labels'>
            {humidity.map((f, i) => <div key={`humidity-${i}`} className={classNames('label', getAlt(f.time))}>
              <b>{f.value}%</b>
              <span className='suff'>hum</span>
            </div>)}
          </div>
        </div>}
        {forecastParams.includes('Pressure') && <div className='line-graph-wrap'>
          <SimpleLineGraph
            data={pressure}
            margin={{ left: 37, right: 40, top: 5, bottom: 5 }}
            dot={info => {
              if (getAlt(info.payload.time)) {
                return <circle {...info} className='alt' />
              }
            }}
          />
          <div className='labels'>
            {pressure.map((f, i) => <div key={`barom-${i}`} className={classNames('label', getAlt(f.time))}>
              <FormattedDataPoint type='baromrelin' value={f.value} />
            </div>)}
          </div>
        </div>}
      </div>
    </div>
  )
}

export default function ForecastCard ({ forecast, label, user, currentTemp, currentConditions }) {
  const [selectedDay, setSelectedDay] = useState(null)
  if (!forecast) {
    return <Loader />
  }
  const getValue = getAstroValue(moment, { lat: forecast.lat, lon: forecast.lon, tz: forecast.tz })
  
  const { currently = {}, hourly = { data: [] }, daily = { data: [] } } = forecast
  const days = daily.data.filter(f => moment(f.time * 1000).startOf('day').valueOf() >= moment().startOf('day').valueOf())
  const today = days[0]

  const daysToShow = 10
  const hoursToShow = hourly.data.slice(0, 48)
  const hourlyShow = <Hourlies hoursToShow={hoursToShow} user={user} />
  const otherDays = days.slice(1, 1 + daysToShow)
  const otherDayHourlies = user.plus ? hourly.data : hourly.data.slice(0, 72)
  return (
    <div className='forecast'>
      <div className='forecast-header'>
        <div className='location'>{label}</div>
        <div className='forecast-summary'>
          {currentConditions ||
            <FormattedDataPoint type='tempf' value={currently.temperature} valueTransform={Math.round} after='°' />
          }
          <div className='the-rest'>
            <div className='forecast-container'>
              <div className='forecast-summary__currently'>{path(['currently', 'summary'], forecast)}</div>
              <div className={classNames('weather-icon', today.icon)} />
            </div>
            <div className='high-lows'>
              <span>
                H
                <FormattedDataPoint type='tempf' value={today.temperatureMax} valueTransform={Math.round} after='°' />
              </span>
              <span>
                L
                <FormattedDataPoint type='tempf' value={today.temperatureMin} valueTransform={Math.round} after='°' />
              </span>
            </div>
          </div>
        </div>
        <div className={classNames('device-forecast-widget component-forecast-long-term has-hourly')}>
          {hourlyShow}
        </div>
        <div className='extended'>
          <div className='extended-summary'>
            <div className='forecast-summary__today'><FormattedDataPoint value={today.summary} /> {path(['daily', 'summary'], forecast)}</div>
            <div className='forecast-summary__details'>
              <div className='label'>
                <div className='label-title'>Feels like</div>
                <FormattedDataPoint type='tempf' value={currently.apparentTemperature} valueTransform={Math.round}/>
              </div>
              <div className='label'>
                <div className='label-title'>Precip chance</div>
                <FormattedDataPoint value={currently.precipProbability} after='%' valueTransform={v => Math.round(v * 100)}/>
              </div>
              <div className='label'>
                <div className='label-title'>Wind</div>
                <FormattedDataPoint type='windspeedmph' value={currently.windSpeed} valueTransform={Math.round} />
              </div>
              {currently.precipIntensity > 0 && <div className='label'>
                <div className='label-title'>Precip</div>
                <FormattedDataPoint value={currently.precipIntensity} type='hourlyrainin' />
              </div>}
              <div className='label'>
                <div className='label-title'>Humidity</div>
                <FormattedDataPoint type='humidity' value={currently.humidity} valueTransform={v => Math.round(v * 100)} />
              </div>
              <div className='label'>
                <div className='label-title'>Pressure</div>
                <FormattedDataPoint type='baromrelin' value={currently.pressure} valueTransform={Math.round} />
              </div>
              <div className='label'>
                <div className='label-title'>Sunrise</div>
                <span className='fdp'>{getValue(astro.solar.rise, Date.now()).format(timeFormatForUser(user)).replace(' ', '')}</span>
              </div>
              <div className='label'>
                <div className='label-title'>Sunset</div>
                <span className='fdp'>{getValue(astro.solar.set, Date.now()).format(timeFormatForUser(user)).replace(' ', '')}</span>
              </div>
            </div>
          </div>

          <div className='extended-forecast'>
            <div className='other-days'>
              {otherDays.map((f, i) => (
                <a
                  className='other-day-a'
                  onClick={() => setSelectedDay(i === selectedDay ? null : i)}
                  key={`forecast-${f.time}`}
                >
                  <OtherDays
                    f={f}
                    user={user}
                    hourlies={otherDayHourlies.filter(h => moment(h.time * 1000).dayOfYear() === moment(f.time * 1000).dayOfYear())}
                    selected={selectedDay === i}
                  />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ForecastCard.propTypes = {}
ForecastCard.defaultProps = {}

ForecastCard.displayName = 'ForecastCard'
