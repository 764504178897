import React, { PureComponent } from 'react';
import bindAllActions from '../../common/bindAllActions';
import PropTypes from 'prop-types';
import { prepend, reverse, last, range, insert, filter, unnest, lift, join, lensIndex, over, flip, prop, intersection, pluck, concat, find, propEq, contains, pipe, flatten, map } from 'ramda'
import { timeFormatForUser, padData, isWindDir, getHlAvg, fcontains, removeFromArrWith, getSummaryDocDate, toggleArr, reduceSummaries, getLabel, getDisplayKeys, getDateTz, currentDeviceHasSummaries, convertUnitForUser, isSomething, getTheDevice, theDeviceIsMine, isAdmin, getDateTzForDevice, getSummaryDocDateGetDate, getSuffForUser } from '../../common/ambient'
import classNames from 'classnames'
import { QuestionMark, DatePicker } from '../../components';
import { getThemeObj } from '../../common/skinner.js';
import { convertUnitInverse } from '../../common/lib/ambient-weather-common/ambient';

const csvGetLabel = (device, user, k) => getLabel(k, device).replace('#', '') + (getSuffForUser(user, k) ? ' (' + getSuffForUser(user, k).replace(' ', '') + ')': '') 

const cleanCsvHeader = (label = '') => `"${label.replace(/"/g, '\\"')}"`

class ExportData extends PureComponent {
  static propTypes = {

  }
  state = {
    fetching: false,
    docs: [],
    days: []
  }
  constructor(props) {
    super(props)
    this._start = ::this._start
    this._cancel = ::this._cancel
    this._fetchDay = ::this._fetchDay
  }
  componentDidMount() {
    const { userActions, user, device } = this.props
    const { theDevice, start, end } = user.modal
    const getDate = getDateTzForDevice(moment, theDevice)
    const endDate = getDate(end)
    const startDate = start ? getDate(start) : getDate(moment(endDate).subtract(24, 'hours'))
    this.setState({
      macAddress: theDevice.macAddress,
      startDate,
      endDate,
      getDate
    })
  }
  downloadCsv(data) {
    const { user, device } = this.props
    const getDate = getDateTz(moment, device)
    const convertUnit = convertUnitForUser(user)
    const fprop = flip(prop)
    const keys = concat(['dateutc', 'dateutc'], getDisplayKeys(data).filter(key => key !== 'dateutc'))
    let didDate = false
    const headerRow = keys
      .map(k => {
        if (k === 'dateutc' && didDate) {
          return 'Simple Date'
        }
        didDate = true
        return csvGetLabel(device, user, k)
      })
      .map(cleanCsvHeader)
      .join(',')
    const dataRow = (obj) => {
      const getConvertedValue = (param) => {
        return pipe(
          fprop(obj),
          convertUnit(param),
          val => {
            if (isNaN(val)) {
              return ''
            }
            return val
          }
        )(param)
      }
      const row = keys.map(getConvertedValue)
      const formattedRow = pipe(
        over(lensIndex(0), d => getDate(d).format()),
        over(lensIndex(1), d => getDate(d).format('YYYY-MM-DD HH:mm:ss'))
      )(row)
      return formattedRow.join(',')
    }
    const dataRows = data.map(dataRow).reverse()
    const csv = 'data:text/csv;charset=utf-8,' + headerRow + "\n" + dataRows.join("\n")
    this._doDownload(csv, 'ambient-weather-' + getDate(data[0].dateutc).format('YYYYMMDD') + '-' + getDate(last(data).dateutc).format('YYYYMMDD'))
  }
  _docDate(doc) {
    return getSummaryDocDateGetDate(this.state.getDate, doc.dateutc)
  }
  downloadSummaryCsv(details) {
    const { getDate, startDate, endDate } = this.state
    const { user, device } = this.props
    const { deviceSummaries } = device
    const { theDevice } = user.modal
    const convertUnit = convertUnitForUser(user)
    return () => {
      const fprop = flip(prop)
      const dayFmt = 'YYYY-MM-DD'
      const allDocs = filter(doc => {
        const docDay = getDate(doc.dateutc)
        return startDate.valueOf() <= docDay.valueOf() && endDate.valueOf() >= docDay.valueOf()
      })(prepend(theDevice.lastData.hl, deviceSummaries))
      const keys = getDisplayKeys(deviceSummaries).filter(key => key !== 'dateutc')
      const headerRow = ',' + (details ? 'Date,' : '') + keys.map(k => csvGetLabel(device, user, k)).map(cleanCsvHeader).join(',')
      const getDocRows = doc => {
        const dataRows = [] 
        const getHlProp = p => {
          return (type) => {
            const hl = doc[type]
            if (!hl) return
            if (isWindDir(type)) return
            if(/.t/.test(p)) return getDate(hl[p]).format()
            return convertUnit(type, hl[p])
          }
        }
        const getConvertedHlAvg = (doc) => {
          return type => {
            const avg = getHlAvg(doc, type)
            if (isSomething(avg)) {
              return convertUnit(type, avg)
            }
          }
        }
        if (details) {
          const dayField = this._docDate(doc).format('YYYY-MM-DD')
          dataRows.push(['Average', dayField, ...keys.map(getConvertedHlAvg(doc))])
          dataRows.push(['High', dayField, ...keys.map(getHlProp('h'))])
          dataRows.push(['High Datetime', dayField, ...keys.map(getHlProp('ht'))])
          dataRows.push(['Low', dayField, ...keys.map(getHlProp('l'))])
          dataRows.push(['Low Datetime', dayField, ...keys.map(getHlProp('lt'))])
        } else {
          dataRows.push(['Average', ...keys.map(getConvertedHlAvg(doc))])
          dataRows.push(['High', ...keys.map(getHlProp('h'))])
          dataRows.push(['High Datetime', ...keys.map(getHlProp('ht'))])
          dataRows.push(['Low', ...keys.map(getHlProp('l'))])
          dataRows.push(['Low Datetime', ...keys.map(getHlProp('lt'))])
        }
        return dataRows
      }
      let title = 'ambient-weather-high-lows-'
      const fmt = 'YYYYMMDD'
      if (details) {
        title += 'details-'
      } else {
        title += 'summary-'
      }
      const dateRangeDocs = allDocs
      if (dateRangeDocs.length > 1) {
        title += this._docDate(dateRangeDocs[(dateRangeDocs.length - 1)]).format(fmt) + '-' + this._docDate(dateRangeDocs[0]).format(fmt)

      } else {
        title += this._docDate(dateRangeDocs[0]).format(fmt)
      }
      const allRows = unnest(allDocs.map(getDocRows))
      const stringRows = allRows.map(join(','))
      let csv = 'data:text/csv;charset=utf-8,' + headerRow + "\n" + stringRows.join("\n")
      this._doDownload(csv, title)
    }
  }
  _doDownload(csv, title) {
      const link = document.createElement('a')
      link.setAttribute('href', encodeURI(csv))
      link.setAttribute('download', title + '.csv')
      document.body.appendChild(link)
      link.click()
      this._cancel()
  }
  _fetchDay() {
    const { device, deviceActions } = this.props
    const { days, docs, macAddress } = this.state
    if (days.length < 1) {
      this.downloadCsv(flatten(docs))
      return this.setState({
        fetching: false
      })
    }
    const day = days.pop()
    deviceActions.fetchDeviceData({
      start: day[0],
      end: day[1],
      macAddress: macAddress,
      res: 1,
      limit: 3600
    })
      .then(data => {
        this.setState({
          docs: concat(docs, reverse(data)),
          days
        }, this._fetchDay)
      })
  }
  _lastDateWeHave() {
    const { device, user } = this.props
    const { deviceSummaries } = device
    const { theDevice } = user.modal
    return last(prepend(theDevice.lastData.hl, deviceSummaries)).dateutc
  }
  _start() {
    const { macAddress, startDate, endDate, getDate } = this.state
    const lastDayWeHave = this._lastDateWeHave()
    const start = startDate.valueOf() < lastDayWeHave ? getDate(lastDayWeHave).startOf('day') : startDate
    const dayCount = Math.abs(start.diff(endDate, 'days')) + 1
    const days = reverse(range(0, dayCount).map(i => {
      const day = moment(start).add(i, 'days' )
      return [day.valueOf(), day.endOf('day').valueOf()]
    }))
    this.setState({ 
      days, 
      startTime: Date.now(),
      fetching: true,
      docs: []
    }, this._fetchDay)
  }
  _cancel() {
    this.setState({
      fetching: false,
      days: [],
      docs: []
    })
    this.props.userActions.doModal('')
  }

  render() {
    const { userActions, user, device } = this.props
    const { startTime, getDate, startDate, endDate, fetching, days, docs } = this.state
    const { theDevice, start, end } = user.modal
    const lastDayWeHave = this._lastDateWeHave()
    const error = startDate && startDate.valueOf() < getDate(lastDayWeHave).startOf('day').valueOf()
    const fmt = 'MMM Do, YYYY'
    if (!startDate) return null
    let stepContent = <div>
      <a className="close export" onClick={this._cancel} />
      <h3>Export Data<QuestionMark link={getThemeObj().helpUrlBase + 'how-do-i-download-data-ambientweather-net/'} /></h3>
      <DatePicker 
        device={device} 
        start={startDate}
        end={endDate}
        getDate={getDate}
        onChange={dates => {
          this.setState({ startDate: dates[0], endDate: dates[1] })
        }}
      />
      {error ? <div className='estimated'>Range too great. This device has data starting {getDate(lastDayWeHave).format(fmt)}</div> : ''}
      <div className="btns">
        <a onClick={this._start.bind(this)} className="btn btn-primary">Export All Data</a>
        <a className="white btn" onClick={this.downloadSummaryCsv(true)}>Export Daily Summary</a>
      </div>
    </div>
    if (fetching) {
      const total = days.length * 288 + docs.length
      const day = last(days)
      const percent = docs.length / total * 100
      const perPage = (Date.now() - startTime) / docs.length 
      const est = perPage * days.length * 1000
      stepContent = <div>
        <b>{day ? 'Exported ' + startDate.format(fmt) + ' - ' + moment(day[0]).format(fmt) : 'Processing...'}</b>
        <div className='progress-wrap'>
          <div className="progress">
            <div className="blue progress-bar" style={{ width: (percent) + '%' }}></div>
          </div>
          <span>{Math.floor(percent) + '%'}</span>
        </div>
        <div className="estimated">{docs.length > 0 ? 'Estimated time remaining: ' + moment.duration(est).humanize() : ''}</div>
        <a className="btn white" onClick={this._cancel}>Cancel</a>
        <a onClick={() => this.downloadCsv(flatten(docs))}>Download partial file</a>
      </div>
    }
    return (
      <div className={classNames('device-export-data', { fetching, error })}>
        <div className="wrap">
          {stepContent}
        </div>
      </div>
    )
  }
}

export default bindAllActions(ExportData)
ExportData.displayName = 'ExportData'

ExportData.displayName = 'ExportData'

ExportData.displayName = 'ExportData'

ExportData.displayName = 'ExportData'

ExportData.displayName = 'ExportData'

ExportData.displayName = 'ExportData'

ExportData.displayName = 'ExportData'
