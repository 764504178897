import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import classNames from 'classnames'
import {
  FormattedDataPoint
} from '../features/device'

export default class DayForecast extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    forecast: PropTypes.object
  }

  render () {
    const { forecast, className, title = '' } = this.props
    if (!forecast) {
      return null
    }
    return (
      <div className={classNames('component-day-forecast', className, `title-${(typeof title === 'string' ? title : '').replace(' ', '-').toLowerCase()}`, {
        'precip-below-thresh': forecast.precipProbability < 0.1
      })}
      >
        <div className='title'>{title}</div>
        <div className='title-precip precip'><span className='drop'>{parseInt(forecast.precipProbability * 100, 10)}%</span></div>
        <div className={classNames('weather-icon', forecast.icon)} />
        <div className='hl'>
          <FormattedDataPoint type='tempf' value={forecast.temperatureMax} valueTransform={Math.round} after='°' />
          <FormattedDataPoint type='tempf' value={forecast.temperatureMin} valueTransform={Math.round} after='°' />
        </div>
        <div className={classNames('precip', forecast.icon)}>
          <span className='drop'>{parseInt(forecast.precipProbability * 100, 10)}%</span>
          {/* <FormattedDataPoint type="hourlyrainin" value={forecast.precipIntensityMax}  /> */}
        </div>
      </div>
    )
  }
}

DayForecast.displayName = 'DayForecast'

