import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { widgetExpanded, dataHas } from '../../common/ambient'
import FormattedDataPoint from './FormattedDataPoint'
import classNames from 'classnames'

export default class WindWidget extends PureComponent {
  static propTypes = {
    currentDevice: PropTypes.object.isRequired
  }

  state = {
    clicked: false
  }

  render () {
    const { currentDevice } = this.props
    const { clicked } = this.state
    const { lastData } = currentDevice
    const data = lastData
    let widget = <div className='wind-widget hidden' />
    const currentDataHas = dataHas(data)
    if (currentDataHas(['windspeedmph', 'winddir'])) {
      let expanded = ''
      if (widgetExpanded('wind', currentDevice)) {
        const expandeds = []
        if (currentDataHas(['winddir_avg2m']) || currentDataHas(['windspdmph_avg2m'])) {
          expandeds.push(
            <div className='avg avg-2' key={1}>
              <h3>2 Minute</h3>
              <div className='flex'>
                {currentDataHas(['winddir_avg2m']) ? <FormattedDataPoint type='winddir_avg2m' row={data} /> : null}
                {currentDataHas(['windspdmph_avg2m']) ? <FormattedDataPoint type='windspdmph_avg2m' row={data} /> : null}
              </div>
            </div>
          )
        }
        if (currentDataHas(['winddir_avg10m']) || currentDataHas(['windspdmph_avg10m'])) {
          expandeds.push(
            <div className='avg avg-10' key={2}>
              <h3>10 Minute</h3>
              <div className='flex'>
                {currentDataHas(['winddir_avg10m']) ? <FormattedDataPoint type='winddir_avg10m' row={data} /> : null}
                {currentDataHas(['windspdmph_avg10m']) ? <FormattedDataPoint type='windspdmph_avg10m' row={data} /> : null}
              </div>
            </div>
          )
        }
        if (expandeds.length > 0) {
          expanded = (
            <div className='expanded-wrap'>
              {expandeds}
            </div>
          )
        }
      }
      const winddir = data.winddir
      /* const winddir = 0 */
      const rot = `rotate(${winddir}deg)`
      const arrowStyle = {
        WebkitTransform: rot,
        MozTransform: rot,
        OTransform: rot,
        msTransform: rot,
        transform: rot
      }
      let gust = ''
      if (currentDataHas('windgustmph')) {
        gust = (
          <div className='corner-wrap right'>
            Gusts
            <FormattedDataPoint type='windgustmph' row={data} suff={' '} />
          </div>
        )
      }
      let dir = ''
      if (currentDataHas('winddir')) {
        dir = (
          <a onClick={() => this.setState({ clicked: !clicked })} className='corner-wrap'>
            From
            <FormattedDataPoint className='direction' type='winddir' row={data} />
            <FormattedDataPoint className='degrees' type='winddir' row={data} skipWindLabel />
          </a>
        )
      }
      widget = (
        <div className={classNames('wind-widget', { clicked })}>
          {expanded}
          <div className='rel'>
            <div className={data.maxdailygust ? 'val maxdailygust' : 'val'}>
              <FormattedDataPoint type='windspeedmph' row={data} suff={' '} />
              <div className='label'>
                <FormattedDataPoint type='windspeedmph' row={data} />
              </div>
              {data.maxdailygust
                ? <span className='max'><span className='peak'>Today's Peak:</span> <FormattedDataPoint type='maxdailygust' row={data} /></span>
                : ''}
            </div>
            <div className='compass' style={arrowStyle} />
          </div>
          {dir}
          {gust}
        </div>
      )
    }
    return widget
  }
}

WindWidget.displayName = 'WindWidget'
