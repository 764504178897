import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { contains } from 'ramda'
import bindUserActions from './redux/bindUserActions';
import { withRouter } from 'react-router'
import { getUrl, guid, OAUTH_CONFIG, getUrlQueryParam } from '../../common/ambient'


class OauthTile extends PureComponent {
  static propTypes = {
    user: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.approve = ::this.approve
    this.state = {
      redirected: false
    }
  }

  getService() {
    const { location } = this.props
    return OAUTH_CONFIG[getUrlQueryParam(location, 'client_id')]
  }

  getRedirect() {
    const { location } = this.props
    return getUrlQueryParam(location, `redirect_uri`) || this.getService().redirect
  }

  approve() {
    const { location } = this.props
    const { patch } = this.props.actions
    const { info } = this.props.user
    const oauth = info.oauth || {}
    const code = 'aw-' + guid()
    const key = this.getService().userOauthKey || this.getService().name.replace(' ', '-').toLowerCase()
    oauth[key] = code
    patch(info._id, { oauth })
    .then(() => {
      const redirected = this.getRedirect() + '?code=' + code + '&state=' + getUrlQueryParam(location, 'state') 
      this.setState({ redirected })
      window.open(redirected, '_self')
    })
  }
  render() {
    const { redirected } = this.state
    const { user, location } = this.props
    const { patchPending } = user
    const service = this.getService()
    console.log(service);
    let statement = `Authorize ${service.name} to access your account?`
    if (/google/i.test(service.name)) {
      statement = 'By signing in, you are authorizing Google to control your devices'
    }
    let button = <button onClick={this.approve} className="btn btn-primary">Authorize app</button>
    const approvalStep = <div>
      {button}
      <p>
        <a href={this.getRedirect() + '?error=access_denied'}>Cancel</a>
      </p>
      <div className="details">
        <p>
          <b>This Application will be able to:</b>
        </p>
        <ul>
          <li>Read your device names</li>
          <li>Access your devices' weather data</li>
        </ul>
        <p>
          <b>It will <em>not</em> be able to:</b>
        </p>
        <ul>
          <li>See your email address</li>
          <li>See your password</li>
        </ul>
      </div>
    </div>
    let show = approvalStep
    if (patchPending && contains('oauth', patchPending)) {
      show = <p>Ok, no problem...</p>
    } else if (redirected) {
      show = <p>Redirecting...if you're stuck <a href={redirected}>click here</a></p>
    }
    return (
      <div className="user-oauth-tile">
        <div className={'icon ' + service.name.replace(' ', '-').toLowerCase()} />
        <h2>{statement}</h2>
        {show}
      </div>
    )
  }
}

export default bindUserActions(withRouter(OauthTile))

OauthTile.displayName = 'OauthTile'
