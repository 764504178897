import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withRouter } from 'react-router'
import DashboardWrap from '../device/DashboardWrap'
import bindAllActions from '../../common/bindAllActions'

export class Dashboard extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  _handleRedirect () {
    const { user, match } = this.props
    const { fetchUserError } = user
    const { key } = match.params
    if (fetchUserError && !key) {
      this.props.history.replace('/')
    }
  }

  componentDidMount () {
    this._handleRedirect()
  }

  componentDidUpdate () {
    this._handleRedirect()
  }

  render () {
    return (
      <div className='user-dashboard'>
        <DashboardWrap params={this.props.params} />
      </div>
    )
  }
}

export default bindAllActions(withRouter(Dashboard))

Dashboard.displayName = 'Dashboard'
