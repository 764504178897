import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames'
import { dataHas } from '../../common/ambient'
import FormattedDataPoint from './FormattedDataPoint'

export default class SoilTensionWidget extends PureComponent {
  static propTypes = {
    currentDevice: PropTypes.object.isRequired,
    sensor: PropTypes.number.isRequired
  };

  render() {
    const { currentDevice, sensor } = this.props
    const { lastData } = currentDevice
    const currentDataHas = dataHas(lastData)
    const tempKey = `soiltemp${sensor}`
    const tensionKey = `soiltens${sensor}`
    const mains = []
    let add = ''
    let single = true 
    if (currentDataHas([tempKey])) {
      mains.push(
        <div key={1}>
          <div className="label">
            Temperature
          </div>
          <FormattedDataPoint type={tempKey} row={lastData} />
        </div>
      )
    }
    if (currentDataHas([tensionKey])) {
      single = false
      mains.push(
        <div key={2}>
          <div className="label">
            Tension
          </div>
          <FormattedDataPoint type={tensionKey} row={lastData} />
        </div>
      )
    }
    return (
      <div className={classNames('device-soil-tension-widget', { single })}>
        <div className="top">
          <div className="wrap">
            {mains}
          </div>
        </div>
      </div>
    );
  }
}


SoilTensionWidget.displayName = 'SoilTensionWidget'

SoilTensionWidget.displayName = 'SoilTensionWidget'

SoilTensionWidget.displayName = 'SoilTensionWidget'

SoilTensionWidget.displayName = 'SoilTensionWidget'

SoilTensionWidget.displayName = 'SoilTensionWidget'

SoilTensionWidget.displayName = 'SoilTensionWidget'

SoilTensionWidget.displayName = 'SoilTensionWidget'
