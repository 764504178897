import React, { PureComponent } from 'react';
import bindAllActions from '../common/bindAllActions'
import PropTypes from 'prop-types';
import { path, pick } from 'ramda'


/**
 * Used for legacy reasons. This has all the props you need
 * to share something. It passes them to the modal
 */
class Share extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    redirect: PropTypes.string,
    component: PropTypes.string,
    layerParam: PropTypes.string,
    paths: PropTypes.array,
    priv: PropTypes.object,
    post: PropTypes.object,
    onClick: PropTypes.func
  }
  state = {
  }
  render() {
    const { currentDevice, userActions } = this.props
    const thingsWeMightPassIn = [
      'layerParam', 
      'post', 
      'paths', 
      'title', 
      'component', 
      'description', 
      'redirect', 
      'priv', 
      'currentDevice'
    ]

    return (
      <span className="component-share">
        <a onClick={evt => {
          evt.preventDefault()
          const share = () => {
            userActions.doModal({
              type: 'external-share',
              data: pick(thingsWeMightPassIn, this.props)
            })
          }
          if (!currentDevice.public && !path(['info', 'slug'], currentDevice)) {
            userActions.doModal({
              type: 'toggle-public-device',
              data: {
                currentDevice,
                onClick: share
              }
            })
          } else {
            share()
          }
        }} className="share" ></a>
      </span>
    )
  }
}

export default bindAllActions(Share)
Share.displayName = 'Share'

Share.displayName = 'Share'

Share.displayName = 'Share'

Share.displayName = 'Share'

Share.displayName = 'Share'

Share.displayName = 'Share'

Share.displayName = 'Share'
