import {
  FETCH_USERS_BEGIN,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  FETCH_USERS_DISMISS_ERROR,
} from './constants';
import { serviceFind } from '../../../common/feathers'

export const fetchUsers = serviceFind('users', {
  begin: FETCH_USERS_BEGIN,
  success: FETCH_USERS_SUCCESS,
  fail: FETCH_USERS_FAILURE
})

export function dismissFetchUsersError() {
  return {
    type: FETCH_USERS_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FETCH_USERS_BEGIN:
      return {
        ...state,
        users: false,
        fetchUsersPending: true,
        fetchUsersError: null,
      };

    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        users: action.data,
        fetchUsersPending: false,
        fetchUsersError: null,
      };

    case FETCH_USERS_FAILURE:
      return {
        ...state,
        fetchUsersPending: false,
        fetchUsersError: action.data.error,
      };

    case FETCH_USERS_DISMISS_ERROR:
      return {
        ...state,
        fetchUsersError: null,
      };

    default:
      return state;
  }
}
