import {
  MANUAL_REFRESH,
} from './constants';

export function manualRefresh() {
  return {
    type: MANUAL_REFRESH,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case MANUAL_REFRESH:
      return {
        ...state,
        manualRefresh: Math.random(),
        graphDataRefined: false
      }
    default:
      return state;
  }
}
