import {
  LOGOUT_USER_BEGIN,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_FAILURE,
  LOGOUT_USER_DISMISS_ERROR,
} from './constants';
// import { browserHistory } from 'react-router'
import history from '../../../common/history'
import { app } from '../../../common/feathers'
import { isApp, reloadApp } from '../../../common/ambient'
import { path } from 'ramda'
import { patch } from './patch';

export function logoutUser(args) {
  return async (dispatch, getState) => {
    if (isApp()) {
      const { info, appDevice = {} } = getState().user
      const usersAppDevice = path(['appDevices', appDevice.uuid], info)
      if (appDevice && usersAppDevice && usersAppDevice.notificationsEnabled) {
        const appDevices = { ...info.appDevices }
        appDevices[appDevice.uuid].notificationsEnabled = false
        await dispatch(patch(info._id, { appDevices }))
      }
    }
    dispatch({
      type: LOGOUT_USER_BEGIN,
    });
    return app.ambientLogout()
      .then(() => {
        dispatch({
          type: LOGOUT_USER_SUCCESS,
          data: {
            user : {}
          },
        });
        dispatch({
          type : 'RESET_DEVICE'
        })
        history.push('/')
        reloadApp()
      })
      .catch((err) => {
        reloadApp()
        dispatch({
          type: LOGOUT_USER_FAILURE,
          data: {
            error: err,
          },
        });
      })
  };
}

export function dismissLogoutUserError() {
  return {
    type: LOGOUT_USER_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case LOGOUT_USER_BEGIN:
      return {
        ...state,
        logoutUserPending: true,
        logoutUserError: null,
      };

    case LOGOUT_USER_SUCCESS:
      return {
        ...state,
        info : false,
        logoutUserPending: false,
        logoutUserError: null,
      };

    case LOGOUT_USER_FAILURE:
      return {
        ...state,
        logoutUserPending: false,
        logoutUserError: action.data.error,
      };

    case LOGOUT_USER_DISMISS_ERROR:
      return {
        ...state,
        logoutUserError: null,
      };

    default:
      return state;
  }
}
