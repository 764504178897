import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import AwnSwitch from '../common/AwnSwitch'
import { Link } from 'react-router-dom'
import { isLoggedIn, getForecastParams, getUserSetting } from '../../common/ambient'


export default function ForecastSettings ({ user, userActions }) {
  const forecastParams = getForecastParams(user)
  const [flash, setFlash] = useState(false)
  useEffect(() => {
    if (flash) {
      setTimeout(() => {
        setFlash(false)
      }, 7000)
    }
  }, [flash])

  if (!isLoggedIn(user)) {
    return (
      <div className='user-forecast-settings create-account'>
        <header>
          <h1>Customize Your Forecast</h1>
          <p>
            Create a free account to customize you hourly forecast
          </p>
          <Link className='btn btn-primary' to='/welcome'>Create An Account</Link>
        </header>
      </div>
    )
  }

  return (
    <div className='user-forecast-settings'>
      <header>
        <h1>Customize your hourly weather details</h1>
        <p className={classNames('error', { flash })}>
          You must one have at least one forecast parameter enabled.
        </p>
      </header>

      {ForecastSettings.settings.map(setting =>
        <label key={setting}>
          <span>{setting}</span>
          <AwnSwitch
            onChange={(yes) => {
              const currentUserSettings = getUserSetting('forecast')(user) || {}
              currentUserSettings.params = forecastParams
              if (yes) {
                currentUserSettings.params.push(setting)
              } else {
                if (currentUserSettings.params.length === 1) {
                  setFlash(true)
                } else {
                  currentUserSettings.params = currentUserSettings.params.filter(s => s !== setting)
                }
              }
              userActions.updateSetting('forecast', currentUserSettings)
            }}
            checked={forecastParams.includes(setting)}
          />
        </label>
      )}
    </div>
  );
};

ForecastSettings.propTypes = {
  user: PropTypes.object.isRequired,
  userActions: PropTypes.object.isRequired,
}
ForecastSettings.defaultProps = {}

ForecastSettings.settings = [
  'Cloud Cover',
  'Temperature',
  'Precipitation',
  'Wind',
  'UV Index',
  'Humidity',
  'Pressure'
]

ForecastSettings.displayName = 'ForecastSettings'
