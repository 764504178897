import React, { useState, useEffect } from 'react'
import { billingGetUserCards, billingDeleteCard } from './billingAccess'
import { isLoggedIn } from '../../common/ambient'
// import PropTypes from 'prop-types';
import { path } from 'ramda'
import bindUserActions from '../user/redux/bindUserActions'
import { Loader } from '../../components'

function DisplayCard ({ user, actions }) {
  const [fetching, setFetching] = useState(false)
  const [userCards, setUserCards] = useState(null)
  const defaultPaymentMethod = path(['info', 'payments', 'defaultPaymentMethod'], user)

  useEffect(() => {
    if (!isLoggedIn(user)) return
    if (fetching) return
    if (!defaultPaymentMethod) return
    const uc = path(['cards'], userCards)
    if (uc && uc.length === 0) return
    const theCard = (uc || []).find(card => card.id === defaultPaymentMethod)
    if (theCard) return

    async function wrapBillingGetUserCards () {
      setFetching(true)
      const userId = path(['info', '_id'], user)
      const success = await billingGetUserCards(userId, setUserCards)
      if (!success) {
        console.log('¯\\_(ツ)_/¯   billingGetUserCards failed')
        return // stay in fetching mode so we dont keep trying
      }
      setFetching(false)
    }
    wrapBillingGetUserCards()
  }, [user, fetching, userCards, defaultPaymentMethod])

  if (!defaultPaymentMethod) return null

  let brand
  let exp_month
  let exp_year
  let last4 = ''
  let desc = 'You do not currently have any credit cards on file.'
  let hasCard = false
  if (userCards && userCards.cards && userCards.cards.length) {
    const { card } = userCards.cards[0]
    if (card) {
      hasCard = true
      // console.log('DisplayCard: card is')
      // console.log(card)
      brand = path(['brand'], card)
      brand = brand.charAt(0).toUpperCase() + brand.slice(1)
      exp_month = path(['exp_month'], card)
      exp_year = path(['exp_year'], card)
      last4 = path(['last4'], card)
      desc = 'Only one card may be kept on file to perform subscription auto-renewal.  If you would like to change cards you must delete this one first.  The change will affect all auto-renewing subscriptions.'
    }
  }

  async function delClicked (e) {
    e.preventDefault()
    if (!window.confirm("Your subscriptions will not renew if you don't have a card on file. Are you sure you want to delete this card?")) return
    const userId = path(['info', '_id'], user)
    const paymentId = path(['info', 'payments', 'defaultPaymentMethod'], user)
    if (!userId || !paymentId) return
    setFetching(true)
    const success = await billingDeleteCard(userId, paymentId)
    if (!success) {
      console.log('¯\\_(ツ)_/¯   billingDeleteCard failed')
    }
    delete user.info.payments.defaultPaymentMethod
    await actions.refreshUser()
    setFetching(false)
  }
  const delBtn = fetching ? <Loader /> : <a onClick={delClicked} className=''>Delete card</a>

  return (
    <div className='payment-display-card'>
      <div className='aw-fields'>
        <div className='item'>
          <label>Card Type</label>
          {brand}
        </div>
        <div className='item'>
          <label>Last 4</label>
          ****-****-****-{last4}
        </div>
        <div className='item'>
          <label>Exp</label>
          {exp_month} / {exp_year}
        </div>
      </div>
      <div>
        {desc}
      </div>
      <br />
      <div className='text-right'>{hasCard && delBtn}</div>
    </div>
  )
};

DisplayCard.propTypes = {}
DisplayCard.defaultProps = {}

export default bindUserActions(DisplayCard)

DisplayCard.displayName = 'DisplayCard'
