import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedDataPoint } from '.'
import { ResizeObserver } from '@juggle/resize-observer'
import classNames from 'classnames'

export default class LightningWidget extends PureComponent {
  static propTypes = {
    currentDevice: PropTypes.object
  }
  state = {

  }
  componentDidMount() {
    this.resizeObserver = new ResizeObserver((entries) => {
      this.setState({
        width: entries[0].contentRect.width
      })
    })
    this.resizeObserver.observe(this.ref);
  }
  componentWillUnmount() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  render() {
    const { width } = this.state
    const { lastData } = this.props.currentDevice
    const { lightning_day, lightning_hour, lightning_time } = lastData 
    return (
      <div className={classNames("device-lightning-widget", {
        lg: width > 350
      })} ref={ref => this.ref = ref} >
        <div className="top-wrap">
          <div className="top">
            <div className="val">{lightning_day}</div>
            <div className="labels">
              <div className="icon" />
              <div>Today</div>
            </div>
          </div>
        </div>
        <div className="bottom">
          <div>
            <div className="label">Hourly</div>
            <div className="val"><span className="icon">{lightning_hour}</span></div>
          </div>
          <div>
            <div className="label">Last Strike</div>
            <div className="val">{lightning_time ? moment(lightning_time).fromNow() : 'No report yet'}</div>
          </div>
          <div>
            <div className="label">Distance</div>
            <div className="val"><FormattedDataPoint type="lightning_distance" row={lastData} /></div>
          </div>
        </div>
      </div>
    );
  }
}

LightningWidget.displayName = 'LightningWidget'

LightningWidget.displayName = 'LightningWidget'

LightningWidget.displayName = 'LightningWidget'

LightningWidget.displayName = 'LightningWidget'

LightningWidget.displayName = 'LightningWidget'

LightningWidget.displayName = 'LightningWidget'

LightningWidget.displayName = 'LightningWidget'
