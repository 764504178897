import {
  REMOVE_ALERT_BEGIN,
  REMOVE_ALERT_SUCCESS,
  REMOVE_ALERT_FAILURE,
  REMOVE_ALERT_DISMISS_ERROR,
} from './constants';
import { app } from '../../../common/feathers'
import { removeFromArr } from '../../../common/ambient'

const alertService = app.service('alerts')

export function removeAlert (args) {
  return (dispatch) => {
    dispatch({
      type: REMOVE_ALERT_BEGIN,
      data: args
    });
    return alertService.remove(args._id)
      .then(function (res) {
        dispatch({
          type: REMOVE_ALERT_SUCCESS,
          data: args,
        });
      })
      .catch(function (err) {
        dispatch({
          type: REMOVE_ALERT_FAILURE,
          data: {
            error: err,
          },
        });
      })
  };
}

export function dismissRemoveAlertError() {
  return {
    type: REMOVE_ALERT_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case REMOVE_ALERT_BEGIN:
      return {
        ...state,
        removeAlertPending: true,
        removeAlertError: null,
      };

    case REMOVE_ALERT_SUCCESS:
      return {
        ...state,
        removeAlertPending: false,
        removeAlertError: null,
        alerts: removeFromArr(action.data, state.alerts)
      };

    case REMOVE_ALERT_FAILURE:
      return {
        ...state,
        removeAlertPending: false,
        removeAlertError: action.data.error,
      };

    case REMOVE_ALERT_DISMISS_ERROR:
      return {
        ...state,
        removeAlertError: null,
      };

    default:
      return state;
  }
}
