import {
  SET_UNITS,
} from './constants';
import { setStorage } from '../../../common/ambient';

export function setUnits(metric, storeUserUnits = true) {
  if (storeUserUnits) {
    setStorage('userUnits', metric)
  }
  return {
    type: SET_UNITS,
    data: metric
  }
}

export function reducer(state, action) {
  switch (action.type) {
    case SET_UNITS:
      return {
        ...state,
        userUnits: action.data
      }

    default:
      return state;
  }
}
