import React, { PureComponent } from 'react';
import classNames from 'classnames'
import PropTypes from 'prop-types';
import { map, filter, toPairs, values, join, append, mapObjIndexed, pipe, nth } from 'ramda'
import { AD_CONFIG } from '../common/ambient'
import { owise1 } from '../common/lib/ambient-weather-common'


const _adsSeen = {}
const getAdKey = (tags) => {
  const tagKey = tags.sort((a, b) => a.localeCompare(b)).join('') || 'all'
  const adsSeen = _adsSeen[tagKey] || []
  const adKeys = pipe(
    toPairs,
    filter(arr => owise1.doArraysIntersect(tags, arr[1].tags || [])),
    map(nth(0))
  )(AD_CONFIG)
  const availableAdKeys = adKeys.filter(k => !adsSeen.includes(k))
  const adKey = availableAdKeys[Math.floor(Math.random() * availableAdKeys.length)]
  const newAdsSeen = append(adKey, adsSeen)
  _adsSeen[tagKey] = newAdsSeen.length === adKeys.length ? [] : newAdsSeen
  return adKey
}

export default class GenericAd extends PureComponent {
  static propTypes = {
    query: PropTypes.object,
    tags: PropTypes.array
  }
  state = {
  }
  constructor(props) {
    super(props)
    this.state.adKey = getAdKey(props.tags || [])
  }

  render() {
    const { query } = this.props
    const { adKey } = this.state
    if (!adKey) return null
    const ad = AD_CONFIG[adKey] || {}
    let url = ad.url || `https://ambientweather.com/go/${adKey}`
    if (query) {
      url += '?' + pipe(
        mapObjIndexed((val, key) => `${key}=${val}`),
        values,
        join('&')
      )(query)
    }
    return (
      <a target='_blank' style={{
        backgroundImage: `url(https://ambientweather.net/ads/${adKey}.png)`
      }} href={url} className={classNames('component-generic-ad', adKey)} />
    )
  }
}

GenericAd.displayName = 'GenericAd'

GenericAd.displayName = 'GenericAd'

GenericAd.displayName = 'GenericAd'

GenericAd.displayName = 'GenericAd'

GenericAd.displayName = 'GenericAd'

GenericAd.displayName = 'GenericAd'

GenericAd.displayName = 'GenericAd'
