import React, { PureComponent } from 'react';
import Select from 'react-select'
import PropTypes from 'prop-types';
import { not, pipe, propEq, find } from 'ramda'
import { fcontains } from '../common/ambient'

const invalidZones = [ 'America/Argentina/ComodRivadavia',
  'America/Knox_IN',
  'Antarctica/DumontDUrville',
  'Antarctica/McMurdo',
  'Australia/ACT',
  'Australia/LHI',
  'Australia/NSW',
  'Brazil/DeNoronha',
  'CET',
  'CST6CDT',
  'Chile/EasterIsland',
  'Cuba',
  'EET',
  'EST',
  'EST5EDT',
  'Egypt',
  'Eire',
  'Etc/GMT0',
  'Etc/UCT',
  'GB',
  'GB-Eire',
  'GMT+0',
  'GMT-0',
  'GMT0',
  'Greenwich',
  'HST',
  'Hongkong',
  'Iceland',
  'Iran',
  'Israel',
  'Jamaica',
  'Japan',
  'Kwajalein',
  'Libya',
  'MET',
  'MST',
  'MST7MDT',
  'Mexico/BajaNorte',
  'Mexico/BajaSur',
  'America/Ciudad_Juarez',
  'NZ',
  'NZ-CHAT',
  'Navajo',
  'PRC',
  'PST8PDT',
  'Poland',
  'Portugal',
  'ROC',
  'ROK',
  'Singapore',
  'Turkey',
  'UCT',
  'US/Alaska',
  'US/Aleutian',
  'US/Arizona',
  'US/Central',
  'US/East-Indiana',
  'US/Eastern',
  'US/Hawaii',
  'US/Indiana-Starke',
  'US/Michigan',
  'US/Mountain',
  'US/Pacific',
  'US/Pacific-New',
  'US/Samoa',
  'Universal',
  'W-SU',
  'WET',
  'Zulu'
]

export default class TimezonePicker extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.string
  }
  state = {
    tzName: 'Etc/GMT',
    options: []
  }

  onChange () {
    const { onChange } = this.props
    const { tzName } = this.state
    if (onChange) {
      onChange(moment.tz.zone(tzName))
    }
  }
  componentWillUnmount(){
    this._mounted = false 
  }

  componentDidMount() {
    const { value } = this.props
    this._mounted = true

    if (!this._mounted) return
    this.setState({
      tzName: value || moment.tz.guess(),
      options: moment.tz.names().filter(pipe(fcontains(invalidZones), not)).map(name => ({
        label: name,
        value: moment.tz.zone(name)
      }))
    }, this.onChange.bind(this))
  }

  render() {
    const { value, onBlur, onFocus } = this.props
    const { options, tzName } = this.state
    return (
      <div className="component-timezone-picker">
        <Select
          className='tz-select-container'
          classNamePrefix='tz-select'
          onFocus={onFocus}
          onBlur={onBlur}
          styles={{
            option: (styles, { isSelected, isFocused }) => {
              return {
                ...styles,
                backgroundColor: isFocused ? '#09a8e6' : 'transparent',
                color: isFocused ? '#fff' : '#313236'
              }
            }
          }}
          options={options}
          value={find(propEq('label', value || tzName), options)}
          onChange={selected => {
            this.setState({
              tzName: selected.label
            }, this.onChange.bind(this))
          }}
        />
      </div>
    );
  }
}

TimezonePicker.displayName = 'TimezonePicker'
