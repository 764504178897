import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { getThemeObj } from '../common/skinner';

export default class HelpLinks extends PureComponent {
  static propTypes = {

  };

  render() {
    return (
      <div className="component-help-links help">
        {/* <a href="https://help.ambientweather.net" target="_blank">Help Center</a>
        <a href="https://www.facebook.com/groups/ambientweathernetwork/" target="_blank">Facebook Group</a> */}
        <a href={getThemeObj().helpUrl} target="_blank">Help Center</a>
        <a href={getThemeObj().facebookUrl} target="_blank">Facebook Group</a>
      </div>
    )
  }
}

HelpLinks.displayName = 'HelpLinks'

HelpLinks.displayName = 'HelpLinks'

HelpLinks.displayName = 'HelpLinks'

HelpLinks.displayName = 'HelpLinks'

HelpLinks.displayName = 'HelpLinks'

HelpLinks.displayName = 'HelpLinks'

HelpLinks.displayName = 'HelpLinks'
