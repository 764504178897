import classNames from 'classnames'
import React from 'react'
import { useDoModal } from '../user/redux/doModal'
// import PropTypes from 'prop-types';

const DEVICE_TYPES = [
  {
    id: 'WS-1965',
    title: 'Ambient Weather WS-1965'
  }, {
    id: 'WS-tbd',
    title: 'WS-2902/WS-2000'
  }, {
    id: 'WS-5000',
    title: 'Ambient Weather WS-5000'
  }, {
    id: 'WS-4000',
    title: 'Ambient Weather WS-4000'
  }, {
    id: 'AWX',
    title: 'AccuWeather Stations'
  }, {
    id: 'WS-1938',
    title: 'Ambient Weather WS-1938'
  }, {
    id: 'WEATHERBRIDGE',
    title: 'AWN Weather Bridge'
  }, {
    id: 'WEATHERBRIDGEPRO2',
    title: 'AWN Weather Bridge Pro 2'
  }, {
    id: 'OBSERVERIP2',
    title: 'AWN Weather Hub'
  }, {
    id: 'legacy',
    title: 'Legacy / Other'
  }
]

const AWX_DEVICE_TYPES = [
  {
    id: 'AWX-tbd',
    title: 'WS-2902-AW/WS-2000-AW'
  }, {
    id: 'WS-5000-AW',
    title: 'WS-5000-AW'
  }
]

const AWX_TBD_DEVICE_TYPES = [
  {
    id: 'WS-2902-AW',
    title: 'WS-2902-AW'
  }, {
    id: 'WS-2000-AW',
    title: 'WS-2000-AW'
  }
]

const TBD_DEVICE_TYPES = [
  {
    id: 'WS-2902',
    title: 'WS-2902'
  }, {
    id: 'WS-2000',
    title: 'WS-2000'
  }
]

export function TbdStationModal ({ onSuccess, types }) {
  const { doModal } = useDoModal()
  if (!types) types = TBD_DEVICE_TYPES
  return (
    <div className='device-tbd-station-modal'>
      <h1>Find your station model:</h1>
      <p>
        Check the back of your console's screen for the model number.
      </p>
      <img src={require('../../assets/device-types/station-model.jpg')} />
      <div className='btns'>
        {types.map((deviceType) => (
          <a
            onClick={() => {
              if (!onSuccess) return
              doModal(null)
              onSuccess(deviceType.id)
            }}
            className={classNames(deviceType.id, 'btn btn-primary')}
            key={deviceType.id}
          >
            {deviceType.title}
          </a>
        ))}
        <br />
        <a className='btn cancel' onClick={() => doModal(null)}>Cancel</a>
      </div>
    </div>
  )
}

export default function DeviceTypeChooser ({ onSuccess }) {
  const { doModal } = useDoModal()
  const [type, setType] = React.useState('awn')
  let types = DEVICE_TYPES
  let title = 'Select your device'
  let description = 'Please identify your weather station from the choices below. If you are unsure which station model you have, please check the back of your console.'
  if (type === 'awx') {
    title = 'Select your AccuWeather device'
    types = AWX_DEVICE_TYPES
  }
  return (
    <div className='device-device-type-chooser'>
      <h1>{title}</h1>
      <p>{description}</p>
      <div className='device-type-list'>
        {types.map((deviceType) => (
          <a
            onClick={() => {
              if (!onSuccess) return
              if (deviceType.id === 'AWX') {
                setType('awx')
                return
              }
              if (/tbd/.test(deviceType.id)) {
                doModal({
                  type: 'component',
                  data: <TbdStationModal onSuccess={onSuccess} types={type === 'awx' && AWX_TBD_DEVICE_TYPES} />
                })
              } else {
                onSuccess(deviceType.id)
              }
            }}
            className={classNames(deviceType.id)}
            key={deviceType.id}
          >
            {deviceType.title}
          </a>
        ))}
      </div>
      {type === 'awx' && (
        <div className='mt'>
          <a
            onClick={() => setType('awn')}
            className='btn cancel'
          >
            &lt;&lt; Back
          </a>
        </div>
      )}
    </div>
  )
};

DeviceTypeChooser.propTypes = {}
DeviceTypeChooser.defaultProps = {}

DeviceTypeChooser.displayName = 'DeviceTypeChooser'
