import React from 'react'
// import PropTypes from 'prop-types';
import DisplayCard from './DisplayCard'
import DisplaySubscription from './DisplaySubscription'
import { path } from 'ramda'
import { AllPayments, isPlus, plusSubHasCode } from '.'
import classNames from 'classnames'
import { isApp } from '../../common/ambient'

export default function ManageSubscriptions ({ user, userActions }) {
  const userId = path(['info', '_id'], user)
  let giftMessage = ''
  let buyMessage = 'Redeem AWN+ subscription gift code or Buy one for your family or friends.'
  const subscribedWithCode = plusSubHasCode(user)
  if (isPlus(user) && !subscribedWithCode) {
    giftMessage = 'Your free annual AWN+ gift subscription will be added on top of your current plan.'
  }
  if (subscribedWithCode) {
    buyMessage = 'Buy an AWN+ subscription gift code for your family or friends.'
    giftMessage = (
      <>
        <b className='blue'>You already have a free AWN+ gift subscription</b><br/>
        You will be able to activate a new gift code after your current gift subscription expires.
      </>
    )
  }

  return (
    <div className='payment-manage-subscriptions block'>
      {/* <h2>Manage Subscriptions</h2>
      <br /><br /> */}
      <h3>Credit Card on File</h3>
      <p>This card will be used if you choose the auto-renew option for any of your subscriptions.</p>
      <DisplayCard user={user} />
      <AllPayments userId={userId} mode='file' />

      <h3>Gift Codes</h3>
      <p>{buyMessage}</p>
      {giftMessage && <p>{giftMessage}</p>}
      <div className='btns'>
        <button
          disabled={subscribedWithCode}
          className={classNames('btn', { 'btn-primary': !subscribedWithCode })}
          onClick={() => userActions.doModal({ type: 'plus-subscription', data: {
            redeemCode: isPlus(user),
            stepTwo: !isPlus(user)
          } })}
        >Redeem Gift Code</button>
        {!isApp() && (
          <a className={classNames('btn', { 'btn-primary': subscribedWithCode })} target='_blank' href='https://ambientweather.com/awn-plus-annual-subscription'>Buy Gift Code</a>
        )}
      </div>

      <h3 id='subscriptions'>Your Subscriptions</h3>
      <p>Cancel your subscription at any time by turning off auto-renew.  You can turn auto-renew back on any time before the subscritpion expires.</p>
      <div className='subscription-list'>
        {/* {(hasCellularDevice(device) || (subs && (subs.length > 0))) ? <DisplaySubscription /> : "You currently have no subscriptions."} */}
        <DisplaySubscription />
      </div>

    </div>
  )
};

ManageSubscriptions.propTypes = {}
ManageSubscriptions.defaultProps = {}

ManageSubscriptions.displayName = 'ManageSubscriptions'
