import React, { PureComponent } from 'react';
import classNames from 'classnames'
import { Link, withRouter } from 'react-router-dom'
import Instances from '../alert/Instances'
import bindAllActions from '../../common/bindAllActions'
import PropTypes from 'prop-types';
import { filter, pipe, sort, groupBy, mapObjIndexed, values, pluck, uniq, find, propEq, path, length } from 'ramda'
import { dateVal, getDeviceSlug, pathsChanged } from '../../common/ambient'
import { 
  FromNow,
  Loader
 } from '../../components'

/**
 * User must be logged in to mount this component!
 */
class Notifications extends PureComponent {
  static propTypes = {
    socialActions: PropTypes.object

  }
  state = {
    tab: 'notifications',
    notifications: [],
    notificationLoading: false
  }
  constructor(props) {
    super(props)
    this._fetchSocialActivity = ::this._fetchSocialActivity
    this._close = ::this._close
    this.allUserActions = []
  }
  componentDidUpdate(prevProps, prevState) {
    const { device, userActions, user, commonActions } = this.props
    if (pathsChanged(this.props, prevProps, [['common', 'appClass']])) {
      if (this._isOpen()) {
        userActions.patch(user.info._id, { notificationsLastChecked: Date.now() })
        this._fetchSocialActivity()
      }
    }
    if (pathsChanged(this.props, prevProps, [['device', 'appPaused']])) {
      if (device.appPaused) {
        clearTimeout(this._tout)
      } else {
        this._fetchSocialActivity()
      }
    }
  }
  _isOpen() {
    return this.props.common.appClass.includes('notifications-open')
  }
  _close() {
    const { commonActions } = this.props
    commonActions.toggleAppClass('notifications-open', false)
  }
  _fetchSocialActivity(){
    const { alertActions, socialActions, user } = this.props
    const { lastChecked } = this.state
    clearTimeout(this._tout)
    this._tout= setTimeout(this._fetchSocialActivity, 1000 * 60 * 5)
    const minimum = Date.now() - 1000 * 60 * 60 * 24 * 14
    const args = {
      'to.userId': user.info._id,
      'to.type': 'post',
      $limit: 500
    }
    const aiArgs = {
      userId: path(['info', '_id'], user),
      $sort: { createdAt: -1 }
    }
    if (lastChecked) {
      args.createdAt = {
        $gt: lastChecked
      } 
      aiArgs.createdAt = {
        $gt: lastChecked
      }
    } else if (user.info.notificationsLastChecked) {
      const userLastChecked = dateVal(user.info.notificationsLastChecked)
      args.createdAt = {
        $gt: userLastChecked > minimum ? minimum : userLastChecked 
      } 
      aiArgs.createdAt = {
        $gt: userLastChecked
      }
    }
    this.setState({
      notificationLoading: true,
      lastChecked: Date.now()
    })
    alertActions.fetchInstances(aiArgs)
    socialActions.fetchUserActions(args)
      .then(actions => {
        const dateSort = sort((a,b) => dateVal(b.createdAt) - dateVal(a.createdAt))
        if (actions.data.length < 1) {
          const st8 = {
            notificationLoading: false,
          } 
          if (!lastChecked) {
            st8.tab = 'alerts'
          }
          this.setState(st8)
          throw 'skip the rest'
        }
        this.allUserActions = this.allUserActions.concat(actions.data)
        this.notificationData = pipe(
          groupBy(ua => `${ua.to._id}-${ua.type}`),
          mapObjIndexed((arr, key) => {
            return {
              key,
              createdAt: dateSort(arr)[0].createdAt, // the most recent userAction
              type: key.split('-')[1],
              postId: arr[0].to._id,
              arr
            }
          }),
          values,
          dateSort
        )(this.allUserActions)
        return socialActions.fetchPosts({
          _id: {
            $in: uniq(pluck('postId', this.notificationData)) 
          }
        })
      })
        .then(posts => {
          const notifications = this.notificationData.map(nd => {
            return Object.assign({
              post: find(propEq('_id', nd.postId), posts.data) 
            }, nd)
          }).filter(nd => nd.post)
          const st8 = {
            notificationLoading: false,
            notifications,
          }
          this.setState(st8)
        })
        .catch(e => {
        })
  }
  componentDidMount() {
    this._fetchSocialActivity()
  }
  componentWillUnmount() {
    clearInterval(this._int)
  }
  _tab() {
    const { deviceActions, device, history } = this.props
    const { tab, notifications } = this.state
    const typeDivs = {
      like: nd => {
        return <div className='like'>
          {nd.post.likes} {nd.post.likes === 1 ? 'person' : 'people'} liked your post.
        </div>
      },
      comment: nd => {
        const firstUserId = nd.arr[0].userId
        const others = pipe(
          pluck('userId'),
          uniq,
          filter(uId => uId !== firstUserId),
          length
        )(nd.arr)
        return <div className='comment'>
          {nd.arr[0].data.username} {others > 0 ? `and ${others} other${others === 1 ? '' : 's'}` : ''} commented on your post.
        </div>
      }
    }
    const ret = []
    if (tab === 'alerts' && this._isOpen()) {
      ret.push(
        <Link key="alerts" onClick={this._close} to="/alerts" className='alerts-tab'><Instances /></Link>
      )
    }
    ret.push(
      <div key={`notifications-${this._isOpen() && 'open'}`} className='notifications-tab'>
        {notifications.map(nd => {
          const theDevice = find(propEq('_id', nd.post.deviceId), device.devices || [])
          return <Link onClick={() => {
            this._close()
            // you need these two steps to change the dashboard
            deviceActions.setDashboardDevice(theDevice)
            history.push(`/dashboard/${getDeviceSlug(theDevice)}/social/${nd.post._id}`)

          }} key={nd.key} className='notification'>
            {typeDivs[nd.type](nd)}
            <FromNow dateutc={nd.createdAt} />
          </Link>
        })}
      </div>
    )
    return ret
  }
  _showBadge() {
    const { user, alert } = this.props
    const { alertInstances } = alert 
    const { tab, notifications, notificationLoading } = this.state
    const  notificationsLastChecked = path(['info', 'notificationsLastChecked'], user)
    if (!notificationsLastChecked && (notifications.length > 0 || alertInstances.length > 0)) {
      return true
    }
    const moreRecentThings = filter(nd => dateVal(nd.createdAt) > dateVal(notificationsLastChecked))
    if (moreRecentThings(notifications).length > 0) {
      return true
    }
    if (moreRecentThings(alertInstances).length > 0) {
      return true
    }
    return false
  }
  render() {
    const { user, alert, commonActions } = this.props
    const { fetchInstancesPending } = alert
    const { tab, notifications, notificationLoading } = this.state
    const  notificationsLastChecked = path(['info', 'notificationsLastChecked'], user)
    const loading = fetchInstancesPending || notificationLoading
    return (
      <div className={classNames('social-notifications', { open: this._isOpen(), active: this._showBadge() })}>
        <a onClick={() => commonActions.toggleAppClass('notifications-open', !this._isOpen())} className='notifications' />
        <div className='notifications-wrap'>
          <div className='top'>
            <div className='pills'>
              <a onClick={() => this.setState({ tab: 'notifications' })} className={classNames('notifications-btn btn btn-circle', { active: tab === 'notifications' })}>Notifications</a>
              <a onClick={() => this.setState({ tab: 'alerts' })} className={classNames('alerts-btn btn btn-circle', { active: tab !== 'notifications' })}>Alerts</a>
            </div>
            <div className='right'>
              {loading && <Loader />}
              <Link onClick={this._close} to="/alerts" className='settings'></Link>
              <a className='close' onClick={this._close}></a>
            </div>
          </div>
          <div className='notifications-list-wrap'>
            <div className={classNames('notifications-list', `tab-${tab}`)}>
              {this._tab()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default bindAllActions(withRouter(Notifications))

Notifications.displayName = 'Notifications'
