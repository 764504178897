import {
  CREATE_SHORT_URL_BEGIN,
  CREATE_SHORT_URL_SUCCESS,
  CREATE_SHORT_URL_FAILURE,
  CREATE_SHORT_URL_DISMISS_ERROR,
} from './constants';
import { serviceCreate } from '../../../common/feathers'

export const createShortUrl = serviceCreate('shares', {
  begin: CREATE_SHORT_URL_BEGIN,
  success: CREATE_SHORT_URL_SUCCESS,
  fail: CREATE_SHORT_URL_FAILURE
})

export function dismissCreateShortUrlError() {
  return {
    type: CREATE_SHORT_URL_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CREATE_SHORT_URL_BEGIN:
      return {
        ...state,
        createShortUrlPending: true,
        createShortUrlError: null,
      };

    case CREATE_SHORT_URL_SUCCESS:
      return {
        ...state,
        createShortUrlPending: false,
        createShortUrlError: null,
      };

    case CREATE_SHORT_URL_FAILURE:
      return {
        ...state,
        createShortUrlPending: false,
        createShortUrlError: action.data.error,
      };

    case CREATE_SHORT_URL_DISMISS_ERROR:
      return {
        ...state,
        createShortUrlError: null,
      };

    default:
      return state;
  }
}
