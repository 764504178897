import PropTypes from 'prop-types';
import { pick, path } from 'ramda';
import React, { PureComponent } from 'react';
import classNames from 'classnames'
import { RainWidget, TempWidget, WindWidget, HumidityWidget, SoilWidget, Air2Widget, PressureWidget, UvWidget, SolarWidget, LightningWidget, IndoorWidget, MapWidget, SunMoonWidget, Co2Widget } from '.';
import { getDateTzForDevice, getWidgetTitle } from '../../common/ambient';
import {
  ForecastLongTerm
} from '../../components'

/**
 * Kind of like a big switch statement for widgets
 */
export default class EveryWidget extends PureComponent {
  static propTypes = {
    type: PropTypes.string,
    snapshot: PropTypes.bool
  }

  render() {
    const { snapshot, type, currentDevice, forecast } = this.props
    const widgetProps = pick(['currentDevice', 'forecast', 'user', 'device'], this.props)
    const dateutc = path(['lastData', 'dateutc'], currentDevice) 
    let show, snapshotShow, widgetTitle
    // tempf or temp
    show = <TempWidget slim {...widgetProps} />
    if (type === 'rain') {
      show = <RainWidget {...widgetProps} />
    } else if (type === 'wind') {
      show = <WindWidget {...widgetProps} />
    } else if (type === 'humidity') {
      show = <HumidityWidget {...widgetProps} />
    } else if (type === 'co2') {
      show = <Co2Widget {...widgetProps} />
    } else if (type === 'aqin') {
      show = <Air2Widget {...widgetProps} type='aqin' />
    } else if (type === 'air') {
      show = <Air2Widget {...widgetProps} type='air' />
    } else if (type === 'airin') {
      show = <Air2Widget type={'pm25_in'} {...widgetProps} type='airin' />
    } else if (type === 'uv') {
      show = <UvWidget {...widgetProps} />
    } else if (type === 'pressure') {
      show = <PressureWidget {...widgetProps} />
    }else if (type === 'solar') {
      show = <SolarWidget {...widgetProps} />
    } else if (/temp\d0?f/.test(type)) {
      show = <IndoorWidget {...widgetProps} sensor={type.match(/temp(\d0?)f/)[1]} />
    } else if (type === 'indoor') {
      show = <IndoorWidget {...widgetProps} />
    } else if (type === 'lightning') {
      show = <LightningWidget {...widgetProps} />
    } else if (/forecast/.test(type)) {
      show = <ForecastLongTerm now={dateutc} {...widgetProps} />
    } else if (type === 'map') {
      show = <MapWidget {...widgetProps} />
    } else if (/soiltemp/.test(type)) {
      show = <SoilWidget {...widgetProps} sensor={type.match(/\d0?/)[0]} />
    } else if (type === 'sunMoon') {
      show = <SunMoonWidget {...widgetProps} />
    }
    if (snapshot) {
      if (dateutc) {
        snapshotShow = <div className='snapshot'>{`captured ${getDateTzForDevice(moment, currentDevice, dateutc).format('MMM D, YYYY h:mma')}`}</div> 
      }
      widgetTitle = <div className='widget-title'><span>{getWidgetTitle(currentDevice, type)}</span></div> 
    }
    return (
      <div className={classNames("device-every-widget", type)}>
        {snapshotShow}
        {show}
        {widgetTitle}
      </div>
    )
  }
}

EveryWidget.displayName = 'EveryWidget'

EveryWidget.displayName = 'EveryWidget'

EveryWidget.displayName = 'EveryWidget'

EveryWidget.displayName = 'EveryWidget'

EveryWidget.displayName = 'EveryWidget'

EveryWidget.displayName = 'EveryWidget'

EveryWidget.displayName = 'EveryWidget'
