import React, { useEffect } from 'react'
import { DeviceChooser, Loader } from '../../components'
import { useGetDevice } from './redux/getDevice'
import { useDashboardDevice, useSetDashboardDevice } from './redux/setDashboardDevice'
import { canWxwWidgetGoHere, getAvailWidgets, getWxwWidgetSize, widgetUsesDefaultLocation } from '../../common/ambient'
import PropTypes from 'prop-types';
import classNames from 'classnames'

export default function DeviceWidgetChooser ({ onCancel, onSave, widget = {}, layout, i }) {
  const [currentDevice, setCurrentDevice] = React.useState()
  const [loaded, setLoaded] = React.useState(false)
  const [macAddress, setMacAddress] = React.useState(widget.device)
  const [title, setTitle] = React.useState(widget.title || '')
  const [type, setType] = React.useState(widget.type)
  const { getDevice } = useGetDevice()
  const { dashboardDevice } = useDashboardDevice()
  const { setDashboardDevice } = useSetDashboardDevice()

  useEffect(() => {
    if (currentDevice) {
      return
    }
    if (!macAddress) {
      setLoaded(true)
      if (dashboardDevice) {
        setCurrentDevice(dashboardDevice)
      }
      return
    }
    getDevice(macAddress)
      .then(d => {
        setDashboardDevice(d)
        setCurrentDevice(d)
        setLoaded(true)
      })
  }, [setCurrentDevice, getDevice, macAddress, setDashboardDevice, setLoaded, currentDevice, dashboardDevice])
  if (!loaded) {
    return <Loader />
  }
  let saveOk = true
  const availWidgetTypes = getAvailWidgets(currentDevice)
  Object.keys(availWidgetTypes).forEach(key => {
    if (!canWxwWidgetGoHere(layout, key, i)) {
      delete availWidgetTypes[key]
    }
  })
  let select = (
    <select
      className='form-control'
      onChange={evt => {
        if (evt.target.value) {
          setTitle(availWidgetTypes[evt.target.value].title)
          setType(evt.target.value)
        }
      }}
      value={type}
    >
      <option value=''>Select a Tile</option>
      {Object.keys(availWidgetTypes).map(key => <option key={key} value={key}>{availWidgetTypes[key].title}</option>)}
    </select>
  )
  if (Object.keys(availWidgetTypes).length === 0) {
    select = <div>No tiles available for this device</div>
    saveOk = false
  }
  let station = (
    <DeviceChooser
      onChange={dev => {
        setMacAddress(dev.macAddress)
        setCurrentDevice()
      }}
    />
  )
  if (widgetUsesDefaultLocation(type)) {
    station = <div style={{ width: '100%' }}>This tile will display the forecast set for your Weather Window.</div>
  }
  return (
    <div className='device-device-widget-chooser'>
      <h3>Tile Details</h3>
      {type && (
        <>
          <label>Tile Label</label>
          <input type='text' className='form-control' value={title} onChange={evt => setTitle(evt.target.value)} />
        </>
      )}
      <label>Station</label>
      {station}
      <label>Tile</label>
      {select}
      {type && <div className='widget-preview-wrap'>Example:<br/><div className={classNames('widget-preview', type, getWxwWidgetSize(layout, i))} /></div>}
      <div className='btns'>
        <a
          disabled={!saveOk}
          className='btn btn-primary' onClick={() => {
            if (!type) {
              if (onCancel) {
                onCancel()
              }
              return
            }
            if (onSave) {
              onSave({
                device: currentDevice.macAddress,
                title,
                type
              })
            }
          }}
        >
          Save
        </a>
        <a
          className='btn' onClick={() => {
            if (onCancel) {
              onCancel()
            }
          }}
        >
          Cancel
        </a>
      </div>
    </div>
  )
};

DeviceWidgetChooser.propTypes = {
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
}
DeviceWidgetChooser.defaultProps = {}

DeviceWidgetChooser.displayName = 'DeviceWidgetChooser'
