import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom'
import { slice, map, path, split, take, join, splitEvery, replace, pipe, toUpper, last } from 'ramda'
import { getUrlQueryParam } from '../../common/ambient';


class MacAddressInput extends PureComponent {
  static propTypes = {

  }
  constructor(props) {
    super(props)
    this.changed = ::this.changed
    this.state = {
      macAddress: (props.value || getUrlQueryParam(this.props.location, 'macAddress') || getUrlQueryParam(this.props.location, 'mac') || '').toUpperCase()
    }
    this.onChange = props.onChange
    this._cleanAddr = ::this._cleanAddr
  }
  _cleanAddr(evt) {
    const { macAddress } = this.state
    const newMacAddress = pipe(
      path(['target', 'value']),
      toUpper,
      replace(/[O]/g, '0'),
      replace(/[LI]/g, '1'),
      newMacAddress => {
        // backspacing 
        if (last(macAddress) === ':' && newMacAddress.length < macAddress.length) {
          return slice(0, -1, newMacAddress)
        }
        return newMacAddress
      },
      replace(/[^A-F0-9]/g, ''),
      splitEvery(2),
      map(p => p.length === 2 ? p + ':' : p),
      join(''),
      split(''),
      take(17),
      join('')
    )(evt)
    this.setState({
      macAddress: newMacAddress
    })
  }
  componentDidMount() {
    this.changed()
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.macAddress !== this.state.macAddress) this.changed()
  }
  changed() {
    if (this.onChange) {
      this.onChange({
        macAddress: this.state.macAddress,
        isValid: this.state.macAddress.length === 17
      })
    }
  }
  clear() {
    this.setState({ macAddress: '' })
  }
  render() {
    const { className } = this.props
    return (
        <input type="text" value={this.state.macAddress} className={className} onChange={this._cleanAddr} placeholder="00:00:AA:0A:00:0A" />
    )
  }
}
export default withRouter(MacAddressInput)

MacAddressInput.displayName = 'MacAddressInput'
