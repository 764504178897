import {
  DefaultPage,
} from './index';

export default {
  path: 'alerts',
  childRoutes: [
    { path: '', component: DefaultPage, isIndex: true },
  ],
};
