import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { last, allPass, find, pathEq, prepend, identity, concat } from 'ramda'
import { getUserSetting, getLabel, isLoggedIn, getTheDevice, dataHasAny, setStorage, getStorage, theDeviceIsMine, getLabelForDevice } from '../common/ambient'
import { getThemeObj } from '../common/skinner'

import { Link } from 'react-router-dom'
import { app } from '../common/feathers'
import {
  AlertBanner
} from '../features/device'

export default class SiteAlerts extends PureComponent {
  static propTypes = {
    user: PropTypes.object.isRequired,
    device: PropTypes.object,
    alertActions: PropTypes.object,
  }
  state = {
    alertObj: {}
  }

  constructor(props) {
    super(props)
    this.checkAlert = ::this.checkAlert
    this._fetchLeakAlertInstances = ::this._fetchLeakAlertInstances
  }
  componentDidMount() {
    this.checkAlert()
    this._fetchLeakAlertInstances()
  }
  componentDidUpdate() {
    this.checkAlert()
    this._fetchLeakAlertInstances()
  }

  checkAlert() {
    const { user, device } = this.props
    if (isLoggedIn(user) && !this.alertObjFetched) {
      this.alertObjFetched = true
      app.service('siteStats').find({ query : { key: getThemeObj().token === 'aw' ? 'alert' : `alert-${getThemeObj().token}` } })
      .then((ssRes) => {
        this.setState({ alertObj: (ssRes.data[0] || {}).body || {} })
      })
    }
  }
  _fetchLeakAlertInstances() {
    const { fetchedLeakAlertInstances, lastLeakId } = this.state
    const { device, alertActions, user } = this.props
    const howLong = 1000 * 60 * 60 // hour
    if (isLoggedIn(user) 
      && this._leakingParams() 
      && (lastLeakId !== this._leakingParamsId() || Date.now() - fetchedLeakAlertInstances > howLong)) {
      this.setState({
        fetchedLeakAlertInstances: Date.now(),
        lastLeakId: this._leakingParamsId()
      }, () => {
        const theDevice = getTheDevice(device)
        alertActions.fetchInstances({
          deviceId: theDevice._id,
          $sort: { createdAt: - 1 },
          $limit: 50,
          skipCache: true
        })
          .then(res => {
            this.setState({
              alertInstances: res.data
            })
          })
      })
    }

  }
  _dismissButton(btnId) {
    return <a key={btnId} className="dismiss" onClick={() => {
      setStorage(btnId, true)
      this.forceUpdate()
    }}>Acknowledge</a>
  }
  _leakingParams() {
    const { device } = this.props
    const dev = getTheDevice(device)
    const leakParams = ['leak1', 'leak2', 'leak3', 'leak4'] 
    if (!dev || !dev.lastData || !dataHasAny(dev.lastData, leakParams) || !theDeviceIsMine(device)) {
      return
    }
    const alerts = leakParams.filter(param => {
      const val = dev.lastData[param]
      return val === 1 || val === 2
    })
    return alerts.length < 1 ? false : alerts
  }
  // unique string that identifies this combination of leaking things
  _leakingParamsId() {
    if (!this._leakingParams()) { 
      return ''
    }
    const { device } = this.props
    const dev = getTheDevice(device)
    return this._leakingParams().map(param => param + dev.lastData[param]).join('')
  }
  _leakAlerts() {
    const { alertInstances } = this.state
    const { user, device } = this.props
    const { devices, deviceSummaries } = device
    const dev = getTheDevice(device)
    const leakParams = this._leakingParams() 
    if (!leakParams) {
      return null
    }
    const alertSettings = getUserSetting('alerts')(user) || {}
    return leakParams.map(param => {
      const val = dev.lastData[param]
      let message, className, fromNow, btnId
      const children = []
      if (alertInstances && alertInstances.length > 0) {
        const mostRecentAi = find(allPass([pathEq(['origAlert', 'param'], param), pathEq(['origAlert', 'threshold'], val)]), alertInstances)
        if (mostRecentAi) {
          // offline alerts happen 10 mins after the sensor is offline
          const timeToUse = val === 2 ? moment(mostRecentAi.createdAt).subtract(10, 'm') : moment(mostRecentAi.createdAt)
          fromNow = ', ' + timeToUse.fromNow()
          btnId = mostRecentAi._id
        // it's not in the last 50 alertInstances
        } else if (alertInstances.length > 0) {
          fromNow = ', more than ' + moment(last(alertInstances).createdAt).fromNow()
          btnId = param + '-' + val
        }
      }
      if (val === 1) {
        message = <div><b>Leak Detected:</b> {getLabel(param, device)}{fromNow}</div>
        className = 'error'
        // figure out when it happened
      } else if (val === 2) {
        message = <div><b>Leak Offline:</b> {getLabel(param, device)}{fromNow}</div>
        className = 'warn offline'
      }
      // get alerts button
      if(!alertSettings.type || alertSettings.type === 'none') {
        children.push(<Link to="/alerts" key="alerts">Get SMS & Email Alerts</Link>)
      }
      // have they dismissed this banner
      if (btnId) {
        if (getStorage(btnId)) {
          message = null
        } else {
          children.push(this._dismissButton(btnId))
        }
      }
      if (message) {
        return <AlertBanner key={param} message={message} className={className}>
            {children}
          </AlertBanner>
      }
    })
  }

  render() {
    const { alertObj } = this.state
    let alerts = []
    if (alertObj.active) {
      const btnId = 'site-' + alertObj.message
      if (!getStorage(btnId)) {
        alerts.push(
          <AlertBanner key="alert" {...alertObj} >
            {this._dismissButton(btnId)}
          </AlertBanner>
        )
      }
    }
    if (this._leakAlerts()) {
      alerts = concat(alerts, this._leakAlerts())
    }
    if (alerts.length < 1) {
      return null
    }
    return (
      <div className="component-site-alerts">
        {alerts}
      </div>
    );
  }
}


SiteAlerts.displayName = 'SiteAlerts'

SiteAlerts.displayName = 'SiteAlerts'

SiteAlerts.displayName = 'SiteAlerts'

SiteAlerts.displayName = 'SiteAlerts'

SiteAlerts.displayName = 'SiteAlerts'

SiteAlerts.displayName = 'SiteAlerts'

SiteAlerts.displayName = 'SiteAlerts'
