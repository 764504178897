import { uniq } from 'ramda'
import { getMasterUnitParam } from './'


export const GRAPH_TYPES = {
  multiDevice: {
    name: 'Multi Station',
    description: 'Compare a single type of measurement at different stations'
  },
  multiParam: {
    name: 'Multi Parameter',
    description: 'Compare different sensors at one station'
  }
}

export const getGraphMasterParams = (graph) => {
  return uniq(graph.params.map(({ param }) => getMasterUnitParam(param) || param))
}

export const CACHE_DATA = true

export const GRAPH_COLORS = [
  '#15A7E2',
  '#57A709',
  '#E07D5A',
  '#9D3EC3',
  '#EA47DF',
  '#6C74E8',
  '#E5514D',
  '#99972B'
]
