import {
  FETCH_IFTTT_ALERTS_BEGIN,
  FETCH_IFTTT_ALERTS_SUCCESS,
  FETCH_IFTTT_ALERTS_FAILURE,
  FETCH_IFTTT_ALERTS_DISMISS_ERROR,
} from './constants';
import { serviceFind } from '../../../common/feathers'

export const fetchIftttAlerts = serviceFind('alerts', {
  begin: FETCH_IFTTT_ALERTS_BEGIN,
  success: FETCH_IFTTT_ALERTS_SUCCESS,
  fail: FETCH_IFTTT_ALERTS_FAILURE
})

export function dismissFetchIftttAlertsError() {
  return {
    type: FETCH_IFTTT_ALERTS_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FETCH_IFTTT_ALERTS_BEGIN:
      return {
        ...state,
        fetchIftttAlertsPending: true,
        fetchIftttAlertsError: null,
        iftttAlerts: [],
      };

    case FETCH_IFTTT_ALERTS_SUCCESS:
      return {
        ...state,
        fetchIftttAlertsPending: false,
        fetchIftttAlertsError: null,
        iftttAlerts: action.data,
      };

    case FETCH_IFTTT_ALERTS_FAILURE:
      return {
        ...state,
        fetchIftttAlertsPending: false,
        fetchIftttAlertsError: action.data.error,
        iftttAlerts: false
      };

    case FETCH_IFTTT_ALERTS_DISMISS_ERROR:
      return {
        ...state,
        fetchIftttAlertsError: null,
      };

    default:
      return state;
  }
}
