import React, { PureComponent } from 'react'
import { summaryDocsForDates, reduceSummariesForType, convertUnitForUser, getTheDevice } from '../../common/ambient'
import PropTypes from 'prop-types'
import { append, path, map, filter, pipe } from 'ramda'
import PoleGraph from '../../components/PoleGraph'

export default class HighLowGraphs extends PureComponent {
  static propTypes = {
    user: PropTypes.object,
    currentDevice: PropTypes.object,
    fetchDeviceData: PropTypes.func,
    device: PropTypes.object,
    type: PropTypes.string,
    format: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.state = {
      now: false
    }
  }

  _dataKey (label) {
    const { type } = this.props
    return type + 'HighLowGraphs' + label
  }

  _summaryLines () {
    const { format, device, user, type } = this.props
    const { deviceSummaries } = device
    if (!deviceSummaries) return
    const theDevice = getTheDevice(device)
    if (!path(['lastData', 'hl'], theDevice)) return
    const stat = theDevice.lastData.hl[type]
    if (!stat) return
    const convert = convertUnitForUser(user, type)
    const filterSummaryDocsByTime = (t) => {
      return filter(summary => summary.dateutc >= t)
    }
    const getStatsForTime = t => {
      const timeErr = 1000 * 60 * 30 // 30 mins
      return pipe(
        filterSummaryDocsByTime(t - timeErr),
        reduceSummariesForType(type)
      )
    }
    const retInfo = [
      {
        label: 'today',
        stats: stat
      }
    ]
    const allDocs = append(theDevice.lastData.hl, deviceSummaries)

    const nowTz = moment.tz(Date.now(), theDevice.tz.name)
    const yesterdayDoc = summaryDocsForDates(moment, device, nowTz.clone().subtract(1, 'days').startOf('day'), allDocs)[0]
    if (yesterdayDoc) {
      const yesterdayStats = yesterdayDoc[type]
      if (yesterdayStats && yesterdayStats.c > 0) {
        retInfo.push({
          label: 'yesterday',
          stats: yesterdayStats
        })
      }
    }
    const weekStats = getStatsForTime(nowTz.clone().startOf('week').valueOf())(allDocs)
    if (weekStats.c > 0) {
      retInfo.push({
        label: 'week',
        stats: weekStats
      })
    }
    const monthStats = getStatsForTime(nowTz.clone().startOf('month').valueOf())(allDocs)
    if (monthStats.c > 0) {
      retInfo.push({
        label: 'month',
        stats: monthStats
      })
    }
    const yearStats = getStatsForTime(nowTz.clone().startOf('year').valueOf())(allDocs)
    if (yearStats.c > 0) {
      retInfo.push({
        label: 'year',
        stats: yearStats
      })
    }
    const statsMax = Math.max(...map(pipe(path(['stats', 'h']), convert), retInfo))
    const statsMin = Math.min(...map(pipe(path(['stats', 'l']), convert), retInfo))
    return retInfo.map(info => {
      return format
        ? format({
          key: type + info.label,
          label: info.label,
          stat: {
            count: info.stats.c,
            sum: info.stats.s,
            max: info.stats.h,
            min: info.stats.l
          }
        })
        : <PoleGraph
            min={convert(info.stats.l)}
            max={convert(info.stats.h)}
            avg={convert(info.stats.s / info.stats.c)}
            statsMin={statsMin}
            statsMax={statsMax}
            label={info.label}
            key={type + info.label}
          />
    })
  }

  render () {
    return (
      <div className='device-high-low-graphs'>
        {this._summaryLines()}
      </div>
    )
  }
}

HighLowGraphs.displayName = 'HighLowGraphs'
