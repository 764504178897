import {
  SET_MAP_VISIBLE_DEVICES
} from './constants'

export function setMapVisibleDevices (data) {
  return {
    type: SET_MAP_VISIBLE_DEVICES,
    data
  }
}

export function reducer (state, action) {
  switch (action.type) {
    case SET_MAP_VISIBLE_DEVICES:
      return {
        ...state,
        mapVisibleDevices: action.data
      }

    default:
      return state
  }
}
