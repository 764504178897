import {
  UPDATE_CURRENT,
} from './constants';
import { merge } from 'ramda'

export function updateCurrent(obj = {}) {
  return {
    type: UPDATE_CURRENT,
    data : obj
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case UPDATE_CURRENT:
      return {
        ...state,
        current : merge(state.current || {}, action.data)
      };

    default:
      return state;
  }
}
