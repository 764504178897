import {
  REMOVE_USER_ACTION_BEGIN,
  REMOVE_USER_ACTION_SUCCESS,
  REMOVE_USER_ACTION_FAILURE,
  REMOVE_USER_ACTION_DISMISS_ERROR,
} from './constants';
import { serviceRemove } from '../../../common/feathers'

export const removeUserAction = serviceRemove('userActions', {
  begin: REMOVE_USER_ACTION_BEGIN,
  success: REMOVE_USER_ACTION_SUCCESS,
  fail: REMOVE_USER_ACTION_FAILURE
})


export function dismissRemoveUserActionError() {
  return {
    type: REMOVE_USER_ACTION_DISMISS_ERROR,
  };
}

const allPostsForRemoveUa = (data, allPosts) => {
  return allPosts.map(p => {
    if (p.liked === data) {
      p.likes = p.likes || 1
      p.likes -= 1
      p.liked = false
    }
    return p
  })
}

export function reducer(state, action) {
  let st8
  switch (action.type) {
    case REMOVE_USER_ACTION_BEGIN:
      st8 = {
        ...state,
        removeUserActionPending: true,
        removeUserActionError: null,
        allPosts: allPostsForRemoveUa(action.data, state.allPosts)
      }
      if (st8.favs) {
        st8.favs = st8.favs.filter(f => f._id !== action.data)
      }
      return st8

    case REMOVE_USER_ACTION_SUCCESS:
      return {
        ...state,
        removeUserActionPending: false,
        removeUserActionError: null,
      };

    case REMOVE_USER_ACTION_FAILURE:
      return {
        ...state,
        removeUserActionPending: false,
        removeUserActionError: action.data.error,
      };

    case REMOVE_USER_ACTION_DISMISS_ERROR:
      return {
        ...state,
        removeUserActionError: null,
      };

    default:
      return state;
  }
}
