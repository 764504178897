import React from 'react';
import { WXW_WIDGETS, doesWidgetHaveSize } from '../../common/ambient';
import classNames from 'classnames';
// import PropTypes from 'prop-types';

export default function WxwTileGallery () {
  const tileHasDupes = type => {
    return (/soil/.test(type) || /sensor/.test(type)) && !/1$/.test(type)
  }
  const getTiles = size => {
    return Object.keys(WXW_WIDGETS)
      .filter(type => !tileHasDupes(type))
      .filter(type => !/sensor/.test(type))
      .map((type, i) => {
        if (doesWidgetHaveSize(type, size)) {
          const url = WXW_WIDGETS[type].url
          return (
            <div className={classNames(`widget-preview ${size} ${type}`, { url })} key={`size-${type}`} >
              {url && <a href={url} target='_blank' className='widget-link' />}
            </div>
          )
        }
      })
      .filter(Boolean)
  }
  return (
    <div className="device-wxw-tile-gallery">
      <header>
        <h1>Tile Gallery</h1>
      </header>
      <h2>Small Size Tiles</h2>
      <div className='tile-wrap'>
        {getTiles('small')}
      </div>
      <h2>Medium Size Tiles</h2>
      <div className='tile-wrap'>
        {getTiles('medium')}
      </div>
      <h2>Large Size Tiles</h2>
      <div className='tile-wrap'>
        {getTiles('large')}
      </div>
    </div>
  );
};

WxwTileGallery.propTypes = {};
WxwTileGallery.defaultProps = {};

WxwTileGallery.displayName = 'WxwTileGallery'
