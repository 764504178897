import React, { PureComponent } from 'react';
import { AmbRadio, CopyLink, Loader, QuestionMark } from '../../components'
import { debounce, hasVideo, isBillingDev, pathsChanged } from '../../common/ambient'
import { WebcamImage } from '.'
import classNames from 'classnames'
import PropTypes from 'prop-types';
import { path } from 'ramda'
import { getThemeObj } from '../../common/skinner.js'
import { deviceCamTierI, deviceCamTierObj } from '../payment';

const MANUFACTURERS = [
  'Ambient',
  'Foscam',
  'Reolink',
  'Nest',
  'Ring',
  'Other'
]

export default class CreateWebcam extends PureComponent {
  static propTypes = {
    currentDevice: PropTypes.object,
    deviceActions: PropTypes.object,
    userActions: PropTypes.object,
    user: PropTypes.object
  }
  state = {
    open: false,
    type: '',
    url: '',
    imageFound: false,
    findingImage: false,
    creatingWebcam: false,
    password: false
  }
  constructor(props) {
    super(props)
    this._next = ::this._next
    this._reset = ::this._reset
    this._delete = ::this._delete
    this._public = ::this._public
    this._resetPassword = ::this._resetPassword
    this._fetchImage = debounce(this._fetchImage.bind(this), 100)
  }
  _resetPassword() {
    if (window.confirm('Are you sure you want to reset your FTP password?')) {
      this.setState({
        type: 'ftp'
      }, this._next())
    }
  }
  _reset() {
    this.setState({
      open: false,
      url: '',
      manufacturer: '',
      model: '',
      imageFound: false,
      findingImage: false,
      creatingWebcam: false
    })
  }
  _delete() {
    const { deviceActions, currentDevice } = this.props
    if (window.confirm('Are you sure you want to delete this camera?')) {
      this._doPatch({
        _delete: true,
        username: currentDevice.webcam.username
      })
        .then(d => {
          deviceActions.fetchDevices() // this will clear out the altered device's webcam property
          this._reset()
        })
    }
  }
  _doPatch (webcam) {
    const { deviceActions, currentDevice } = this.props
    const { url, type } = this.state
    return new Promise((resolve, reject) => {
      this.setState({
        creatingWebcam: true
      }, () => {
        deviceActions.patch(currentDevice._id, { webcam })
          .then(d => {
            this.setState({
              creatingWebcam: false
            }, () => resolve(d))
          })
      })
    })
  }
  _next() {
    const { deviceActions, currentDevice, user } = this.props
    const { url, type, manufacturer, model } = this.state
    const patch = {
      manufacturer,
      model
    }
    if (deviceCamTierI(user, currentDevice.macAddress)) {
      patch.enhanced = deviceCamTierI(user, currentDevice.macAddress)
    }
    if (type === 'ftp') {
      patch.username = currentDevice.macAddress.replace(/:/g, '')
      this._doPatch(patch)
        .then(d => {
          this.setState({
            password: d.result.webcam.password,
          })
        })

    // url
    } else {
      patch.url = url
      this._doPatch(patch)
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (pathsChanged(this.state, prevState, ['url'])) {
      this._fetchImage()
    }

  }
  _public(evt) {
    const { currentDevice } = this.props
    if (evt.target.checked) {
      this._doPatch(Object.assign({}, currentDevice.webcam, {
        public: true,
        approved: false
      }))

    } else {
      this._doPatch(Object.assign({}, currentDevice.webcam, {
        public: false
      }))
    }
  }
  _fetchImage() {
    const { url } = this.state
    if (this._urlOk(url)) {
      const img = new Image()
      img.addEventListener('load', () => {
        this.setState({
          imageFound: true,
          findingImage: false
        })
      })
      img.addEventListener('error', (err) => {
        this.setState({
          findingImage: false,
          imageFound: false,
        })
      })
      img.src = url + '?t=' + Date.now() 
    }
  }
  _urlOk(url) {
    return /http/.test(url)
  }
  _url(url) {
    const st8 = {
      url,
    }
    this.setState(st8)
  }
  _right() {
    const { creatingWebcam, open, imageFound, findingImage, url } = this.state
    const { currentDevice, userActions, user } = this.props
    if (!open) {
      return null
    }
    if (findingImage) {
      return <Loader />
    }
    if (creatingWebcam) {
      return null
    }
    let image
    if (url && this._urlOk(url)) {
      let message = 'Image not found'
      if (this._ambientcam2()) {
        message = <span className='ambientcam'>Please use your AmbientVMS application to point your camera to "{currentDevice.macAddress}" <a href='https://ambientweather.com/mwdownloads/download/link/id/1210' target='_blank'>Instructions</a><br/><br/>...when that is complete <a onClick={() => this._fetchImage()}>Refresh</a></span>
      }
      image = <WebcamImage 
        message={message}
        currentDevice={{ webcam: { url }}} 
        onSuccess={() => this.setState({ imageFound: true })} 
        onFail={() => this.setState({ imageFound: false })}
      />
    } else if (path(['webcam'], currentDevice)) {
      image = <WebcamImage 
        currentDevice={currentDevice}         
        onSuccess={() => this.setState({ imageFound: true })} 
        onFail={() => this.setState({ imageFound: false })} 
      />
    }
    let camBanner
    if (hasVideo(currentDevice)) {
      const tier = deviceCamTierObj(user, currentDevice.macAddress) 
      // const btnDowngrade = () => {
      //   history.push('/account#subscriptions')
      //   userActions.doModal(null)
      // }
      // low res
      camBanner = <div className="cam-banner">
        <span>Your time-lapse video is {tier.label} resolution...</span>
        {(tier.tier < 3) ? 
          <button onClick={() => userActions.doModal({ type: 'enhanced-cam', data: { currentDevice } })} className='btn btn-primary btn-lg'>Upgrade Now</button>
          : <a onClick={() => userActions.doModal({ type: 'enhanced-cam', data: { currentDevice } })} className=''>downgrade</a>
        }
      </div>
    }
    return <>
      <div className='img-wrap'>{image}</div>
      {camBanner}
    </>
  }
  _ambientcam2(webcam) {
    if (webcam) {
      return webcam.manufacturer === 'Ambient' && webcam.model === 'AMBIENTCAM2'
    }
    return this.state.type === 'ambientcam2'
  }
  _top() {
    const { open, creatingWebcam } = this.state
    const { webcam } = this.props.currentDevice
    let show = <span><a className="add" onClick={() => this.setState({ open: true })}>Add Weather Cam</a> <QuestionMark link={getThemeObj().helpUrlBase + "weathercams/"} /></span> 
    const title = this._ambientcam2(webcam) ? 'AmbientCam Solar' : 'Weather Cam'
    if (open) {
      if (creatingWebcam) {
        return <Loader />
      } else if (webcam) {
        return <h2 className='camera'>{title} <a onClick={() => this.setState({ open: false })}>hide details</a></h2>
      } else {
        return <h2 className="camera">Connect your Weather Cam</h2>
      }
    } else if(webcam) {
        return <h2 className='camera'>{title} <a onClick={() => this.setState({ open: true })}>view details</a></h2>
    }
    return show
  }

  render() {
    const { creatingWebcam, url, open, type, imageFound, password, manufacturer, model } = this.state
    const { currentDevice, user } = this.props
    const { webcam } = currentDevice
    let show
    const types = [ 'ambientcam2', 'ftp', 'url']
    if (open) {
      if (creatingWebcam) {
        show = null
      } else if (webcam) {
        let inside = <div>
          <table>
            <tbody>
              <tr>
                <td>Server:</td>
                <td>{isBillingDev() ? 'test-ftp' : 'ftp2'}.ambientweather.net</td>
              </tr>
              <tr>
                <td>Port:</td>
                <td>21</td>
              </tr>
              <tr>
                <td>FTP Mode:</td>
                <td>PASV (passive)</td>
              </tr>
              <tr>
                <td>Username:</td>
                <td>{currentDevice.webcam.username}</td>
              </tr>
              <tr>
                <td>Password:</td>
                <td>{password ? <span>{password} <span className="sans small">(you will only have access to this once)</span></span> : <span>****</span>}</td>
              </tr>
              <tr>
                <td>Min Interval:</td>
                <td>1 minute</td>
              </tr>
            </tbody>
          </table>
        </div>
        if (webcam.url) {
          inside = <div>
            <p>{this._ambientcam2(webcam) ? 'Ambientcam2' : 'Camera'} URL:</p>
            <p className="wrap-text"><a href={webcam.url} className="dark monospace" target="_blank">{webcam.url}</a></p>
            <CopyLink text={webcam.url} linkTitle="Copy URL" className="small" />
            {webcam.error && <p className="error">{webcam.error}. Please check the url for your camera. Then, if you'd like it to show publicly you'll need to delete this camera and re-add it.</p>}
          </div>
        }
        let pub
        if (webcam.public && !webcam.approved) {
          pub = <label>Your weather cam is awaiting approval to become public</label>
        } else if (imageFound || webcam.approved) {
          pub = [
            <label key={1}><input checked={webcam.public} onChange={this._public} type='checkbox' /> Allow others to view your weather cam.</label>,
            <a key={2} href='https://help.ambientweather.net/privacy-policy/' className='small'>See our privacy policy</a>,
            <br key={3}/>
          ]
        }
        let video
        if (hasVideo(currentDevice)) {
          const tierObj = deviceCamTierObj(user, currentDevice.macAddress)
          video = <>
            <h3>{tierObj.label} Cam Features</h3>
            <div className='bordered'>
              <table>
                <tbody>
                  <tr>
                    <td>Time lapse resolution:</td>
                    <td>{tierObj.timelapseRes}</td>
                  </tr>
                  <tr>
                    <td>Regeneration rate:</td>
                    <td>{tierObj.regenRate}</td>
                  </tr>
                  <tr>
                    <td>Weather banner:</td>
                    <td>{tierObj.banner}</td>
                  </tr>
                  <tr>
                    <td>Sampling:</td>
                    <td>{tierObj.sampling}</td>
                  </tr>
                  <tr>
                    <td>Time lapse storage:</td>
                    <td>{tierObj.storage}</td>
                  </tr>
                  <tr>
                    <td>Map Icon:</td>
                    <td>{tierObj.mapIcon}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        }
        show = <>
          <div className="ftp">
          <div className="bordered">
            {inside}
            {pub}
            <a onClick={this._delete} className="small delete">Delete Camera</a>
          </div>
          {video}
        </div>
        </>

      } else {
        show = <div>
          <p>What format does your webcam use?</p>
          <div className='radios'>
            {types.map(t => <AmbRadio
              key={t}
              selected={type === t}
              onClick={() => {
                let obj = { type: t, url: '' }
                if (t === 'ambientcam2') {
                  obj.url = `https://images.ambientweather.net/${currentDevice.macAddress.replace(/:/g, '')}/latest.jpg`
                  obj.manufacturer = 'Ambient'
                  obj.model = 'AMBIENTCAM2'
                } else {
                  obj.manufacturer = ''
                  obj.model = ''
                }
                this.setState(obj)
              }}
              label={t === 'ambientcam2' ? <span>AmbientCam Solar <QuestionMark link='https://ambientweather.com/ambient-weather-network-solar-wi-fi-weather-camera' /></span> : t.toUpperCase()}
            />)}
          </div>
          {type === 'url' ? <div className='url'>
            <p>Enter the URL for your webcam's static image</p>
            <input type="text" className='form-control' value={url} onChange={evt => this._url(evt.target.value)} />
          </div> : null}
          {type && !this._ambientcam2() && (
            <>
              <div className='url'>
                <p>Webcam Manufacturer&nbsp;&nbsp;&nbsp;&nbsp;<a href='https://ambientweather.com/wifi-weather-camera-wc-fmwac2k' target='_blank'>Learn more about Ambient Weather cameras</a></p>
                <div className='radios'>
                  {MANUFACTURERS.map(t => <AmbRadio
                    key={t}
                    selected={manufacturer === t}
                    onClick={() => this.setState({ manufacturer: t })}
                    label={t}
                  />)}
                </div>
              </div>
              <div className='url'>
                <p>Webcam Model</p>
                <input type="text" className='form-control' value={model} onChange={evt => this.setState({ model: evt.target.value })} />
              </div>
            </>
          )}
          <div className="btns">
            <button onClick={this._next} disabled={!type || !manufacturer || !model || (['url', 'ambientcam2'].includes(type) && !imageFound)} className="btn btn-primary">Next</button>
            <a className="btn" onClick={this._reset}>cancel</a>
          </div>
        </div>
      }
    }
    return (
      <div className={classNames('device-create-webcam', { 
        open,
        video: hasVideo(currentDevice),
        ambientcam2: this._ambientcam2(webcam),
      }, `tier-${deviceCamTierI(user, currentDevice.macAddress)}`)}>
        {this._top()}
        <div className="wrap">
          <div className="left">
            {show}
          </div>
          <div className='right'>
            {this._right()}
          </div>
        </div>
      </div>
    )
  }
}

CreateWebcam.displayName = 'CreateWebcam'
