import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { path } from 'ramda'
import bindUserActions from './redux/bindUserActions';
import { Link } from 'react-router';
import { isLoggedIn } from '../../common/ambient'
import classNames from 'classnames'

class Avatar extends PureComponent {
  static propTypes = {
    user: PropTypes.object.isRequired,
    u: PropTypes.object,
    noDefault: PropTypes.bool
  }
  _backgroundImage(props) {
    const { user, u, noDefault } = props || this.props
    return u ? path(['image'], u) : noDefault ? null : path(['info', 'image'], user)
  }
  // shouldComponentUpdate(nextProps){
  //   return this._backgroundImage() !== this._backgroundImage(nextProps)
  // }

  render() {
    const { noDefault } = this.props
    const backgroundImage = this._backgroundImage()
    const style = backgroundImage ? { backgroundImage: `url(${backgroundImage.replace('cdn.filestackcontent.com', 'd2pr2a8f0ao4pf.cloudfront.net')})` } : {}
    return (
      <div className={classNames("user-avatar", {
        'is-def': !backgroundImage,
        'no-def': noDefault
      })} style={style} />
    )
  }
}

export default bindUserActions(Avatar)

Avatar.displayName = 'Avatar'

Avatar.displayName = 'Avatar'

Avatar.displayName = 'Avatar'

Avatar.displayName = 'Avatar'

Avatar.displayName = 'Avatar'

Avatar.displayName = 'Avatar'

Avatar.displayName = 'Avatar'
