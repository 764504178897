import React, { PureComponent } from 'react';
import bindAllActions from '../../common/bindAllActions';
import { isRole, isAdmin, isLoggedIn } from '../../common/ambient'
import PropTypes from 'prop-types';
import { pick, not, isEmpty, filter, pipe, path } from 'ramda'
import classNames from 'classnames'
import TimezonePicker from '../../components/TimezonePicker'
import {
  DeviceLine,
  ShareButton,
  ServersForm
} from './'
import { appendSkinModifier } from '../../common/skinner.js'

class DeviceListRows extends PureComponent {
  static propTypes = {
    device: PropTypes.object,
    user: PropTypes.object,
    deviceActions: PropTypes.object,
  }
  constructor(props) {
    super(props)
    this.deviceLine = ::this.deviceLine
    this.getInput = ::this.getInput
    this.removeDevice = ::this.removeDevice
    this.state = {
      wu: false,
      pws: false
    }
  }
  componentWillMount() {
    const { user, alert, alertActions } = this.props
    if (isLoggedIn(user) && !alert.alerts) {
      alertActions.fetchAlerts({
        userId: user.info._id
      })
    }
  }
  getInput(dev, field) {
    const { deviceActions } = this.props
    const val = dev.info ? dev.info[field] : ''
    return (
      <input
        type="text"
        value={val}
        className={'editable-title type-info-' + field}
        onChange={(evt) => {
          const info = dev.info || {}
          info[field] = evt.target.value
          deviceActions.patch(dev._id, { info })
        }}
      />
    )
  }
  removeDevice(dev) {
    if (window.confirm('Are you sure you want to delete this device?')) {
      const { removeDevice, fetchDevices, setDeviceI } = this.props.deviceActions
      removeDevice(dev).then(() => fetchDevices())
      setDeviceI(0)
    }
  }
  badge(on) {
    return <div className={classNames("badge", { on })} />
  }
  deviceLine (dev) {
    const { deviceActions, user, alert } = this.props
    const alexaConnected = path(['info', 'oauth', 'alexa'], user)
    const gaConnected = path(['info', 'oauth', 'google-assistant'], user)
    const iftttConnected = alert.alerts && pipe(
      filter(a => a.ifttt && a.deviceId === dev._id),
      pipe(isEmpty, not)
    )(alert.alerts)
    let tzInput
    if (dev.tz) {
      tzInput = <div className="item tz">
        <label>Timezone: </label>
        <TimezonePicker
          value={dev.tz.name}
          onChange={tz => {
            if (tz.name !== dev.tz.name) {
              deviceActions.patch(dev._id, { tz: pick(['name'], tz) })
            }
          }}
        />
      </div>
    }
    return (
      <div key={dev._id} className="device-line">
        <div className="block">
          <header className="main-top">
            {this.getInput(dev, 'name')}
            <div className="right">
              <ShareButton currentDevice={dev} />
              <div className="icon" />
            </div>
          </header>
          <div className="sub-head clearfix">
            <div className="item">
              <label>MAC Address:</label>
              <span className="mac">{dev.macAddress}</span>
            </div>
            <div className="item">
              <label>Sharing:</label>
              <span>{dev.public ? <span className="public">public</span> : <span className="private">private</span>}</span>
            </div>
            {tzInput}
            <div className="item">
              <label>Location: </label>
              <span>{this.getInput(dev, 'location')}</span>
            </div>
          </div>
          <div className="services">
            <ServersForm theDevice={dev} service={'wu'} deviceActions={deviceActions} />
            <ServersForm theDevice={dev} service={'pws'} deviceActions={deviceActions} />
          </div>
          <a className="delete-device" onClick={() => this.removeDevice(dev)} >Delete this device</a>
        </div>
        <div className="connections block">
          <a title="Learn more about our Amazon Alexa integration" href="https://help.ambientweather.net/help/amazon-alexa/" target="_blank" className={classNames("alexa-service")}>
            <div className="icon" />
            {this.badge(alexaConnected)}
          </a>
          <a href="https://help.ambientweather.net/help/google-home/" title="Learn more about our Google Assistant integration" target="_blank" className={classNames("ga-service")}>
            <div className="icon" />
            {this.badge(gaConnected)}
          </a>
          <a href="https://help.ambientweather.net/help/ifttt/" title="Learn more about controling other connected devices with your weather data via IFTTT" target="_blank" className={classNames("ifttt-service")}>
            <div className="icon" />
            {this.badge(iftttConnected)}
          </a>
        </div>
      </div>
    )
    
  }

  render() {
    const { device, userActions, deviceActions, user, alert } = this.props
    const { devices } = device
    const { info } = user
    if (!devices || devices.length < 1) {
     return <div className="hidden" /> 
    }
    return (
      <div className={ appendSkinModifier("device-device-list-rows") }>
      {/* <div className="device-device-list-rows"> */}
        {devices.map(dev => {
           if (true) return (
            <DeviceLine
              currentDevice={dev}
              alerts={alert.alerts || []}
              deviceActions={deviceActions}
              userActions={userActions}
              device={device}
              user={user} 
              key={dev._id}
            />
           )
           return this.deviceLine(dev)
         })}
      </div>
    );
  }
}

export default bindAllActions(DeviceListRows)

DeviceListRows.displayName = 'DeviceListRows'

DeviceListRows.displayName = 'DeviceListRows'

DeviceListRows.displayName = 'DeviceListRows'

DeviceListRows.displayName = 'DeviceListRows'

DeviceListRows.displayName = 'DeviceListRows'

DeviceListRows.displayName = 'DeviceListRows'

DeviceListRows.displayName = 'DeviceListRows'
