import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { pipe, flatten, filter, apply, pluck, concat } from 'ramda'
import { Area, Line, YAxis, Bar } from 'recharts'
import { rowsHave, isSomething, getLabel, getSuffForUser } from '../../common/ambient'
import { CommonGraph } from './'

export default class LightningGraph extends PureComponent {
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    graphData: PropTypes.array,
    onClick: PropTypes.func,
    mode: PropTypes.string,
    user: PropTypes.object
  }

  render () {
    const { user, width, onClick, height, graphData, mode } = this.props
    const isRefined = graphData[0] && graphData[0].refined
    let dataKey = 'lightning_res'
    let hourlyDataKey = dataKey
    // let dailyDataKey = 'lightning_day'
    const dataKeyHl = 'lightning_hourHl'
    const getSuff = getSuffForUser(user)
    // let hasHigh = false
    // if (rowsHave(graphData, 'lightning_dayH')) {
    //   dailyDataKey = 'lightning_dayH'
    //   hasHigh = true
    // }
    let hasAvg = false
    if (rowsHave(graphData, 'lightning_hourAvg')) {
      hourlyDataKey = 'lightning_hourAvg'
      hasAvg = true
    }
    let allVals = pluck(hourlyDataKey, graphData)
    let hourlyHlArea
    if (rowsHave(graphData, dataKeyHl)) {
      console.log('graphData', graphData)
      hourlyHlArea = (
        <Area
          yAxisId='left'
          type='monotone'
          dataKey={dataKeyHl}
          stroke='#b4dcf6'
          strokeWidth={0}
          fill='#b4dcf6'
          legendType='circle'
          connectNulls={false}
          dot={false}
          isAnimationActive={false}
          name={getLabel('lightning_hour') + ' High/Low'}
        />
      )
      dataKey = dataKeyHl
      allVals = concat(allVals, pipe(
        pluck(dataKeyHl),
        flatten
      )(graphData))
    }
    allVals = filter(isSomething, allVals)
    console.log('allVals', allVals)
    const cleanedLightingGraphData = graphData.map((d) => {
      if (!d.lightning_res || !d.lightning_res === 0) {
        d.lightning_distance = 0
      }
      if (d.lightning_hourHl && d.lightning_hourHl[1] === 0) {
        d.lightning_distanceAvg = 0
      }
      return d
    })
    console.log(graphData.map(d => d.lightning_hourHl))
    const distanceKey = 'lightning_distance' + (hasAvg ? 'Avg' : '')
    return (
      <CommonGraph
        onClick={onClick}
        width={width}
        height={height}
        mode={mode}
        graphData={graphData}
        id='lightning'
        composed
      >
        <YAxis
          yAxisId='left'
          dataKey={dataKey}
          orientation='right'
          type='number'
          axisLine={false}
          domain={[0, apply(Math.max, allVals)]}
        />
        <YAxis
          yAxisId='right'
          dataKey='lightning_distance'
          orientation='left'
          type='number'
          domain={[0, apply(Math.max, pluck(distanceKey, cleanedLightingGraphData))]}
        />
        <Bar
          yAxisId='right'
          dataKey={distanceKey}
          fill='#cdd4d9'
          barSize={2}
          barGap={0}
          name={'Lightning Distance' + (hasAvg ? ' Avg' : '')}
          unit={getSuff('lightning_distance')}
        />
        {hourlyHlArea}
        <Line
          yAxisId='left'
          type='monotone'
          dataKey={hourlyDataKey}
          stroke='#2c9ce6'
          strokeWidth={2}
          fill='#2c9ce6'
          dot={false}
          isAnimationActive={!isRefined}
          name={'Lightning Strikes' + (hasAvg ? ' Avg' : '')}
          legendType='circle'
          connectNulls
        />
        {this.props.children}
      </CommonGraph>
    )
  }
}

LightningGraph.displayName = 'LightningGraph'
