import React, { PureComponent } from 'react';
import { ProtectedLink } from '.'
import classNames from 'classnames'
import PropTypes from 'prop-types';
import { path } from 'ramda'
import bindSocialActions from '../features/social/redux/bindSocialActions'

// https://stackoverflow.com/questions/9461621/format-a-number-as-2-5k-if-a-thousand-or-more-otherwise-900
function abbreviateNumber(number){
  var SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];

  // what tier? (determines SI symbol)
  var tier = Math.log10(Math.abs(number)) / 3 | 0;

  // if zero, we don't need a suffix
  if(tier == 0) return number;

  // get suffix and determine scale
  var suffix = SI_SYMBOL[tier];
  var scale = Math.pow(10, tier * 3);

  // scale the number
  var scaled = number / scale;

  // format number and add suffix
  return scaled.toFixed(1) + suffix;
}

class Like extends PureComponent {
  static propTypes = {
    type: PropTypes.string,
    thing: PropTypes.object,
    thingType: PropTypes.string,
    actions: PropTypes.object // social actions
  }

  render() {
    const { thing, type, actions } = this.props
    const { likes } = thing
    let c
    if (likes) {
      c = abbreviateNumber(likes)
    }
    const liked = path(['liked'], thing) 
    return (
      <span className='component-like'>
        <ProtectedLink
          className={classNames({ liked })}
          onClick={e => {
            if (liked) {
              actions.removeUserAction(liked)
            } else {
              actions.addUserAction({
                to: {
                  _id: thing._id,
                  userId: thing.userId,
                  type
                },
                type: 'like'
              })
            }
            e.stopPropagation()
            e.preventDefault()
          }}
        />
        <span className='count'>{c}</span>
      </span>
    )
  }
}

export default bindSocialActions(Like)
Like.displayName = 'Like'
