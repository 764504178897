import React, { PureComponent } from 'react'
import PropTypes from 'prop-types';
import classNames from 'classnames'
import aqi from '../../common/lib/aqi'
import { path } from 'ramda'
import {
  FormattedDataPoint
} from '../device/'


export default class Air2Widget extends PureComponent {
  static propTypes = {
    currentDevice: PropTypes.object.isRequired,
    type: PropTypes.string

  }

  render() {
    const { currentDevice, type = 'aqin' } = this.props
    const aqiBands = [{
      cutoff: [0, 50],
      label: 'Good',
      degrees: [0, 28]
    }, {
      cutoff: [51, 100],
      label: 'Moderate',
      degrees: [29, 57]
    },{
      cutoff: [101, 150],
      label: 'Unhealthy for Sensitive Groups',
      degrees: [58, 86]
    },{
      cutoff: [151, 200],
      label: 'Unhealthy',
      degrees: [87, 114]
    }, {
      cutoff: [201, 300],
      label: 'Very Unhealthy',
      degrees: [115, 143]
    }, {
      cutoff: [301, 500],
      label: 'Hazardous',
      degrees: [144, 173]
    }]
    const stuffForPm = value => {
      let v = value > 500 || isNaN(value) ? 500 : value
      const aqiBand = aqiBands.find(band => v <= band.cutoff[1])
      const degrees = aqiBand.degrees[0] + (v - aqiBand.cutoff[0]) * (aqiBand.degrees[1] - aqiBand.degrees[0]) / (aqiBand.cutoff[1] - aqiBand.cutoff[0])
      const arrowStyle = {
        transform: `rotate(${degrees}deg)`
      }
      return {
        ...aqiBand,
        arrowStyle,
        v
      }
    }
    let mainValue = ''
    let label = ''
    let extended, altArrow
    let units = <span>µg/m<sup>3</sup></span>
    const classes = [type]
    const stuffs = {}
    const hasHl = currentDevice.lastData.hl
    if (type === 'aqin') {
      const pm10aqi = currentDevice.lastData.aqi_pm10_aqin || aqi.pm10(currentDevice.lastData.pm10_in_aqin)
      const higherAqi = currentDevice.lastData.aqi_pm25_aqin > pm10aqi ? 'pm25' : 'pm10'
      mainValue = currentDevice.lastData.aqi_pm25_aqin > pm10aqi ? currentDevice.lastData[`aqi_pm25_aqin`] : pm10aqi
      classes.push(higherAqi)
      stuffs.pm10 = stuffForPm(pm10aqi)
      stuffs.pm25 = stuffForPm(currentDevice.lastData.aqi_pm25_aqin)
      label = stuffs[higherAqi].label
      altArrow = <div style={stuffs.pm10.arrowStyle} className='arrow alt' />
      units = 'AQI'
      extended = <div className='extended'>
        <div className='pm25'>
          <div className='label'>PM2.5 indoor</div>
          <table>
            <tr><td>Current</td><td><FormattedDataPoint type='pm25_in_aqin' row={currentDevice.lastData} /></td><td><span>µg/m<sup>3</sup></span></td></tr>
            <tr><td>24h avg</td><td><FormattedDataPoint type='pm25_in_24h_aqin' row={currentDevice.lastData} /></td><td><span>µg/m<sup>3</sup></span></td></tr>
            { hasHl && <tr><td>Peak</td><td><FormattedDataPoint type='pm25_in_aqin' value={path(['lastData', 'hl', 'pm25_in_aqin', 'h'], currentDevice)} /></td><td><span>µg/m<sup>3</sup></span></td></tr>}
            <tr><td>AQI</td><td><FormattedDataPoint type='aqi_pm25_aqin' row={currentDevice.lastData} /></td></tr>
          </table>
        </div>
        <div className='pm10'>
          <div className='label'>PM10 indoor</div>
          <table>
            <tr><td>Current</td><td><FormattedDataPoint type='pm10_in_aqin' row={currentDevice.lastData} /></td><td><span>µg/m<sup>3</sup></span></td></tr>
            <tr><td>24h avg</td><td><FormattedDataPoint type='pm10_in_24h_aqin' row={currentDevice.lastData} /></td><td><span>µg/m<sup>3</sup></span></td></tr>
            {hasHl && <tr><td>Peak</td><td><FormattedDataPoint type='pm10_in_aqin' value={path(['lastData', 'hl', 'pm10_in_aqin', 'h'], currentDevice)} /></td><td><span>µg/m<sup>3</sup></span></td></tr>}
            <tr><td>AQI</td><td><FormattedDataPoint type='aqi_pm10_aqin' value={pm10aqi} /></td></tr>
          </table>
        </div>
      </div>

    // air and airin
    } else {
      const base = type === 'air' ? 'pm25' : 'pm25_in' 
      mainValue = currentDevice.lastData[base]
      stuffs.pm25 = stuffForPm(aqi.pm25(mainValue))
      label = stuffs.pm25.label
      const peak = path(['lastData', 'hl', base, 'h'], currentDevice)
      extended = <div className='corners'>
        <div>
          Avg 
          <FormattedDataPoint type={`${base}_24h`} row={currentDevice.lastData} />
        </div>
        {peak &&
        <div>
          Peak 
          <FormattedDataPoint type={base} value={peak} />
        </div>
      }
      </div>
    }
    return (
      <div className={classNames("device-air-2-widget", classes)}>
        <div className='gauge-wrap'>
          <div className='gauge' />
          <div style={stuffs.pm25.arrowStyle} className='arrow main' />
          {altArrow}
          <div className='values'>
            <div className='value main'>{mainValue}</div>
            <div className='units'>{units}</div>
          </div>
        </div>
        <div className='bottom'>
          <div className='label'>{label}</div>
          {extended}
        </div>
      </div>
    )
  }
}

Air2Widget.displayName = 'Air2Widget'
