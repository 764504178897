import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { dataHas } from '../../common/ambient'
import FormattedDataPoint from './FormattedDataPoint'

/**
 * A quick widget for displaying a single param. expand if you like....
 */
export default function GenericWidget ({ currentDevice, type }) {
  const { lastData } = currentDevice
  const currentDataHas = dataHas(lastData)
  if (!currentDataHas([type])) return null

  return (
    <div className={classNames('device-generic-widget', `type-${type}`)}>
      <div className='big-number'>
        <FormattedDataPoint type={type} row={lastData} />
      </div>
    </div>
  )
}

GenericWidget.propTypes = {
  currentDevice: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired
}
GenericWidget.defaultProps = {}

GenericWidget.displayName = 'GenericWidget'
