import React, { PureComponent } from 'react';
import { withRouter } from 'react-router'

class PageNotFound extends PureComponent {
  componentWillMount() {
    this.props.history.push('/')
  }
  render() {
    return (
      <div className="component-page-not-found">
        Page not found.
      </div>
    );
  }
}
export default withRouter(PageNotFound)

PageNotFound.displayName = 'PageNotFound'

PageNotFound.displayName = 'PageNotFound'

PageNotFound.displayName = 'PageNotFound'

PageNotFound.displayName = 'PageNotFound'

PageNotFound.displayName = 'PageNotFound'

PageNotFound.displayName = 'PageNotFound'

PageNotFound.displayName = 'PageNotFound'
