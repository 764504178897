import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { dataHas } from '../../common/ambient'
import FormattedDataPoint from './FormattedDataPoint'

export default class PressureWidget extends PureComponent {
  static propTypes = {
    device: PropTypes.object,
    currentDevice: PropTypes.object.isRequired,
    fetchDeviceData: PropTypes.func,
    unitI: PropTypes.number
  }
  constructor(props) {
    super(props)
    this.state = {
      now: false,
      hourOffset: Math.floor(Math.random() * 60)
    }
  }
  componentWillReceiveProps(nextProps) {
    const { now, hourOffset } = this.state
    const { fetchDeviceData, currentDevice } = this.props
    const data = currentDevice.lastData
    if (!fetchDeviceData) return
    if (
      !now
      || Date.now() - 1000 * 60 * 60 > now
      || nextProps.currentDevice._id !== currentDevice._id
    ) {
      this.setState({
        now: Date.now() 
      })
      fetchDeviceData({
        macAddress: nextProps.currentDevice.macAddress,
        dataKey: 'pressureWidgetLastHour',
        start: moment(data.dateutc).subtract(1, 'hour').valueOf(),
        asc: true,
        limit: 1
      })
    }
  }

  render() {
    const { unitI, currentDevice, device = {} } = this.props
    const { lastData } = currentDevice
    const { pressureWidgetLastHour } = device
    let widget = <div className="pressure-widget hidden" />
    const currentDataHas = dataHas(lastData)
    if (currentDataHas(['baromrelin'])) {
      let change = <div className="top-spacer" />
      if (pressureWidgetLastHour
       && pressureWidgetLastHour[0]
       && dataHas(pressureWidgetLastHour[0], 'baromrelin')
       && lastData.dateutc - pressureWidgetLastHour[0].dateutc > 1000 * 55 // make sure it's really from an hour ago
      ) {
        const diff = lastData.baromrelin - pressureWidgetLastHour[0].baromrelin
        let cls = 'change '
        cls += diff === 0 ? '' : (diff > 0 ? 'up' : 'down')
        change =
          <div className={cls}>
            <FormattedDataPoint type={'baromrelin'} value={Math.abs(diff)} after={'/hr'} />
          </div>
      }
      let barom = lastData.baromrelin
      let gaugeMin = 27.5
      let gaugeMax = 32.5
      if (barom < 28.1) {
        gaugeMin = 27.72
      } else if (barom < 28.6) {
        gaugeMin = 27.65
      } else if (barom < 29) {
        gaugeMin = 27.6
      }

      if (barom > 32) {
        gaugeMax = 32.23
      } else if (barom > 31.75) {
        gaugeMax = 32.29
      } else if (barom > 31.25) {
        gaugeMax = 32.36
      }
      barom = (barom < gaugeMin) ? gaugeMin : barom
      barom = (barom > gaugeMax) ? gaugeMax : barom

      const gaugeRange = gaugeMax - gaugeMin
      const rot = `rotate(${(((barom - gaugeMin) / gaugeRange) * 263) - 166}deg)`
      const arrowStyle = {
        WebkitTransform: rot,
        MozTransform: rot,
        OTransform: rot,
        msTransform: rot,
        transform: rot
      }
      widget =
        <div className={"pressure-widget unit-" + unitI}>
          {change}
          <div className="gauge">
            <div className="compass" style={arrowStyle} />
            <div className="barom">
              <FormattedDataPoint type={'baromrelin'} row={lastData} />
            </div>
          </div>
          <div className="spacer"></div>
        </div>
    }
    return widget
  }
}

PressureWidget.displayName = 'PressureWidget'
