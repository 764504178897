import React, { PureComponent } from 'react';
import { ReferenceLine, ScatterChart, ResponsiveContainer, Legend, ComposedChart, LineChart, XAxis, CartesianGrid, Tooltip } from 'recharts'
import PropTypes from 'prop-types';
import { curry, contains, isArrayLike, reverse, prop, pipe, map } from 'ramda'
import { getDateTz, tooltipFormatDate, addPropToObj, roundDownMins, timeFormatForUser } from '../../common/ambient'
import { InView } from 'react-intersection-observer'
import Loader from '../../components/Loader'
import bindAllActions from '../../common/bindAllActions'


class CommonGraph extends PureComponent {
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    graphData: PropTypes.array,
    mode: PropTypes.string,
    id: PropTypes.string,
    children: PropTypes.array,
    composed: PropTypes.bool,
    scatter: PropTypes.bool,
    onClick: PropTypes.func,
    dontChangeHeight: PropTypes.bool,
    user: PropTypes.object,
    device: PropTypes.object,
  };
  state = {
    show: true 
  }
  // shouldComponentUpdate(nextProps, nextState) {
  //   if (this.props.graphData !== nextProps.graphData 
  //     || this.state.show !== nextState.show
  //     ) {
  //     return true
  //   }
  //   return false
  // }

  render() {
    const { show } = this.state
    const { device, tooltipProps, user, scatter, dontChangeHeight, id, children, width, height, graphData, mode, composed, onClick } = this.props
    let theChildren = children
    const tzDate = getDateTz(window.moment, device)
    const dateFmt = pipe(
      (d) => {
        if (mode === 'daily') {
          return tzDate(roundDownMins(5, d)).format(timeFormatForUser(user).replace(' a', ''))
        }
        return tzDate(roundDownMins((mode === 'weekly') ? 30 : 1440, d)).format('M/D')
      }
    )
    const adds = pipe(
      addPropToObj('name', prop('dateutc'))
    )
    const data = reverse(map(adds, graphData))
    let Graph = composed ? ComposedChart : LineChart
    if (scatter) {
      Graph = ScatterChart
    }
    // phone height
    let h = height
    if (width < 768 && !dontChangeHeight) {
      h = width * 0.8
    }
    const visible =
      <InView
        rootMargin="300px 0px 0px 0px"
        onChange={(inView, entry) => {
          this.setState({
            show: inView
          })
        }}
      />
    let message = <div id={`${id}_graph`} className="common-graph-wrap" style={{ height: h, width }} >
      {visible}
      <Loader />
    </div>
    if (show) {
      message =
        <div id={id + '_graph'} className="common-graph-wrap">
          {visible}
          <ResponsiveContainer height={h}>
            <Graph
              onClick={onClick}
              data={data}
              margin={{ left: -20, top: 10 }}
              syncId="1"
            >
              <Legend
                align="right"
                wrapperStyle={{
                  top: 15
                }}
              />
              <XAxis
                axisLine={false}
                dataKey="name"
                tickFormatter={dateFmt}
                minTickGap={13}
              />
              <CartesianGrid
                vertical={false}
                fill="#fff"
                strokeWidth={1}
                stroke="#eeeeee"
              />
              <Tooltip
                labelFormatter={tooltipFormatDate(device, user, mode)}
                formatter={(val) => {
                  const doVal = v => isNaN(v) ? '--' : v
                  if (Array.isArray(val)) return doVal(val[0]) + ' - ' + doVal(val[1])
                  return doVal(val) 
                }}
                {...tooltipProps}
              />
              {theChildren}
            </Graph>
          </ResponsiveContainer>
        </div>
    }
    return message
  }
}

export default bindAllActions(CommonGraph)

CommonGraph.displayName = 'CommonGraph'

CommonGraph.displayName = 'CommonGraph'

CommonGraph.displayName = 'CommonGraph'

CommonGraph.displayName = 'CommonGraph'

CommonGraph.displayName = 'CommonGraph'

CommonGraph.displayName = 'CommonGraph'

CommonGraph.displayName = 'CommonGraph'
