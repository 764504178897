import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames'

export default class MinMaxBtns extends PureComponent {
  static propTypes = {
    id: PropTypes.string,
    onExpand: PropTypes.func,
    onPin: PropTypes.func,
    onMinus: PropTypes.func,
    onGraph: PropTypes.func,
    expanded: PropTypes.bool,
    pinned: PropTypes.bool
  }

  render() {
    const { onGraph, pinned, expanded, id, onExpand, onPin, onMinus, children } = this.props
    const expandable = []
    if (onExpand) {
      expandable.push(
        <a
          tabIndex="-1"
          className="exp"
          title={expanded ? "collapse widget" : "expand widget" }
          key={'exp'}
          onClick={onExpand}
        />
      )
    }
    if (onGraph) {
      expandable.push(
        <a
          tabIndex="-1"
          title="Jump to graph"
          className="graph"
          key={'graph'}
          onClick={onGraph}
        />
      )
    }
    if (onPin) {
      expandable.push(
        <a
          tabIndex="-1"
          className={classNames(
              "pin glyphicon glyphicon-pushpin",
              {
                pinned
              }
          )}
          key={'pin'}
          onClick={onPin}
        />
      )
    }
    if (children) {
      expandable.push(children)
    }
    if (onMinus) {
      expandable.push(
        <a
          tabIndex="-1"
          className={classNames("minus")}
          title="Hide widget"
          key={'minus'}
          onClick={onMinus}
        />
      )
    }
    return (
      <div className="buttons-wrap component-min-max-btns">
        <div className="buttons-rounded">
          {expandable}
        </div>
      </div>
    )
  }
}

MinMaxBtns.displayName = 'MinMaxBtns'

MinMaxBtns.displayName = 'MinMaxBtns'

MinMaxBtns.displayName = 'MinMaxBtns'

MinMaxBtns.displayName = 'MinMaxBtns'

MinMaxBtns.displayName = 'MinMaxBtns'

MinMaxBtns.displayName = 'MinMaxBtns'

MinMaxBtns.displayName = 'MinMaxBtns'
