import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { path } from 'ramda'
import {
  ForecastLongTerm,
  FetchesForecast
} from '../../components'
import { widgetExpanded } from '../../common/ambient';

export default class ForecastWidget extends PureComponent {
  render () {
    const coords = path(['info', 'coords', 'coords'], this.props.currentDevice)
    return <FetchesForecast coords={[coords.lon, coords.lat]}>
      <ForecastLongTerm
        expanded={widgetExpanded('forecast', this.props.currentDevice)}
        currentDevice={this.props.currentDevice}
      />
    </FetchesForecast>
  }
}

ForecastWidget.displayName = 'ForecastWidget'
