import * as turf from '@turf/helpers'
import centerOfMass from '@turf/center-of-mass'
import circleToPolygon from 'circle-to-polygon'

export const centerPointForGeo = geo => {
  if (!geo) return geo
  if (geo.type === 'Point') {
    return geo
  } else if (geo.type === 'Polygon') {
    const ret = centerOfMass(turf.polygon(geo.coordinates))
    return ret.geometry
  }
}

export const circleToPolygonSafe = (centerCoords, radiusMi) => {
  const centerPoly = circleToPolygon(centerCoords, radiusMi * 1609, { numberOfEdges: 12 })
  return {
    type: 'Polygon',
    coordinates: [centerPoly.coordinates[0].map(([lng, lat]) => {
      if (lng > 180) lng = 180
      if (lng < -180) lng = -180
      if (lat > 90) lat = 90
      if (lat < -90) lat = -90
      return [lng, lat]
    })]
  }
}
