import React, { PureComponent } from 'react'
import { Area, Line, YAxis } from 'recharts'
import PropTypes from 'prop-types'
import { apply, not, flatten, pluck, pipe, concat, identity } from 'ramda'
import { rowsHave, getSuffForUser, getLabel } from '../../common/ambient'
import bindDeviceActions from './redux/bindDeviceActions'
import CommonGraph from './CommonGraph'

class GenericGraph extends PureComponent {
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    graphData: PropTypes.array,
    mode: PropTypes.string,
    dataKey: PropTypes.string.isRequired,
    id: PropTypes.string,
    stroke: PropTypes.string,
    user: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    tickFormatter: PropTypes.func,
    domain: PropTypes.array
  }

  render () {
    const { onClick, domain, tickFormatter, id, stroke, children, dataKey, user, width, height, graphData, mode, device } = this.props
    const isRefined = graphData[0] && graphData[0].refined
    const getSuff = getSuffForUser(user)
    const label = getLabel(dataKey, device)
    const dataKeyHl = dataKey + 'Hl'
    let composed = false
    let areaHl
    let allVals = pluck(dataKey, graphData)

    const hasAvg = rowsHave(graphData, dataKey + 'Avg')
    if (hasAvg) {
      allVals = pluck(dataKey + 'Avg', graphData)
    }
    let hasHl
    if (rowsHave(graphData, dataKeyHl)) {
      hasHl = true
      allVals = concat(allVals, pipe(
        pluck(dataKeyHl),
        flatten
      )(graphData))
      composed = true
      areaHl = (
        <Area
          type='monotone'
          dataKey={dataKeyHl}
          stroke='#b4dcf6'
          strokeWidth={0}
          fill='#b4dcf6'
          legendType='circle'
          connectNulls={false}
          dot={false}
          isAnimationActive={false}
          name={label + ' High/Low'}
          unit={getSuff(dataKey)}
        />
      )
    }
    allVals = allVals.filter(pipe(isNaN, not))
    if (domain && domain[1] === 'dataMax' && hasHl) {
      domain[1] = apply(Math.max, allVals)
    }
    const tf = tickFormatter || identity
    return (
      <CommonGraph
        width={width}
        height={height}
        mode={mode}
        graphData={graphData}
        composed={composed}
        id={id || dataKey}
        onClick={onClick}
      >
        <YAxis
          dataKey={hasAvg ? dataKey + 'Avg' : dataKey}
          orientation='left'
          type='number'
          axisLine={false}
          tickFormatter={tf}
          domain={domain || [apply(Math.min, allVals), apply(Math.max, allVals)]}
        />
        {areaHl}
        <Line
          type='monotone'
          dataKey={hasAvg ? dataKey + 'Avg' : dataKey}
          stroke={stroke || '#2c9ce6'}
          strokeWidth={2}
          dot={false}
          isAnimationActive={!isRefined}
          name={label + (hasAvg ? ' Avg' : '')}
          unit={getSuff(dataKey)}
          legendType='circle'
          connectNulls
        />
        {children}
      </CommonGraph>
    )
  }
}

export default bindDeviceActions(GenericGraph)

GenericGraph.displayName = 'GenericGraph'
