import React from 'react'
import classNames from 'classnames'
import { ResponsiveContainer, LineChart, CartesianGrid, YAxis, Line } from 'recharts'
// import PropTypes from 'prop-types'

export default function SimpleLineGraph ({ className, data = [], height = 50, margin, dot }) {
  return (
    <div className={classNames('common-simple-line-graph', className)}>
      <ResponsiveContainer height={height}>
        <LineChart
          data={data}
          syncId='simple-line-graph'
          margin={margin}
        >
          <YAxis hide type='number' domain={['dataMin', 'dataMax']} />
          <CartesianGrid
            horizontal={false}
            strokeWidth={1}
            stroke='#eeeeee'
          />
          <Line
            type='monotone'
            dataKey='value'
            stroke='#000'
            isAnimationActive={false}
            dot={what => {
              if (dot) {
                const customDot = dot(what)
                if (customDot) {
                  return customDot
                }
              }
              return <circle {...what} />
            }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

SimpleLineGraph.propTypes = {}
SimpleLineGraph.defaultProps = {}

SimpleLineGraph.displayName = 'SimpleLineGraph'

SimpleLineGraph.displayName = 'SimpleLineGraph'

SimpleLineGraph.displayName = 'SimpleLineGraph'

SimpleLineGraph.displayName = 'SimpleLineGraph'

SimpleLineGraph.displayName = 'SimpleLineGraph'

SimpleLineGraph.displayName = 'SimpleLineGraph'

SimpleLineGraph.displayName = 'SimpleLineGraph'
