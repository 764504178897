import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Mousetrap from 'mousetrap'

export default class SearchBar extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    open: PropTypes.bool
  };
  constructor(props) {
    super(props)
    this.state = {
      open: this.props.open || false,
      val: ''
    }
    this.handleInput = ::this.handleInput
    this.lookForEscape = ::this.lookForEscape
    this.toggleOpen = ::this.toggleOpen
  }
  handleInput(evt) {
    const { onChange } = this.props
    const val = evt.target.value
    this.setState({
      val
    })
    if (onChange) {
      onChange(val)
    }
  }
  lookForEscape(evt) {
    const { onChange } = this.props
    if (evt.keyCode === 27) {
      this.toggleOpen()
      if (onChange) {
        onChange('')
      }
    }
  }
  toggleOpen() {
    const newState = !this.state.open
    this.setState({
      open: newState
    })
    if (newState) {
      this.refs.input.focus()
    } else {
      this.refs.input.blur()
      this.setState({
        val: ''
      })
    }
  }
  componentDidMount() {
    Mousetrap.bind(['ctrl+s','ctrl+f'], this.toggleOpen)
  }
  componentWillUnmount() {
    Mousetrap.unbind(['ctrl+s','ctrl+f'], this.toggleOpen)
  }
  render() {
    const { placeholder = 'Enter Keyword' } = this.props
    const { open, val } = this.state
    let cls = 'device-search-bar'
    if (open) {
      cls += ' open'
    }

    return (
      <div className={cls}>
        <input
          className="search"
          type="text"
          ref="input"
          value={val}
          onChange={this.handleInput}
          onKeyDown={this.lookForEscape}
          placeholder={placeholder}
        />
        <a
          tabIndex="-1"
          onClick={this.toggleOpen}
          title="Ctrl+S opens the search and Esc closes it"
        >
          <i className="glyphicon glyphicon-search" />
        </a>
      </div>
    );
  }
}

SearchBar.displayName = 'SearchBar'
