import initialState from './initialState';
import { reducer as socialTestAction } from './socialTestAction';
import { reducer as createPost } from './createPost';
import { reducer as fetchPosts } from './fetchPosts';
import { reducer as patchPost } from './patchPost';
import { reducer as addUserAction } from './addUserAction';
import { reducer as removeUserAction } from './removeUserAction';
import { reducer as fetchUserActions } from './fetchUserActions';
import { reducer as setMapVisiblePosts } from './setMapVisiblePosts';
import { reducer as getPost } from './getPost';
import { reducer as getUserActionReducer } from './getUserAction';
import { reducer as patchUserActionReducer } from './patchUserAction';

const reducers = [
  socialTestAction,
  createPost,
  fetchPosts,
  patchPost,
  addUserAction,
  removeUserAction,
  fetchUserActions,
  setMapVisiblePosts,
  getPost,
  getUserActionReducer,
  patchUserActionReducer,
];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  return reducers.reduce((s, r) => r(s, action), newState);
}
