import React from 'react';
import { MAP_LEGENDS } from '../../common/ambient';
import classNames from 'classnames'
// import PropTypes from 'prop-types';

export default function MapLegend({ type }) {
  const config = MAP_LEGENDS[type]
  if (!config) return null
  return (
    <span className={classNames('common-map-legend', type)}>
      <h3>{config.title}</h3>
      <div className={classNames('common-map-legend-items', type)}>
        {config.items.map((item, i) => (
          <div className={classNames('common-map-legend-item', `i-${i}`)} key={i}>
            <div className='common-map-legend-item-color' style={{ backgroundColor: item.color }} />
            <div className='common-map-legend-item-label'>{item.label}</div>
          </div>
        ))}
      </div>
      <p className='info'>{config.info}</p>
    </span>
  )
}

MapLegend.propTypes = {};
MapLegend.defaultProps = {};

MapLegend.displayName = 'MapLegend'
