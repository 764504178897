import React from 'react';
import Switch from 'react-switch'
import bindUserActions from '../user/redux/bindUserActions'
import { getTheme } from '../../common/ambient'
// import PropTypes from 'prop-types';

function AwnSwitch ({ checked, onChange, user }) {
  const isDark = getTheme(user) === 'dark'
  return (
    <Switch
      onColor='#a6def6'
      onHandleColor='#09a8e6'
      offHandleColor='#9b9b9b'
      offColor={isDark ? '#2F5A6B' : '#c7c7c7'}
      handleDiameter={23}
      uncheckedIcon={false}
      checkedIcon={false}
      height={15}
      width={35}
      checked={checked}
      onChange={onChange}
    />
  );
};

export default bindUserActions(AwnSwitch)

AwnSwitch.propTypes = {};
AwnSwitch.defaultProps = {};

AwnSwitch.displayName = 'AwnSwitch'
