import {
  REMOVE_DEVICE_BEGIN,
  REMOVE_DEVICE_SUCCESS,
  REMOVE_DEVICE_FAILURE,
  REMOVE_DEVICE_DISMISS_ERROR,
} from './constants';
import { app } from '../../../common/feathers'
import { fetchDevices } from './fetchDevices'
const deviceService = app.service('devices')

export function removeDevice(args) {
  return (dispatch) => {
    dispatch({
      type: REMOVE_DEVICE_BEGIN,
      data : args
    });
    return deviceService.remove(args._id)
    .then(function (res){
      dispatch({
        type: REMOVE_DEVICE_SUCCESS,
        data: args,
      });
    })
    .catch(function (err){
      dispatch({
        type: REMOVE_DEVICE_FAILURE,
        data: {
          error: err,
        },
      });
    })
  };
}

export function dismissRemoveDeviceError() {
  return {
    type: REMOVE_DEVICE_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case REMOVE_DEVICE_BEGIN:
      return {
        ...state,
        removingDevice : action.data,
        removeDevicePending: true,
        removeDeviceError: null,
      };

    case REMOVE_DEVICE_SUCCESS:
      return {
        ...state,
        lastRemovedDevice : action.data,
        removingDevice : false,
        removeDevicePending: false,
        removeDeviceError: null,
        current: {}
      };

    case REMOVE_DEVICE_FAILURE:
      return {
        ...state,
        removeDevicePending: false,
        removeDeviceError: action.data.error,
      };

    case REMOVE_DEVICE_DISMISS_ERROR:
      return {
        ...state,
        removeDeviceError: null,
      };

    default:
      return state;
  }
}
