import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { getStorage, setStorage } from '../common/ambient';
import { getSiteName, getThemeObj } from '../common/skinner.js'
export default class CookiesBanner extends PureComponent {
  static propTypes = {

  }

  constructor(props) {
    super(props)
    this.state = {
      hide: getStorage('cookies-accept')
    }
  }
  _onClick() {
    setStorage('cookies-accept', true)
    this.setState({
      hide: true
    })

  }

  render() {
    if (this.state.hide) return null
    return (
      <div className="component-cookies-banner">
        <span>
          { getSiteName() } uses cookies to analyze traffic to this site.
        </span>
        <a className="clean" target="_blank" href={getThemeObj().helpUrlBase + "cookies/"}>See details</a>
        <a className="btn btn-primary" onClick={this._onClick.bind(this)}>OK, got it</a>
      </div>
    );
  }
}

CookiesBanner.displayName = 'CookiesBanner'
