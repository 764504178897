import { path } from 'ramda'
import React from 'react'
import FormattedDataPoint from './FormattedDataPoint'
// import PropTypes from 'prop-types';

export default function CrosswindWidget ({ currentDevice }) {
  return (
    <div className='device-crosswind-widget'>
      <div>
        <div className='label'>Crosswind</div>
        <FormattedDataPoint row={path(['lastData'], currentDevice)} type='crosswindmph' />
      </div>
      <div>
        <div className='label'>Headwind</div>
        <FormattedDataPoint row={path(['lastData'], currentDevice)} type='headwindmph' />
      </div>

    </div>
  )
}

CrosswindWidget.propTypes = {}
CrosswindWidget.defaultProps = {}

CrosswindWidget.displayName = 'CrosswindWidget'
