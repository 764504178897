import {
  PATCH_BEGIN,
  PATCH_SUCCESS,
  PATCH_FAILURE,
  PATCH_DISMISS_ERROR,
} from './constants';
import { keys, merge } from 'ramda'
import { servicePatch } from '../../../common/feathers'

export const patch = servicePatch('users', {
  begin: PATCH_BEGIN,
  success: PATCH_SUCCESS,
  fail: PATCH_FAILURE
})

export function dismissPatchError() {
  return {
    type: PATCH_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case PATCH_BEGIN:
      return {
        ...state,
        info: merge(state.info, action.data.args),
        patchPending: keys(action.data.args),
        patchError: null,
      };

    case PATCH_SUCCESS:
      return {
        ...state,
        patchPending: false,
        patchError: null,
        info: merge(state.info, action.data.result),
      };

    case PATCH_FAILURE:
      return {
        ...state,
        patchPending: false,
        patchError: action.data.error,
      };

    case PATCH_DISMISS_ERROR:
      return {
        ...state,
        patchError: null,
      };

    default:
      return state;
  }
}
