import initialState from './initialState';
import { reducer as alertTestAction } from './alertTestAction';
import { reducer as createAlert } from './createAlert';
import { reducer as fetchAlerts } from './fetchAlerts';
import { reducer as removeAlert } from './removeAlert';
import { reducer as fetchInstances } from './fetchInstances';
import { reducer as fetchIftttAlerts } from './fetchIftttAlerts';

const reducers = [
  alertTestAction,
  createAlert,
  fetchAlerts,
  removeAlert,
  fetchInstances,
  fetchIftttAlerts,
];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  return reducers.reduce((s, r) => r(s, action), newState);
}
