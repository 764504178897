import React, { Component, PropTypes } from 'react';
import { Link } from 'react-router'


export default class KmSupport extends Component {
  static propTypes = {
  }

  render() {
    return (
      <div className="user-kmsupport">
        <header className="main">
          <h1>
            Support
          </h1>
        </header>
        <div className="page-body">
          <h1>KestrelMet 6000 Product Support</h1>
          <div className="prodSupport">
            <div className="page-img">
              <br/>
              <center><img src={require("../../assets/KestrelMet_6000AG_station.jpg")} /></center>
            </div>

            <div className="videos">
              <h2>Support Videos</h2>
              <ul>
                <li>
                <a href="https://vimeo.com/510354539" target="_blank">Cellular Weather Station Setup Guide</a>
                </li>
                <li>
                  <a href="https://vimeo.com/showcase/7398993/video/805555420" target="_blank">Wi-Fi Weather Station Setup Guide</a>
                </li>
                <li>
                  <a href="https://vimeo.com/showcase/7398993/video/805565912" target="_blank">Troubleshooting Your Wi-Fi Connection</a>
                </li>
                <li>
                  <a href="https://vimeo.com/499270640" target="_blank">Mono mount installation</a>
                </li>
                <li>
                  <a href="https://vimeo.com/499799036" target="_blank">Tripod mount installation</a>
                </li>
              </ul>
              </div>

            <div className="manuals">
              <h2>Support Materials</h2>
              <ul>
              <li>
                  <a href="https://kestrelmet.com/mwdownloads/download/link/id/1090" target="_blank">KestrelMet 6000 Cellular Weather Station Instruction Manual</a>
                </li>
                <li>
                  <a href="https://kestrelmet.com/mwdownloads/download/link/id/1188" target="_blank">KestrelMet 6000 Wi-Fi Weather Station Instruction Manual</a>
                </li>
                <li>
                  <a href="https://kestrelinstruments.com/help/battery-mode" target="_blank">KestrelMet 6000 Battery Level, LED Indicator and Operating Modes</a>
                </li>
                <li>
                  <a href="https://kestrelmet.com/faqs/question/view/id/1265/" target="_blank">KestrelMet 6000  Cellular Signal Optimization</a>
                </li>
                <li>
                  <a href="https://kestrelmet.com/faqs/question/view/id/3365/" target="_blank">KestrelMet Soil Sensor Array Kit - Installation and Integration References</a>
                </li>
              </ul>
            </div>
          </div>
          <br/><br/>
          <h3>
            For the full Kestrel Instruments product line or to contact our Customer service, please visit <a href="https://kestrelmet.com/" target="_blank">KestrelMet.com</a>.
          </h3>
        </div>
      </div>
    );
  }
}

KmSupport.displayName = 'KmSupport'

