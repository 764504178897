import React, { PureComponent } from 'react';
import { widgetExpanded, dataHas, WIDGET_CONFIG } from '../../common/ambient'
import PropTypes from 'prop-types';
import { take, map, filter } from 'ramda'
import EditableWidgetTitleInput from './EditableWidgetTitleInput'
import classNames from 'classnames'

export default class LeakWidget extends PureComponent {
  static propTypes = {
    currentDevice: PropTypes.object.isRequired,
    onChange: PropTypes.func,
  }

  render() {
    const { currentDevice, onChange } = this.props
    const { lastData } = currentDevice
    const createLine = field => {
      let label = 'OK'
      const val = lastData[field]
      if (val === 1) {
        label = 'LEAK'
      } else if (val === 2) {
        label = 'OFFLINE'
      }
      return <div key={field} className={classNames('line', 'leak-' + val)}>
        <div>
          <EditableWidgetTitleInput
            type={field}
            currentDevice={currentDevice}
            onChange={onChange}
          />
        </div>
        <div className="icon">
          {label}
        </div>
      </div>
    }

    const currentLeaks = filter(dataHas(lastData), WIDGET_CONFIG.leak.fields)
    const lines = map(createLine, currentLeaks)
    return <div className="device-leak-widget table-widget">
        {lines}
      </div>
  }
}

LeakWidget.displayName = 'LeakWidget'
