import {
  SET_DEVICE_I
} from './constants'
import { removeDayDataCache } from '../../../common/ambient'

export function setDeviceI (i) {
  return {
    type: SET_DEVICE_I,
    data: i
  }
}

export function reducer (state, action) {
  switch (action.type) {
    case SET_DEVICE_I:
      const st8 = removeDayDataCache(state)
      window.localStorage.deviceI = action.data
      st8.deviceI = action.data
      return st8
    default:
      return state
  }
}
