import { AD_CONFIG, getStorage } from "../../../common/ambient";

const initialState = {
  fetchUserPending: false,
  fetchUserError: null,
  createUserPending: false,
  createUserError: null,
  logoutUserPending: false,
  logoutUserError: null,
  confirmUserPending: false,
  confirmUserError: null,
  info: {},
  users: false,
  adminImpersonation: false,
  sendConfirmationPending: false,
  sendConfirmationError: null,
  patchPending: false,
  patchError: null,
  fetchUsersPending: false,
  fetchUsersError: null,
  fetchAlexaDataPending: false,
  fetchAlexaDataError: null,
  alexaData: [],
  ipInfo: false,
  fetchInfoPending: false,
  fetchInfoError: null,
  modal: '',
  pro: false,
  userUnits: getStorage('userUnits'),
  createShortUrlPending: false,
  createShortUrlError: null,
  userPatchings: 0,
  refreshUserPending: false,
  refreshUserError: null,
};

export default initialState;
