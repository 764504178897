import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import bindUserActions from './redux/bindUserActions';
import { Link } from 'react-router-dom';
import { isLoggedIn } from '../../common/ambient'
import Avatar from './Avatar'

class Nav extends PureComponent {
  static propTypes = {
    user: PropTypes.object.isRequired
  };


  render() {
    const { user } = this.props
    let link
    if (isLoggedIn(user)) {
      link = [
        <li key={3} className={user.adminImpersonation ? 'avatar impersonating' : 'avatar'}>
          <Link to="/account">
            <Avatar />
          </Link>
        </li>,
        <li key={4} className="my-dashboard">
          <Link className="btn btn-primary" to="/dashboard">My Dashboard</Link>
        </li>
      ]
    } else {
      link = [
        <li key={1}><Link className="add-device btn btn-default" to="/welcome">Add Device</Link></li>,
        <li key={2}><Link className="login btn btn-default" to="/signin">Login</Link></li>,
        <li key={3}><Link className="login btn btn-default mobile-login" to="/welcome">Login</Link></li>
      ]
    }
    return <div className="user-nav">
    <ul className="nav navbar-nav pull-right simple-nav">
      {link}
    </ul>
  </div>
  }
}

export default bindUserActions(Nav);

Nav.displayName = 'Nav'
