const fetch = require('isomorphic-fetch')

async function postToRainWise(data = {}) {
  // TODO NPS remove console logs....  maybe leave resopnse if error?
  // console.log("###============================###")  
  // console.log("Data to send")
  // console.log(data)
  const url = 'https://diagnostics.rainwise.net/mk4'
  // const url = 'https://devapp.rainwise.net/mk4'
  try {
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(data)
    });
    // TODO NPS --- exception handling?????  try / catch ???  resp == 40x???
    const rainwiseResponse = response.json()
  
    if ((response.status != '200') && (response.status != '204')) {
      console.log('ERROR: postToRainWise resulted in response status ' + response.status);
    } 
    // console.log("###----------------------------###")  
    // console.log("Response from RainWise")
    // console.log(rainwiseResponse)
    // console.log("###============================###")  
    return rainwiseResponse;
  } catch (error) {
    return ({})
    console.error('ERROR: postToRainWise failed with error: ' + error);
  }
}

export { postToRainWise };