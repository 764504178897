import {
  UPDATE_DEVICE_DATA_BEGIN,
  UPDATE_DEVICE_DATA_SUCCESS,
  UPDATE_DEVICE_DATA_FAILURE,
  UPDATE_DEVICE_DATA_DISMISS_ERROR,
} from './constants';
import { serviceUpdate } from '../../../common/feathers'

// places to look for docs to update
const possiblyUpdate = ['deviceSummaries']

const updateDocArrays = (st8, dateutc, fn) => {
  possiblyUpdate.forEach(key => {
    if (st8[key] && Array.isArray(st8[key])) {
      st8[key] = st8[key].map(doc => {
        if (doc.dateutc === dateutc) {
          return fn(doc)
        }
        return doc
      })
    }
  })
  return st8
}

export const updateDeviceData = serviceUpdate('device-data', {
  begin: UPDATE_DEVICE_DATA_BEGIN,
  success: UPDATE_DEVICE_DATA_SUCCESS,
  fail: UPDATE_DEVICE_DATA_FAILURE
})

export function dismissUpdateDeviceDataError() {
  return {
    type: UPDATE_DEVICE_DATA_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case UPDATE_DEVICE_DATA_BEGIN:
      return updateDocArrays({
        ...state,
        updateDeviceDataPending: true,
        updateDeviceDataError: null,
      }, action.data.dateutc, () => {
        return action.data
      })

    case UPDATE_DEVICE_DATA_SUCCESS:
      return updateDocArrays({
        ...state,
        updateDeviceDataPending: false,
        updateDeviceDataError: null,
      }, action.data.dateutc, (doc) => {
        doc._rev = action.data.rev
        return doc
      })

    case UPDATE_DEVICE_DATA_FAILURE:
      return {
        ...state,
        updateDeviceDataPending: false,
        updateDeviceDataError: action.data.error,
      };

    case UPDATE_DEVICE_DATA_DISMISS_ERROR:
      return {
        ...state,
        updateDeviceDataError: null,
      };

    default:
      return state;
  }
}
