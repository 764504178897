import { merge } from 'ramda'
import {
  UPDATE_SETTING
} from './constants'
import { app } from '../../../common/feathers'
const userService = app.service('users')

export function updateSetting (key, setting) {
  return {
    type: UPDATE_SETTING,
    data: {
      key,
      setting
    }
  }
}
let _tout = false
export function reducer (state, action) {
  switch (action.type) {
    case UPDATE_SETTING:
      const { settings = {} } = state.info
      const { key } = action.data
      if (Array.isArray(action.data.setting)) {
        settings[key] = action.data.setting
      } else {
        settings[key] = merge(settings[key] || {}, action.data.setting)
      }
      state.info = {
        ...state.info,
        settings
      }
      if (_tout) {
        clearTimeout(_tout)
      }
      _tout = setTimeout(() => {
        userService.emit('patch')
        userService.patch(state.info._id, { settings })
      }, 500)
      return {
        ...state
      }

    // used in userListener
    case 'UPDATE_USER_PATCHINGS':
      return {
        ...state,
        userPatchings: action.data === '+' ? state.userPatchings + 1 : state.userPatchings - 1
      }

    default:
      return state
  }
}
