import {
  PATCH_BEGIN,
  PATCH_SUCCESS,
  PATCH_FAILURE,
  PATCH_DISMISS_ERROR,
} from './constants'
import { servicePatch } from '../../../common/feathers'
import { clone, pluck, indexOf, merge } from 'ramda'
import { updateArrayForObject } from '../../../common/ambient';


export const patch = servicePatch('devices', {
  begin: PATCH_BEGIN,
  success: PATCH_SUCCESS,
  fail: PATCH_FAILURE
})

export function dismissPatchError() {
  return {
    type: PATCH_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case PATCH_BEGIN:
      const { data } = action
      state.devices = updateArrayForObject(state.devices, data)
      state.allDevices = updateArrayForObject(state.allDevices, data)
      if (state.dashboardDevice && state.dashboardDevice._id === data.id) {
        state.dashboardDevice = merge(state.dashboardDevice, data.args)
      }
      return {
        ...state,
        patchPending: true,
        patchError: null,
      };

    case PATCH_SUCCESS:
      return {
        ...state,
        patchPending: false,
        patchError: null,
      };

    case PATCH_FAILURE:
      return {
        ...state,
        patchPending: false,
        patchError: action.data.error,
      };

    case PATCH_DISMISS_ERROR:
      return {
        ...state,
        patchError: null,
      };

    default:
      return state;
  }
}
