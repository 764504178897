import React, { useState, useEffect } from 'react'
import AddCard from './AddCard';
import { billingFetchSecret } from './billingAccess'

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { isBillingDev } from '../../common/ambient'

const PUBLISHABLE_KEY = isBillingDev()
  ? 'pk_test_51JXnb3DQ6Y4AP0xhC9A8YRZYlxh9RPyZWamiw43N7g7LGDaut7K4qkiqo5nAi7HKhY9Q66mQpgQ6CiXY4uxVmS0300QsulYdRi'
  : 'pk_live_51JXnb3DQ6Y4AP0xh7DTfb527tVYVoDC3GWjSOQEOSxFXPNw79SE36zdxxSnq61dGTPqrAMLY2nkvnFixAx97eghR00Q3UMqPqE'
const stripePromise = loadStripe(PUBLISHABLE_KEY)

const AllPayments = ({ userId, deviceId, mode, onSuccess }) => {
  const [secret, setSecretTo] = useState()
  const [reset, setReset] = useState(false)
  useEffect(() => { // this is a ComponentDidMount surrogate, cannot be async
    // console.log("ALLPAYMENTS: Secret = " + secret + ", Reset = " + reset)
    if (!userId) return
    if (secret && !reset) return
    async function wrapFetchSecret () {
      billingFetchSecret(userId, setSecretTo)
    }
    wrapFetchSecret()
    setReset(false)
  }, [userId, secret, mode, reset])

  const options = {
    clientSecret: secret
  }

  const adds = []
  if (mode === 'register') {
    adds.push(
      <AddCard
        key='register'
        userId={userId}
        deviceId={deviceId}
        subscribe='kestrelmet-one-year-subscription'
        onSuccess={onSuccess}
        reset={setReset}
      />
    )
  }
  if (mode === 'file') {
    adds.push(
      <AddCard
        key='file'
        userId={userId}
        deviceId={deviceId}
        subscribe=''
        onSuccess={onSuccess}
        reset={setReset}
      />
    )
  }

  const uponLoad = (
    <Elements stripe={stripePromise} options={options} key={secret}>
      <div className='payment-all-payments'>
        {adds}
      </div>
    </Elements>
  )

  const loading = (
    <div>
      Loading, please wait...
    </div>
  )

  const content = secret ? uponLoad : loading

  return (
    content
  )
};

// AllPayments.propTypes = {};
// AllPayments.defaultProps = {};

export default AllPayments
AllPayments.displayName = 'AllPayments'
