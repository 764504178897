const initialState = {
  createPostPending: false,
  createPostError: null,
  allPosts: [],
  mapVisiblePosts: [],
  fetchPostsPending: false,
  fetchPostsError: null,
  favs: false,
  patchPostPending: false,
  patchPostError: null,
  addLikePending: false,
  addLikeError: null,
  addUserActionPending: false,
  addUserActionError: null,
  removeUserActionPending: false,
  removeUserActionError: null,
  fetchUserActionsPending: false,
  fetchUserActionsPendingDataKeys: [],
  fetchUserActionsError: null,
  getPostPending: false,
  getPostError: null,
  getUserActionPending: false,
  getUserActionError: null,
  patchUserActionPending: false,
  patchUserActionError: null,
};

export default initialState;
