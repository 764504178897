import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import { isPlus } from '../payment'
import { Link } from 'react-router-dom'
// import PropTypes from 'prop-types';

export default function AdvancedGraphModal ({ user, userActions }) {
  const [currentI, setCurentI] = React.useState(0)
  let finalSlide = (
    <a
      onClick={() => {
        userActions.doModal({
          type: 'plus-subscription'
        })
      }}
      className='slide s-5'
    >
      <div className='imgs'>
        <div />
      </div>
    </a>
  )
  if (isPlus(user)) {
    finalSlide = (
      <Link
        onClick={() => {
          userActions.doModal(null)
        }}
        to='/advanced-graphing'
        className='slide s-5 plus'
      >
        <div className='imgs'>
          <div />
        </div>
        <button className='btn btn-primary green'>
          Try it now!
        </button>
      </Link>
    )
  }
  return (
    <div className='common-advanced-graph-modal'>
      <header className='logo' />
      <Carousel
        selectedItem={currentI}
        onChange={setCurentI}
        showThumbs={false}
        showStatus={false}
        showArrows={false}
        autoPlay={false}
        interval={1000000}
      >
        <a
          onClick={() => setCurentI(1)}
          className='slide s-0'
        >
          <h2>Multi-Station <span className='desktop'>Graphing</span> &<br/>Multi-Parameter Graphing</h2>
          <div className='imgs'>
            <div />
            <p className='mobile'>
              Compare a single type of measurement at different stations or compare different sensor measurements at one station.
            </p>
            <div />
          </div>
          <p className='desktop'>
            Compare a single type of measurement at different stations or compare different sensor measurements at one station.
          </p>
        </a>
        <a
          onClick={() => setCurentI(2)}
          className='slide s-1'
        >
          <h2>Advanced Graph Controls</h2>
          <div className='top'>
            <div className='desktop' />
            <div />
            <p>
              Multi-axis graph display allows for differing measurements to display on one timeline (i.e. temperature vs. humidity)
            </p>
          </div>
          <div className='imgs'>
            <div />
          </div>
          <p>
            Zoom in on a selected section of a graph to see more granular detail
          </p>
        </a>
        <a
          onClick={() => setCurentI(3)}
          className='slide s-2'
        >
          <h2>Advanced Graph Controls</h2>
          <div className='imgs'>
            <div>
              <p>
                Combination bar/line charts allow you to display each parameter/station as either a bar chart or line graph
              </p>
            </div>
            <div>
              <p>
                Easily turn parameters or stations off or on by clicking or tapping on the chart legend
              </p>
            </div>
          </div>
        </a>
        <a
          onClick={() => setCurentI(4)}
          className='slide s-3'
        >
          <h2>Easy Organization</h2>
          <div className='centered'>
            <div className='desktop'>
              <p>
                Minimize graphs, retaining names for ease of location
              </p>
              <p>
                Duplicate a graph and quickly edit the parameters and stations
              </p>
            </div>
            <div />
            <div className='desktop'>
              <p>
                Easily organize and rearrange the order of existing graphs
              </p>
            </div>
            <div className='mobile'>
              <p>
                Minimize, Duplicate, and Re-Order graphs for easy organization
              </p>
            </div>
          </div>
        </a>
        <a
          onClick={() => setCurentI(5)}
          className='slide s-4'
        >
          <h2>Export & Data Handling</h2>
          <div className='imgs'>
            <div />
          </div>
          <p>
            Export detailed weather data as a CSV or XLS file or select from an array of image formats for exporting graphs
          </p>
        </a>
        {finalSlide}
      </Carousel>
    </div>
  )
};

AdvancedGraphModal.propTypes = {}
AdvancedGraphModal.defaultProps = {}

AdvancedGraphModal.displayName = 'AdvancedGraphModal'
