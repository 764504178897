import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { isSomething, widgetExpanded, feelsLike, dewPoint } from '../../common/ambient'
import {
  FormattedDataPoint,
  HighLowGraphs,
  FromYesterday
} from './'

export default class TempWidget extends PureComponent {
  static propTypes = {
    slim: PropTypes.bool,
    device: PropTypes.object,
    user: PropTypes.object,
    currentDevice: PropTypes.object.isRequired,
    fetchDeviceData: PropTypes.func
  }

  render() {
    const { slim, fetchDeviceData, device, currentDevice, user } = this.props
    const { lastData } = currentDevice
    const data = lastData
    let tempWidget = <div className="hidden" />
    if (data && (isSomething(data.tempf) || isSomething(data.humidity))) {
      const topWidget = []
      const bottomWidget = []
      if (isSomething(data.tempf)) {
        topWidget.push(<FormattedDataPoint type={'tempf'} row={data} key={1} suff={'°'} />)
        const dp = dewPoint(data.tempf, data.humidity) 
        if (isSomething(data.humidity) && !isNaN(dp)) {
          bottomWidget.push(
            <div key={'dewPoint'}>
              <div className="label">Dew Point</div>
              <div className="val">
                <FormattedDataPoint value={dp} type={'tempf'} />
              </div>
            </div>
          )
        }
        if (!slim) {
          bottomWidget.push(<FromYesterday type={'tempf'} currentDevice={currentDevice} key={'from-yesterday'} />)
        }
        const windspd = data.windspeedmph
        const fl = feelsLike(data.tempf, data.humidity, windspd)
        if (isSomething(fl)) {
          bottomWidget.push(
            <div key={'feelsLike'}>
              <div className="label">Feels Like</div>
              <div className="val">
                <FormattedDataPoint value={fl} type={'tempf'} />
              </div>
            </div>
          )
        }
      }
      let expanded = ''
      if (widgetExpanded('temp', currentDevice) && !slim) {
        expanded =
          <div className="expanded-wrap">
            <HighLowGraphs
              device={device}
              user={user}
              currentDevice={currentDevice}
              fetchDeviceData={fetchDeviceData}
              type={'tempf'}
            />
          </div>
      }
      tempWidget =
        <div className="device-temp-widget center-aligned">
          <div className="body">
            <div className="top">
              {topWidget}
            </div>
            <div className="bottom">
              {bottomWidget}
            </div>
          </div>
          {expanded}
        </div>
    }
    return tempWidget
  }
}

TempWidget.displayName = 'TempWidget'
