import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export default class AmbRadio extends PureComponent {
  static propTypes = {
    selected: PropTypes.bool,
    onClick: PropTypes.func,
    label: PropTypes.any
  };

  render() {
    const { className, selected, onClick, label, value } = this.props
    return (
      <div
        className={classNames('component-amb-radio', { active: selected }, className)}
        onClick={onClick}
      >
        <div>{label}</div>
        <i className="circle" />
      </div>
    );
  }
}

AmbRadio.displayName = 'AmbRadio'
