import React from 'react';
// import PropTypes from 'prop-types';
import { billingSubscribe } from './billingAccess';
import { path } from 'ramda';
export default function AddSubscription(user, deviceId, plan) {


  // const success = await billingSubscribe(userId, deviceId, plan)
  // if (success) {
  //   onSuccess()
  // } else {
  //   console.log("¯\\_(ツ)_/¯  AddCard: Subscription failed")
  // }

  // async function subClicked(e) {
  //   e.preventDefault()
  //   const userId = path(['info', '_id'], user)
  //   const paymentId = path(['info', 'payments', 'defaultPaymentMethod'], user)
  //   const success = await billingDeleteCard(userId, paymentId)
  //   if (!success) {
  //     console.log('¯\\_(ツ)_/¯   billingDeleteCard failed')
  //   }
  // } 
  // let subBtn = <button onClick={subClicked} className="btn btn-primary">Subscribe</button>


  return (
    <div className="payment-add-subscription">
      Component content: payment/AddSubscription
    </div>
  );
};

AddSubscription.propTypes = {};
AddSubscription.defaultProps = {};

AddSubscription.displayName = 'AddSubscription'
