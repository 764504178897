import React, { Component } from 'react';
import bindAllActions from '../../common/bindAllActions'
import { currentDeviceHasSummaries, isEmbed, dataHas, PRIVATE_PARAMS } from '../../common/ambient'
import { withRouter } from 'react-router'
import DeviceRealtimeDashboard from './DeviceRealtimeDashboard'
import DeviceDataTableWrap from './DeviceDataTableWrap'
import DevicesHeader from './DevicesHeader'
import PropTypes from 'prop-types';
import { contains, mapObjIndexed } from 'ramda'
import Loader from '../../components/Loader'

class Public extends Component {
  static propTypes = {
    params: PropTypes.object,
    deviceActions: PropTypes.object,
    userActions: PropTypes.object,
    device: PropTypes.object,
  }
  _int = false

  doFetch() {
    const { match, deviceActions, userActions } = this.props
    const { params } = match
    deviceActions.fetchDevice({ 'public.slug': params.key })
    .then(dev => {
      if (dev.data[0] && dev.data[0].unitSettings) {
        userActions.setUnits(dev.data[0].unitSettings, false)
      }
    })
  }
  componentDidMount() {
    const { location, match  } = this.props
    const { params } = match
    if (!params || !params.key) {
      return this.props.history.push('/')
    }
    if (!isEmbed(location)) {
      return this.props.history.push(`/dashboard/${params.key}`)
    }
    this.doFetch()
    this._int = setInterval(this.doFetch.bind(this), 1000 * 60)
  }
  componentWillUnmount() {
    clearInterval(this._int)
  }
  componentDidUpdate() {
    const { device, deviceActions } = this.props
    const { fetchedDevices, fetchDeviceDataKeysPending } = device
    const dataKey = 'deviceSummaries'
    if (fetchedDevices[0] && !device[dataKey] && !contains(dataKey, fetchDeviceDataKeysPending)) {
      const theDevice = fetchedDevices[0] 
      const macAddress = theDevice.macAddress 
      deviceActions.fetchDeviceData({
        macAddress,
        dataKey,
        start: Date.now() - 1000 * 60 * 60 * 24 * 365,
        stats: 'summary'
      })
    }
  }
  _isClean() {
    return /ref=hp/.test(window.location.href)
  }
  hideKeys (data) {
    const dh = dataHas(data)
    return PRIVATE_PARAMS.reduce((acc, key) => {
      if (!dh(key) || this._isClean()) {
        acc.push(key)
      }
      return acc
    }, [])
  }
  render() {
    const { location, device } = this.props
    const { fetchedDevices, fetchDevicePending } = device
    let add = fetchDevicePending ? <Loader /> : <div className="sorry">Sorry. We can't find that device.</div>
    const devices = fetchedDevices
    if (devices && devices.length > 0) {
      const dev = devices[0]
      const hideKeys = this.hideKeys(dev.lastData)

      // clean public page
      if (this._isClean()) {
        dev.settings = {}
        hideKeys.push('view')
      }
      if (dev) {
        add = <div>
          <DevicesHeader
            device={device}
            fetchDevices={this.doFetch.bind(this)}
            fetchDevicesPending={fetchDevicePending}
          />
          <DeviceRealtimeDashboard currentDevice={dev} hideKeys={hideKeys} />
          {isEmbed(location) ? '' : <DeviceDataTableWrap hideKeys={hideKeys} macAddress={dev.macAddress} />}
        </div>
      }
    }
    return (
      <div className="device-public">
        {add}
      </div>
    )
  }
}
export default bindAllActions(withRouter(Public))

Public.displayName = 'Public'

Public.displayName = 'Public'

Public.displayName = 'Public'

Public.displayName = 'Public'

Public.displayName = 'Public'

Public.displayName = 'Public'

Public.displayName = 'Public'
