import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { path } from 'ramda'
import { debounce, getWidgetTitle } from '../../common/ambient'
import { withRouter } from 'react-router'
import bindDeviceActions from './redux/bindDeviceActions'

class EditableWidgetTitleInput extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    currentDevice: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    location: PropTypes.object,
    device: PropTypes.object
  }
  constructor(props) {
    super(props)
    const { type, currentDevice } = props
    this.handleOnChange = debounce(this.handleOnChange.bind(this), 300)
    this.state = {
      value: getWidgetTitle(currentDevice, type),
      focused: false
    }
  }
  componentWillUpdate(nextProps) {
    const { type, currentDevice, device } = nextProps 
    if (nextProps.currentDevice !== this.props.currentDevice) {
      this.setState({
        value: getWidgetTitle(currentDevice, type)
      })
    }
  }
  componentDidUpdate() {
    const { type, currentDevice, device } = this.props
    const { value, focused } = this.state
    const newValue = getWidgetTitle(currentDevice, type)
    // if the widget title is changed elsewheres
    if (device.patchPending && value !== newValue && !focused) {
      this.setState({
        value: newValue 
      })
    }
  }
  handleOnChange() {
    const { onChange, type } = this.props
    const { value } = this.state
    if (onChange) {
      onChange({ title: value }, type)
    }
  }
  render() {
    const { type, onChange, location, currentDevice } = this.props
    const { value } = this.state
    let disabled = currentDevice.restrict
    // router isn't available in standalone, we may need to address this
    if (this.props.router) {
      const pathname = this.props.router.createLocation(window.location).pathname
      if (/devices\/public/.test(pathname)) {
        disabled = true
      }
    }
    return (
      <input
        disabled={disabled}
        type="text"
        value={value}
        className={'editable-title type-' + type}
        onChange={(evt) => {
          const value = evt.target.value
          this.setState({
            value
          })
          this.handleOnChange()
        }}
        onFocus={() => this.setState({ focused: true })}
        onBlur={() => this.setState({ focused: false })}
      />
    )
  }
}

export default bindDeviceActions(withRouter(EditableWidgetTitleInput))

EditableWidgetTitleInput.displayName = 'EditableWidgetTitleInput'

EditableWidgetTitleInput.displayName = 'EditableWidgetTitleInput'

EditableWidgetTitleInput.displayName = 'EditableWidgetTitleInput'

EditableWidgetTitleInput.displayName = 'EditableWidgetTitleInput'

EditableWidgetTitleInput.displayName = 'EditableWidgetTitleInput'

EditableWidgetTitleInput.displayName = 'EditableWidgetTitleInput'

EditableWidgetTitleInput.displayName = 'EditableWidgetTitleInput'
