import React, { useState, useEffect, useCallback, useRef } from 'react'

const DebouncedInput = ({
  initialValue = '',
  onChange,
  debounceTime = 300,
  className = ''
}) => {
  const [inputValue, setInputValue] = useState(initialValue)
  const prevValueRef = useRef(initialValue)
  const timerRef = useRef(null)

  const debouncedOnChange = useCallback(
    (value) => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }

      timerRef.current = setTimeout(() => {
        if (value !== prevValueRef.current) {
          onChange(value)
          prevValueRef.current = value
        }
      }, debounceTime)
    },
    [onChange, debounceTime]
  )

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [])

  const handleInputChange = (event) => {
    const newValue = event.target.value
    setInputValue(newValue)
    debouncedOnChange(newValue)
  };

  return (
    <input
      type='text'
      value={inputValue}
      onChange={handleInputChange}
      className={`debounced-input form-control ${className}`}
    />
  )
};

export default DebouncedInput

DebouncedInput.displayName = 'DebouncedInput'
