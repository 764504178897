import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { pipe, flatten, filter, apply, pluck, concat } from 'ramda'
import { Area, Line, YAxis } from 'recharts'
import { rowsHave, getUserUnitI, isSomething, countDecimals, getSuffForUser, getLabel } from '../../common/ambient'
import { CommonGraph } from './'

export default class RainGraph extends PureComponent {
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    graphData: PropTypes.array,
    mode: PropTypes.string,
    user: PropTypes.object
  }

  render() {
    const { user, width, height, graphData, mode } = this.props
    const isRefined = graphData[0] && graphData[0].refined
    const dataKey = 'hourlyrainin'
    let hourlyDataKey = dataKey
    let dailyDataKey = 'dailyrainin'
    const dataKeyHl = dataKey + 'Hl'
    const getSuff = getSuffForUser(user)
    const unitI = getUserUnitI('hourlyrainin', user)
    const fixDecimals = (d) => {
      return unitI === 0 ? d.toFixed(2) : d.toFixed(1)

      if (d < 1 && countDecimals(d) > 3) {
        return d.toFixed(3)
      }
      if (d > 1 && d < 10 && countDecimals(d) > 3) {
        return d.toFixed(1)
      }
      return d
    }
    let hasRainHigh = false
    if (rowsHave(graphData, 'dailyraininH')) {
      dailyDataKey = 'dailyraininH'
      hasRainHigh = true
    }
    let hasRainAvg = false
    if (rowsHave(graphData, 'hourlyraininAvg')) {
      hourlyDataKey = 'hourlyraininAvg'
      hasRainAvg = true
    }
    let allVals = concat(pluck(hourlyDataKey, graphData), pluck(dailyDataKey, graphData))
    let hourlyHlArea, composed = false
    if (rowsHave(graphData, dataKeyHl)) {
      composed = true
      hourlyHlArea = <Area
          type="monotone"
          dataKey={dataKeyHl}
          stroke="#b4dcf6"
          strokeWidth={0}
          fill="#b4dcf6"
          legendType="circle"
          connectNulls={false}
          dot={false}
          isAnimationActive={false}
          name={getLabel(dataKey) + ' High/Low'}
          unit={getSuff(dataKey)}
        />
      allVals = concat(allVals, pipe(
        pluck(dataKeyHl),
        flatten
      )(graphData))
    }
    allVals = filter(isSomething, allVals)
    return (
      <CommonGraph
        width={width}
        height={height}
        mode={mode}
        graphData={graphData}
        id={'rain'}
        composed={composed}
        onClick={this.props.onClick}
      >
        <YAxis
          dataKey={dataKey}
          orientation="left"
          type="number"
          axisLine={false}
          tickFormatter={fixDecimals}
          domain={[0, apply(Math.max, allVals)]}
        />
        {hourlyHlArea}
        <Line
          type="monotone"
          dataKey={dailyDataKey}
          stroke="#50bfbd"
          fill="#50bfbd"
          strokeWidth={2}
          dot={false}
          isAnimationActive={!isRefined}
          name={getLabel('dailyrainin') + (hasRainHigh ? ' High' : '')}
          unit={getSuff('dailyrainin')}
          legendType="circle"
          connectNulls={true}
        />
        <Line
          type="monotone"
          dataKey={hourlyDataKey}
          stroke="#2c9ce6"
          strokeWidth={2}
          fill="#2c9ce6"
          dot={false}
          isAnimationActive={!isRefined}
          name={getLabel(dataKey) + (hasRainAvg ? ' Avg' : '')}
          unit={getSuff(dataKey)}
          legendType="circle"
          connectNulls={true}
        />
        {this.props.children}
      </CommonGraph>
    );
  }
}

RainGraph.displayName = 'RainGraph'

RainGraph.displayName = 'RainGraph'

RainGraph.displayName = 'RainGraph'

RainGraph.displayName = 'RainGraph'

RainGraph.displayName = 'RainGraph'

RainGraph.displayName = 'RainGraph'

RainGraph.displayName = 'RainGraph'
