import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  DO_MODAL
} from './constants'

export function doModal (type) {
  return {
    type: DO_MODAL,
    data: type
  }
}

export function useDoModal () {
  const dispatch = useDispatch()
  const boundAction = useCallback((...params) => dispatch(doModal(...params)), [dispatch])
  return { doModal: boundAction }
}

export function reducer (state, action) {
  switch (action.type) {
    case DO_MODAL:
      return {
        ...state,
        modal: action.data
      }

    default:
      return state
  }
}
