import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { isSomething, widgetExpanded } from '../../common/ambient'
import {
  FormattedDataPoint,
} from './'

export default class WbgtWidget extends PureComponent {
  static propTypes = {
    slim: PropTypes.bool,
    device: PropTypes.object,
    user: PropTypes.object,
    currentDevice: PropTypes.object.isRequired,
    fetchDeviceData: PropTypes.func
  }

  render() {
    const { slim, fetchDeviceData, device, currentDevice, user } = this.props
    const { lastData } = currentDevice
    const data = lastData
    let wbgtWidget = <div className="hidden" />
    const flagZone = isSomething(data.wbgtflagzone) ?
          (data.wbgtflagzone && data.wbgtflagzone[0].toUpperCase() + data.wbgtflagzone.slice(1).toLowerCase()) : "";
    if (data && (isSomething(data.wbgtf))) {
      const topWidget = []
      const bottomWidget = []
      if (isSomething(data.wbgtf)) {
        topWidget.push(<FormattedDataPoint type={'wbgtf'} row={lastData} />)
        if (widgetExpanded('wbgt', currentDevice) && isSomething(data.blackglobef)) {
          bottomWidget.push(
            <div>
              <div className="label">Black Globe Temp</div>
              <div className="val">
                <FormattedDataPoint type={'blackglobef'} row={lastData} />
              </div>
            </div>
          )
        }
        if (widgetExpanded('wbgt', currentDevice) && isSomething(data.nawbf)) {
          bottomWidget.push(
            <div>
              <div className="label">NWB Temp</div>
              <div className="val">
                <FormattedDataPoint type={'nawbf'} row={lastData} />
              </div>
            </div>
          )
        }
      }
      wbgtWidget =
        <div className="device-wbgt-widget">
          <div className="body">
            <div className="top">
              {topWidget}
            </div>
            <div className="bottom">
              {bottomWidget}
            </div>
            {(flagZone !== "") && <div className="flag-zone">Zone: {flagZone}</div>}
          </div>
        </div>
    }
    return wbgtWidget
  }
}

WbgtWidget.displayName = 'WbgtWidget'
