import React, { PureComponent } from 'react';
import InputRange from 'react-input-range'
import classNames from 'classnames'
import 'react-input-range/lib/css/index.css'
import { debounce, getUrl, pathsChanged, roundDownMins } from '../common/ambient';
import PropTypes from 'prop-types';
import { range, path, find } from 'ramda'
import {
  FormattedDataPoint
} from '../features/device'

export default class Forecast extends PureComponent {
  static propTypes = {
    forecast: PropTypes.object
  }
  state = {
  }
  _forecast() {
    const { forecast } = this.props
    if (!forecast) {
      return
    }
    let show = <div className="currently">
      <FormattedDataPoint type={'tempf'} value={forecast.currently.temperature} after="°" />
      &nbsp;
      <span>{path(['minutely', 'summary'], forecast)} </span>
      <span>{path(['hourly', 'summary'], forecast)} </span>
      {/* <div className="link">See 24 hour forecast</div> */}
    </div>
    return <div className="forecast">
      {show}
      {this.props.chilrens || this.props.children}
    </div>
  }

  render() {
    return (
      <div className="component-forecast">
        <div className="wrap">
          {this._forecast()}
        </div>
      </div>
    )
  }
}

Forecast.displayName = 'Forecast'
