import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  USER_SET_APP_DEVICE
} from './constants'

export function setAppDevice (data) {
  return {
    type: USER_SET_APP_DEVICE,
    data
  }
}

export function useSetAppDevice () {
  const dispatch = useDispatch()
  const boundAction = useCallback((...params) => dispatch(setAppDevice(...params)), [dispatch])
  return { setAppDevice: boundAction }
}

export function reducer (state, action) {
  switch (action.type) {
    case USER_SET_APP_DEVICE:
      return {
        ...state,
        appDevice: action.data
      }
    default:
      return state
  }
}
