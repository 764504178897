import { useCallback } from 'react'
import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import {
  COMMON_SET_FORECAST_OPEN
} from './constants'

export function setForecastOpen (data) {
  if (data && window.ga) {
    window.ga('send', 'pageview', '/forecast')
  }
  return {
    type: COMMON_SET_FORECAST_OPEN,
    data
  }
}

export function useSetForecastOpen () {
  const dispatch = useDispatch()
  const boundAction = useCallback((...params) => dispatch(setForecastOpen(...params)), [dispatch])
  const st8Obj = useSelector(state => ({ forecastOpen: state.common.forecastOpen }), shallowEqual)
  return Object.assign({ setForecastOpen: boundAction }, st8Obj)
}

export function reducer (state, action) {
  switch (action.type) {
    case COMMON_SET_FORECAST_OPEN:
      return {
        ...state,
        forecastOpen: action.data
      }

    default:
      return state
  }
}
