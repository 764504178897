import React, { PureComponent } from 'react';
import { isSomething, widgetExpanded, dataHas, fetchStartEndRes } from '../../common/ambient'
import PropTypes from 'prop-types';
import { max, map, pick, mergeAll } from 'ramda'
import FormattedDataPoint from './FormattedDataPoint'

export default class SolarWidget extends PureComponent {
  static propTypes = {
    device: PropTypes.object,
    currentDevice: PropTypes.object.isRequired,
    fetchDeviceData: PropTypes.func
  }
  state = {
    now: false
  }

  render() {
    const { currentDevice, device } = this.props
    const { lastData } = currentDevice
    let widget = <div className="solar-widget hidden" />
    const currentDataHas = dataHas(lastData)
    if (currentDataHas(['solarradiation'])) {
      let from = ''
      let todaysSolarMax 
      if (lastData.hl && lastData.hl.solarradiation) {
        todaysSolarMax = lastData.hl.solarradiation.h
      }
      if (isSomething(todaysSolarMax)) {
        from =
          <div className="from zero">
            <div className="label">
              Today's Peak 
            </div>
            <FormattedDataPoint type={'solarradiation'} value={todaysSolarMax} />
          </div>
      }
      widget =
        <div className="solar-widget center-aligned">
          <FormattedDataPoint className='solarmax' type={'solarradiation'} row={lastData} />
          {from}
        </div>
    }
    return widget
  }
}

SolarWidget.displayName = 'SolarWidget'

SolarWidget.displayName = 'SolarWidget'

SolarWidget.displayName = 'SolarWidget'

SolarWidget.displayName = 'SolarWidget'

SolarWidget.displayName = 'SolarWidget'

SolarWidget.displayName = 'SolarWidget'

SolarWidget.displayName = 'SolarWidget'
