import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom'
import {
  Map
} from '../../components/'
import { pathsChanged, widgetWhat } from '../../common/ambient'
import PropTypes from 'prop-types';
import { path } from 'ramda'

export default class MapWidget extends PureComponent {
  static propTypes = {
    deviceActions: PropTypes.object
  }

  shouldComponentUpdate(nextProps) {
    return (pathsChanged(this.props, nextProps, [['currentDevice', '_id'], ['currentDevice', 'settings', 'map', 'expanded']]))
  }
  componentDidUpdate(prevProps) {
    const { currentDevice } = this.props
    if (this.map) {
      this.map.setDevices([currentDevice])
    }
    setTimeout(() => {
      if (this.map && this.map.map) {
        this.map.map.resize()
      }
    }, 1000)
  }

  render() {
    const { currentDevice, deviceActions } = this.props
    let map = <Map 
      coords={currentDevice && path(['info', 'coords', 'coords'], currentDevice)}
      mapOpts={{
        hideNavControl: true,
        noScrollZoom: true,
        padding: 0,
        paddingTop: 0,
        maxZoom: 8.5,
        interactive: false
      }} 
      onDeviceClick={deviceActions.setDashboardDevice}
      layer="radar"
      onMapLoad={map => {
        map.resize()
      }}
      onRef={ref => {
        if (ref) {
          this.map = ref
          this.map.setDevices([currentDevice])
        }
      }}
    />
    if (widgetWhat('minimized', 'map', currentDevice)) {
      map = null
    }
    return (
      <div className="device-map-widget">
        <Link key="expand" to="/" className="expand" />
        {map}
      </div>
    )
  }
}


MapWidget.displayName = 'MapWidget'

MapWidget.displayName = 'MapWidget'

MapWidget.displayName = 'MapWidget'

MapWidget.displayName = 'MapWidget'

MapWidget.displayName = 'MapWidget'

MapWidget.displayName = 'MapWidget'

MapWidget.displayName = 'MapWidget'
