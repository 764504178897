import React, { useRef, useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { SubscribeProcess, awnPlusAnnualMonthlySwitchAvail, displayCancelPlusOnIosMessage, freeTrialUsed, getAllPlusSubs, getAwnPlusAppPrice, plusSubHasCode, showAwnFreeTrial, subIsAndroid, subIsIap, subIsIos, subIsMonthly } from './';
import { Link } from 'react-router-dom';
import Switch from 'react-switch'
import classNames from 'classnames';
import { filter, intersection, map, path, pipe } from 'ramda';
import { useDoModal } from '../user/redux/doModal';
import bindDeviceActions from '../device/redux/bindDeviceActions';
import { AmbRadio } from '../../components';
import { isApp } from '../../common/ambient';
import { isIosApp } from '../../common/ambient/utils';

const awnsOnly = pipe(
  filter(sub => /^awn-plus-/.test(sub.productName)),
  filter(sub => sub.status !== 'incomplete'),
  map(sub => sub.productName)
)

function PlusSubscriptionModal ({ user, message, device, actions, userActions, redeemCode, code, stepTwo }) {
  const subscribedWithCode = plusSubHasCode(user)
  const [annual, setAnnual] = useState(true)
  const [plusUserSwitch, setPlusUserSwitch] = useState(false)
  const [step, setStep] = useState((code && !user.plus) || stepTwo ? 2 : 1)
  const [_redeemCode, setRedeemCode] = useState(redeemCode)
  const { doModal } = useDoModal()
  const userSubscriptionsRef = useRef()
  const { paymentComplete } = device
  useEffect(() => {
    actions.setThing('paymentComplete', false)
    userActions.refreshUser()
  }, [actions, userActions])
  useEffect(() => {
    const currentSubscriptions = awnsOnly(path(['info', 'payments', 'subscriptions'], user) || [])
    const lastSubscriptions = awnsOnly(userSubscriptionsRef.current || [])
    if (intersection(currentSubscriptions, lastSubscriptions).length !== currentSubscriptions.length) {
      if (plusUserSwitch) {
        setPlusUserSwitch(false)
      }
      if (userSubscriptionsRef.current) {
        setStep(1)
      }
    }
    userSubscriptionsRef.current = path(['info', 'payments', 'subscriptions'], user) || []
  }, [user, plusUserSwitch, setPlusUserSwitch])
  const term = annual ? 'yearly' : 'monthly'
  const freeTrialAvail = showAwnFreeTrial(user)
  const active = false
  const btnLabel = freeTrialAvail ? 'Try 1 month free' : 'Upgrade'
  const monthlyPrice = getAwnPlusAppPrice(device, false) ? getAwnPlusAppPrice(device, false) + ' /mo' : '$4.99/mo'
  // const annualMonthlyPrice = getAwnPlusAppPrice(device, true) ? getAwnPlusAppPrice(device, true) + ' /yr' : <>$4.16/mo<sup>*</sup></>

  const showPlans = (
    <>
      <div className='product-tiers'>
        <div className={classNames(`tier tier-0 step-${step}`, { active, annual })}>
          <div className='top'>
            <h3>Standard</h3>
            <h2>Free</h2>
            <p>&nbsp;</p>
          </div>
          <div className='bottom'>
            <strong>Features</strong>
            <div className='group'>
              <div className='line'>
                <div className='label'>Map Layers</div>
                <div className='val'>
                  Radar, Temperature, Wind and Lightning Layers
                </div>
              </div>
              <div className='line'>
                <div className='label'>Notifications</div>
                <div className='val'>
                  E-mail & Push Alerts
                </div>
              </div>
              <div className='line'>
                <div className='label'>Hourly Forecast</div>
                <div className='val'>
                  3 Days
                </div>
              </div>
              <div className='line'>
                <div className='label'>Data History</div>
                <div className='val'>
                  1 Year
                </div>
              </div>
              <div className='line'>
                <div className='label'>Basic Graphing</div>
                <div className='val'>
                  Preset charts measuring individual parameters per - single station with customizable timeframes
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classNames(`tier tier-1 step-${step}`, { active, annual, trial: freeTrialAvail })}>
          <div className='top'>
            <h3>AWN+</h3>
            {!awnPlusAnnualMonthlySwitchAvail(user) && (
              <div className={classNames('switch-wrap', annual ? 'annual' : 'monthly')}>
                <div className={classNames('switch-container', annual ? 'annual' : 'monthly')}>
                  <span className='slider' />
                  <button
                    className={`toggle-btn ${annual ? 'active' : ''}`}
                    onClick={() => setAnnual(true)}
                  >
                    Annual
                    <div>
                      {getAwnPlusAppPrice(device, true) || '$49.99'}
                      <span>/year</span>
                    </div>
                  </button>
                  <button
                    className={`toggle-btn ${!annual ? 'active' : ''}`}
                    onClick={() => setAnnual(false)}
                  >
                    Monthly
                    <div>
                      {getAwnPlusAppPrice(device, false) || '$4.99'}
                      <span>/month</span>
                    </div>
                  </button>
                </div>
                <span className='discount'>Save 17%</span>
              </div>
            )}
            <p>
              <a onClick={() => {
                setAnnual(true)
                setStep(2)
              }}>Enter Gift code here</a> to redeem a Gift Subscription 
            </p>
            <button
              className='btn btn-primary'
              onClick={() => setStep(2)}
            >{btnLabel}</button>
            {/* <p className='trial'>Limited time free trial - ends {freeTrialUntil.format('MMMM Do, YYYY')}</p> */}
            <p className='trial'>Limited Time Offer - First Month Free!</p>
          </div>
          <div className='bottom'>
            <strong>Features</strong>
            <div className='group'>
              <div className='line'>
                <div className='label'>Map Layers</div>
                <div className='val'>
                  Air Quality, Snow Depth, Fires, Tropical Cyclones and Water Map <span>in addition to Standard Layers</span>
                </div>
              </div>
              <div className='line'>
                <div className='label'>Notifications</div>
                <div className='val'>
                  Configurable SMS Alerts (10 per day) <span>in addition to Push & Email Alerts</span>
                </div>
              </div>
              <div className='line'>
                <div className='label'>Hourly Forecast</div>
                <div className='val'>
                  10 Days
                </div>
              </div>
              <div className='line'>
                <div className='label'>Data History</div>
                <div className='val'>
                  3 Years
                </div>
              </div>
              <div className='line'>
                <div className='label'>Advanced Graphing</div>
                <div className='val'>
                  Multi Station & Multi Parameter Graphing, Enhanced Graph Controls & Customization, Increased Organization, Increased Export & Data handling Capabilities. <a onClick={() => userActions.doModal({ type: 'adgraph' })}>Details</a>
                </div>
              </div>
              <div className='line'>
                <div className='label'>Bonus Dashboard Tiles</div>
                <div className='val'>
                  Growing Degree Days, Heating Degree Days and Cooling Degree Days
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
  let header = (
    <h2>{message || 'Expanded Layers, Extended History, Precision Forecast'}</h2>
  )
  let show = ''
  if (paymentComplete) {
    message = 'Thank you for your purchase!'
    show = (
      <div className='payment-complete'>
        <div className='btns text-center'>
          <a className='btn-primary btn' onClick={() => doModal(null)}>Close</a>
        </div>
      </div>
    )
  } else if ((user.plus && !plusUserSwitch && !_redeemCode) || subscribedWithCode) {
    let plan = ''
    const monthlyChosen = subIsMonthly(user.plus)
    if (/free/.test(user.plus.productName)) {
      plan = 'Free'
    } else if (awnPlusAnnualMonthlySwitchAvail(user)) {
      plan = (
        <div className='switch-term'>
          <AmbRadio
            selected={!monthlyChosen}
            label={(
              <>
                Annual <span className='save'>{getAwnPlusAppPrice(device, true) ? getAwnPlusAppPrice(device, true) + ' /yr' : '$49.99/yr'}</span>
              </>
            )}
            onClick={() => {
              if (!monthlyChosen) {
                return
              }
              setAnnual(true)
              setPlusUserSwitch(true)
              setStep(2)
            }}
          />
          <AmbRadio
            selected={monthlyChosen}
            label={(
              <>
                Monthly <span className='save'>{monthlyPrice}</span>
              </>
            )}
            onClick={() => {
              if (monthlyChosen) {
                return
              }
              setAnnual(false)
              setPlusUserSwitch(true)
              setStep(2)
            }}
          />
        </div>
      )
    } else if (monthlyChosen) {
      plan = 'Monthly'
    } else {
      plan = 'Annual'
    }
    let trialShow = (
      <h3>You are subscribed to AWN+!</h3>
    )
    if (user.plus.status === 'trialing') {
      trialShow = (
        <>
          <h3>You are using the AWN+ free trial!</h3>
          <p>Free trial until {moment(user.plus.currentPeriodEnd * 1000).format('M/D/YYYY')}</p>
        </>
      )
    }
    if (subscribedWithCode) {
      let warning = (
        <p><b>Renews on:</b> {moment(user.plus.currentPeriodEnd * 1000).format('M/D/YYYY')}</p>
      )
      if (displayCancelPlusOnIosMessage(user)) {
        warning = (
          <div className='warning'>
            <p>
              To avoid being charged twice, you will need to manually cancel your Apple subscription to your current plan.
            </p>
            {isIosApp() && (
              <p>
                <a href='https://apps.apple.com/account/subscriptions' target='_blank' rel='noreferrer'>Cancel your Apple subscription</a>
              </p>
            )}
          </div>
        )
      }
      trialShow = (
        <div className='success box'>
          <h2>You have successfully activated your annual free AWN+ gift subscription!</h2>
          {warning}
        </div>
      )
    }
    show = (
      <div className={classNames('awn-plus-member', {
        switchAvail: awnPlusAnnualMonthlySwitchAvail(user),
        giftCode: subscribedWithCode
      })}>
        {trialShow}
        <div className='box plan-changer'>
          <div className='table'>
            <div>
              <span>Subscription Plan</span>
              <span>AWN+</span>
            </div>
            <div className='options'>
              <span>Billing option</span>
              <span>{plan}</span>
            </div>
            <div>
              <span>{user.plus.cancelAtPeriodEnd ? 'Expires' : 'Renews'}</span>
              <span>{user.plus.currentPeriodEnd ? moment(user.plus.currentPeriodEnd * 1000).format('M/D/YYYY') : '∞'}</span>
            </div>
          </div>
        </div>
        <div className='btns'>
          <Link to='/account/subscriptions#subscriptions' onClick={() => doModal(null)} className='btn btn-bordered'>Manage Subscription</Link>
          {!subscribedWithCode && (
            <a className='btn btn-primary' onClick={() => {
              setRedeemCode(true)
            }}>Redeem Gift Code</a>
          )}
        </div>
      </div>
    )
  } else if (_redeemCode && step === 1) {
    show = (
      <div className='dont-worry'>
        <h3>Your remaining subscription time will not be lost!</h3>
        <p>
          Please note, that when you redeem a gift code, the new annual gift subscription plan will be added to your active ANW+ plan.
        </p>
        <button className='btn btn-primary' onClick={() => setStep(2)}>Redeem Gift Code</button>
      </div>
    )
  } else if (step === 1) {
    show = showPlans
  } else if (step === 2) {
    let proRate = ''
    if (!isApp() && plusUserSwitch) {
      proRate = annual
        ? (
          <p className='prorate'>Your account will be prorated for the remaining time on your current monthly subscription. This means we'll credit the unused portion of your monthly plan against the cost of the yearly plan. You'll only be charged the difference. Enjoy the savings that come with your yearly subscription!</p>
        ) : (
          <p className='prorate'>
            Since you're moving from a yearly to a monthly plan, we will prorate the change. This means we will calculate the unused portion of your yearly subscription and apply this as a credit to your account. This credit will offset the cost of your upcoming monthly bills until it is fully used. Enjoy the flexibility of your new monthly subscription!
          </p>
        )
    }
    header = (
      <>
        <h2>{annual ? 'Annual' : 'Monthly'} subscription plan</h2>
        <h3>{message || 'Expanded Layers, Extended History, Precision Forecast'}</h3>
      </>
    )
    show = (
      <div>
        <SubscribeProcess
          plusUserSwitch={plusUserSwitch}
          user={user}
          subscription={`awn-plus-account-${term}`}
          setSubscription={newSub => {
            setAnnual(/yearly/.test(newSub))
          }}
          code={code}
        >
          {proRate}
        </SubscribeProcess>
        <p className='privacy'>
          <a rel='noreferrer' href='https://nkhome.com/terms' target='_blank'>Terms of Service</a>
          <span> and </span>
          <a rel='noreferrer' href='https://nkhome.com/privacy' target='_blank'>Privacy Policy</a>
        </p>
      </div>
    )
  }
  return (
    <div className='payment-plus-subscription-modal'>
      <div>
        <header className='awn-logo'>
          {header}
        </header>
        {show}
      </div>
    </div>
  );
};

PlusSubscriptionModal.propTypes = {};
PlusSubscriptionModal.defaultProps = {};

PlusSubscriptionModal.displayName = 'PlusSubscriptionModal'

export default bindDeviceActions(PlusSubscriptionModal)
