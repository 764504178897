import React, { useEffect, useState } from 'react'
import bindAllActions from '../../common/bindAllActions'
import { getThemeObj } from '../../common/skinner'
// import PropTypes from 'prop-types';

function DeleteAccount ({ socialActions, userActions }) {
  const [step, setStep] = useState(0)
  const [secondsLeft, setSecondsLeft] = useState(false)
  useEffect(() => {
    if (secondsLeft === false) return
    if (secondsLeft > 1) {
      setTimeout(() => {
        setSecondsLeft(secondsLeft - 1)
      }, 1000)
    } else {
      userActions.logoutUser()
    }
  }, [secondsLeft, userActions])
  let show = (
    <>
      To delete your {getThemeObj().sitename} account <a onClick={() => setStep(1)}>click here</a>.
    </>
  )
  if (step === 1) {
    show = (
      <>
        <p>Are you sure you want to delete your {getThemeObj().sitename} account? This will remove all your devices and personal data.</p>
        <p>
          <a className='btn btn-primary' onClick={() => setStep(0)}>Nevermind</a>
          <a
            className='btn'
            onClick={() => {
              socialActions.addUserAction({
                type: 'delete-account'
              })
              // setSecondsLeft(10)
              setStep(2)
            }}>
            Yes, delete my account
          </a>
        </p>
      </>
    )
  } else if (step === 2) {
    show = (
      <>
        <p>Okie doke.  We've initiated the account deletion process. <b>Please check your email to confirm your account deletion.</b> Thanks for using {getThemeObj().sitename}!</p>
      </>
    )
  }
  return (
    <div className='user-delete-account'>
      {show}
    </div>
  )
}

export default bindAllActions(DeleteAccount)

DeleteAccount.propTypes = {}
DeleteAccount.defaultProps = {}

DeleteAccount.displayName = 'DeleteAccount'
