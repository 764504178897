import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { path, pick } from 'ramda';
import classNames from 'classnames'
import { roundDownMins } from '../../common/lib/ambient-weather-common/ambient';
import EXIF from 'exif-js'
import { getDeviceLabel, ssisShareLink, trackEvent, getDeviceSlug, getDeviceShareLink, getDateTzForDevice, getUrl, isIos, pathsChanged, hasVideo } from '../../common/ambient';
import VisibilitySensor from 'react-visibility-sensor'
import fetch from 'isomorphic-fetch'
import ReactPlayer from 'react-player'
import {
  CopyLink
} from '../../components/'

export default class WebcamImage extends PureComponent {
  static propTypes = {
    playVideo: PropTypes.bool,
    currentDevice: PropTypes.object,
    onSuccess: PropTypes.func,
    onFail: PropTypes.func,
    onTimestamp: PropTypes.func
  }
  state = {
    imageFound: false,
    onScreen: false,
  }
  constructor(props) {
    super(props)
    this._fetchImage = ::this._fetchImage
    this._mounted = true
  }
  componentWillUnmount() {
    clearInterval(this._int)
    this._mounted = false 
  }
  _time() {
    this._t = roundDownMins(5, Date.now())
  }
  _url() {
    const { currentDevice } = this.props
    let url = path(['webcam', 'url'], currentDevice) || path(['info', 'webcam'], currentDevice)
    const iosKey = 'K6NGQaZTI3d6JabLcSGX25Y41BuBQPkVcJmeNHibVNhJ4k' 
    if (url) {
      if (/ambientweather\.net\/images/.test(url)) return url + (isIos() ? `?ios=${iosKey}` : '')
      if (/images\.ambientweather\.net/.test(url)) return url
      return getUrl() + `/images/test?url=${encodeURIComponent(url) + (isIos() ? `&ios=${iosKey}` : '')}`
    } 
    const username = path(['webcam', 'username'], currentDevice)
    if (username) return `https://images.ambientweather.net/${username}/latest.jpg`
  }
  _videoUrl() {
    return hasVideo(this.props.currentDevice)
  }
  _setState(data) {
    if (this._mounted) {
      this.setState(data)
    }
  }
  _fetchImage() {
    const url = this._url()
    if (!url) return
    const { onScreen } = this.state
    const { onTimestamp, onSuccess, onFail, currentDevice } = this.props
    // console.log(onScreen);
    // if (!onScreen) return

    this.setState({
      findingImage: true,
      timestamp: false
    }, () => {
      const img = new Image
      img.addEventListener('load', () => {
        if (/images\.ambientweather/.test(url)) {
          const _this = this
          EXIF.getData(img, function() {
            const dateTime = EXIF.getTag(this, 'DateTimeOriginal')
            let localDate = false
            if (dateTime) {
              localDate = getDateTzForDevice(moment, currentDevice, moment(dateTime + '+00:00', 'YYYY:MM:DD hh:mm:ssZ'))
            }
            _this._setState({
              timestamp: localDate
            })
            if (onTimestamp) {
              onTimestamp(localDate)
            }
            // todo parse for server errors
          })
        }
        this._setState({
          imageFound: true,
          findingImage: false
        })
        if (onSuccess) {
          onSuccess()
        }
      })
      img.addEventListener('error', (err) => {
        this._setState({
          findingImage: false,
          imageFound: false,
          timestamp: false
        })
        if (onFail) {
          onFail()
        }
      })
      img.src = url
    })
  }
  _playVideo() {
    return this.props.playVideo && this._videoUrl()
  }
  componentDidMount() {
    this._time()
    this._fetchImage()
    this._int = setInterval(() => {
      this._time()
      this._fetchImage()
    }, 1000 * 60 * 5)
  }
  componentDidUpdate(prevProps, prevState) {
    if (pathsChanged(this.props, prevProps, [['currentDevice', 'macAddress']])
    || pathsChanged(this.props, prevProps, [['currentDevice', 'webcam', 'url']])
    || pathsChanged(this.state, prevState, [['onScreen']])
    ) {
      this._fetchImage()
    }
  }

  render() {
    const { foundVideo, timestamp, imageFound, findingImage, onScreen } = this.state
    const { playVideo, message, currentDevice, onPlay } = this.props
    // const url = this._url() + (/\?/.test(this._url()) ? '&' : '?') + `t=${this._t}`
    const url = this._url()
    let show = <div className='not-found-message'>
        <h5>{message}</h5>
      </div>
    let style = { backgroundImage: `url(${url})`} 

    let copyBtn
    if (this._playVideo()) {
      style = {}
      const shareObj = {
        image: this._url(),
        video: {
          video:  this._videoUrl(),
          width: 640,
          height: 360
        },
        redirect: getDeviceShareLink(currentDevice) + '?show=webcam',
        title: `${getDeviceLabel(currentDevice)} - 24 hour timelapse`
      } 
      const shareLink = ssisShareLink('v', shareObj)
      copyBtn = <CopyLink 
        className='copy-link btn btn-primary'
        onClick={e => e.stopPropagation()}
        text={shareLink} 
        linkTitle='Live Feed URL' 
      />
      // if they dont want controls, then they might want to use the click VVVV
      show = <div className='video-container' onClick={this.props.controls !== false && (e => e.stopPropagation())}>
        <ReactPlayer
          className='video'
          url={this._videoUrl()}
          light={this._url()}
          controls
          muted
          loop
          width='100%'
          height='100%'
          onPlay={() => {
            trackEvent('webcam', 'play', getDeviceSlug(currentDevice))
            if (onPlay) onPlay()
          }}
          // this is stupid.....
          {...pick([
            'playing',
            'light',
            'controls',
            'playsinline',
            'onReady',
            'onStart',
            'onPause',
            'config',
            'onDuration',
            'onProgress',
            'onBuffer',
            'onBufferEnd',
            'progressInterval'], this.props
          )}
        />
      </div>
    } else if (imageFound || findingImage) {
      show = null
    }

    return <div className={classNames("device-webcam-image", { 
      finding: findingImage, 
      video: this._videoUrl(),
      'not-found': !imageFound,
      'play-video': this._playVideo(),
    })} style={style}>
      {timestamp ? <a className='timestamp' onClick={this._fetchImage}>{timestamp.fromNow()}</a> : null}
      {show}
      <a className='video-link' />
      {copyBtn}
    </div>
    // return (
    //   <VisibilitySensor onChange={os => this.setState({ onScreen: os})} partialVisibility>
    //   </VisibilitySensor>
    // )
  }
}

WebcamImage.displayName = 'WebcamImage'
