import React, { PureComponent } from 'react';
import classNames from 'classnames'
import {
  TimezonePicker,
  Location
} from '../../components'
import {
  BarometerInfo
} from './'
import { toggleDevicePublic, pathsChanged, isWxw } from '../../common/ambient';
import PropTypes from 'prop-types';
import { find, path } from 'ramda';
import bindSocialActions from '../social/redux/bindSocialActions';
import { getBusinessName, getThemeObj } from '../../common/skinner';
import { postToRainWise } from '../../common/postToRainWise';


class DeviceEditForm extends PureComponent {
  static propTypes = {
    currentDevice: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    actions: PropTypes.object, // social actions
    social: PropTypes.object,
    wxw: PropTypes.bool, // is this a wxw device?
  }

  _fetchActiveDevicePosts() {
    const { actions, currentDevice } = this.props
    if (currentDevice._id) {
      actions.fetchPosts({
        deviceId: currentDevice._id,
        expiresAt: {
          $gte: Date.now()
        }
      })
    }
  }
  componentDidMount() {
    this._fetchActiveDevicePosts()
  }
  componentDidUpdate(prevProps) {
    if (pathsChanged(this.props, prevProps, [['currentDevice', '_id']])) {
      this._fetchActiveDevicePosts()
    }
  }
  _isWxw() {
    return this.props.wxw || isWxw(this.props.currentDevice)
  }

  render() {
    const { className, children, currentDevice, onChange, social, namePlaceholder = 'Ex: My Weather Station' } = this.props
    const { allPosts } = social
    const deviceTz = path(['tz', 'name'], currentDevice)
    const showPostWarning = find(p => p.deviceId === path(['_id'], currentDevice) && Date.parse(p.expiresAt) > Date.now(), allPosts)
    console.log(currentDevice)
    return (
      <div className={classNames("device-device-edit-form", className)}>
        <h2>Now let's get some details:</h2>
        <label className="name">Give your device a name</label>
          <input
            type="text"
            className="name"
            ref={inp => this.nameInput = inp}
            value={currentDevice.info.name}
            placeholder={namePlaceholder}
            onChange={(evt) => {
              currentDevice.info.name = evt.target.value
              onChange(Object.assign({}, currentDevice), 'info')
            }}
          />
        <label className='loc'>Where is your device located?</label>
        <Location location={Object.assign({}, currentDevice.info.coords)} onChange={location => {
          const cd = Object.assign({}, currentDevice)
          if (location.coords) {
            cd.info.coords = location
            const relayData = Object.assign({}, {"macAddress":currentDevice.macAddress}, location)
            postToRainWise(relayData)
          } else {
            cd.info.coords = null
          }
          onChange(cd, 'info')
        }} />
        {showPostWarning ? <p className='warning'>Changing your device's location will expire your active social posts.</p> : null}
        <BarometerInfo currentDevice={currentDevice} />
        <div className="item tz">
          <label>Timezone</label>
          <TimezonePicker
            value={deviceTz}
            onChange={tz => {
              if (tz.name !== deviceTz){
                const relayData = Object.assign({}, 
                  {"macAddress":currentDevice.macAddress},
                  {"tz":{"name":tz.name}})
                postToRainWise(relayData)
                onChange(Object.assign({}, currentDevice, {
                  tz: {
                    name: tz.name
                  }
                }), 'tz')
              }
            }}
          />
        </div>
        <div className="sharemydevice">
          <label>Community</label>
          <p>
            <label className="normal"><input type="checkbox" name="public" checked={currentDevice.public || false} onChange={() => {
              onChange(toggleDevicePublic(currentDevice), 'public')
            }} /> Allow others to view my weather station data. </label>
            {currentDevice.public && !currentDevice.info.coords ? <span className="warning">Add a location to your device above so others can find it on the map.</span> : null}
          </p>
        </div>
        {children}
        <div className="privacy">
          <p>
            By setting your location, you will gain access to local forecasts, sunrise and sunset times, moon phases, and other location-based features on your dashboard. { getBusinessName() } reserves the right to utilize this data to improve the platform and help other services make better tools that incorporate weather data. <a href={ getThemeObj().privacyUrl } target="_blank">See privacy standards >></a>
          </p>
        </div>
      </div>
    )
  }
}

export default bindSocialActions(DeviceEditForm)
DeviceEditForm.displayName = 'DeviceEditForm'
