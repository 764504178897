import React from 'react';
// import PropTypes from 'prop-types';

export default function DeleteCard() {
  return (
    <div className="payment-delete-card">
      Component content: payment/DeleteCard
    </div>
  );
};

DeleteCard.propTypes = {};
DeleteCard.defaultProps = {};

DeleteCard.displayName = 'DeleteCard'
