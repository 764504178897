import React, { PureComponent } from 'react'
import classNames from 'classnames'
import bindAllActions from '../common/bindAllActions'
import PropTypes from 'prop-types'
import { path, isEmpty } from 'ramda'
import { getUserSetting, deviceIsMine, isFav, isLoggedIn, pathsChanged } from '../common/ambient'
import {
  ProtectedLink
} from './'

class FavStar extends PureComponent {
  static propTypes = {
    currentDevice: PropTypes.object,

    device: PropTypes.object,
    deviceActions: PropTypes.object,
    user: PropTypes.object,
    userActions: PropTypes.object
  }

  // moved to fetchUser 12.1.22 olw
  // _fetchFavs () {
  //   const { user, social, socialActions } = this.props
  //   const { favs, fetchUserActionsPendingDataKeys } = social
  //   if (favs) return
  //   if (!isLoggedIn(user) || fetchUserActionsPendingDataKeys.includes('favs')) return
  //   socialActions.fetchUserActions({
  //     userId: user.info._id,
  //     'to.type': 'device',
  //     dataKey: 'favs',
  //     $select: ['to'],
  //     $limit: 1000,
  //     $sort: { createdAt: -1 }
  //   })
  // }

  // componentDidMount () {
  //   this._fetchFavs()
  // }

  // componentDidUpdate (prevProps) {
  //   if (pathsChanged(this.props, prevProps, ['user', 'userChecked'])) {
  //     this._fetchFavs()
  //   }
  // }

  _userFavs () {
    const favs = getUserSetting('favs')(this.props.user) || {}
    return isEmpty(favs) ? false : favs
  }

  render () {
    const { currentDevice, device, social, userActions } = this.props
    if (!currentDevice || deviceIsMine(device, currentDevice._id)) {
      return null
    }
    const { socialActions } = this.props
    let favs = this._userFavs()
    const fav = isFav(social, currentDevice)
    return (
      <ProtectedLink
        className={classNames('favstar', { on: fav })}
        onClick={(e) => {
          if (fav) {
            socialActions.removeUserAction(fav._id)
            delete favs[path(['to', 'macAddress'], fav)]
          } else {
            socialActions.addUserAction({
              to: {
                _id: currentDevice._id,
                macAddress: currentDevice.macAddress,
                type: 'device'
              },
              type: 'fav'
            })
            favs = favs || {}
            favs[currentDevice.macAddress] = {}
          }
          userActions.updateSetting('favs', favs)
          e.stopPropagation()
          e.preventDefault()
        }}
      />
    )
  }
}

export default bindAllActions(FavStar)
FavStar.displayName = 'FavStar'
