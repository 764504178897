import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { getDeviceLabel, getDeviceSetting, hasWxw, wxwDevices } from '../../common/ambient'
import bindDeviceActions from './redux/bindDeviceActions'
import { path, pluck, uniq } from 'ramda'
import { AmbRadio, Location } from '../../components'
import WxwEdit from './WxwEdit'
import WxwPreview from './WxwPreview'
import classNames from 'classnames'
import { useDoModal } from '../user/redux/doModal'
// import PropTypes from 'prop-types';

/**
 * 
 * 
 * 
 */

function WxwDefault ({ device, actions }) {
  const [macAddress, setMacAddress] = React.useState('')
  const [layoutEditing, setLayoutEditing] = React.useState(null)
  const [showPreview, setShowPreview] = React.useState(true)
  const { doModal } = useDoModal()
  useEffect(() => {
    if (!device.devices) return
    const wxws = wxwDevices(device)
    if (wxws.length > 0 && !macAddress) {
      setMacAddress(wxws[0].macAddress)
    }
  }, [device, macAddress])
  const themes = ['Light', 'Dark', 'Dynamic']
  const wxws = wxwDevices(device)
  const currentDevice = wxws.find(wxw => wxw.macAddress === macAddress)
  const wxwSetting = getDeviceSetting('wxw')(currentDevice) || {}

  const patchWxwSetting = newWxwSetting => {
    const settings = currentDevice.settings || {}
    settings.wxw = Object.assign({}, wxwSetting, newWxwSetting)
    setShowPreview(false)
    return actions.patch(currentDevice._id, { settings })
      .then(() => setShowPreview(true))
  }


  let title = 'Weather Window'
  let cta = <Link to='/devices?open=true' className='btn white'>Register</Link>
  let show = (
    <>
      <h2>Dont' have a Weather Window yet?</h2>
      <p>
        <a className='btn white' target='_blank' href='http://ambientweather.com'>Buy Weather Window</a>
      </p>
      <p>
        View all your weather in one window! With a vibrant LCD, the highly configurable AWN Weather Window displays personalized weather information from your dashboard and any public AWN dashboard. With easy setup and Wi-Fi connectivity, you can view the hyper-local forecast and weather measurements anywhere within your home.
      </p>
    </>
  )
  if (layoutEditing) {
    title = `Configure "${getDeviceLabel(currentDevice)}"`
    const close = () => {
      setLayoutEditing(false)
      const inactiveLayouts = (wxwSetting.layouts || []).filter(l => l.inactive)
      if (inactiveLayouts.length > 0) {
        patchWxwSetting({ layouts: wxwSetting.layouts.filter(l => !l.inactive) })
      }
    }
    cta = <a className='btn white' onClick={close}>Cancel Configuration</a>
    show = (
      <WxwEdit
        device={device}
        macAddress={macAddress}
        layout={layoutEditing}
        onCreate={layoutConfig => {
          wxwSetting.layouts = wxwSetting.layouts || []
          let layouts
          if (wxwSetting.layouts.find(l => l.id === layoutConfig.id)) {
            layouts = wxwSetting.layouts.map(l => l.id === layoutConfig.id ? layoutConfig : l)
          } else {
            layouts = [...wxwSetting.layouts, layoutConfig]
          }
          const newConfig = Object.assign({}, wxwSetting, { layouts })
          if (!newConfig.activeLayout || !newConfig.layouts.find(l => l.id === newConfig.activeLayout)) {
            newConfig.activeLayout = newConfig.layouts[0].id
          }
          return patchWxwSetting(newConfig)
        }}
        onChange={layoutConfig => {
          if (!layoutConfig) {
            return close()
          }
          console.log('calling', layoutConfig)
          wxwSetting.layouts = wxwSetting.layouts || []
          let layouts
          if (wxwSetting.layouts.find(l => l.id === layoutConfig.id)) {
            layouts = wxwSetting.layouts.map(l => l.id === layoutConfig.id ? layoutConfig : l)
          } else {
            layouts = [...wxwSetting.layouts, layoutConfig]
          }
          const newConfig = Object.assign({}, wxwSetting, { layouts })
          if (!newConfig.activeLayout || !newConfig.layouts.find(l => l.id === newConfig.activeLayout)) {
            newConfig.activeLayout = newConfig.layouts[0].id
          }
          patchWxwSetting(newConfig)
            .then(() => setLayoutEditing(false))
        }}
      />
    )
  } else if (hasWxw(device)) {
    title = 'Configure Weather Window'
    cta = <Link to='/devices?open=true' className='btn white'>Add Another Weather Window</Link>
    const theme = wxwSetting.theme || themes[0].toLocaleLowerCase()
    show = (
      <>
        <div className='wxw-line'>
          <h2>1. Select your Weather Window</h2>
          <select
            className='form-control'
            onChange={evt => setMacAddress(evt.target.value)}
            value={macAddress}
          >
            {wxws.map(wxw => <option key={wxw.macAddress} value={wxw.macAddress}>{path(['info', 'name'], wxw)} - {wxw.macAddress}</option>)}
          </select>
        </div>
        <div className='wxw-line'>
          <h2>2. Set the location for your full screen Weather Forecast</h2>
          <Location
            location={wxwSetting.forecastLocation}
            onChange={location => {
              const settings = currentDevice.settings || {}
              settings.wxw = Object.assign({}, wxwSetting, { forecastLocation: location, forecastLayout: 0 })
              if (!location.coords) {
                delete settings.wxw.forecastLocation
              }
              actions.patch(currentDevice._id, { settings })
            }}
          />
          {wxwSetting.forecastLocation && (
            <div className='forecast-layout'>
              <h3>2b. Please select the level of detail you want your Weather Forecast to have</h3>
              <AmbRadio
                label={<span>7 hour + 5 day Forecast <b>(easier to read)</b></span>}
                selected={!wxwSetting.forecastLayout}
                onClick={() => {
                  patchWxwSetting({ forecastLayout: 0 })
                }}
              />
              <AmbRadio
                label={<span>10 hour + 10 day Forecast <b>(more forecast data)</b></span>}
                selected={wxwSetting.forecastLayout === 1}
                onClick={() => {
                  patchWxwSetting({ forecastLayout: 1 })
                }}
              />
            </div>
          )}
        </div>
        {wxwSetting.forecastLocation && (
          <>
            <div className='wxw-line'>
              <h2>3. Select your Theme</h2>
              <select
                className='form-control theme'
                onChange={evt => {
                  patchWxwSetting({ theme: evt.target.value })
                }}
                value={theme}
              >
                {themes.map(t => <option key={t} value={t.toLowerCase()}>{t}</option>)}
              </select>
              <p className='help'>
                {wxwSetting.theme === 'dynamic' ? 'Dynamic background changes the dashboard background imagery with weather conditions.' : `Static background provides a cleaner appearance on the dashboard and forecast screens.`}
              </p>
            </div>
            <div className='wxw-line'>
              <header>
                <h2>
                  <span>4. My Dashboards</span>
                  <a className='btn btn-primary' onClick={() => {
                    setLayoutEditing({})
                    window.scrollTo(0, 0)
                  }}>Add New Layout</a>
                </h2>
                <a className='' onClick={() => {
                  doModal({
                    type: 'wxw-tile-gallery'
                  })
                }}>View all available tiles</a>
              </header>
              <div className='wxw-layouts'>
                {wxwSetting.layouts && wxwSetting.layouts.map(layout => (
                  <div className={classNames('wxw-layout', { active: wxwSetting.activeLayout === layout.id })} key={layout.id}>
                    <header>
                      <h3>{layout.title}</h3>
                      <a
                        className='pencil icon'
                        onClick={() => {
                          setLayoutEditing(layout)
                        }}
                      />
                      <a
                        className='trash icon'
                        onClick={() => {
                          if (window.confirm('Are you sure you want to delete this layout?')) {
                            const layouts = wxwSetting.layouts.filter(l => l.id !== layout.id)
                            const newConfig = Object.assign({}, wxwSetting, { layouts })
                            if (!newConfig.activeLayout || !newConfig.layouts.find(l => l.id === newConfig.activeLayout)) {
                              if (newConfig.layouts.length !== 0) {
                                newConfig.activeLayout = newConfig.layouts[0].id
                              }
                            }
                            patchWxwSetting(newConfig)
                          }
                        }}
                      />
                    </header>
                    {showPreview && <WxwPreview macAddress={macAddress} layoutId={layout.id} />}
                    <footer>
                      <a
                        className='btn btn-primary'
                        disabled={wxwSetting.activeLayout === layout.id}
                        onClick={() => { patchWxwSetting({ activeLayout: layout.id }) }}
                      >{wxwSetting.activeLayout === layout.id ? 'Activated' : 'Set as Active'}</a>
                      <span className='active' />
                    </footer>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </>
    )
  }
  return (
    <div className='device-wxw-default'>
      <header className='main'>
        <h1>
          {title}
        </h1>
        {cta}
      </header>
      <div className='page-body'>
        <div className='block'>
          {show}
        </div>
      </div>
    </div>
  )
}
export default bindDeviceActions(WxwDefault)

WxwDefault.propTypes = {}
WxwDefault.defaultProps = {}

WxwDefault.displayName = 'WxwDefault'
