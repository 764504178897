import {
  FOCUS_DEVICE
} from './constants'

export function focusDevice (data) {
  return {
    type: FOCUS_DEVICE,
    data
  }
}

export function reducer (state, action) {
  switch (action.type) {
    case FOCUS_DEVICE:
      return {
        ...state,
        focusDevice: action.data
      }

    default:
      return state
  }
}
