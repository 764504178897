import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import classNames from 'classnames'

export default class ImageUploadLink extends PureComponent {
  static propTypes = {
    onSuccess: PropTypes.func,
    pickerOpts: PropTypes.object
  }

  componentDidMount () {
    if (!window.filepicker) {
      const s = document.createElement('script')
      s.type = 'text/javascript'
      // s.src = '//api.filestackapi.com/filestack.js'
      s.src = 'https://static.filestackapi.com/filestack-js/3.x.x/filestack.min.js'
      document.head.appendChild(s)
    }
  }

  render () {
    const { className, onSuccess, children, pickerOpts } = this.props
    return (
      <a
        className={classNames('component-image-upload-link', className)}
        onClick={() => {
          const client = filestack.init("AfHgMsa53Rv6WaWHxxZOfz")
          client.picker(Object.assign({
            accept: ['image/*'],
            fromSources: ['local_file_system', 'url', 'facebook', 'instagram'],
            onUploadDone: res => {
              if (res.filesUploaded[0] && onSuccess) {
                onSuccess(res.filesUploaded[0].url, res)
              }
            }
          }, pickerOpts)).open()
        // filepicker.setKey("AfHgMsa53Rv6WaWHxxZOfz")
        // filepicker.pick(Object.assign({
        //   mimetype: 'image/*',
        //   services: ['COMPUTER', 'CONVERT', 'IMAGE_SEARCH'],
        //   conversions: ['crop'],
        //   cropRatio: 1/1
        // }, pickerOpts || {}), (Blob) => {
        //   if (onSuccess) {
        //     onSuccess(Blob.url)
        //   }
        // }, err => console.log(err)
        // )
        }}
      >{children}</a>
    )
  }
}

ImageUploadLink.displayName = 'ImageUploadLink'
