import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

export default class PublicHeader extends PureComponent {
  static propTypes = {

  };

  render() {
    return (
      <div className="component-public-header">
        <div className="wrap">
          <a className="navbar-brand" href="https://ambientweather.net" >
            <span>Your Personal Weather Dashboard</span>
          </a>
          <div className="buttons">
            <Link className="btn" to="/devices">Add Device</Link>
            <Link className="btn-primary btn" to="/">Login</Link>
          </div>
        </div>
      </div>
    );
  }
}

PublicHeader.displayName = 'PublicHeader'

PublicHeader.displayName = 'PublicHeader'

PublicHeader.displayName = 'PublicHeader'

PublicHeader.displayName = 'PublicHeader'

PublicHeader.displayName = 'PublicHeader'

PublicHeader.displayName = 'PublicHeader'

PublicHeader.displayName = 'PublicHeader'
