import {
  ADD_USER_ACTION_BEGIN,
  ADD_USER_ACTION_SUCCESS,
  ADD_USER_ACTION_FAILURE,
  ADD_USER_ACTION_DISMISS_ERROR,
} from './constants';
import { serviceCreate } from '../../../common/feathers'
import { pick, prepend } from 'ramda'
import { trackEvent } from '../../../common/ambient';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useCallback } from 'react';

export const addUserAction = serviceCreate('userActions', {
  begin: ADD_USER_ACTION_BEGIN,
  success: ADD_USER_ACTION_SUCCESS,
  fail: ADD_USER_ACTION_FAILURE
})


export function dismissAddUserActionError() {
  return {
    type: ADD_USER_ACTION_DISMISS_ERROR,
  };
}

export function useAddUserAction() {
  const dispatch = useDispatch();

  const { addUserActionPending, addUserActionError } = useSelector(
    state => ({
      addUserActionPending: state.social.addUserActionPending,
      addUserActionError: state.social.addUserActionError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(addUserAction(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissAddUserActionError());
  }, [dispatch]);

  return {
    addUserAction: boundAction,
    addUserActionPending,
    addUserActionError,
    dismissAddUserActionError: boundDismissError,
  };
}

// how to alter the post we're adding to
const config = {
  like: (p, data) => {
    p.likes = p.likes || 0
    // after save
    if (data._id) {
      p.liked = data._id
    // before save
    } else {
      p.likes += 1
      p.liked = true // this will be replaced soon enough
    }
    return p
  },
  comment: (p, data) => {
    p.comments = p.comments || 0
    // after save
    if (data._id) {
      p.recentComments = p.recentComments || []
      p.recentComments.push(data)
    // before save
    } else {
      p.comments += 1
    }
    return p
  }
}

const allPostsForLike = (data, allPosts) => {
  // liking...
  if (Object.keys(config).includes(data.type)) {
    // a post
    if (data.to.type === 'post') {
      if (!data._id) {
        trackEvent('social', data.to.type, data.type)
      }
      return allPosts.map(p => {
        if (p._id === data.to._id) {
          p = config[data.type](p, data)
        }
        return p
      })
    }
  }
  return allPosts
}

const updateFavs = (data, favs) => {
  if (data.type !== 'fav') return favs
  if (!favs || data.to.type !== 'device') return favs
  return prepend(pick(['_id', 'to'], data), favs.filter(f => f.to._id !== data.to._id))
}

export function reducer(state, action) {
  switch (action.type) {
    case ADD_USER_ACTION_BEGIN:
      return {
        ...state,
        addUserActionPending: true,
        addUserActionError: null,
        allPosts: allPostsForLike(action.data, state.allPosts),
        favs: updateFavs(action.data, state.favs),
      }

    case ADD_USER_ACTION_SUCCESS:
      return {
        ...state,
        addUserActionPending: false,
        addUserActionError: null,
        allPosts: allPostsForLike(action.data, state.allPosts),
        favs: updateFavs(action.data, state.favs),
      };

    case ADD_USER_ACTION_FAILURE:
      return {
        ...state,
        addUserActionPending: false,
        addUserActionError: action.data.error,
      };

    case ADD_USER_ACTION_DISMISS_ERROR:
      return {
        ...state,
        addUserActionError: null,
      };

    default:
      return state;
  }
}
