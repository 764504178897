import {
  CLEAR_USERS,
} from './constants';

export function clearUsers() {
  return {
    type: CLEAR_USERS,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CLEAR_USERS:
      return {
        ...state,
        users: false
      };

    default:
      return state;
  }
}
