import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import bindUserActions from './redux/bindUserActions'
import { getUserSetting } from '../../common/ambient'

class SettingSelect extends PureComponent {
  static propTypes = {
    user: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    setting: PropTypes.string.isRequired,
    settingKey: PropTypes.string.isRequired,
    values: PropTypes.array.isRequired,
    label: PropTypes.string
  };

  render() {
    const { user, actions, setting, settingKey, values, label } = this.props
    const thisSetting = getUserSetting(setting)(user) || {}
    const options = values.map((arr) => {
      return <option key={arr[1]} value={arr[1]}>{arr[0]}</option>
    })
    return (
      <select
        className="user-setting-select form-control"
        value={thisSetting[settingKey]}
        onChange={(evt) => {
          const value = evt.target.value
          thisSetting[settingKey] = value
          actions.updateSetting(setting, thisSetting)
        }}
      >
        <option>{label}</option>
        {options}
      </select>
    );
  }
}
export default bindUserActions(SettingSelect)

SettingSelect.displayName = 'SettingSelect'
