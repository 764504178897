import React, { useEffect, useRef, useState, useCallback } from 'react'
// import PropTypes from 'prop-types';
import { DisplayCard, AllPayments, userHasCardOnFile, getAwnPlusAppPrice, freeTrialUsed, isPlus, plusSubHasCode } from './'
import { Loader } from '../../components'
import classnames from 'classnames'
import { path } from 'ramda'
import { useRefreshUser } from '../user/redux/refreshUser'
import { appIsGteVersion, isAndroidApp, isApp, isIosApp } from '../../common/ambient'
import { billingFetch, billingSubscribe, checkCode } from './billingAccess'
import { useAddUserAction } from '../social/redux/addUserAction'
import bindDeviceActions from '../device/redux/bindDeviceActions'

const isGiftCodeProcess = (codeValidity) => {
  if (isApp() && codeValidity !== true) {
    return false
  }
  return true
}

function SubscribeProcess ({ user, subscription, children, device, actions, code = '', plusUserSwitch, setSubscription }) {
  const [hasPaymentMethod, setHasPaymentMethod] = useState(userHasCardOnFile(user))
  const [loading, setLoading] = useState(false)
  const [_code, setCode] = useState(code)
  const [_plusUserSwitch, setPlusUserSwitch] = useState(plusUserSwitch)
  const [codeValidity, setCodeValidity] = useState(null)
  const intRef = useRef(null)
  const { refreshUser } = useRefreshUser()
  const { addUserAction } = useAddUserAction()
  const isPlusUser = isPlus(user)
  useEffect(() => {
    return () => {
      clearInterval(intRef.current && intRef.current.int)
    }
  }, [])
  useEffect(() => {
    if (device.appPurchaseFlowCanceled && loading) {
      setLoading(false)
      actions.setThing('appPurchaseFlowCanceled', false)
    }
  }, [device, loading, setLoading, actions])

  const annual = /yearly/.test(subscription)
  const appSubscriptionId = annual ? 281025 : 281024
  // btnBuyHandler ---------------------------------
  const btnBuyHandler = useCallback(async () => {
    setLoading(true)
    actions.setThing('appPurchaseFlowCanceled', false)
    if (typeof window.saveAppleSubscriptionPricingInfo === 'undefined') {
      window.saveAppleSubscriptionPricingInfo = (data) => {
        addUserAction({
          data,
          // we need "to" for the index
          to: {
            type: 'user',
            _id: user.info._id
          },
          type: 'iap-subscription'
        })
      }
    }
    const userId = path(['info', '_id'], user)
    // iOS native flow
    if (isIosApp() && !isGiftCodeProcess(codeValidity)) {
      if (!appIsGteVersion('4.5.0')) {
        setLoading(true)
        actions.setThing('appPurchaseFlowCanceled', true)
        window.alert('Please update your app to the latest version to subscribe.')
        window.open('https://apps.apple.com/us/app/ambient-weather-network/id1426025887', '_blank')
        return
      }
      window.webkit.messageHandlers.buySubscriptionWebNotification.postMessage({ userId, subscriptionId: appSubscriptionId })
    // android native flow
    } else if (isAndroidApp() && !isGiftCodeProcess(codeValidity)) {
      if (!appIsGteVersion('4.5.0')) {
        setLoading(true)
        actions.setThing('appPurchaseFlowCanceled', true)
        window.alert('Please update your app to the latest version to subscribe.')
        window.open('https://play.google.com/store/apps/details?id=net.ambientweather.dashboard', '_blank')
        return
      }
      window.ANDROID && window.ANDROID.buySubscriptionWebNotification(appSubscriptionId, userId)
    // stripe flow (not ios or android native app)
    } else {
      const billingSubOpts = {}
      let productName = subscription
      // changing product name requires a PUT
      if (user.plus && user.plus.productName !== productName) {
        billingSubOpts.body = { new_product_name: productName }
        productName = user.plus.productName
        billingSubOpts.method = 'PUT'
      }
      if (codeValidity === true) {
        billingSubOpts.promoCode = _code
      }
      if (user.plus && codeValidity === true) {
        await billingFetch(`/subscriptions/apply-promo-code/?userId=${path(['info', '_id'], user)}&productName=${user.plus.productName}&promoCode=${_code}`, { method: 'POST' })
      } else {
        await billingSubscribe(path(['info', '_id'], user), null, productName, billingSubOpts)
      }
      intRef.current = {
        int: setInterval(() => {
          intRef.current.count++
          refreshUser()
          if (intRef.current.count >= 10) {
            clearInterval(intRef.current.int)
          }
        }, 1500),
        count: 0
      }
    }
  }, [user, _code, subscription, codeValidity, actions, refreshUser, addUserAction, appSubscriptionId])
  // btnBuyHandler ---------------------------------
  const streamlinedCodeProcess = isPlusUser && !_plusUserSwitch && hasPaymentMethod
  useEffect(() => {
    if (streamlinedCodeProcess && codeValidity === true) {
      btnBuyHandler()
    }
  }, [codeValidity, streamlinedCodeProcess, btnBuyHandler])

  const _checkCode = async (code) => {
    if (!_code) return
    setLoading(true)
    const res = await checkCode(_code)
    const allGood = path(['valid'], res) === true
    setCodeValidity(allGood)
    setLoading(false)
  }

  let price = annual ? '$49.99' : '$4.99'
  let btnDisabled = !hasPaymentMethod || loading
  if (isApp()) {
    if (!isGiftCodeProcess(codeValidity)) {
      btnDisabled = false
    }
    if (getAwnPlusAppPrice(device, annual)) {
      price = getAwnPlusAppPrice(device, annual)
    }
  }
  let btnText = 'Upgrade'
  let codeError = false
  let priceSubCopy = ''
  let codeValid = false
  let codeBtn = 'Apply'
  let extraText = ''
  let btnFunc = btnBuyHandler
  if (streamlinedCodeProcess && codeValidity !== true) {
    btnFunc = _checkCode
    btnText = 'Redeem Code'
  }
  if (!freeTrialUsed(user, 'awn-plus-free-trial')) {
    priceSubCopy = 'First month free trial'
  }
  if (codeValidity === true) {
    btnText = 'Finish'
    price = '$0.00'
    priceSubCopy = 'Free year then $49.99/year'
    codeValid = true
    codeBtn = 'Applied'
    if (!hasPaymentMethod) {
      extraText = 'To activate your gift subscription, please register your card first.'
    }

  // submitted code is invalid
  } else if (codeValidity === false) {
    codeError = true
    codeBtn = 'Try again'
  }

  let show = ''
  if (path(['info', '_id'], user)) {
    let codeShow = (
      <>
        {codeValid !== true && <p>Enter your code to redeem a Gift Subscription</p>}
        <form
          onSubmit={evt => {
            evt.preventDefault()
            _checkCode()
            return false
          }}
        >
          <div className='input-group'>
            <input type='text' disabled={codeValid} value={_code} onChange={evt => setCode(evt.target.value.toUpperCase())} />
            <button type='submit' disabled={codeValid} className='btn btn-primary'>{codeBtn}</button>
          </div>
          {codeError && <p className='error'>Invalid code</p>}
        </form>
      </>
    )
    if (!annual) {
      codeShow = (
        <>
          <p>
            Do you have a gift code for a FREE AWN+ annual subscription?
          </p>
          <p>
            <a onClick={() => {
              setSubscription(subscription.replace('monthly', 'yearly'))
              setPlusUserSwitch(false)
            }}>Redeem gift code here</a>
          </p>
        </>
      )
    }
    show = (
      <>
        {isGiftCodeProcess(codeValidity) && (
          <>
            <DisplayCard />
            <AllPayments
              userId={path(['info', '_id'], user)}
              mode='file'
              onSuccess={setHasPaymentMethod}
            />
          </>
        )}
        <div className={classnames('cart', { error: codeError })}>
          <div className='code'>
            {codeShow}
          </div>
          <div className='price'>
            <div>{price}/{annual ? 'year' : 'month'}</div>
            {priceSubCopy && <b>{priceSubCopy}</b>}
            {annual && <p>-17% discount ($4.16/month)</p>}
          </div>
        </div>
        <div className='buyBtn'>
          <button
            className='btn btn-primary buy-plan'
            disabled={btnDisabled}
            onClick={btnFunc}
          >
            {btnText}
          </button>
          {extraText && <p><b>{extraText}</b></p>}
          {loading ? <><br /><p>Please wait</p><Loader /></> : ''}
          {children}
        </div>
      </>
    )
  }
  return (
    <div className={classnames('subscribe-process', { plus: streamlinedCodeProcess })}>
      {show}
    </div>
  )
}

SubscribeProcess.propTypes = {}
SubscribeProcess.defaultProps = {}

SubscribeProcess.displayName = 'SubscribeProcess'

export default bindDeviceActions(SubscribeProcess)
