import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { set, join, lensProp, replace, bind, splitEvery, take, path, pipe } from 'ramda'
import bindAllActions from '../../common/bindAllActions'

class PhoneProcess extends PureComponent {
  static propTypes = {
    user: PropTypes.object.isRequired,
    userActions: PropTypes.object.isRequired
  };

  constructor (props) {
    super(props)
    this.state = {
      phone: '',
      code: '',
      codeTries: 0,
      tooMany: false
    }
  }

  componentWillUpdate (nextProps, nextState) {
    const { user, userActions } = this.props
    if (nextState.codeTries > 2) {
      userActions.patch(user.info._id, { phone: '', phoneConfirmKey: false })
      this.setState({
        phone: '',
        codeTries: 0,
        tooMany: true
      })
    }
  }

  render () {
    const { user, userActions } = this.props
    const { phone, code, codeTries, tooMany } = this.state
    let message = ''
    if (!path(['info', 'phone'], user)) {
      message = (
        <div>
          {tooMany ? <p className="alert alert-danger">Too many tries!</p> : ''}
          <p>Enter your phone number below. Standard message rates apply.</p>
          <div className="form-row">
            <input
              className="form-control"
              value={phone}
              type="text"
              placeholder="Phone"
              onChange={pipe(
                path(['target', 'value']),
                replace(/[^0-9]/g, ''),
                splitEvery(3),
                join('-'),
                replace(/(\d\d\d-\d\d\d-\d\d\d)-(\d)/, '$1$2'),
                take(12),
                set(lensProp('phone')),
                bind(this.setState, this)
              )}
            />
            <button
              disabled={phone.length !== 12}
              className="btn btn-primary"
              onClick={() => {
                userActions.patch(user.info._id, { phone })
                this.setState({
                  tooMany: false
                })
              }}
            >Save</button>
          </div>
        </div>
      )} else if (path(['info', 'phoneConfirmKey'], user)) {
      message = (
        <div>
          <p>Enter your phone number below. Standard message rates apply.</p>
          <div className="form-row">
            <p className="warning">
              Please confirm the 4 digit code sent to {user.info.phone}<small><a className="glyphicon glyphicon-trash" onClick={() => {
                if (window.confirm('Are you sure you\'d like to remove this phone number?')) {
                  userActions.patch(user.info._id, { phone: '', phoneConfirmKey: false })
                }
              }} /></small>
            </p>
            <input
              className="form-control"
              value={code}
              type="text"
              placeholder="Enter Code"
              onChange={pipe(
                path(['target', 'value']),
                replace(/[^0-9]/g, ''),
                take(4),
                set(lensProp('code')),
                bind(this.setState, this)
              )}
            />
            <button
              disabled={code.length !== 4}
              className="btn btn-primary"
              onClick={() => {
                if (code === user.info.phoneConfirmKey) {
                  userActions.patch(user.info._id, { phoneConfirmKey: false })
                } else {
                  this.setState({
                    code: '',
                    codeTries: codeTries + 1
                  })
                }
              }}
            >Confirm</button>
          </div>
        </div>
      )}
    return (
      <div className='user-phone-process'>
        {message}
      </div>
    )
  }
}

export default bindAllActions(PhoneProcess)

PhoneProcess.displayName = 'PhoneProcess'
