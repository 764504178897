import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { FormattedDataPoint } from '../features/device';
import { getUserUnitI } from '../common/ambient';
import PropTypes from 'prop-types';
import { range, path } from 'ramda';
import { ResizeObserver } from '@juggle/resize-observer';
import {
  DayForecast
} from './';
import bindUserActions from '../features/user/redux/bindUserActions';


class ForecastLongTerm extends PureComponent {
  static propTypes = {
    forecast: PropTypes.object,
    user: PropTypes.object.isRequired,
    expanded: PropTypes.bool,
    now: PropTypes.number
  }

  state = {

  }

  _ref (ref) {
    if (!this.resizeObserver) {
      this.resizeObserver = new ResizeObserver((entries) => {
        this.setState({
          width: entries[0].contentRect.width,
          height: entries[0].contentRect.height,
        })
      })
      this.resizeObserver.observe(ref);
    }
  }

  componentWillUnmount () {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  render () {
    const { now, onClose, user, forecast, expanded } = this.props
    const { width } = this.state
    if (!forecast) return null
    const { currently = {}, hourly, daily = {} } = forecast
    const days = (daily.data || []).filter(f => moment(f.time * 1000).startOf('day').valueOf() >= moment(now).startOf('day').valueOf())
    const today = days[0]
    let daysToShow = 5
    let hourlyShow
    if (expanded) {
      daysToShow = 10
      let lastDay = moment(currently.time * 1000).dayOfYear()
      let newDays = 0
      const hourlyData = (hourly && hourly.data) || []
      const combinedData = [currently, ...hourlyData].slice(0, 48)
      hourlyShow = (
        <div className='hourly-wrap'><div className='hourly'>{combinedData.map((hour, i) => {
          if (!hour) return null; // Skip if hour data is missing

          const m = moment(hour.time * 1000);
          let title = i === 0 ? 'Now' : m.format(getUserUnitI('hour24', user) === 1 ? 'H' : 'h');

          if (lastDay !== m.dayOfYear()) {
            lastDay = m.dayOfYear();
            title = m.format('M/D');
            newDays++;
          }
          return (
            <div key={`hour-${i}`} className={classNames('hour', { alt: newDays === 1 })}>
              <div className='title'>{title}</div>
              <div className={classNames('weather-icon', hour.icon)} />
              <FormattedDataPoint type='tempf' value={hour.temperature} after='°' valueTransform={Math.round} />
            </div>
          )
        })}</div></div>
      )
    }
    const otherDays = days.slice(1, 1 + daysToShow)
    return (
      <div
        ref={this._ref.bind(this)}
        className={classNames('device-forecast-widget component-forecast-long-term', {
          md: width >= 260,
          lg: width >= 320,
          'has-hourly': expanded
        })}
      >
        <a className={classNames('close', { 'on-close': onClose })} onClick={onClose} />
        <div className='forecast-text'>
          <FormattedDataPoint type='tempf' value={currently.temperature} after='°' />
          &nbsp;
          <span>{path(['minutely', 'summary'], forecast)} </span>
          <span>{path(['hourly', 'summary'], forecast)} </span>
        </div>
        <DayForecast
          className='today'
          title='Today'
          forecast={today}
        />
        {hourlyShow}
        <div className='other-days'>
          {otherDays.map(f => (
            <DayForecast
              key={`forecast-${f.time}`}
              title={moment(f.time * 1000).format('dd').slice(0, 1)}
              forecast={f}
            />))
          }
        </div>
      </div>
    )
  }
}

export default bindUserActions(ForecastLongTerm)
ForecastLongTerm.displayName = 'ForecastLongTerm'
