import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom'
import { getUrlQueryParam } from '../../common/ambient';
import {
  HelpLinks,
  FavStar
} from '../../components/'
import { getThemeObj } from '../../common/skinner';
import bindSocialActions from '../social/redux/bindSocialActions';

class WhereTo extends Component {
  static propTypes = {
  }

  componentDidMount () {
    if (getUrlQueryParam(this.props.location, 'macAddress') ||
      getUrlQueryParam(this.props.location, 'mac') ||
      getUrlQueryParam(this.props.location, 'ref')) {
      this.props.history.push('/devices' + this.props.location.search)
    }
  }

  render () {
    const { fetchUserActionsPendingDataKeys } = this.props.social
    console.log(fetchUserActionsPendingDataKeys)
    console.log(fetchUserActionsPendingDataKeys.includes('favs'))
    return (
      <div className='user-where-to'>
        <div className='wrap'>
          <h1>Do you have a device to add?</h1>

          {getThemeObj().sharePublicly && (
            <a href='https://www.amazon.com/stores/page/EAA7AF90-380A-4AEA-A8A9-F4E0368510BB' target='_blank'>
              <img src='https://ambientweather.net/ambient-cta.jpg' />
            </a>
          )}
          <Link to='/devices' className='btn btn-primary btn-long'>Add Device</Link>
          {!fetchUserActionsPendingDataKeys.includes('favs') && <Link to='/dashboard'>Continue to Dashboard</Link>}
          <HelpLinks />
          <FavStar currentDevice={{ _id: 'use this to load our favs' }} />
        </div>
      </div>
    );
  }
}

export default bindSocialActions(withRouter(WhereTo))
WhereTo.displayName = 'WhereTo'

