import React, { PureComponent } from 'react';
import { roundDownMins, pathsChanged, isIos, generalModalStyle, timeFormatForUser, trackEvent } from '../common/ambient'
import classNames from 'classnames'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import PropTypes from 'prop-types';
import { slice, set, reduce, path, lensPath, last } from 'ramda'
import bindAllActions from '../common/bindAllActions';
import { ssisShareLink } from '../common/ambient'
import Base64 from '../common/lib/base64';

const IS_LOCAL = false  // for local devlopment of ssis

// const SHARE_IMG_BASE = 'https://share.ambientweather.net/ssr/'
let SHARE_IMG_BASE = 'https://d1hff0alv60e0m.cloudfront.net/ssr/'

if (IS_LOCAL) {
  SHARE_IMG_BASE = 'http://localhost:3030/ssr/'

}

class ExternalShareModal extends PureComponent {
  static propTypes = {

  };
  state = {

  }
  componentDidMount() {
    const { userActions, title, description, redirect } = this.props
    const obj = {
      title,
      desc: this._desc(),
      redirect
    }
    const url = ssisShareLink(this._imgStr(), obj)
    trackEvent('share', this.props.component, last((redirect || '').split('/')))

    if (IS_LOCAL) {
      this.setState({ shortUrl: 'local' })
      console.log(SHARE_IMG_BASE + this._imgStr() + '?width=800&height=421');

    } else {
      // ping cloudfront to preload for social services
      const img = new Image
      img.src = SHARE_IMG_BASE + this._imgStr() + '?width=800&height=421'

      userActions.createShortUrl({
        url
      })
        .then(res => {
          this.setState({
            shortUrl: 'https://ambientweather.net/share/' + res.shortUrl
          })
        })
    }
  }
  _imgStr() {
    const { paths, component } = this.props

    // create an object using the keys from paths and the values from props
    const obj = reduce((obj, pth) => {
      let val
      // if the last thing is a function call it to get the value
      if (typeof last(pth) === 'function') {
        const fn = last(pth) 
        pth = slice(0, -1, pth)
        val = fn(this.props)
      } else {
        val = path(pth, this.props) 
      }
      return set(lensPath(pth), val, obj)
    }, { now: roundDownMins(1, Date.now()) }, paths)
    const str = `component=${component}&props=${JSON.stringify(obj)}`
    // console.log(str);
    return Base64.encode(str)
  }
  _imgSrc() {
    return SHARE_IMG_BASE + this._imgStr() + '?width=800&height=421&crop=true'
  }
  _shareUrl() {
    return this.state.shortUrl || ''
  }
  _desc() {
    return this.props.description + `  ${moment().format('MMM Do, YYYY @ ' + timeFormatForUser(this.props.user).replace(' ', ''))} on AmbientWeather.net`
  }

  render() {
    const { title, priv } = this.props
    const { copying } = this.state
    const selectAll = evt => evt.target.select()
    return (
      <div className="component-external-share-modal">
        <div className="body">
          <div className="img-wrap">
            <img src={this._imgSrc()} />
          </div>
          <div className="share-icons">
            <a href={`mailto:?subject=${encodeURIComponent(title)} on AmbientWeather.net&body=${encodeURIComponent(this._desc())}`} target="_blank" className="email"></a>
            <a href={`http://www.facebook.com/sharer/sharer.php?u=${this._shareUrl()}`} target="_blank" className="fb"></a>
            <a href={`http://www.twitter.com/intent/tweet?url=${this._shareUrl()}&via=ambientweather`} target="_blank" className="twitter"></a>
            <a href={this._imgSrc()} target="_blank" className="download" download></a>
          </div>
          <div className="priv-wrap">
            {priv}
          </div>
          <div className="copy">
            <input onFocus={selectAll} type="text" readOnly value={this._shareUrl()} className="form-control" />
            <CopyToClipboard
              text={this._shareUrl()}
              onCopy={() => {
                this.setState({ copying: true })
                setTimeout(() => {
                  this.setState({ copying: false })
                }, 2000)
              }}
            >
              <a className="btn btn-primary btn-long" >{copying ? 'Copied!' : 'Copy Link'}</a>
            </CopyToClipboard>
          </div>
        </div>
      </div>
    );
  }
}

export default bindAllActions(ExternalShareModal)
ExternalShareModal.displayName = 'ExternalShareModal'

ExternalShareModal.displayName = 'ExternalShareModal'

ExternalShareModal.displayName = 'ExternalShareModal'

ExternalShareModal.displayName = 'ExternalShareModal'

ExternalShareModal.displayName = 'ExternalShareModal'

ExternalShareModal.displayName = 'ExternalShareModal'

ExternalShareModal.displayName = 'ExternalShareModal'
