import React, { PureComponent } from 'react';
import bindUserActions from './redux/bindUserActions';
import classNames from 'classnames'
import { debounce, pathsChanged } from '../../common/ambient';
import { Loader } from '../../components';
import PropTypes from 'prop-types';
import { path, pick } from 'ramda';

class UsernameEdit extends PureComponent {
  static propTypes = {
    hideSave: PropTypes.bool,
    onChange: PropTypes.func
  }

  state = {
    open: true,
    username: '',
    ok: false
  }
  constructor(props) {
    super(props)
    this._checkUnique = debounce(this._checkUnique.bind(this), 500)
  }
  componentDidMount() {
    this._checkExistingUsername()
  }
  componentDidUpdate(prevProps, prevState) {
    const { onChange } = this.props
    if (pathsChanged(this.state, prevState, [['username']])) {
      this._checkUnique()
    }
    if (pathsChanged(this.state, [['ok'], ['username']]) && onChange) {
      onChange(pick(['ok', 'username'], this.state))
    }
    if (pathsChanged(this.props, prevProps, [['user', 'info', 'username']])) {
      this._checkExistingUsername()
    }
  }
  _checkExistingUsername() {
    const { user } = this.props
    const username = path(['info', 'username'], user)
    if (username) {
      this.setState({
        username,
        open: false
      })
    }
  }
  _isMyUsername() {
    return path(['info', 'username'], this.props.user) === this.state.username
  }
  _checkUnique() {
    const { actions, user } = this.props
    const { username } = this.state
    if (username.length < 5) {
      return this.setState({
        ok: false,
        message: 'Too short'
      })
    }
    if (this._isMyUsername()) {
      return
    }
    this.setState({
      loading: true,
      ok: false
    })
    actions.fetchUsers({ 
      $select: ['username'],
      username,
      $limit: 1
    })
      .then(res => {
        const ok = !res[0].exists
        const st8 = {
          loading: false,
          ok
        }
        if (ok) {
          st8.message = 'It\'s available!'
        }
        this.setState(st8)
      })

  }

  render() {
    const { actions, user, hideSave } = this.props
    const { open, username, message, loading, ok } = this.state
    const { info } = user
    let messageShow = null
    if (this._isMyUsername()) {
      messageShow = <a className='edit' onClick={() => this.setState({ open: false })}>Close</a>
    } else if (loading) {
      messageShow = <Loader />
    } else if (message) {
      messageShow = <div className={classNames('message', { ok })}>
        {message}
      </div>
    }
    return (
      <span className={classNames("user-username-edit", {
        open
      })}>
        <span className='user-label'>
          <span>{username}</span>
          <a className='edit' onClick={() => this.setState({ open: true })}>Edit</a>
        </span>
        <div className='form'>
          <input
            type='text'
            onChange={evt => {
              const value = evt.target.value 
              const newUsername = value.replace(/[^A-Za-z0-9_]/g, '') 
              if (newUsername.length !== value.length) {
                this.setState({ 
                  message: 'Only letters, numbers and underscores are allowed'
                })
                this._checkUnique()
                setTimeout(() => {
                  this.setState({
                    message: '',
                    messageClass: ''
                  })
                }, 4000)
              }
              this.setState({ 
                username: newUsername,
                ok: false
              })
            }}
            className='form-control'
            value={username}
            placeholder="weather_influencer4000"
          />
          {ok && !hideSave ? <button className='btn btn-primary' onClick={() => {
            actions.patch(info._id, { username })
          }}>Save</button> : null}
          {messageShow}
        </div>
      </span>
    )
  }
}

export default bindUserActions(UsernameEdit)

UsernameEdit.displayName = 'UsernameEdit'

UsernameEdit.displayName = 'UsernameEdit'

UsernameEdit.displayName = 'UsernameEdit'

UsernameEdit.displayName = 'UsernameEdit'

UsernameEdit.displayName = 'UsernameEdit'

UsernameEdit.displayName = 'UsernameEdit'

UsernameEdit.displayName = 'UsernameEdit'
