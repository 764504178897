import React, { useMemo } from 'react'
import classNames from 'classnames'
import { uniqBy, prop, path, test, not, pipe, intersection } from 'ramda'
import {
  DeviceChooser, FromNow
} from '../../components'
import Tags from '../common/Tags'
import { getDisplayKeys, getLabelForDevice } from '../../common/ambient'
import DeviceCardHeader from './DeviceCardHeader'
import { useGetDevice } from './redux/getDevice'
// import PropTypes from 'prop-types';

export default function AdvancedGraphEdit ({ graph, setGraph, device, advanced }) {
  const { getDevice } = useGetDevice()
  const { getDeviceIdsPending } = device
  const getCommonParams = (deviceIds) => {
    if (!deviceIds || deviceIds.length === 0) return []
    const missingDevices = deviceIds.filter(id => !device.deviceCache[id])
    if (missingDevices.length > 0) {
      missingDevices.forEach(d => {
        if (getDeviceIdsPending.indexOf(d) === -1) {
          getDevice(d)
        }
      })
    }
    const paramSets = deviceIds
      .filter(id => device.deviceCache[id])
      .map(id => {
        const theDevice = device.deviceCache[id]
        return getDisplayKeys([theDevice.lastData])
          .filter(k => k !== 'dateutc')
          .filter(pipe(test(/^leak\d$/), not))
      })
    return paramSets.reduce((acc, set) => intersection(acc, set))
  }
  const lineBarOptions = [{
    label: 'Line Graph',
    value: 'line'
  }, {
    label: 'Bar Graph',
    value: 'bar'
  }]

  const show = []
  const isMultiParam = graph.type === 'multiParam'
  const isMultiDevice = graph.type === 'multiDevice'
  if (graph.type) {
    if (isMultiDevice ||
      (isMultiParam && (!graph.devices || graph.devices.length < 1))
    ) {
      show.push((
        <div key='stations' className='line stations'>
          <DeviceChooser
            className='plus'
            devicesFilter={device => device.lastData}
            onSelect={device => {
              const devices = graph.devices || []
              setGraph({
                ...graph,
                devices: uniqBy(prop('deviceId'), devices.concat({ deviceId: device._id }))
              })
            }}
          />
        </div>
      ))
    }
    if (graph.devices && graph.devices.length > 0) {
      show.push((
        <>
          <h4 className='stations'>Station{isMultiDevice && 's'}:</h4>
          <div className='devices' key='devices-list'>
            {graph.devices.map((d, i) => {
              const id = d.deviceId
              return (
                <div key={id} className='device-wrap'>
                  <DeviceCardHeader
                    currentDevice={device.deviceCache[id]}
                  >
                    <div className='ago'>
                      <FromNow dateutc={path(['lastData', 'dateutc'], device.deviceCache[id])} />
                    </div>
                  </DeviceCardHeader>
                  {advanced && isMultiDevice && (
                    <div className='option'>
                      {lineBarOptions.map(opt => (
                        <a
                          key={opt.value}
                          className={classNames('opt', opt.value, {
                            active: d.type === opt.value || (!d.type && opt.value === 'line'),
                            'glyphicon glyphicon-stats': opt.value === 'bar'
                          })}
                          onClick={() => {
                            const g = { ...graph }
                            g.devices[i].type = opt.value
                            setGraph(g)
                          }}
                        />
                      ))}
                    </div>
                  )}
                  <a
                    className='close delete'
                    onClick={() => {
                      if (window.confirm('Are you sure you want to remove this device from the graph?')) {
                        setGraph({
                          ...graph,
                          devices: graph.devices.filter(d => d.deviceId !== id)
                        })
                      }
                    }}
                  />
                </div>
              )
            })}
          </div>
        </>
      ))
    }

    if (graph.devices && graph.devices.length > (isMultiDevice ? 1 : 0)) {
      const commonParams = getCommonParams(graph.devices.map(d => d.deviceId))
      let value = ''
      let firstOption = `Choose Parameter${isMultiParam ? 's' : ''}`
      if (graph.params && graph.params.length > 0) {
        if (isMultiDevice) {
          value = graph.params[0].param
        } else {
          firstOption = 'Add Parameter'
        }
      }
      const paramSelect = (
        <select
          className='form-control'
          value={value}
          onChange={evt => {
            const newParam = { param: evt.target.value }
            const params = graph.type === 'multiParam' ? uniqBy(prop('param'), [...(graph.params || []), newParam]) : [newParam]
            setGraph({
              ...graph,
              params: params.filter(p => !!p.param)
            })
          }}
        >
          <option value=''>{firstOption}</option>
          {commonParams.map(key => (
            <option key={key} value={key}>
              {getLabelForDevice(key, device.deviceCache[graph.devices[0].deviceId])}
            </option>
          ))}
        </select>
      )


      show.push((
        <>
          <h4 className='params'>Parameter{isMultiParam && 's'}:</h4>
          <div key='params' className='line params'>
            {paramSelect}
          </div>
        </>
      ))
      if (graph.type === 'multiParam') {
        let tags = (graph.params || []).map(p => ({
          value: p.param,
          label: getLabelForDevice(p.param, device.deviceCache[graph.devices[0].deviceId])
        }))
        if (advanced) {
          tags = tags.map((tag, i) => ({
            ...tag,
            label: (
              <div className='advanced-param'>
                <div className='label'>{tag.label}</div>
                <div className='option'>
                  {lineBarOptions.map(opt => (
                    <a
                      key={opt.value}
                      className={classNames('opt', opt.value, {
                        active: graph.params[i].type === opt.value || (!graph.params[i].type && opt.value === 'line'),
                        'glyphicon glyphicon-stats': opt.value === 'bar'
                      })}
                      onClick={() => {
                        const g = { ...graph }
                        g.params[i].type = opt.value
                        setGraph(g)
                      }}
                    />
                  ))}
                  {/* <SwitchSlider
                    onChange={val => {
                      const g = { ...graph }
                      g.params[i].type = val
                      setGraph(g)
                    }}
                    value={graph.params[i].type || 'line'}
                    options={[{
                      label: 'Line Graph',
                      value: 'line'
                    }, {
                      label: 'Bar Graph',
                      value: 'bar'
                    }]}
                  /> */}
                </div>
              </div>
            )
          }))
        }
        show.push((
          <Tags
            key='tags'
            tags={tags}
            onRemove={id => setGraph({
              ...graph,
              params: (graph.params || []).filter(d => d.param !== id)
            })}
          />
        ))
      }
    }
  }
  // if (advanced) {
  //   show.push(
  //     <div className='advanced' key='advanced'>
  //       <h3>Advanced Graphing Options</h3>
  //       <div>
  //       </div>
  //     </div>
  //   )
  // }
  return (
    <div className={classNames('device-advanced-graph-edit', {
      advanced,
      'multi-param': isMultiParam,
      'multi-device': isMultiDevice
    })}>
      {show}
    </div>
  );
};

AdvancedGraphEdit.propTypes = {};
AdvancedGraphEdit.defaultProps = {};

AdvancedGraphEdit.displayName = 'AdvancedGraphEdit'
