import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export default class SwipeHandler extends PureComponent {
  static propTypes = {
    threshold: PropTypes.number
  }

  constructor(props) {
    super(props)
    this.state = {
      initialClientX: 0,
      finalClientX: 0,
      initialClientY: 0,
      finalClientY: 0,
    }
  }

  handleTouchStart(event) {
    this.setState({
      initialClientX: event.nativeEvent.touches[0].clientX,
      initialClientY: event.nativeEvent.touches[0].clientY,
    })
  }

  handleTouchMove(event) {
    this.setState({
      finalClientX: event.nativeEvent.touches[0].clientX,
      finalClientY: event.nativeEvent.touches[0].clientY
    })
  }

  handleTouchEnd() {
    const { onSwipeUp, onSwipeDown, onSwipeLeft, onSwipeRight } = this.props
    const threshold = this.props.threshold || 10
    if ((this.state.finalClientX > this.state.initialClientX)
    && (this.state.finalClientX - this.state.initialClientX > threshold)
    && onSwipeRight
    ) {
      onSwipeRight(this.state)
    }
    if ((this.state.finalClientX < this.state.initialClientX)
    && (this.state.initialClientX - this.state.finalClientX > threshold)
    && onSwipeLeft
    ) {
      onSwipeLeft(this.state)
    }
    if ((this.state.finalClientY > this.state.initialClientY)
    && (this.state.finalClientY - this.state.initialClientY > threshold)
    && onSwipeDown
    ) {
      onSwipeDown(this.state)
    }
    if ((this.state.finalClientY < this.state.initialClientY)
    && (this.state.initialClientY - this.state.finalClientY > threshold)
    && this.state.finalClientY !== 0
    && onSwipeUp
    ) {
      onSwipeUp(this.state)
    }
    this.setState({
      initialClientX: 0,
      finalClientX: 0,
      initialClientY: 0,
      finalClientY: 0
    })
  }

  render() {
    return (
      <span 
        className={this.props.className}
        onTouchStart={this.handleTouchStart.bind(this)}
        onTouchEnd={this.handleTouchEnd.bind(this)}
        onTouchMove={this.handleTouchMove.bind(this)}
        className="component-swipe-handler">
        {this.props.children}
      </span>
    );
  }
}

SwipeHandler.displayName = 'SwipeHandler'

SwipeHandler.displayName = 'SwipeHandler'

SwipeHandler.displayName = 'SwipeHandler'

SwipeHandler.displayName = 'SwipeHandler'

SwipeHandler.displayName = 'SwipeHandler'

SwipeHandler.displayName = 'SwipeHandler'

SwipeHandler.displayName = 'SwipeHandler'
