import React from 'react';
import classNames from 'classnames';
// import PropTypes from 'prop-types';

export default function SwitchSlider ({ onChange, value, options }) {
  const first = options[0].value === value
  return (
    <div className='common-switch-slider'>
      <div className={classNames('switch-wrap', first ? 'first' : 'second')}>
        <div className={classNames('switch-container', first ? 'first' : 'second')}>
          <span className='slider' />
          {options.map((option, i) => (
            <button
              key={option.value}
              className={`toggle-btn ${value === option.value ? 'active' : ''}`}
              onClick={() => {
                onChange && onChange(option.value)
              }}
            >
              {option.label}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

SwitchSlider.propTypes = {};
SwitchSlider.defaultProps = {};

SwitchSlider.displayName = 'SwitchSlider'
