import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { dataHas } from '../../common/ambient'

export default class UvWidget extends PureComponent {
  static propTypes = {
    currentDevice: PropTypes.object.isRequired,
  };
  constructor(props) {
    super(props)
    this.state = { i: 0 }
    this.descriptions = [
      [
        'Low',
        'Low',
        'Moderate',
        'Moderate',
        'Moderate',
        'High',
        'High',
        'Very High',
        'Very High',
        'Very High',
        'Extreme'
      ],
      [
        'Low',
        'Low',
        'Low',
        'Low',
        'Low',
        'Moderate',
        'Moderate',
        'High',
        'High',
        'High',
        'Very High',
      ],
      [
        'Low',
        'Low',
        'Low',
        'Low',
        'Low',
        'Low',
        'Moderate',
        'Moderate',
        'Moderate',
        'High',
        'High',
      ],
    ]
  }

  render() {
    const { currentDevice } = this.props
    const { lastData } = currentDevice
    const { i } = this.state
    let widget = <div className="uv-widget hidden" />
    const currentDataHas = dataHas(lastData)
    if (currentDataHas(['uv'])) {
      const uv = lastData.uv
      const theUv = uv > 10 ? 10 : uv
      const level = this.descriptions[i][Math.floor(theUv)] + ' Risk'
      widget =
        <div className="uv-widget">
          <div className="top">
            <div className="wrap">
              <div className="uv-data">{uv}</div>
              <div className="level">{level}</div>
            </div>
            <div className="bottom">
              <a className="light"
                onMouseEnter={() => this.setState({ i: 0 })}
                title="Fair Skin"
              >
                light skin
              </a>
              <a
                className="med"
                onMouseEnter={() => this.setState({ i: 1 })}
                title="Medium Skin"
              >
                medium skin
              </a>
              <a
                className="dark"
                onMouseEnter={() => this.setState({ i: 2 })}
                title="Dark Skin"
              >
                dark skin
              </a>
            </div>
          </div>
        </div>
    }
    return widget
  }
}

UvWidget.displayName = 'UvWidget'

UvWidget.displayName = 'UvWidget'

UvWidget.displayName = 'UvWidget'

UvWidget.displayName = 'UvWidget'

UvWidget.displayName = 'UvWidget'

UvWidget.displayName = 'UvWidget'

UvWidget.displayName = 'UvWidget'
