import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { nth, prop, pipe, pluck, concat, apply } from 'ramda'
import lune from '../../common/lib/lune'
import astro from '../../common/lib/astro'
import { Area, Tooltip, Line, YAxis } from 'recharts'
import SunIcon from '../../assets/sun-moon/sun.inline.svg'
import { getMoonI, moons } from '../../common/ambient/moons'
import { timeFormatForUser, getAstroValue, getTheDevice, tooltipFormatDate } from '../../common/ambient'
import {
  CommonGraph
} from './'


export default class SunMoonGraph extends PureComponent {
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    graphData: PropTypes.array,
    user: PropTypes.object,
    device: PropTypes.object,
    mode: PropTypes.string
  }
  state = {
    mTz: window.moment
  }

  _tooltip(props) {
    const { mTz } = this.state
    const { device, user, mode } = this.props
    const { payload } = props
    if (!payload[0] || !mTz) return
    const doc = payload[0].payload
    const hw = 25
    const phase = lune.phase(new Date(doc.dateutc))
    const moonHw = 22 
    const getValue = fn => {
      const t = getAstroValue(mTz, getTheDevice(device), fn, doc.dateutc)
      return t.format(timeFormatForUser(user)) + ' ' + t.zoneAbbr()
    }
    let dayLength
    if (mode === 'yearly') {
      const dayLengthM = mTz.duration(doc.sunRiseSet[1] - doc.sunRiseSet[0])
      dayLength = <span><br/>Day Length: {dayLengthM.hours()} hr, {dayLengthM.minutes()} min</span>
    }
    return <div className="tooltip">
    <p>{tooltipFormatDate(device, user, mode, doc.dateutc)}</p>
    <div className="line">
      <div>
        <SunIcon
          width={hw}
          height={hw}
          />
        </div>
        <p>
          Rise: {getValue(astro.solar.rise)}<br/>
          Set: {getValue(astro.solar.set)} 
          {dayLength}
        </p>
      </div>
      <div className="line">
        <div>
          {moons.v2[getMoonI(phase)]({
            width: moonHw,
            height: moonHw,
          })}
        </div>
        <p>
          Rise: {getValue(astro.lunar.rise)}<br/>
          Set: {getValue(astro.lunar.set)}
        </p>
      </div>
    </div>
  }
  render() {
    const { mTz } = this.state
    const { user, device, width, height, graphData, mode } = this.props 
    if (!mTz) return null
    const data = graphData
    const isYearly = mode === 'yearly'
    let sunLegend = 'Sun Height'
    let moonLegend = 'Moon Height'
    let sunDataKey = 'sun'
    let tick = false
    let tickFormatter = null
    let area = null
    let tooltipContent = this._tooltip.bind(this)
    const theDevice = getTheDevice(device)
    let allVals = concat(pluck('sun', data), pluck('moon', data))
    let dataMax = apply(Math.max, allVals)
    if (isYearly) {
      sunLegend = 'Sun Transit'
      moonLegend = 'Moon Phase'
      sunDataKey = 'sunTransit'
      dataMax = 1000 * 60 * 60 * 24 - 1000 * 60
      tick = true
      const startOfToday = mTz().startOf('day')
      tickFormatter = props => {
        return startOfToday.clone().add(props, 'ms').format(timeFormatForUser(user))
      }
      area = 
        <Area
          type="monotone"
          dataKey={'sunRiseSet'}
          stroke="#f5e5b6"
          strokeWidth={0}
          fill="#f5e5b6"
          legendType="circle"
          connectNulls={false}
          dot={false}
          isAnimationActive={false}
          name={'Sun Rise/Set'}
      />
    }
    return (
      <CommonGraph
        width={width}
        height={height}
        graphData={data}
        id={'sunMoon'}
        composed={true}
        mode={mode}
        onClick={this.props.onClick}
        tooltipProps={{
          content: tooltipContent,
          offset: 20
        }}
        >
        <YAxis
          dataKey="sun"
          orientation="left"
          type="number"
          axisLine={false}
          domain={[0, dataMax]}
          tick={tick}
          tickFormatter={tickFormatter}
        />
        {area}
        <Line
          key="sun"
          type="monotone"
          dataKey={sunDataKey}
          stroke="#fcd754"
          strokeWidth={2}
          fill="#fcd754"
          connectNulls={true}
          dot={false}
          legendType="circle"
          isAnimationActive={false}
          name={sunLegend}
          activeDot={props => {
            const { cx, cy } = props
            const hw = 25 
            return <SunIcon
              width={hw}
              height={hw}
              x={cx - hw / 2}
              y={cy - hw / 2}
            />
          }}
        />
        <Line
          key="moon"
          type="monotone"
          dataKey={'moon'}
          stroke="#b0bbc3"
          strokeWidth={2}
          fill="#b0bbc3"
          connectNulls={true}
          dot={false}
          legendType="circle"
          isAnimationActive={false}
          name={moonLegend}
          activeDot={props => {
            const { cx, cy } = props
            const phase = lune.phase(new Date(props.payload.dateutc))
            const hw = 18 
            return moons.v2[getMoonI(phase)]({
              width: hw,
              height: hw,
              x: cx - hw / 2,
              y: cy - hw / 2
            })
          }}
        />
        {this.props.children}
      </CommonGraph>
    );
  }
}

SunMoonGraph.displayName = 'SunMoonGraph'

SunMoonGraph.displayName = 'SunMoonGraph'

SunMoonGraph.displayName = 'SunMoonGraph'

SunMoonGraph.displayName = 'SunMoonGraph'

SunMoonGraph.displayName = 'SunMoonGraph'

SunMoonGraph.displayName = 'SunMoonGraph'

SunMoonGraph.displayName = 'SunMoonGraph'
