import {
  SET_DASHBOARD_DEVICE
} from './constants'
import { removeDayDataCache, hookMaker } from '../../../common/ambient'

export function setDashboardDevice (device) {
  return {
    type: SET_DASHBOARD_DEVICE,
    data: device
  }
}

export const useSetDashboardDevice = hookMaker('setDashboardDevice', setDashboardDevice)
export const useDashboardDevice = hookMaker('dashboardDevice', ['device', 'dashboardDevice'])

export function reducer (state, action) {
  switch (action.type) {
    case SET_DASHBOARD_DEVICE:
      return {
        ...removeDayDataCache(state),
        dashboardDevice: action.data ? state.deviceCache[action.data._id] || action.data : false
      }

    default:
      return state
  }
}
