// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html
import { Menu, UaPage } from './'

export default {
  path: 'common',
  name: 'Common',
  childRoutes: [
    { path: 'menu', component: Menu },
    { path: 'ua/:id', component: UaPage }
  ]
}
