import PropTypes from 'prop-types';
import { range } from 'ramda'
import classNames from 'classnames'
import React, { PureComponent } from 'react';
import { DayForecast } from '../../components'
import { FormattedDataPoint } from '../device'
import { getUserUnitI, isSomething, pathsChanged } from '../../common/ambient'
import bindUserActions from '../user/redux/bindUserActions'
import { convertUnitInverse } from '../../common/lib/ambient-weather-common/ambient'

class CreateForecast extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    onFinished: PropTypes.func
  }
  state = {
    days: 0,
    forecast: []
  }

  constructor(props) {
    super(props)
    this._setDays = ::this._setDays
    this._dayForecast = ::this._dayForecast
  }
  componentDidUpdate(prevProps, prevState) {
    const { onChange } = this.props
    const { forecast } = this.state
    if (onChange && pathsChanged(this.state, prevState, [['forecast']])) {
      onChange(forecast)
    }
  }
  _setDays(d) {
    const st8 = {
      days: d 
    } 
    st8.forecast = range(0, d).map(i => {
      return {
        time: moment().startOf('day').add(i + 1, 'days').valueOf(),
        icon: 'clear-day',
        temperatureHigh: 70,
        temperatureLow: 50,
        precipIntensity: 0.0
      }
    })
    this.setState(st8)
  }
  _changeEditKeyFn (i, forecastKey) {
    const { forecast } = this.state
    const { user } = this.props
    const editKey = `${forecastKey}Edit` 
    return value => {
      const newForecast = forecast.slice(0) 
      const currentEditValue =  parseInt(newForecast[i][editKey], 10)
      const st8 = {
        activeDay: i
      }
      // when we set the editKey to null we want to use that value for the forecastKey
      if (value === null) {
        if (!isNaN(currentEditValue)) {
          // convert for user
          const unitI = getUserUnitI('tempf', user)
          newForecast[i][forecastKey] = unitI > 0 ? convertUnitInverse('tempf', unitI, currentEditValue) : currentEditValue
        }
        st8.activeDay = null
      }
      newForecast[i][editKey] = value
      st8.forecast = newForecast
      this.setState(st8)
    }
  }
  _editableValue(i, forecastKey) {
    const { forecast } = this.state
    const fieldOrder = [
      'temperatureHigh',
      'temperatureLow',
      // 'precipIntensity'
    ]
    let type = 'tempf'
    let after = '°' 
    const editKey = `${forecastKey}Edit` 
    const f = forecast[i]
    if (/precip/.test(forecastKey)) {
      type = 'dailyrainin'
      after = null
    }
    const changeEditKey = this._changeEditKeyFn(i, forecastKey)
    let show = <a onClick={() => changeEditKey('')}><FormattedDataPoint type={type} value={f[forecastKey]} valueTransform={v => parseInt(v, 10)} after={after} /></a>
    if (f[editKey] !== undefined && f[editKey] !== null) {
      show = <input type="text" autoFocus onKeyDown={evt => {
        // tab or enter
        if (evt.keyCode === 9 || evt.keyCode === 13) {
          evt.preventDefault()
          changeEditKey(null)
          // go to next one
          const fieldI = fieldOrder.indexOf(forecastKey)
          // last one field in this day
          if (fieldI === fieldOrder.length - 1) {
            if (i !== forecastKey.length - 1) {
              const changeEditKeyForNextDay = this._changeEditKeyFn(i + 1, fieldOrder[0])
              changeEditKeyForNextDay('')
            }
          } else {
            const changeEditKeyForNextField = this._changeEditKeyFn(i, fieldOrder[fieldI + 1])
            changeEditKeyForNextField('')
          }
        }
      }} value={f[editKey]} onBlur={() => changeEditKey(null)} onChange={evt => changeEditKey(evt.target.value)} />
    }
    return show 
  }
  _icon(i) {
    const { forecast } = this.state
    const f = forecast[i] || {}

    return <a onClick={() => this.setState({ iconsOpen: i })} className={classNames("weather-icon", f.icon) } />
  }
  // structure copied from DayForecast
  _dayForecast(forecast, i) {
    const { iconsOpen, activeDay } = this.state
    const title = moment(forecast.time).format('dd').slice(0,1) 
    return (
      <div key={`forecast-${forecast.time}`} className={classNames('day-forecast', `title-${title.replace(' ', '-').toLowerCase()}`, {
        open: iconsOpen === i,
        active: activeDay === i || iconsOpen === i
      })}>
        <div className="title">{title}</div>
        {this._icon(i)}
        <div className="hl">
          {this._editableValue(i, 'temperatureHigh')}
          {this._editableValue(i, 'temperatureLow')}
        </div>
        {/* <div className="precip">
          <span className="drop">{this._editableValue(i, 'precipIntensity')}</span>
        </div> */}
      </div>
    )
  }
  _iconChooser() {
    const { iconsOpen, forecast } = this.state
    const icons = [
      'clear-day',
      'partly-cloudy-day',
      'part-sun',
      'cloudy',
      'sprinkles',
      'rain',
      'thunderstorm',
      'lightning',
      'wind',
      'fog',
      'sleet',
      'hail',
      'flurries',
      'snow'
    ]
    const nicerLabels = {
      'clear-day': 'Sun',
      'partly-cloudy-day': 'Part Cloud',
      'thunderstorm': 'Storm'
    }
    return <div className='icon-chooser'>
      {icons.map(icon => 
        <a onClick={() => {
          const newForecast = forecast.slice(0) 
          newForecast[iconsOpen].icon = icon
          this.setState({
            iconsOpen: false,
            forecast: newForecast
          })
        }} key={icon}>
          <div className='label'>{nicerLabels[icon] || icon.replace(/-/g, ' ').replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())}</div>
          <div className={classNames("weather-icon", icon) } />
        </a>)}
    </div>
  }

  render() {
    const { onFinished } = this.props
    const { iconsOpen, days, forecast } = this.state
    // step 1
    const dayOptions = [1, 3, 5]
    let show = <div className='how-many'>
      <h2>Create Your Own Forecast</h2>
      <h3>Choose your forecast range</h3>
      <div className='day-options'>
        {dayOptions.map(d => <a onClick={() => this._setDays(d)} className='option' key={`day-${d}`}>
          {d === 1 ? 'Today' : `${d} days`}
        </a>)}
      </div>
    </div>
    // step 2
    if (days > 0) {
      show = <div className={classNames('create-forecast', {
        icons: isSomething(iconsOpen)
      })}>
        <h3>Click to change forecast</h3>
        <div className='days'>
          {forecast.map(this._dayForecast)}
        </div>
        {this._iconChooser()}
        <div className="text-center">
          <button className="btn" onClick={() => {
            if (onFinished) {
              onFinished()
            }
          }}>Cancel</button>
          <button className="btn btn-primary" onClick={() => {
            if (onFinished) {
              onFinished(forecast)
            }
          }}>Done</button>
        </div>
      </div>
    }
    return (
      <div className="social-create-forecast">
        {show}
      </div>
    )
  }
}

export default bindUserActions(CreateForecast)

CreateForecast.displayName = 'CreateForecast'

CreateForecast.displayName = 'CreateForecast'

CreateForecast.displayName = 'CreateForecast'

CreateForecast.displayName = 'CreateForecast'

CreateForecast.displayName = 'CreateForecast'

CreateForecast.displayName = 'CreateForecast'

CreateForecast.displayName = 'CreateForecast'
