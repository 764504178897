import React from 'react';
import Autocomplete from 'react-google-autocomplete'
import { useSetOnline } from './redux/hooks'
import ErrorBoundary from '../../components/ErrorBoundary';
// import PropTypes from 'prop-types';

export default function Autocomplete2 (props) {
  const { online } = useSetOnline()
  if (!online || !window.google) return null
  return (
    <ErrorBoundary>
      <Autocomplete {...props} />
    </ErrorBoundary>
  )
}

Autocomplete.propTypes = {};
Autocomplete.defaultProps = {};

Autocomplete.displayName = 'Autocomplete'
