import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { append, concat, path, paths, props } from 'ramda'
import classNames from 'classnames'
import { getDeviceShareLink, getWidgetTitle, getDeviceLabel, widgetWhat, widgetPinned, widgetExpanded, WIDGET_CONFIG, getDeviceSetting } from '../../common/ambient'
import { deviceIsAKestrel } from '../../common/lib/ambient-weather-common/ambient';
import {
  EditableWidgetTitleInput,
  ToggleDevicePublicBtn
} from './'
import {
  MinMaxBtns,
  Share
} from '../../components/'
import { bindOnlyUserActions } from '../user/redux/bindUserActions'

class Widget extends PureComponent {
  static propTypes = {
    type: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onPin: PropTypes.func,
    device: PropTypes.object,
    notExpandable: PropTypes.bool,
    hidden: PropTypes.bool,
    actions: PropTypes.object, // userActions
    className: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object
    ])
  }

  constructor(props) {
    super(props)
    this._tout = false
    this.state = {}
    this.rand = ::this.rand
  }
  componentDidMount() {
    window.addEventListener('resize', this.rand)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.rand)
  }
  rand() {
    this.setState({
      rand: Math.random()
    })
  }
  render() {
    const { user, actions, onPin, hidden, type, onChange, device, notExpandable, className, extraButtons } = this.props
    const { width } = this.state
    const expanded = widgetExpanded(type, device)
    const pinned = widgetWhat('pinned', type, device)
    const minimized = widgetWhat('minimized', type, device)
    const expandable = []
    let onExpand
    if (!notExpandable) {
      onExpand = () => {
        if (onChange) {
          onChange({ expanded: !expanded }, type)
        }
      }
    }
    const id = type === 'solar' ? 'solarradiation' : type
    let onPinFn
    if (onPin) {
      onPinFn = () => {
        if (onChange) {
          onChange({ pinned: !pinned }, type)
        }
      }
    }
    const widgetStyle = width ? { height: width % 2 === 1 ? width + 1 : width } : {}
    const commonSharePaths = [
      ['currentDevice', 'settings', type],
      ['currentDevice', 'unitSetting', 'image'],
      ['currentDevice', 'lastData', 'dateutc'],
      ['currentDevice', 'tz'],
      ['currentDevice', 'info', 'name'],
      ['currentDevice', 'info', 'coords', 'location'],
      ['user', 'info', 'image'],
    ]
    let share, shareProps
    if(WIDGET_CONFIG[type].share) {
      if (WIDGET_CONFIG[type].share.paths) {
        shareProps = {
          title: getDeviceLabel(device),
          description: `${getWidgetTitle(device, type)}`,
          redirect: getDeviceShareLink(device),
          paths: concat(commonSharePaths, WIDGET_CONFIG[type].share.paths),
          component: WIDGET_CONFIG[type].share.component,
          currentDevice: device
        }
        share = <Share {...shareProps} /> 
      }
      const userId = path(['info', '_id'], user)
      if (WIDGET_CONFIG[type].share.social && userId && userId === device.userId) {
        share = <span className='component-share'><a className='share' onClick={() => {
          const cp = () => {
            actions.doModal({ 
              type: 'create-post', 
              data: {
                currentDevice: device,
                postType: `widget-${type}`,
                share: shareProps
              },
            })
          }
          if (!device.public && !path(['info', 'slug'], device)) {
            actions.doModal({
              type: 'toggle-public-device',
              data: {
                currentDevice: device,
                onClick: cp 
              }
            })
          } else {
            cp()
          }
        }} /></span>
      }
    } else if (type === 'webcam') {
      share = <span className='component-share'><a className='share' onClick={() => {
        actions.doModal({ 
          type: 'create-post', 
          data: {
            currentDevice: device,
            webcamSnapshot: device._id
          }
        })
      }} /></span>
    }
    return (
      <div
        className={classNames(
          'device-widget',
          'square',
          type,
          className,
          {
            hidden,
            expanded,
            minimized,
            pinned,
            kestrel: deviceIsAKestrel(device)
          }
        )}
        style={widgetStyle}
        ref={(el) => {
          if (el) {
            this.setState({
              width: el.offsetWidth
            })
          }
        }}
      >
        <header>
          <EditableWidgetTitleInput
            type={type}
            currentDevice={device}
            onChange={onChange}
          />
        </header>
        {this.props.children}
        <MinMaxBtns
          expanded={expanded}
          pinned={pinned}
          id={id}
          onExpand={onExpand}
          onGraph={() => {
            if (onChange) {
              onChange({ dashboard: { minimized: true } }, type)
            }
          }}
          onMinus={() => {
            if (onChange) {
              onChange({ minimized: true }, type)
            }
          }}
          onPin={onPinFn}
        >
          {share}
          {extraButtons}
        </MinMaxBtns>
      </div>
    )
  }
}

export default bindOnlyUserActions(Widget)

Widget.displayName = 'Widget'
