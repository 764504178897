import {
  REMOVE_DEVICE_DATA_BEGIN,
  REMOVE_DEVICE_DATA_SUCCESS,
  REMOVE_DEVICE_DATA_FAILURE,
  REMOVE_DEVICE_DATA_DISMISS_ERROR
} from './constants'
import { app } from '../../../common/feathers'
import { pluck, prop, flip, contains, isArrayLike, pipe, filter, not, propEq, pick } from 'ramda'
const dataService = app.service('device-data')

export function removeDeviceData (data) {
  return (dispatch) => {
    dispatch({
      type: REMOVE_DEVICE_DATA_BEGIN
    })
    let remove
    if (isArrayLike(data)) {
      remove = dataService.remove('bulk', { query: { docs: data.map(pick(['_id', 'loc', 'PASSKEY', 'type', 'dateutc'])) } })
    } else {
      remove = dataService.remove(data._id)
    }
    return remove
      .then(function (res) {
        dispatch({
          type: REMOVE_DEVICE_DATA_SUCCESS,
          data: data
        })
      })
      .catch(function (err) {
        dispatch({
          type: REMOVE_DEVICE_DATA_FAILURE,
          data: {
            error: err
          }
        })
      })
  }
}

export function dismissRemoveDeviceDataError() {
  return {
    type: REMOVE_DEVICE_DATA_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case REMOVE_DEVICE_DATA_BEGIN:
      return {
        ...state,
        removeDeviceDataPending: true,
        removeDeviceDataError: null,
      };

    case REMOVE_DEVICE_DATA_SUCCESS:
      const removeFn = isArrayLike(action.data) ? pipe(prop('_id'), flip(contains)(pluck('_id', action.data))) : propEq('_id', action.data._id)
      const removeDoc = filter(pipe(removeFn, not))
      const keysToCheck = ['graphDataRefined', 'dataTableData', 'hiresData', 'deviceSummaries']
      const st8 = {
        ...state,
        removeDeviceDataPending: false,
        removeDeviceDataError: null
      }
      for (var i in st8) {
        if (/DayData$/.test(i) && Array.isArray(st8[i])) keysToCheck.push(i)
      }
      keysToCheck.forEach(key => {
        if (st8[key]) {
          st8[key] = removeDoc(st8[key])
        }
      })
      return st8

    case REMOVE_DEVICE_DATA_FAILURE:
      return {
        ...state,
        removeDeviceDataPending: false,
        removeDeviceDataError: action.data.error,
      };

    case REMOVE_DEVICE_DATA_DISMISS_ERROR:
      return {
        ...state,
        removeDeviceDataError: null,
      };

    default:
      return state;
  }
}
