import React, { PureComponent } from 'react';
import bindAllActions from '../../common/bindAllActions'
import Loader from '../../components/Loader'
import { getDeviceLabel, getConditionLabel, getLabelForDevice, isLoggedIn } from '../../common/ambient'
import FormattedDataPoint from '../device/FormattedDataPoint'
import PropTypes from 'prop-types';
import { filter, identity, concat, equals, append, flip, pipe, difference, pluck, uniq, find, propEq } from 'ramda'

const fdiff = flip(difference)
const alertSort = (a, b) => {
  const comp = a.param.localeCompare(b.param)
  if (comp !== 0) return comp
  if (a.threshold !== b.threshold) {
    return a.threshold < b.threshold ? -1 : 1
  }
  return a._id.localeCompare(b._id)
}

class AlertList extends PureComponent {
  static propTypes = {
    alert: PropTypes.object.isRequired,
    device: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    deviceActions: PropTypes.object.isRequired,
    alertActions: PropTypes.object.isRequired,
  }
  state = {
    devicesFetching: [],
    devicesFetched: []
  }
  _checkAlertDevices() {
    const { devicesFetched, devicesFetching } = this.state
    const { deviceActions, alert, device } = this.props
    const { alerts } = alert
    const { devices } = device
    if (!alerts || !devices) {
      return
    }
    const deviceIdsToBeFetched = pipe(
      pluck('_id'),
      difference(this._alertDeviceIds()),
      fdiff(devicesFetching),
      fdiff(pluck('_id', devicesFetched)) 
    )(devices)
    // do fetching
    if (deviceIdsToBeFetched[0]) {
      const deviceId = deviceIdsToBeFetched[0]
      this.setState({
        devicesFetching: append(deviceId, devicesFetching)
      }, () => {
        deviceActions.getDevice(deviceId)
          .then(d=> {
            if (!d) {
              d = {
                _id: deviceId,
                info: {
                  name: '{device missing}'
                } 
              }
            }
            this.setState({
              devicesFetched: append(d, this.state.devicesFetched),
              devicesFetching: this.state.devicesFetching.filter(df => df !== deviceId)
            })
          })
      })
    }
  }
  _alertDeviceIds() {
    const { alert } = this.props
    const { alerts } = alert
    if (!alerts) {
      return []
    }
    return pipe(
      pluck('deviceId'),
      uniq,
      filter(Boolean)
    )(alerts)
  }
  _getDevice(deviceId) {
    const { devicesFetched } = this.state
    const { device } = this.props
    const { devices } = device
    return find(propEq('_id', deviceId), concat(devices || [], devicesFetched))
  }
  componentDidMount() {
    const { user, alert, alertActions } = this.props
    if (isLoggedIn(user) && !alert.alerts) {
      alertActions.fetchAlerts({
        userId: user.info._id
      })
    }
    this._checkAlertDevices()
  }
  componentDidUpdate() {
    this._checkAlertDevices()
  }
  _alert(alrt) {
    const { alertActions } = this.props
    let devLabel = ''
    let theDevice = this._getDevice(alrt.deviceId)
    let icon =
      <a
        tabIndex="-1"
        onClick={() => {
          if (window.confirm('Delete alert?')) {
            alertActions.removeAlert(alrt)
          }
        }}
      >
        <i className="glyphicon glyphicon-trash" />
      </a>
    if (alrt.ifttt) {
      icon = <a href="https://ifttt.com/my_applets" rel="noopener noreferrer" target="_blank" title="This alert was created on IFTTT. You can modify it there" className="ifttt" />
    }
    let add = devLabel + getLabelForDevice(alrt.param, theDevice)
    if (alrt.param !== 'notReporting' && !/^leak/.test(alrt.param)) {
      add = <span>
        {devLabel + getLabelForDevice(alrt.param, theDevice) + ' ' + getConditionLabel(alrt.condition)+ ' '}
        <FormattedDataPoint type={alrt.param} value={alrt.threshold} />
      </span>
    } else if (/^leak/.test(alrt.param)) {
      add = <span>
        {add}
        {alrt.threshold === 1 ? ' Leak Detected' : ' Leak Sensor Offline'}
      </span>
      icon = <i className="leak" />
    }
    return (
      <div className="line" key={alrt._id}>
        {icon}
        {add}
      </div>
    )
  }
  _alertGroup(device) {
    const { alert } = this.props
    const { alerts = [] } = alert
    return <div key={device._id} className="alert-group">
      <h3>{getDeviceLabel(device)}</h3>
      {alerts
        .filter(propEq('deviceId', device._id))
        .sort(alertSort)
        .map(this._alert.bind(this))
      }
    </div>
  }

  render() {
    const { device, alert, alertActions } = this.props
    const { fetchAlertsPending } = alert
    const { devices } = device
    const alertGroups = this._alertDeviceIds()
      .map(this._getDevice.bind(this))
      .filter(identity)
      .map(this._alertGroup.bind(this))
    return <div className="list alert-alert-list">
      { fetchAlertsPending ? <Loader /> : alertGroups}
    </div>
  }
}

export default bindAllActions(AlertList)
AlertList.displayName = 'AlertList'

AlertList.displayName = 'AlertList'

AlertList.displayName = 'AlertList'

AlertList.displayName = 'AlertList'

AlertList.displayName = 'AlertList'

AlertList.displayName = 'AlertList'

AlertList.displayName = 'AlertList'
