import React, { PureComponent } from 'react';
import classNames from 'classnames'
import { ambient } from '../../common/lib/ambient-weather-common'
import PropTypes from 'prop-types';
import { splitEvery, append, filter, flip, pluck, contains, uniq, flatten, map, pipe, keys } from 'ramda'
import { getLabel, displayProps, getDisplayKeys, log, removeDoc } from '../../common/ambient'
import FormattedDataPoint from './FormattedDataPoint'

export default class DeviceDataTable extends PureComponent {
  static propTypes = {
    rowAction: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    bulkAction: PropTypes.func,
    mode: PropTypes.string,
    device: PropTypes.object
  }
  constructor(props){
    super(props) 
    this.state = {
      checked : []
    }
    this.handleRowCheck = ::this.handleRowCheck
    this.handleBulkAction = ::this.handleBulkAction
    this.isChecked = ::this.isChecked
  }

  isChecked(row){
    let keys = pluck('_id', this.state.checked)
    return contains(row._id, keys)
  }

  handleRowCheck(row){
    if (this.isChecked(row)) {
      this.setState({
        checked : removeDoc(row._id)(this.state.checked)
      })
    } else {
      this.setState({
        checked : append(row, this.state.checked) 
      })
    }
  }
  handleBulkAction(){
    this.props.bulkAction(this.state.checked)
    this.setState({
      checked : []
    })
  }

  render() {
    const { device, bulkAction, data, pending, mode } = this.props
    const { checked } = this.state
    let table = ''
    let thead = ''
    if (pending) {
      table = pending 
    }
    if (data) {
      let headingKeys = getDisplayKeys(data)

      let headingFields = headingKeys.map((key) => {
        return <th key={key}>{getLabel(key, device)}</th>
      })

      let rows = data.map((row, i) => {
        let cells = headingKeys.map((key) => {
          return <td key={key}><FormattedDataPoint type={key} row={row} /></td>
        })
        let rowAdd = ''
        if (this.props.rowAction) {
          rowAdd = <a onClick={() => { this.props.rowAction(row) }} className={this.props.actionClass}></a>
        }
        if (bulkAction && mode === 'daily' && !device.removeDeviceDataPending) {
          rowAdd = <input type="checkbox" checked={this.isChecked(row)} onChange={() => { this.handleRowCheck(row) }} />
        }
        return <tr key={i}>
          <td className="dateutc">{rowAdd}<FormattedDataPoint type={'dateutc'} row={row} mode={mode} /></td>
          {cells}
        </tr>
      })
      let chunkedRows = splitEvery(20, rows)

      if (data.length < 1){
        table = 'No data for this time period.'
        
      } else {
        let addToHead = ''
        if (bulkAction && mode === 'daily' && checked.length > 0) {
          addToHead = <div className="buttons">
            <a className="glyphicon glyphicon-trash" title="Delete selected rows" onClick={this.handleBulkAction}></a>
            <a className="glyphicon glyphicon-remove" title="Deselect all rows" onClick={() => { this.setState({ checked : [] })}}></a>
          </div>
        }

        thead = 
          <thead>
            <tr>
              <th className={classNames("dateutc", {
                  checks: addToHead !== ''
                })}>{addToHead}Time</th>
              {headingFields}
            </tr>
          </thead>
        
        let tables = chunkedRows.map(function (rws, i){
          return <table key={i} className="table-row table table-striped main">
                {thead}
              <tbody>
                {rws}
              </tbody>
            </table>
        })
        table =
          <div className="scroller">
            {tables}
          </div>
      }

    }
    return (
      <div className="device-device-data-table">
        {table}
      </div>
    );
  }
}

DeviceDataTable.displayName = 'DeviceDataTable'

DeviceDataTable.displayName = 'DeviceDataTable'

DeviceDataTable.displayName = 'DeviceDataTable'

DeviceDataTable.displayName = 'DeviceDataTable'

DeviceDataTable.displayName = 'DeviceDataTable'

DeviceDataTable.displayName = 'DeviceDataTable'

DeviceDataTable.displayName = 'DeviceDataTable'
