import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom'
import PasswordConfirmInputs from '../../components/PasswordConfirmInputs'
import { authManagement } from '../../common/feathers'

class PasswordReset extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };
  constructor(props) {
    super(props)
    this.state = {
      ok: false,
      reset: false
    }
  }
  componentWillMount() {
    const { location, history } = this.props
    const searchParams = new URLSearchParams(location.search)
    const key = searchParams.get('key')
    if (!location.search || !key) {
      history.push('/')
    }
    this.setState({
      key
    })
  }

  render() {
    const { ok, key, reset, err } = this.state
    let message =
      <div>
        <h1>Reset your Password</h1>
        <PasswordConfirmInputs
          onChange={w => this.setState(w)}
        />
        <p className="text-center">
          <input type="submit" disabled={!ok} name="reset" className="btn btn-primary" />
        </p>
      </div>
    if (reset) {
      message = <div>
        <h2>Your password has been reset!</h2>
        <p>
          <b><Link to="/signin">Sign in</Link> to try it out.</b>
        </p>
      </div>
    }
    if (err) {
      message = <p>Something went wrong.</p>
    }
    return (
      <div className="user-password-reset form-center">
        <form
          onSubmit={(evt) => {
            const { password } = this.state
            evt.preventDefault()
            authManagement.resetPwdLong(key, password)
            .then(() => this.setState({ reset: true }))
            .catch(() => this.setState({ err: true }))
          }}
        >
          {message}
        </form>
      </div>
    );
  }
}
export default withRouter(PasswordReset)


PasswordReset.displayName = 'PasswordReset'

PasswordReset.displayName = 'PasswordReset'

PasswordReset.displayName = 'PasswordReset'

PasswordReset.displayName = 'PasswordReset'

PasswordReset.displayName = 'PasswordReset'

PasswordReset.displayName = 'PasswordReset'

PasswordReset.displayName = 'PasswordReset'
