import { fetchInfo, patch, setAppDevice } from './actions'
import { isApp, isLoggedIn } from '../../../common/ambient'
import { path } from 'ramda'

export default function (store) {
  let checkedAppDevice = false
  if (isApp()) {
    window.setAppDevice = function (data) {
      checkedAppDevice = false
      console.log('setAppDevice', data)
      store.dispatch(setAppDevice(typeof data === 'string' ? JSON.parse(decodeURIComponent(data)) : data))
    }
    if (path(['webkit', 'messageHandlers', 'setAppDevice'], window)) {
      window.webkit.messageHandlers.setAppDevice.postMessage({});
    } else if (window.ANDROID) {
      window.ANDROID.setAppDeviceNotification();
    }
  }
  store.subscribe((what) => {
    if (checkedAppDevice) return
    const { user } = store.getState()
    if (!isLoggedIn(user)) return
    if (user && user.appDevice) {
      checkedAppDevice = true
      // compare appDevice
      const appDevices = user.info.appDevices || {}
      let doPatch = false
      if (!appDevices[user.appDevice.uuid]) {
        doPatch = true
      } else {
        const appDevice = appDevices[user.appDevice.uuid]
        if (Object.keys(appDevice).find(key => appDevice[key] !== user.appDevice[key]) ||
          Object.keys(appDevice).length !== Object.keys(user.appDevice).length
        ) {
          doPatch = true
        }
      }
      if (doPatch) {
        appDevices[user.appDevice.uuid] = user.appDevice
        appDevices[user.appDevice.uuid].lastUpdated = Date.now()
        store.dispatch(patch(user.info._id, {
          appDevices
        }))
      }
    }
  })


  // set mapLocation
  const { mapLocation } = store.getState().common
  if (!mapLocation) {
    fetchInfo()(store.dispatch)
  }
}
