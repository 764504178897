import React, { PureComponent } from 'react';
import classNames from 'classnames'
import { WIDGET_CONFIG, dataHas, getLabel } from '../../common/ambient'
import PropTypes from 'prop-types';
import { curry } from 'ramda'

export default class BatteryWidget extends PureComponent {
  static propTypes = {
    currentDevice: PropTypes.object,
    device: PropTypes.object,
  }

  render() {
    const { currentDevice, device } = this.props
    const deviceHasField = dataHas(currentDevice.lastData)
    const isLow = curry((lastData, field) => {
      const lowIs1 = lastData[field] === 1 || lastData[field] === '1' 
      if (lastData.stationtype
       && (lastData.stationtype === 'weatherbridge' || lastData.stationtype === 'weatherbridgepro')) {
         return lowIs1
      }
      // leak and lightning batteries are also flipped
      if (/leak/.test(field) || /lightning/.test(field)) {
        return lowIs1
      }
      return !lowIs1 // other devices have the bit flipped
    })
    const lowBatteryFields = WIDGET_CONFIG.battery.fields
      .filter(deviceHasField)
      .filter(isLow(currentDevice.lastData))
    let show = <h5><div>All Batteries OK</div> <i className="battery-full" /></h5>
    let allOk = true
    if (lowBatteryFields.length > 0) {
      allOk = false
      show = <div className="group">
        <h5>Low Batteries <i className="battery-low" /></h5>
        {lowBatteryFields.map(f => {
            return (
              <div key={f} className="line">
                <span className="label">{getLabel(f, device)}</span>
              </div>
            )
          })}
      </div>
    }
    return (
      <div className={classNames("device-battery-widget small-flex-lines", { ok: allOk })}>
        <div className="wrap">
          {show}
        </div>
      </div>
    );
  }
}

BatteryWidget.displayName = 'BatteryWidget'

BatteryWidget.displayName = 'BatteryWidget'

BatteryWidget.displayName = 'BatteryWidget'

BatteryWidget.displayName = 'BatteryWidget'

BatteryWidget.displayName = 'BatteryWidget'

BatteryWidget.displayName = 'BatteryWidget'

BatteryWidget.displayName = 'BatteryWidget'
