import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import Avatar from './Avatar'
import {
  WebcamImage
} from '../device/'
import { getDeviceLabel, fmtzDate, removeFromArr, getDeviceSharePath } from '../../common/ambient'
import bindAllActions from '../../common/bindAllActions'
import PropTypes from 'prop-types'
import { append, contains, filter, propEq } from 'ramda'
import ApiKeys from './ApiKeys'
import { deviceCamTierObj, deviceSubscriptionStatus } from '../payment'
import { rawFeathersRequest } from '../../common/feathers'

const ROLES = ['user',
  'admin',
  'social',
  'dev',
  'wxw-user',
  'awn-plus'
]

class UserCard extends PureComponent {
  static propTypes = {
    u: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    device: PropTypes.object.isRequired,
    userActions: PropTypes.object.isRequired,
    deviceActions: PropTypes.object.isRequired
  }

  constructor (props) {
    super(props)
    this.state = {
      roles: props.u.roles
    }
  }

  _webcam (currentDevice) {
    if (!currentDevice.webcam) return null
    const { userActions, deviceActions, u } = this.props
    const camTierObj = deviceCamTierObj({ info: u }, currentDevice.macAddress)
    console.log('camTierObj', camTierObj)
    let freeEnhancedCam = null
    if (camTierObj.tier < 1) {
      const freeTierOnClick = tier => {
        return () => {
          if (window.confirm(`Grant Free tier ${tier}?`)) {
            const payments = u.payments || {}
            payments.subscriptions = payments.subscriptions || []
            payments.subscriptions.push({
              productName: `awn-camera-tier${tier}-monthly`,
              macAddress: currentDevice.macAddress,
              status: 'free',
              stationId: currentDevice._id,
              created: Math.floor(Date.now() / 1000)
            })
            userActions.patch(u._id, { payments })
          }
        }
      }
      freeEnhancedCam = (
        <div>
          <button className='btn btn-primary' onClick={freeTierOnClick(1)}>Grant Free Tier 1</button>
          <button className='btn btn-primary' onClick={freeTierOnClick(2)}>Grant Free Tier 2</button>
        </div>
      )
    }
    let removeFreeBtn = null
    if (deviceSubscriptionStatus({ info: u }, currentDevice.macAddress, camTierObj.productName) === 'free') {
      removeFreeBtn = (
        <>
          ⭐️ FREE! ⭐️
          &nbsp;
          <button
            className='btn'
            onClick={() => {
              if (window.confirm('Remove Free Tier?')) {
                const payments = u.payments || {}
                payments.subscriptions = payments.subscriptions || []
                payments.subscriptions = filter(sub => sub.macAddress !== currentDevice.macAddress && /camera/.test(sub.productName), payments.subscriptions)
                userActions.patch(u._id, { payments })
              }
          }}>Remove Free Tier</button>
        </>
      )
    }
    return (
      <div>
        <WebcamImage currentDevice={currentDevice} />
        <div>{currentDevice.webcam.public ? 'public' : 'private'}</div>
        <label>
          <input
            type='checkbox'
            checked={currentDevice.webcam.approved}
            onChange={evt => {
              deviceActions.patch(currentDevice._id, {
                webcam: Object.assign({}, currentDevice.webcam, { approved: evt.target.checked })
              })
              deviceActions.patch(currentDevice._id, {
                rank: evt.target.checked ? 2.1 : 0
              })
            }}
          />
          Approved
        </label>
        <label>
          Cam Tier: {camTierObj.label} {removeFreeBtn}
        </label>
        {freeEnhancedCam}
      </div>
    )
  }

  _appDevices () {
    const { u } = this.props
    if (!u.appDevices) return null
    console.log('appDevices', u.appDevices)
    return (
      <div className='app-devices'>
        <h3>Mobile Devices</h3>
        {Object.keys(u.appDevices).map(appId => {
          const d = u.appDevices[appId]
          return (
            <div className='app-device' key={appId}>
              <div>
                {d.manufacturer} {d.model} {d.version}
              </div>
              <div>
                Notifications enabled: {d.notificationsEnabled ? 'yes' : 'no'}
              </div>
              <div>
                FCM instance_id: {d.fcmToken && d.fcmToken.split(':')[0]}<br />
              </div>
              <div>
                Last Updated: {fmtzDate('MMM Do YYYY, h:mm:ss a')(d.lastUpdated)}
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  render () {
    const { device, u, userActions, deviceActions, user } = this.props
    const { setDashboardDevice, setDeviceI, fetchDevices, patch } = deviceActions
    const { becomeUser } = userActions
    const { allDevices } = device
    const { roles } = this.state
    const devices = allDevices && filter(propEq('userId', u._id), allDevices)
    const fmt = fmtzDate('MMM Do YYYY')
    let bottom
    if (devices && devices.length > 0) {
      bottom = devices.map(d =>
        <div className='device' key={d._id}>
          <label>
            {/* <input
              type='checkbox'
              checked={d.active}
              onChange={() => patch(d._id, { active: !d.active })}
            />
            &nbsp; */}
            {getDeviceLabel(d)} - {d.macAddress}
          </label>
          {d.public ? <Link className='dashboard-link' to={getDeviceSharePath(d)}>dashboard</Link> : null}
          {this._webcam(d)}
          {/* <WipeData d={d} /> */}
        </div>
      )
    }
    let become = ''

    if (user.info._id !== u._id) {
      become = (
        <>
          <a
            className='btn btn-primary'
            onClick={async () => {
              const res = await rawFeathersRequest('/users/aaadmin/become', {
                method: 'POST',
                body: JSON.stringify({ _id: u._id })
              })
              const { token } = res
              if (token) {
                window.localStorage.setItem('feathers-jwt', token)
                window.location.replace('/dashboard')
              } else {
                window.alert('error becoming user')
              }
            }}
          >Acutally Become</a>
          <a
            onClick={() => {
              if (user.adminImpersonation && user.adminImpersonation._id === u._id) {
                window.location.reload()
              } else {
                setDeviceI(0)
                setDashboardDevice(false)
                becomeUser(u)
                fetchDevices()
              }
            }}
          >Legacy (kinda) Become</a>
        </>
      )
    }
    return (
      <div className='user-user-card'>
        <div className='top'>
          <Avatar u={u} />
          <div className='name'>
            {u.email}
            <div className='details'>
              Created: {fmt(u.createdAt)}
            </div>
            <div className='roles'>
              {ROLES.map(key =>
                <label className='role' key={key}>
                  {key}
                  <input
                    type='checkbox'
                    checked={contains(key, roles)}
                    onChange={evt => {
                      const newRoles = contains(key, roles) ? removeFromArr(key, roles) : append(key, roles)
                      this.setState({
                        roles: newRoles
                      })
                      userActions.patch(u._id, { roles: newRoles })
                    }}
                  />
                </label>
              )}
            </div>
          </div>
          {become}
        </div>
        <div className='bottom'>
          {this._appDevices()}
          {bottom}
          <ApiKeys u={u} />
        </div>
      </div>
    )
  }
}

export default bindAllActions(UserCard)

UserCard.displayName = 'UserCard'
