

import React, { PureComponent } from 'react';
import { isSomething, widgetExpanded, dataHas, fetchStartEndRes } from '../../common/ambient'
import PropTypes from 'prop-types';
import { max, map, pick, mergeAll } from 'ramda'
import FormattedDataPoint from './FormattedDataPoint'

// Binary Leaf Wetness
// Shows current state (Wet or Dry) and sum of minutes in the Wet state today
export default class LeafBinWidget extends PureComponent {
  static propTypes = {
    device: PropTypes.object,
    currentDevice: PropTypes.object.isRequired,
    fetchDeviceData: PropTypes.func
  }
  state = {
    now: false
  }

  render() {
    const { currentDevice, device } = this.props
    const { lastData } = currentDevice
    let widget = <div className="device-leaf-bin-widget hidden" />
    const currentDataHas = dataHas(lastData)
    if (currentDataHas(['leafwet1x'])) {
      let from = ''
      let todaysLeafwetSum 
      if (lastData.hl && lastData.hl.leafwetsum) {
        todaysLeafwetSum = lastData.hl.leafwetsum.s
      }
      if (isSomething(todaysLeafwetSum)) {
        from =
          <div className="from zero">
            <div className="label">
              Total Wet Minutes Today 
            </div>
            <FormattedDataPoint type={'leafwet1x'} value={todaysLeafwetSum} />
          </div>
      }
      let currState = 'Dry'
      if (lastData.leafwet1x && (lastData.leafwet1x > 0)) {
        currState = 'Wet'
      }
      widget =
        <div className="device-leaf-bin-widget center-aligned">
          <div className="label">
            Current State 
          </div>
          <div className="leafwet">
            <div className="fdp-val">
              {currState}
            </div>
          </div>
          {from}
        </div>
    }
    return widget
  }
}



LeafBinWidget.displayName = 'LeafBinWidget'

LeafBinWidget.displayName = 'LeafBinWidget'

LeafBinWidget.displayName = 'LeafBinWidget'

LeafBinWidget.displayName = 'LeafBinWidget'

LeafBinWidget.displayName = 'LeafBinWidget'

LeafBinWidget.displayName = 'LeafBinWidget'

LeafBinWidget.displayName = 'LeafBinWidget'
