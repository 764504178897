import React, { PureComponent } from 'react';
import { ambient } from '../../common/lib/ambient-weather-common'
import bindAllActions from '../../common/bindAllActions'
import PropTypes from 'prop-types';
import { not, clone, map, propEq, equals, pipe, find, nth, indexOf, contains, test } from 'ramda'
import { shouldGetDevice, getTheDevice, isRole, isSomething, isLoggedIn, convertUnitInverse, getMasterUnitParam, getLabelForDevice, getDeviceLabel, getDisplayKeys, objsContainId, ALERT_CONDITIONS, getConditionLabel } from '../../common/ambient'
import classNames from 'classnames'
import DeviceChooser from '../../components/DeviceChooser'
import AlertList from './AlertList'

const conditions = ALERT_CONDITIONS

class Crud extends PureComponent {
  static propTypes = {
    alertActions: PropTypes.object.isRequired,
    deviceActions: PropTypes.object.isRequired,
    alert: PropTypes.object.isRequired,
    user: PropTypes.object,
  };
  constructor(props) {
    super(props)
    this.state = {
      dirtyThreshold: ''
    }
    this.createAlert = ::this.createAlert
  }
  // this needs to be moved to componentDidUpdate :) olw 1.20.22
  componentWillUpdate(nextProps, nextState) {
    const { alertActions, deviceActions } = this.props
    const { device, user, alert } = nextProps
    const { devices, deviceCache } = device
    const { deviceId, param, dirtyThreshold, unit } = nextState
    if (isLoggedIn(user) && !alert.alerts) {
      alertActions.fetchAlerts({
        userId: user.info._id
      })
    }
    const masterParam = getMasterUnitParam(param)
    const st8 = {
      units: masterParam ? ambient.DATA_SPEC[masterParam].units : false,
      threshold: dirtyThreshold,
      unit
    }
    // no unit set
    if (st8.units && (!unit || !contains(unit, st8.units))) {
      st8.unit = st8.units[0]
    }
    // convert threshold
    if (st8.unit && st8.units && dirtyThreshold) {
      const i = indexOf(st8.unit, st8.units)
      st8.threshold = convertUnitInverse(masterParam, i, parseFloat(dirtyThreshold))
    }
    this.setState(st8)
  }
  componentDidUpdate() {
    const { device, deviceActions } = this.props
    const theDevice = this._theDevice()
    if (theDevice && theDevice._id && shouldGetDevice(device, theDevice._id, 60)) {
      deviceActions.getDevice(theDevice._id)
    }
  }
  reset() {
    this.setState({
      param: false,
      condition: false,
      dirtyThreshold: ''
    })
  }
  createAlert() {
    const { alertActions } = this.props
    const alrt = clone(this.state)
    alrt.deviceId = this._theDevice()._id
    alertActions.createAlert(alrt)
    this.reset()
  }
  _theDevice() {
    const { deviceId, theDevice } = this.state
    const { device } = this.props
    return getTheDevice(device)
  }

  render() {
    const { dirtyThreshold, threshold, unit, units, param, condition, deviceId } = this.state
    const { user, alertActions, device } = this.props
    const { devices } = device
    let deviceSelect = ''
    if (this._theDevice()) {
      deviceSelect = <div className="device-chooser">
          <DeviceChooser  />
        </div>
    }
    let unitSelect = ''
    if (units) {
      unitSelect =
        <select
          className="form-control"
          onChange={evt => this.setState({ unit: evt.target.value })}
          value={unit}
        >
          {units.map(unt => <option key={unt} value={unt}>{unt}</option>)}
        </select>
    }
    let buttonDisabled = !(param && contains(condition, map(nth(1), conditions)) && isSomething(threshold))
    if (param === 'notReporting') {
      buttonDisabled = false
    }
    let paramSelect = ''
    const theDevice = this._theDevice()
    if (theDevice) {
      const theKeys = getDisplayKeys([theDevice.lastData])
        .filter(k => k !== 'dateutc')
        .filter(pipe(test(/^leak\d$/), not)) // leak alerts are auto-created
      theKeys.push('notReporting')
      paramSelect = <select
        className="form-control"
        onChange={evt => this.setState({ param: evt.target.value })}
        value={param}
      >
        <option>Parameter</option>
        {theKeys.map(key => <option key={key} value={key}>{getLabelForDevice(key, theDevice)}</option>)}
      </select>
    }
    return (
      <div className="alert-crud">
        <div className={classNames("create", param)}>
          {deviceSelect}
          {paramSelect}
          <select
            className="form-control condition"
            onChange={evt => this.setState({ condition: evt.target.value })}
            value={condition}
          >
            <option>Condition</option>
            {conditions.map((arr, i) => <option key={i} value={arr[1]}>{arr[0]}</option>)}
          </select>
          <div className="input-group amount">
            <input
              type="number"
              placeholder="Amount"
              onChange={evt => this.setState({ dirtyThreshold: evt.target.value })}
              className="boxy"
              value={dirtyThreshold}
            />
            {unitSelect}
          </div>
          <button
            disabled={buttonDisabled}
            className="btn btn-primary"
            onClick={this.createAlert}
          >
            Create
          </button>
        </div>
        <AlertList />
      </div>
    );
  }
}

export default bindAllActions(Crud)
Crud.displayName = 'Crud'
