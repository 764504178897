
function createShader (gl, type, source) {
  const shader = gl.createShader(type)
  gl.shaderSource(shader, source)

  gl.compileShader(shader)
  if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
    throw new Error(gl.getShaderInfoLog(shader))
  }

  return shader
}

function createProgram (gl, vertexSource, fragmentSource) {
  const program = gl.createProgram()

  const vertexShader = createShader(gl, gl.VERTEX_SHADER, vertexSource)
  const fragmentShader = createShader(gl, gl.FRAGMENT_SHADER, fragmentSource)

  gl.attachShader(program, vertexShader)
  gl.attachShader(program, fragmentShader)

  gl.linkProgram(program)
  if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
    throw new Error(gl.getProgramInfoLog(program))
  }

  const wrapper = { program }

  const numAttributes = gl.getProgramParameter(program, gl.ACTIVE_ATTRIBUTES)
  for (let i = 0; i < numAttributes; i++) {
    const attribute = gl.getActiveAttrib(program, i)
    wrapper[attribute.name] = gl.getAttribLocation(program, attribute.name)
  }
  const numUniforms = gl.getProgramParameter(program, gl.ACTIVE_UNIFORMS)
  for (let i = 0; i < numUniforms; i++) {
    const uniform = gl.getActiveUniform(program, i)
    wrapper[uniform.name] = gl.getUniformLocation(program, uniform.name)
  }

  return wrapper
}

function createTexture (gl, filter, data, width, height) {
  const texture = gl.createTexture()
  gl.bindTexture(gl.TEXTURE_2D, texture)
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE)
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE)
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, filter)
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, filter)
  if (data instanceof Uint8Array) {
    gl.texImage2D(
      gl.TEXTURE_2D,
      0,
      gl.RGBA,
      width,
      height,
      0,
      gl.RGBA,
      gl.UNSIGNED_BYTE,
      data
    )
  } else {
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, data)
  }
  gl.bindTexture(gl.TEXTURE_2D, null)
  return texture
}

function bindTexture (gl, texture, unit) {
  gl.activeTexture(gl.TEXTURE0 + unit)
  gl.bindTexture(gl.TEXTURE_2D, texture)
}

function createBuffer (gl, data) {
  const buffer = gl.createBuffer()
  gl.bindBuffer(gl.ARRAY_BUFFER, buffer)
  gl.bufferData(gl.ARRAY_BUFFER, data, gl.STATIC_DRAW)
  return buffer
}

function bindAttribute (gl, buffer, attribute, numComponents) {
  gl.bindBuffer(gl.ARRAY_BUFFER, buffer)
  gl.enableVertexAttribArray(attribute)
  gl.vertexAttribPointer(attribute, numComponents, gl.FLOAT, false, 0, 0)
}

function bindFramebuffer (gl, framebuffer, texture) {
  gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffer)
  if (texture) {
    gl.framebufferTexture2D(
      gl.FRAMEBUFFER,
      gl.COLOR_ATTACHMENT0,
      gl.TEXTURE_2D,
      texture,
      0
    )
  }
}

//
// Initialize a texture and load an image.
// When the image finished loading copy it into the texture.
//
function loadTexture (gl, url, callback, filter) {
  const root = this
  const texture = gl.createTexture()
  gl.bindTexture(gl.TEXTURE_2D, texture)

  // Because images have to be downloaded over the internet
  // they might take a moment until they are ready.
  // Until then put a single pixel in the texture so we can
  // use it immediately. When the image has finished downloading
  // we'll update the texture with the contents of the image.
  const level = 0
  const internalFormat = gl.RGBA
  const width = 1
  const height = 1
  const border = 0
  const srcFormat = gl.RGBA
  const srcType = gl.UNSIGNED_BYTE
  const pixel = new Uint8Array([0, 0, 255, 255]) // opaque blue
  gl.texImage2D(
    gl.TEXTURE_2D,
    level,
    internalFormat,
    width,
    height,
    border,
    srcFormat,
    srcType,
    pixel
  )

  const image = new Image()
  image.onload = function () {
    gl.bindTexture(gl.TEXTURE_2D, texture)
    gl.texImage2D(
      gl.TEXTURE_2D,
      level,
      internalFormat,
      srcFormat,
      srcType,
      image
    )

    // WebGL1 has different requirements for power of 2 images
    // vs non power of 2 images so check if the image is a
    // power of 2 in both dimensions.
    if (isPowerOf2(image.width) && isPowerOf2(image.height)) {
      // Yes, it's a power of 2. Generate mips.
      gl.generateMipmap(gl.TEXTURE_2D)
    } else {
      // No, it's not a power of 2. Turn off mips and set
      // wrapping to clamp to edge
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE)
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE)
      gl.texParameteri(
        gl.TEXTURE_2D,
        gl.TEXTURE_MIN_FILTER,
        filter || gl.LINEAR
      )
      gl.texParameteri(
        gl.TEXTURE_2D,
        gl.TEXTURE_MAG_FILTER,
        filter || gl.LINEAR
      )
    }

    if (callback) {
      callback.call(root)
    }
  }
  image.crossOrigin = 'anonymous'
  image.src = url

  return texture
}

function getColorRamp (colors) {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  const length = Object.keys(colors).length

  canvas.width = length
  canvas.height = 1

  const gradient = ctx.createLinearGradient(0, 0, length, 0)
  for (const stop in colors) {
    try {
      gradient.addColorStop(+stop, colors[stop])
    } catch (err) {
      console.error('Could not create stop', colors[stop])
    }
  }

  ctx.fillStyle = gradient
  ctx.fillRect(0, 0, length, 1)

  return new Uint8Array(ctx.getImageData(0, 0, length, 1).data)
}

function isPowerOf2 (value) {
  return (value & (value - 1)) === 0
}

const getFirstSymbolLayer = (map) => {
  let firstSymbolLayerID
  map.getStyle().layers.some((layer) => {
    if (layer.type === 'symbol') {
      firstSymbolLayerID = layer.id
      return true
    }
    return false
  })
  return firstSymbolLayerID
}

Math.clamp = function (number, min, max) {
  return Math.max(min, Math.min(number, max))
}

class WindLayer {
  constructor (id, windData) {
    // See https://docs.mapbox.com/mapbox-gl-js/api/properties/#customlayerinterface for more information on custom layers
    this.type = 'custom'
    this.id = id
    // Make sure the width and height match up with the server
    this.width = 720
    this.height = 720
    this.ready = false
    this.windData = windData

    this.uDelta = [-50, 50]
    this.vDelta = [-50, 50]

    // UV min-max is out of 250 (50 * 50) so we calculate these
    //   colors based off of a max of 250
    this.rampColors = {
      0.0: '#dfd1dc', // 0-7 mph
      0.028: '#d1b8df', // 13 mph
      0.052: '#bf98db', // 20 mph
      0.08: '#a56dd2', // 30 mph
      0.12: '#8b41c9', // 40 mph
      0.16: '#7437a8', // 50 mph
      0.2: '#55287b', // 60 mph
      0.24: '#2e1643', // 70 mph
      0.28: '#000000', // >75 mph
      0.5: '#ba0908', // 125 mph - threshold to allow blending
      1.0: '#ba0908' // 250 mph
    }

    this.frameDuration = 1000
  }

  onAdd (map, gl) {
    this.gl = gl
    this.map = map

    this.windTextures = {}
    this.loadWindTextures(gl)

    this.quadBuffer = createBuffer(
      gl,
      new Float32Array([0, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 1])
    )

    this.colorRampTexture = createTexture(
      gl,
      gl.LINEAR,
      getColorRamp(this.rampColors),
      Object.keys(this.rampColors).length,
      1
    )
  }

  mercY (y) {
    const s = Math.sin(Math.PI * (y - 0.5))
    const y2 =
            1.0 - (Math.log((1.0 + s) / (1.0 - s)) / (2 * Math.PI) + 1.0) / 2.0
    return y2 < 0 ? 0 : y2 > 1 ? 1 : y2
  }

  setView (bbox) {
    this.bbox = bbox

    const minX = bbox[0]
    const minY = this.mercY(bbox[3])
    const maxX = bbox[2]
    const maxY = this.mercY(bbox[1])

    const kx = 2 / (maxX - minX)
    const ky = 2 / (maxY - minY)

    this.matrix = new Float32Array([
      kx,
      0,
      0,
      0,
      0,
      ky,
      0,
      0,
      0,
      0,
      1,
      0,
      -1 - minX * kx,
      -1 - minY * ky,
      0,
      1
    ])
  }

  loadWindTextures (gl) {
    let completedFrames = 0
    const maxFrames = this.windData.length
    const callback = () => {
      completedFrames++
      if (completedFrames === maxFrames) {
        this.windTexture = this.windTextures[this.windData[0].timestamp]
        this.previousWindTexture = this.windTexture
        this.ready = true
        this.tryRepaint()
      }
    }
    for (let i = 0; i < maxFrames; i++) {
      this.windTextures[this.windData[i].timestamp] = loadTexture(
        gl,
        this.windData[i].url,
        callback
      )
    }
  }

  changeFrame (frame) {
    this.previousWindTexture = this.windTexture || this.windTextures[frame]
    this.windTexture = this.windTextures[frame]
    this.animationEnd = new Date(Date.now() + this.frameDuration)
  }

  tryRepaint () {
    if (this.map && this.ready) {
      this.map.triggerRepaint()
    }
  }

  prerender () {
    this.tryRepaint()
  }
}
const windFragmentSrc = `
precision highp float;

uniform sampler2D u_wind_prev;
uniform sampler2D u_wind_next;
uniform sampler2D u_color_ramp;

uniform vec2 u_wind_res;
uniform vec2 u_wind_min;
uniform vec2 u_wind_max;
uniform float u_opacity;
uniform float u_transition;

varying vec2 v_tex_coord;

uniform vec2 u_north_west;
uniform vec2 u_south_east;

vec2 lookup_wind(const sampler2D sample, const vec2 uv) {
    // return texture2D(sample, uv).r; // lower-res hardware filtering
    vec2 px = 1.0 / u_wind_res;
    vec2 vc = (floor(uv * u_wind_res)) * px;
    vec2 f = fract(uv * u_wind_res);
    vec2 tl = texture2D(sample, vc).gb;
    vec2 tr = texture2D(sample, vc + vec2(px.x, 0)).gb;
    vec2 bl = texture2D(sample, vc + vec2(0, px.y)).gb;
    vec2 br = texture2D(sample, vc + px).gb;
    return mix(mix(tl, tr, f.x), mix(bl, br, f.x), f.y);
}

void main(void) {
    // convert to global geographic position
    vec2 global_pos = u_north_west.xy + v_tex_coord * (u_south_east.xy - u_north_west.xy);
    global_pos.x = mod(global_pos.x, 1.0);

    vec2 tex0 = lookup_wind(u_wind_prev, global_pos);
    vec2 tex1 = lookup_wind(u_wind_next, global_pos);
    vec2 tex = mix(tex0, tex1, u_transition);

    vec2 velocity = mix(u_wind_min, u_wind_max, tex);
    float speed = length(velocity);
    float speed_t = speed / length(u_wind_max);

    vec2 ramp_pos = vec2(speed_t, 0.5);
    vec4 color = texture2D(u_color_ramp, ramp_pos);
    color.a = u_opacity;
    gl_FragColor = color;
}`

const windVertexSrc = `
precision highp float;

attribute vec2 a_tex_coord;

uniform mat4 u_matrix;
uniform vec2 u_north_west;
uniform vec2 u_south_east;

varying vec2 v_tex_coord;

void main(void) {
    // convert to global geographic position
    vec2 global_pos = u_north_west.xy + a_tex_coord * (u_south_east.xy - u_north_west.xy);
    // project the position with mercator projection
    float s = sin(radians(global_pos.y * 180.0 - 90.0));
    float y = 1.0 - (degrees(log((1.0 + s) / (1.0 - s))) / 360.0 + 1.0) / 2.0;

    y = clamp(y, 0.0, 1.0);

    gl_Position = clamp(u_matrix * vec4(global_pos.x, y, 0.0, 1.0), vec4(-1, -1, -1, -1), vec4(1, 1, 1, 1));
    v_tex_coord = a_tex_coord;
}`

class WindSpeedLayer extends WindLayer {
  constructor (id, windData) {
    super(id, windData)

    this.maxZoom = 12
    this.opacity = 0.85
    this.enabled = true
  }

  onAdd (map, gl) {
    super.onAdd(map, gl)

    this.windProgram = createProgram(gl, windVertexSrc, windFragmentSrc)
    gl.vertexAttribPointer(
      this.windProgram.a_tex_coord,
      2,
      gl.FLOAT,
      false,
      0,
      0
    )

    gl.enableVertexAttribArray(this.windProgram.a_tex_coord)
  }

  render (gl) {
    if (!this.enabled) {
      return
    }
    if (!this.ready) {
      return
    }

    const bounds = this.map.getBounds()
    const northWest = mapboxgl.MercatorCoordinate.fromLngLat(
      bounds.getNorthWest()
    )
    const southEast = mapboxgl.MercatorCoordinate.fromLngLat(
      bounds.getSouthEast()
    )

    this.northWest = [northWest.x, northWest.y]
    this.southEast = [southEast.x, southEast.y]
    this.setView([northWest.x, northWest.y, southEast.x, southEast.y])

    bindTexture(gl, this.previousWindTexture, 0)
    bindTexture(gl, this.windTexture, 1)
    bindTexture(gl, this.colorRampTexture, 2)

    const program = this.windProgram
    gl.useProgram(program.program)

    gl.uniformMatrix4fv(program.u_matrix, false, this.matrix)

    gl.uniform1i(program.u_wind_prev, 0)
    gl.uniform1i(program.u_wind_next, 1)
    gl.uniform1i(program.u_color_ramp, 2)
    gl.uniform2f(program.u_north_west, this.northWest[0], this.northWest[1])
    gl.uniform2f(program.u_south_east, this.southEast[0], this.southEast[1])

    bindAttribute(gl, this.quadBuffer, program.program.a_tex_coord, 2)
    gl.uniform2f(program.u_wind_res, this.height, this.width)
    gl.uniform1f(program.u_opacity, this.opacity)
    gl.uniform2f(program.u_wind_min, this.uDelta[0], this.vDelta[0])
    gl.uniform2f(program.u_wind_max, this.uDelta[1], this.vDelta[1])
    if (this.animationEnd) {
      const transition = (this.animationEnd - Date.now()) / this.frameDuration
      gl.uniform1f(program.u_transition, Math.clamp(1 - transition, 0, 1))
    }

    gl.enable(gl.BLEND)
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA)
    gl.drawArrays(gl.TRIANGLES, 0, 6)
  }
}

const drawVert = `
precision highp float;

attribute float a_index;

uniform mat4 u_matrix;
uniform sampler2D u_particles;
uniform float u_particles_res;
uniform float u_particles_size;

varying vec2 v_particle_pos;

uniform vec2 u_north_west;
uniform vec2 u_south_east;

const vec2 bitEnc = vec2(1.,255.);
const vec2 bitDec = 1./bitEnc;

// decode particle position from pixel RGBA
vec2 fromRGBA(const vec4 color) {
  vec4 rounded_color = floor(color * 255.0 + 0.5) / 255.0;
  float x = dot(rounded_color.rg, bitDec);
  float y = dot(rounded_color.ba, bitDec);
  return vec2(x, y);
}

// encode particle position to pixel RGBA
vec4 toRGBA (const vec2 pos) {
  vec2 rg = bitEnc * pos.x;
  rg = fract(rg);
  rg -= rg.yy * vec2(1. / 255., 0.);

  vec2 ba = bitEnc * pos.y;
  ba = fract(ba);
  ba -= ba.yy * vec2(1. / 255., 0.);

  return vec4(rg, ba);
}

void main() {
    vec4 color = texture2D(u_particles, vec2(
        fract(a_index / u_particles_res),
        floor(a_index / u_particles_res) / u_particles_res));

    // decode current particle position from the pixel's RGBA value
    //v_particle_pos = vec2(
    //    color.r / 255.0 + color.b,
    //    color.g / 255.0 + color.a);

    v_particle_pos = fromRGBA(color);

    // convert to global geographic position
    v_particle_pos = u_north_west.xy + v_particle_pos * (u_south_east.xy - u_north_west.xy);

    // project the position with mercator projection
    float s = sin(radians(v_particle_pos.y * 180.0 - 90.0));
    float y = 1.0 - (degrees(log((1.0 + s) / (1.0 - s))) / 360.0 + 1.0) / 2.0;

    gl_PointSize = u_particles_size;
    gl_Position = u_matrix * vec4(v_particle_pos.x, y, 0, 1);
}`
const drawFrag = `
precision highp float;

uniform sampler2D u_wind;
uniform vec2 u_wind_min;
uniform vec2 u_wind_max;
uniform sampler2D u_color_ramp;
uniform int u_background_enabled;

varying vec2 v_particle_pos;

void main() {
    vec4 tex = texture2D(u_wind, v_particle_pos);
    vec2 velocity = mix(u_wind_min, u_wind_max, tex.gb);
    float speed = length(velocity);
    if (speed < 1.0) {
        discard;
    }

    vec4 color = vec4(1.0, 1.0, 1.0, 1.0) * 0.75;

    if (u_background_enabled == 0) {
        vec2 ramp_pos = vec2(tex.r, 0.5);
        color = texture2D(u_color_ramp, ramp_pos) * 0.75;
    }

    gl_FragColor = color;
}`

const quadVert = `
precision highp float;

attribute vec2 a_pos;

varying vec2 v_tex_pos;

void main() {
    v_tex_pos = a_pos;
    gl_Position = vec4(1.0 - 2.0 * a_pos, 0, 1);
}`

const screenFrag = `
precision highp float;

uniform sampler2D u_screen;
uniform float u_opacity;

varying vec2 v_tex_pos;

void main() {
    vec4 color = texture2D(u_screen, 1.0 - v_tex_pos);
    color.a = floor(255.0 * color.a * u_opacity) / 255.0;
    gl_FragColor = color;
}`
const updateFrag = `
precision highp float;

uniform sampler2D u_particles;
uniform sampler2D u_wind;
uniform vec2 u_wind_res;
uniform vec2 u_wind_min;
uniform vec2 u_wind_max;
uniform float u_rand_seed;
uniform float u_speed_factor;
uniform float u_drop_rate;
uniform float u_drop_rate_bump;

uniform vec2 u_north_west;
uniform vec2 u_south_east;

varying vec2 v_tex_pos;

// pseudo-random generator
const vec3 rand_constants = vec3(12.9898, 78.233, 4375.85453);
float rand(const vec2 co) {
    float t = dot(rand_constants.xy, co);
    return fract(sin(t) * (rand_constants.z + t));
}

const vec2 bitEnc = vec2(1.,255.);
const vec2 bitDec = 1./bitEnc;

// decode particle position from pixel RGBA
vec2 fromRGBA(const vec4 color) {
  vec4 rounded_color = floor(color * 255.0 + 0.5) / 255.0;
  float x = dot(rounded_color.rg, bitDec);
  float y = dot(rounded_color.ba, bitDec);
  return vec2(x, y);
}

// encode particle position to pixel RGBA
vec4 toRGBA (const vec2 pos) {
  vec2 rg = bitEnc * pos.x;
  rg = fract(rg);
  rg -= rg.yy * vec2(1. / 255., 0.);

  vec2 ba = bitEnc * pos.y;
  ba = fract(ba);
  ba -= ba.yy * vec2(1. / 255., 0.);

  return vec4(rg, ba);
}

// wind speed lookup; use manual bilinear filtering based on 4 adjacent pixels for smooth interpolation
vec2 lookup_wind(const vec2 uv) {
    // return texture2D(u_wind, uv).gb; // lower-res hardware filtering
    vec2 px = 1.0 / u_wind_res;
    vec2 vc = (floor(uv * u_wind_res)) * px;
    vec2 f = fract(uv * u_wind_res);
    vec2 tl = texture2D(u_wind, vc).gb;
    vec2 tr = texture2D(u_wind, vc + vec2(px.x, 0)).gb;
    vec2 bl = texture2D(u_wind, vc + vec2(0, px.y)).gb;
    vec2 br = texture2D(u_wind, vc + px).gb;
    return mix(mix(tl, tr, f.x), mix(bl, br, f.x), f.y);
}

void main() {
    vec4 color = texture2D(u_particles, v_tex_pos);
    //vec2 pos = vec2(
    //    color.r / 255.0 + color.b,
    //    color.g / 255.0 + color.a); // decode particle position from pixel RGBA

    vec2 pos = fromRGBA(color);

    // convert to global geographic position
    vec2 global_pos = u_north_west.xy + pos * (u_south_east.xy - u_north_west.xy);
    global_pos.x = mod(global_pos.x, 1.0);

    vec2 velocity = mix(u_wind_min, u_wind_max, lookup_wind(global_pos));
    float speed = length(velocity);
    float speed_t = speed / length(u_wind_max);

    vec2 offset = vec2(velocity.x, -velocity.y) * 0.0001 * u_speed_factor;

    // update particle position, wrapping around the date line
    pos = fract(1.0 + pos + offset);

    // a random seed to use for the particle drop
    vec2 seed = (pos + v_tex_pos) * u_rand_seed;

    // drop rate is a chance a particle will restart at random position, to avoid degeneration
    float drop_rate = u_drop_rate + speed_t * u_drop_rate_bump;

    float retain = step(drop_rate, rand(seed));

    // If a particle is moving too slowly, kill it immediately
    retain = retain * step(1.0, speed);

    vec2 random_pos = vec2(rand(seed + 1.3), 1.0 - rand(seed + 2.1));
    pos = mix(pos, random_pos, 1.0 - retain);

    // encode the new particle position back into RGBA
    //gl_FragColor = vec4(
    //    fract(pos * 255.0),
    //    floor(pos * 255.0) / 255.0);

    gl_FragColor = toRGBA(pos);
}`

class WindParticleLayer extends WindLayer {
  constructor (id, windData, width) {
    super(id, windData)

    this.fadeOpacity = 0.985
    this.dropRate = 0.003
    this.dropRateBump = 0.02

    this.panning = false

    this.updateWidth(width)

    this.particleSize = 2.0 * window.devicePixelRatio
  }

  updateWidth (width) {
    this.numberParticles = width * 3
    this.speedFactor = 0.2 + (1.0 - width / 1920)
    this.speedFactor = Math.max(this.speedFactor, 0.05)
    if (this.gl) {
      this.initializeParticles(this.gl, this.numberParticles)
    }
  }

  onAdd (map, gl) {
    super.onAdd(map, gl)

    this.gl = gl

    this.drawProgram = createProgram(gl, drawVert, drawFrag)
    this.screenProgram = createProgram(gl, quadVert, screenFrag)
    this.updateProgram = createProgram(gl, quadVert, updateFrag)

    this.framebuffer = gl.createFramebuffer()

    this.resize(gl)

    this.initializeParticles(gl, this.numberParticles)

    const root = this
    map.on('movestart', () => {
      root.panning = true
      root.resize(gl)
    })
    map.on('moveend', () => {
      root.initializeParticles(gl, this.numberParticles)
      root.panning = false
    })
  }

  initializeParticles (gl, count) {
    // we create a square texture where each pixel will hold a particle position encoded as RGBA
    const particleRes = (this.particleStateResolution = Math.ceil(
      Math.sqrt(count)
    ))
    this._numParticles = particleRes * particleRes

    // Update Zoom Level
    this.zoom = this.map.getZoom()
    if (this.zoom < 3) {
      this.particleSize = 1.5 * window.devicePixelRatio
    } else {
      this.particleSize = 2.0 * window.devicePixelRatio
    }
    // Define the lat-lon bounds
    const bounds = this.map.getBounds()
    const northWest = mapboxgl.MercatorCoordinate.fromLngLat(
      bounds.getNorthWest()
    )
    const southEast = mapboxgl.MercatorCoordinate.fromLngLat(
      bounds.getSouthEast()
    )

    this.northWest = [northWest.x, northWest.y]
    this.southEast = [southEast.x, southEast.y]
    this.setView([northWest.x, northWest.y, southEast.x, southEast.y])

    // Randomize the initial particle view
    const particleState = new Uint8Array(this._numParticles * 4)
    for (let i = 0; i < this._numParticles; i++) {
      // Red channel
      particleState[i * 4] = Math.floor(Math.random() * 256)
      // Green channel
      particleState[i * 4 + 1] = Math.floor(Math.random() * 256)
      // Blue channel
      particleState[i * 4 + 2] = Math.floor(Math.random() * 256)
      // Alpha channel
      particleState[i * 4 + 3] = Math.floor(Math.random() * 256)
    }

    // textures to hold the particle state for the current and the next frame
    this.particleStateTexture0 = createTexture(
      gl,
      gl.NEAREST,
      particleState,
      particleRes,
      particleRes
    )
    this.particleStateTexture1 = createTexture(
      gl,
      gl.NEAREST,
      particleState,
      particleRes,
      particleRes
    )

    // Initialize a simple particle
    const particleIndices = new Float32Array(this._numParticles)
    for (let i = 0; i < this._numParticles; i++) {
      particleIndices[i] = i
    }
    this.particleIndexBuffer = createBuffer(gl, particleIndices)
  }

  resize (gl) {
    const emptyPixels = new Uint8Array(gl.canvas.width * gl.canvas.height * 4)
    // screen textures to hold the drawn screen for the previous and the current frame
    this.backgroundTexture = createTexture(
      gl,
      gl.NEAREST,
      emptyPixels,
      gl.canvas.width,
      gl.canvas.height
    )
    this.screenTexture = createTexture(
      gl,
      gl.NEAREST,
      emptyPixels,
      gl.canvas.width,
      gl.canvas.height
    )
  }

  render (gl) {
    if (!this.ready || this.panning) {
      return
    }

    gl.disable(gl.DEPTH_TEST)
    gl.disable(gl.STENCIL_TEST)

    bindTexture(gl, this.windTexture, 0)
    bindTexture(gl, this.particleStateTexture0, 1)

    this.drawScreen(gl, this.matrix)
    this.updateParticles(gl)
  }

  drawScreen (gl, matrix) {
    // draw the screen into a temporary framebuffer to retain it as the background on the next frame
    bindFramebuffer(gl, this.framebuffer, this.screenTexture)
    gl.viewport(0, 0, gl.canvas.width, gl.canvas.height)

    gl.clearColor(0, 0, 0, 0)
    gl.clear(gl.COLOR_BUFFER_BIT)

    this.drawTexture(gl, this.backgroundTexture, this.fadeOpacity)

    gl.enable(gl.BLEND)
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA)
    this.drawParticles(gl, matrix)
    gl.disable(gl.BLEND)

    bindFramebuffer(gl, null)

    // enable blending to support drawing on top of an existing background (e.g. a map)
    gl.enable(gl.BLEND)
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA)
    this.drawTexture(gl, this.screenTexture, 1.0)
    gl.disable(gl.BLEND)

    // save the current screen as the background for the next frame
    const temp = this.backgroundTexture
    this.backgroundTexture = this.screenTexture
    this.screenTexture = temp
  }

  drawParticles (gl, matrix) {
    const program = this.drawProgram
    gl.useProgram(program.program)

    gl.uniformMatrix4fv(program.u_matrix, false, matrix)

    bindAttribute(gl, this.particleIndexBuffer, program.a_index, 1)
    bindTexture(gl, this.colorRampTexture, 2)

    gl.uniform1i(program.u_wind, 0)
    gl.uniform1i(program.u_particles, 1)
    gl.uniform1i(program.u_color_ramp, 2)

    gl.uniform1f(program.u_particles_res, this.particleStateResolution)
    gl.uniform2f(program.u_wind_min, this.uDelta[0], this.vDelta[0])
    gl.uniform2f(program.u_wind_max, this.uDelta[1], this.vDelta[1])
    gl.uniform1i(program.u_background_enabled, this.windEnabled)
    gl.uniform1f(program.u_particles_size, this.particleSize)

    gl.uniform2f(program.u_north_west, this.northWest[0], this.northWest[1])
    gl.uniform2f(program.u_south_east, this.southEast[0], this.southEast[1])

    gl.drawArrays(gl.POINTS, 0, this._numParticles)
  }

  drawTexture (gl, texture, opacity) {
    const program = this.screenProgram
    gl.useProgram(program.program)

    bindAttribute(gl, this.quadBuffer, program.a_pos, 2)
    bindTexture(gl, texture, 2)
    gl.uniform1i(program.u_screen, 2)
    gl.uniform1f(program.u_opacity, opacity)

    gl.drawArrays(gl.TRIANGLES, 0, 6)
  }

  updateParticles (gl) {
    const blendingEnabled = gl.isEnabled(gl.BLEND)
    gl.disable(gl.BLEND)

    bindFramebuffer(gl, this.framebuffer, this.particleStateTexture1)
    gl.viewport(
      0,
      0,
      this.particleStateResolution,
      this.particleStateResolution
    )

    const program = this.updateProgram
    gl.useProgram(program.program)

    bindAttribute(gl, this.quadBuffer, program.a_pos, 2)

    gl.uniform1i(program.u_wind, 0)
    gl.uniform1i(program.u_particles, 1)

    gl.uniform1f(program.u_rand_seed, Math.random())
    gl.uniform2f(program.u_wind_res, this.width, this.height)
    gl.uniform2f(program.u_wind_min, this.uDelta[0], this.vDelta[0])
    gl.uniform2f(program.u_wind_max, this.uDelta[1], this.vDelta[1])
    if (this.zoom < 5) {
      gl.uniform1f(program.u_speed_factor, this.speedFactor * this.zoom * 0.2)
    } else {
      gl.uniform1f(program.u_speed_factor, this.speedFactor)
    }
    gl.uniform1f(program.u_drop_rate, this.dropRate)
    gl.uniform1f(program.u_drop_rate_bump, this.dropRateBump)

    gl.uniform2f(program.u_north_west, this.northWest[0], this.northWest[1])
    gl.uniform2f(program.u_south_east, this.southEast[0], this.southEast[1])

    gl.drawArrays(gl.TRIANGLES, 0, 6)

    if (blendingEnabled) {
      gl.enable(gl.BLEND)
    }

    // swap the particle state textures so the new one becomes the current one
    const temp = this.particleStateTexture0
    this.particleStateTexture0 = this.particleStateTexture1
    this.particleStateTexture1 = temp
  }
}
export {
  WindParticleLayer,
  WindSpeedLayer,
  getFirstSymbolLayer
}
