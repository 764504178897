import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import ReactPlayer from 'react-player'
import classNames from 'classnames'
import Linkify from 'linkifyjs/react'
import { postIsExpired, trackEvent, camId2Slug } from '../../common/ambient'
import bindUserActions from '../user/redux/bindUserActions'
import { EveryWidget } from '../device'


const nl2br = function (str) {
  const newlineRegex = /(\r\n|\r|\n)/g
  if (typeof str !== 'string') {
    return str
  }

  return str.split(newlineRegex).map(function (line, index) {
    if (line.match(newlineRegex)) {
      return <br key={index} />
    }
    return line
  })
}

class Post extends PureComponent {
  static propTypes = {
    post: PropTypes.object,
    actions: PropTypes.object // userActions
  }

  state = {
    showMore: false,
    imgLoaded: false
  }

  _tileSnapshot () {
    const { post = {} } = this.props
    if (!/widget/.test(post.type)) return
    const widgetType = post.type.replace('widget-', '')
    const data = post.data || {}
    return (
      <EveryWidget
        snapshot
        type={widgetType}
        {...data}
      />
    )
  }

  _imgLoad (evt) {
    const st8 = {
      imgLoaded: true
    }
    let imgHeight = this.imageRef.clientHeight
    const maxHeight = this.imageRef.clientWidth / 3 * 4
    if (imgHeight > maxHeight) {
      imgHeight = maxHeight
    }
    st8.imgHeight = imgHeight
    if (this.imageRef.clientHeight > this.imageRef.clientWidth) {
      st8.portrait = true
    }
    this.setState(st8)
  }

  render () {
    const { showMore, imgHeight, imgLoaded, portrait } = this.state
    const { actions, post = {} } = this.props
    const userActions = actions
    const showImage = post.image && !post.video
    return (
      <div className={classNames('social-post', post.status, `type-${post.type}`, {
        'show-more': showMore,
        'no-text': !post.text,
        'has-text': post.text,
        'no-img': !post.image,
        'has-img': post.image,
        'has-more': (post.text && post.image) || this._tileSnapshot(),
        'img-loaded': imgLoaded,
        portrait,
        expired: postIsExpired(post)
      })}
      >
        {post.text ? <div className='text'><Linkify options={{ nl2br: true, target: { url: '_blank' } }}>{post.text}</Linkify></div> : null}
        {post.video && <div className='video-container'>
          <ReactPlayer
            className='video'
            url={post.video}
            light={post.image}
            controls
            muted
            loop
            width='100%'
            height='100%'
            onPlay={() => {
              trackEvent('webcam', 'play', camId2Slug(post.video.split('/').slice(-2, -1)[0].replace('.mp4', '')))
            }}
          />
                       </div>}
        {showImage && [
          <a
            key='image-link'
            onClick={() => userActions.doModal({ type: 'image', data: { url: post.image } })}
            className='img img-cover'
            style={{
              backgroundImage: `url(${post.image})`,
              height: imgHeight
            }}
          />,
          <img key='image' ref={ref => this.imageRef = ref} src={post.image} onLoad={this._imgLoad.bind(this)} className='ratio' />
        ]}
        {this._tileSnapshot()}
        <a className='show-more' onClick={() => this.setState({ showMore: true })}>show more...</a>
        <div className='expiration'>
          {postIsExpired(post) ? '' : 'Expires ' + moment(post.expiresAt).fromNow()}
        </div>
      </div>
    )
  }
}

export default bindUserActions(Post)
Post.displayName = 'Post'
