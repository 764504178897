import React, { PureComponent } from 'react';
// import Slider from 'react-slick'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { findDOMNode } from 'react-dom'
import bindAllActions from '../../common/bindAllActions'
import classNames from 'classnames'
import VisibilitySensor from 'react-visibility-sensor'
import {
  DevicePopup,
} from './'
import {
  GenericAd,
  MinMaxBtns,
  SwipeHandler,
  
} from '../../components'
import { isLoggedIn, getUserSetting, roundDecimal, activePosts, deviceIdsWithActivePosts, postIsActive, mapShowSocial, getLayerParam, hasWebcam, isGtBreakpoint, isSomething, latLonDistance, pathsChanged, postsSort, canSocialPost, trackEvent, dateVal, socialDevices, log, centerPointForGeo } from '../../common/ambient'
import PropTypes from 'prop-types';
import { findIndex, prepend, map, filter, find, identity, uniq, append, path, pipe, splitEvery, concat, slice, flatten, always, propEq, groupBy, toPairs } from 'ramda'

const SCROLL_DURATION = 500

class OutsideBar extends PureComponent {
  static propTypes = {
    deviceActions: PropTypes.object,
    device: PropTypes.object,
    social: PropTypes.object
  }
  state = {
    tab: 'stations',
    scrolling: false,
    internalFocusDeviceId: false,
    mobileFs: false
  }
  constructor(props) {
    super(props)
    this._webcamTab = ::this._webcamTab
  }
  // scrolls focused device into view
  _ensureActiveItemVisible() {
    const active  = this.refs.active;
    if (active) {
      var domNode = findDOMNode(active);
      this.setState({
        scrolling: true
      }, () => {
        domNode.scrollIntoView({
          behavior: 'smooth'
        })
        setTimeout(() => {
          this.setState({
            scrolling: false
          })
        }, SCROLL_DURATION)
      })
    }
  }
  _checkTab() {
    const { device, social } = this.props
    const { focusDevice, mapLayer } = device
    const { internalFocusDeviceId, firstClick } = this.state

    if (focusDevice && focusDevice._id !== internalFocusDeviceId && mapLayer === '') {
      let tab = 'stations'
      const socialDeviceIds = deviceIdsWithActivePosts(social.mapVisiblePosts)
      if (socialDeviceIds.includes(focusDevice._id)) {
        tab = 'social'
      } else if (hasWebcam(focusDevice)) {
        tab = 'webcams'
      }
      this.setState({
        tab
      })
    }
  }
  componentDidMount() {
    this._checkTab()
  }
  componentDidUpdate(prevProps, prevState) {
    const { device, deviceActions } = this.props
    const { focusDevice, mapLayer, sidebar } = device
    const { internalFocusDeviceId, tab } = this.state
    const propsChanged = pathsChanged(this.props, prevProps)
    const stateChanged = pathsChanged(this.state, prevState)
    // just got done scrolling
    if (pathsChanged(this.state, prevState, ['scrolling']) && prevState.scrolling) {
      const domNode = findDOMNode(this.refs.wrap)
      domNode.scrollTop = 0
    }
    // reset mobile full screen when sidebar changes
    if (propsChanged([['device', 'sidebar']])) {
      this.setState({
        mobileFs: false
      })
    }
    if (propsChanged([['device', 'focusDevice'], ['device', 'sidebar']])
      && sidebar
    ) {
      this._checkTab()
      if (!this._useCarousel()) {
        this._ensureActiveItemVisible()
        this.lastFocusDevice = prevProps.device.focusDevice
        if (focusDevice && focusDevice._id !== internalFocusDeviceId) {
          this.setState({
            internalFocusDeviceId: false
          })
        }
      } else {
      }
    }
    if (propsChanged(['device', 'mapLayer']) && mapLayer !== '') {
      this.setState({
        tab: 'stations'
      })
    }
    // track tabs
    if (sidebar && (propsChanged([['device', 'sidebar']]) || stateChanged([['tab']]))) {
      trackEvent('map', 'tab', tab)
    }
  }
  _mobileFullScreen(props, state) {
    const { mobileFs, internalFocusDeviceId } = state || this.state
    return mobileFs
  }
  _deviceCard(d) {
    const { social, device, deviceActions, userActions } = this.props
    const { mapLayer, focusDevice, hoverDevice } = device
    const { tab, mobileFs } = this.state
    const showSocial = tab === 'social' && d.post
    const showWebcam = tab === 'webcams' || (this._useCarousel() && !showSocial)
    let layerParam = getLayerParam(mapLayer)
    if (showWebcam) {
      layerParam = 'webcam'
    } else if (showSocial) {
      layerParam = 'social'
    }
    return <div
      onMouseEnter={() => deviceActions.hoverDevice(d)}
      onMouseLeave={() => deviceActions.hoverDevice()}
      key={d.post ? d.post._id : d._id}
      className='card-wrap'
      onClick={evt => {
        if (evt.target.tagName.toLowerCase() === 'input') return
        if(layerParam === 'social') {
          this.setState({
            internalFocusDeviceId: d._id,
            mobileFs: true
          }, () => deviceActions.focusDevice(d))
          if (this._useCarousel() && !mobileFs) {
            userActions.doModal(null)
          }
        }
      }}
    >
      <VisibilitySensor
        scrollDelay={500}
        ref={path(['_id'], focusDevice) === d._id ? 'active' : null}
      >
        {({ isVisible }) => {
          // watch views
          if (isVisible && (tab === 'social' || this._useCarousel())) {
            trackEvent('views', 'social', d._id)
          }
          return <DevicePopup 
            className={classNames({
              active: path(['_id'], focusDevice) === d._id,
              hovered: path(['_id'], hoverDevice) === d._id,
            })}
            post={d.post}
            currentDevice={d}
            layerParam={layerParam}
            skipFetch={!isVisible}
          />
        }}
      </VisibilitySensor>
    </div>  
  }
  _webcamTab() {
    this.setState({
      tab: 'webcams'
    })
    this.props.deviceActions.setThing('mapLayer', '')
  }
  _useCarousel() {
    return !isGtBreakpoint('phone-max')
  }
  _devicesToShow() {
    const { device, social, user } = this.props
    const { mapLayer, focusDevice, mapVisibleDevices, fetchedDevices, deviceCache } = device
    const { scrolling, tab } = this.state
    let devicesToShow = mapVisibleDevices
    let sortFn = always(0)
    if (focusDevice && path(['info', 'coords', 'coords'], focusDevice) && !this._useCarousel()) {
      const deviceToUseForDistance = scrolling && this.lastFocusDevice ? this.lastFocusDevice : focusDevice
      sortFn = (a,b) => {
        const coords = path(['info', 'coords', 'coords'])
        if (!coords(deviceToUseForDistance) || !coords(a) || !coords(b)) return 0
        return latLonDistance(coords(deviceToUseForDistance).lat, coords(deviceToUseForDistance).lon, coords(a).lat, coords(a).lon) - latLonDistance(coords(deviceToUseForDistance).lat, coords(deviceToUseForDistance).lon, coords(b).lat, coords(b).lon)
      }
    }
    let filterFunc = d => {
      const layerParam = getLayerParam(mapLayer)
      return d.lastData && isSomething(d.lastData[layerParam])
    } 
    let mapFunc = identity // dont do anything to them

    // we're only going to sort favs to top
    // on social
    const getSortByFavsFn = defaultVal => {
      const defaultFn = typeof defaultVal === 'function' ? defaultVal : always(defaultVal)
      if (!isLoggedIn(user)) return defaultFn 
      const favs = getUserSetting('favs')(user)
      const favKeys = Object.keys(favs || {})
      if (!favs || favKeys.length === 0) return defaultFn 
      return (a,b) => {
        if (favKeys.includes(a.macAddress) && favKeys.includes(b.macAddress)) return defaultVal
        if (favKeys.includes(a.macAddress)) return -1
        if (favKeys.includes(b.macAddress)) return 1
        return typeof defaultVal === 'function' ? defaultVal(a,b) : defaultVal
      }
    }

    // social tab
    if (mapShowSocial(mapLayer) && tab === 'social') {
      const posts = postsSort(activePosts(social.mapVisiblePosts))
      // sort by time
      // sortFn = (a,b) => {
      //   return getSortByFavsFn(dateVal(b.post.createdAt) - dateVal(a.post.createdAt))(a,b)
      // }

      // all the posts for a device
      const findVisibleDevice = post => find(propEq('_id', post.deviceId), [...mapVisibleDevices, ...fetchedDevices, ...Object.values(deviceCache)])
      devicesToShow = posts
        .map(post => {
          const d = Object.assign({}, findVisibleDevice(post))
          d.post = post
          return d
        })
        .filter(d => d.post)
        .sort(sortFn)
      // only show one post per device per location 
      return pipe(
        groupBy(d => {
          const postCenter =  path(['data', 'centerGeo'], d.post) || centerPointForGeo(d.post.geo)
          const lat = roundDecimal(13, postCenter.coordinates[0])
          const lon = roundDecimal(13, postCenter.coordinates[1])
          return  `${d._id}-${lat}-${lon}`
        }),
        toPairs,
        map(arr => arr[1][0])
      )(devicesToShow)
    } else if (tab === 'webcams') {
      filterFunc = hasWebcam
      // sortFn = getSortByFavsFn(sortFn)
    }
    return devicesToShow.filter(filterFunc).map(mapFunc).sort(sortFn)
  }
  _addAdsToCards(cards) {
    // return cards // skip ads for now

    const { tab } = this.state
    if (!Array.isArray(cards)) return cards
    if (tab === 'webcams') return cards
    const withFirstAd = pipe(
      splitEvery(3),
      arr => concat(concat(arr[0] || [], [<GenericAd tags={[tab]} query={{ pos: 1 }} key={`${tab}-1`} />]), arr.slice(1) || []),
      flatten
    )(cards)
    // after 3, then every 10
    const beginning = withFirstAd.slice(0, 14)
    const ret = pipe(
      splitEvery(10),
      arrs => arrs.map((arr, i) => prepend(<GenericAd tags={[tab]} query={{ pos: 2 + i }}  key={`${tab}-${2 + i}`} />, arr)),
      flatten,
      concat(beginning),
    )(withFirstAd.slice(14))
    return ret
  }
  _cards() {
    const { tab } = this.state
    const { device, social } = this.props
    const { sidebar, mapVisibleDevices, mapZoom } = device
    if (!sidebar) return ' '
    let devicesToShow = this._devicesToShow()
    let altShow = null
    if (tab === 'webcams' && devicesToShow.length < 1 && mapZoom < 7) {
      altShow = <div className='alt-show'>Try zooming in to see more webcams...</div>
    } else if (tab === 'social' && devicesToShow.length < 1) {
      altShow = <div className='alt-show'>Try zooming out to see more social posts...</div>
    }
    return altShow || devicesToShow.map(this._deviceCard.bind(this))
  }

  render() {
    const { userActions, deviceActions, device, user } = this.props
    const { devices, mapZoom, mapLayer, hoverDevice, focusDevice, sidebar } = device
    const { tab, scrolling, mobileFs } = this.state
    let devicesToShow = this._devicesToShow() 
    const wrap = what => {
      if (!this._useCarousel()) {
        // create post if you have one device that can post
        let createPost
        const postableDevices = socialDevices(user, device)
        if (tab === 'social' && postableDevices.length > 0) {
          createPost =<div key='create-post' className='create-post-wrap'>
            <h4>What's it like out there?</h4>
            <a onClick={evt => {
              evt.preventDefault()
              userActions.doModal({ type: 'create-post', data: { currentDevice: postableDevices[0] } })
            }} className="btn create-post btn-primary btn-long">Create a post</a>
          </div>
        }
        return [
          <div ref='wrap' className='wrap' key='wrap'>
            {this._addAdsToCards(what)}
          </div>,
          createPost
        ]
      }
      if (!what || what === ' ') return null 
      const onChange = (i, e) => {
        if (i > devicesToShow.length - 1) {
          i = 0
        }
        this.setState({
          internalFocusDeviceId: devicesToShow[i]._id
        }, () => {
          deviceActions.hoverDevice(devicesToShow[i])
          deviceActions.focusDevice(devicesToShow[i])
        })
      }
      return <Carousel
        showThumbs={false}
        showArrows={false}
        showStatus={false}
        showIndicators={false}
        autoPlay
        interval={3000000}
        centerMode={!mobileFs}
        selectedItem={focusDevice ? findIndex(propEq('_id', focusDevice._id), devicesToShow) : 0}
        onChange={onChange}
      >{what}</Carousel>
    }
    return (
      <div className={classNames('device-outside-bar', { 
        open: sidebar, 
        expanded: sidebar,
        'not-fs': !this._mobileFullScreen(),
        fs: this._mobileFullScreen()
      }, tab)}>
        <div className='top'>
          <div className="tabs inverse">
            <a onClick={() => this.setState({ tab: 'stations'})} className={classNames({ active: tab === 'stations'})} >Stations</a>
            <a onClick={this._webcamTab} className={classNames({ active: tab === 'webcams'})}>Webcams</a>
            {mapShowSocial(mapLayer) ? 
              <a onClick={() => this.setState({ tab: 'social'})} className={classNames({ active: tab === 'social'})} >Social</a>
              : null }
          </div>
        </div>
        {wrap(this._cards())}
      </div>
    );
  }
}

export default bindAllActions(OutsideBar)
OutsideBar.displayName = 'OutsideBar'
