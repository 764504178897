import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export default class QuestionMark extends PureComponent {
  static propTypes = {
    link: PropTypes.string.isRequired
  }

  render() {
    const { onClick, link } = this.props
    return (
      <a href={link} onClick={onClick} target="_blank" className="glyphicon glyphicon-question-sign component-question-mark"></a>
    )
  }
}

QuestionMark.displayName = 'QuestionMark'
