import React, { PureComponent } from 'react'
import { Autocomplete } from '../features/common'
import PropTypes from 'prop-types'
import { path } from 'ramda'
import bindAllActions from '../common/bindAllActions'
import { deviceIsMine, findLocationFromGoogleRes, getStorage, pathsChanged } from '../common/ambient'

const deviceToQuickLocationFormat = d => ({
  label: d.info.name,
  location: d.info.coords,
  device: d
})

class QuickLocation extends PureComponent {
  static propTypes = {
    location: PropTypes.object, // default location in this components format
    currentDevice: PropTypes.object, // use the location of this device as default
    onChange: PropTypes.func
  }

  state = {
    option: 0,
    open: false,
    address: ''
  }

  componentDidMount () {
    const { currentDevice, user, userActions, location } = this.props
    const { fetchInfoPending, ipInfo } = user
    if (!fetchInfoPending && !ipInfo) {
      userActions.fetchInfo()
    }
    if (currentDevice) {
      this.setState({ selected: deviceToQuickLocationFormat(currentDevice) })
    } else if (location) {
      this.setState({ selected: location })
    } else {
      this._checkSelected()
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const { onChange } = this.props
    const { open } = this.state
    this._checkSelected()
    if (onChange && pathsChanged(prevState, this.state, [['selected']])) {
      onChange(this.state.selected)
    }
    if (pathsChanged(prevState, this.state, [['open']]) && open && this.ref) {
      this.ref.refs.input.focus()
    }
  }

  _checkSelected () {
    const { device } = this.props
    const { fetchDevicesFetched } = device
    const { selected } = this.state
    if (selected || !fetchDevicesFetched) return
    const options = this._locationOptions()
    if (options.length > 0) {
      this.setState({
        selected: options[0]
      })
    }
  }

  _locationOptions () {
    const { device, user } = this.props
    const { ipInfo } = user
    let ret = []
    const myDevicesWithLocations = (device.devices || [])
      .filter(theDevice => path(['info', 'coords'], theDevice) && deviceIsMine(device, theDevice._id))
      .map(deviceToQuickLocationFormat)
    if (myDevicesWithLocations.length > 0) {
      ret = myDevicesWithLocations
    }
    const lastLocationSearch = getStorage('lastLocationSearch')
    if (lastLocationSearch) {
      ret.push({
        label: lastLocationSearch.label || lastLocationSearch.location,
        location: {
          location: lastLocationSearch.location,
          geo: lastLocationSearch.geo
        }
      })
    } else if (ipInfo && path(['city'], ipInfo) && path(['state'], ipInfo)) {
      ret.push({
        label: ipInfo.city + ', ' + ipInfo.state,
        location: {
          location: ipInfo.city + ', ' + ipInfo.state,
          geo: {
            type: 'Point',
            coordinates: [ipInfo.longitude, ipInfo.latitude]
          }
        }
      })
    }
    return ret
  }

  render () {
    const { address, selected = {}, open } = this.state
    const options = this._locationOptions()
    let show = (
      <a onClick={() => this.setState({ open: !open })} className='selected'>
        <div>
          <b>{selected.label}</b>
          <span>{path(['location', 'location'], selected)}</span>
        </div>
        <span className='search-locations'>Search locations</span>
      </a>
    )
    if (open) {
      show = (
        <div className='autocomplete-wrap'>
          <Autocomplete
            ref={ref => { this.ref = ref }}
            value={address}
            types={[]}
            type='text'
            className='autocomplete'
            placeholder='Search for your location'
            onPlaceSelected={res => {
              this.setState({
                open: false,
                address: '',
                selected: {
                  label: res.formatted_address,
                  location: {
                    location: findLocationFromGoogleRes(res.address_components),
                    geo: {
                      type: 'Point',
                      coordinates: [res.geometry.location.lng(), res.geometry.location.lat()]
                    }
                  }
                }
              })
            }}
            onChange={evt => {
              this.setState({
                address: evt.target.value
              })
            }}
          />
          <a onClick={() => this.setState({ open: false, address: '' })} className='glyphicon x-gray' />
        </div>
      )
    }
    const optionsToShow = options.filter(o => selected.location && o.location.geo.coordinates[0] !== selected.location.geo.coordinates[0] && o.location.geo.coordinates[1] !== selected.location.geo.coordinates[1])
    return (
      <div className='component-quick-location'>
        {show}
        <div className='locations'>
          {optionsToShow.map((opt, i) =>
            <a
              onClick={() => this.setState({ selected: opt, open: false })}
              key={i}
            >{opt.label !== opt.location.location ? opt.label + ', ' : null}{opt.location.location}
            </a>)}
        </div>
      </div>
    )
  }
}

export default bindAllActions(QuickLocation)
QuickLocation.displayName = 'QuickLocation'


QuickLocation.displayName = 'QuickLocation'

QuickLocation.displayName = 'QuickLocation'
