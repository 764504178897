import {
  BECOME_USER,
} from './constants'
import { app } from '../../../common/feathers'

export function becomeUser(u) {
  app.set('user', u)
  return {
    type: BECOME_USER,
    data: u
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case BECOME_USER:
      return {
        ...state,
        adminImpersonation: state.adminImpersonation || state.info, // only set the first time
        info: action.data,
      };

    default:
      return state;
  }
}
