import { isArrayLike, lensPath, set } from 'ramda'
const HandlesProps = (superclass) => class extends superclass {
  constructor(){
    super(...arguments)
    this.handleChangeProp = ::this.handleChangeProp
  }
  handleChangeProp(prop){
    return (function (evt){
      let doc = {}
      if (isArrayLike(prop)) {
        doc = set(lensPath(prop), evt.target.value, doc)
        
      } else {
        doc[prop] = evt.target.value
      }
      this.setState(doc)
    }).bind(this)
  }
}
export default HandlesProps
