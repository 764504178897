import React, { PureComponent } from 'react'
import PropTypes from 'prop-types';
import QuestionMark from './QuestionMark'
import { getThemeObj } from '../common/skinner';

const fetch = require('isomorphic-fetch')

export default class CellStatus extends PureComponent {
    state = {
        rawCellStatus: {
            error: false,
            lastSeen: 0,
            clarity: 0,
            power: 0,
            vBatt: 0,
            stationType: ""
        }
    }

    static propTypes = {
        mac: PropTypes.string,
    }

    componentDidMount() {
        
        const mac = this.props.mac.replace(/:/g, '');
        // OLD: fetch(`https://app.rainwise.net/mk4onboarding?mac=${mac.replace(/:/g, '')}&ignore_serial=true`)
        // From command line: curl "http://diagnostics.rainwise.net/mk4?mac=303091850709&ignore_serial=true"
        // https://diagnostics.rainwise.net/mk4?mac=30F7036824B0&ignore_serial=true  // Rollin's WiFi #6
        fetch(`https://diagnostics.rainwise.net/mk4?mac=${mac.replace(/:/g, '')}&ignore_serial=true`)
        .then((response) => response.json())
        .then(rawCellResponse => {
            let rawCellStatus = {};
            rawCellStatus.error = rawCellResponse.error;
            rawCellStatus.lastSeen = rawCellResponse.minutesSinceLastContact;
            rawCellStatus.clarity = rawCellResponse.rsrq;
            rawCellStatus.power = rawCellResponse.rsrp;
            rawCellStatus.wifi = rawCellResponse.RSSI;
            rawCellStatus.chargeMode = rawCellResponse.chargeMode;
            rawCellStatus.opMode = rawCellResponse.operatingMode;
            rawCellStatus.vBatt = rawCellResponse.batteryVoltage;
            rawCellStatus.stationType = rawCellResponse.stationType;
            this.setState({ rawCellStatus });
        });
    }
    
  render() {
    const { mac } = this.props
    const {error, lastSeen, clarity, power, chargeMode, opMode, vBatt, wifi, stationType} = {...this.state.rawCellStatus};
    const optimizationUrl = (stationType === "KestrelMet6000") ? "cellular-signal-optimization/" : "wifi-signal-optimization/"
    const batteryUrl = (stationType === "KestrelMet6000") ? "cellular-battery-mode/" : "wifi-battery-mode/"
    return(
        <div className="component-cell-status">
            <div className="status-bars">
            <QuestionMark link={getThemeObj().helpUrlBase + optimizationUrl} />
                {clarity &&
                    <div className="status-bar">
                        <div className="bar-title">Signal Clarity</div>
                        <div className="bar-value">{drawClarity(clarity)}</div>
                    </div>
                }
                {power &&    
                    <div className="status-bar">
                        <div className="bar-title">Signal Strength</div>
                        <div className="bar-value">{drawPower(power)}</div>
                    </div>
                }
                {wifi && 
                    <div className="status-bar">
                        <div className="bar-title">WiFi Strength</div>
                        <div className="bar-value">{drawWiFi(wifi)}</div>
                    </div>                
                }
                <div className="status-bar">
                    <div className="bar-title">Battery Level</div>
                    {/* <div className="bar-value">{drawBattery(vBatt)}</div> */}
                    <div className="bar-value">{drawBatteryByMode(opMode)}</div>
                </div>
                <QuestionMark link={getThemeObj().helpUrlBase + batteryUrl} />
            </div>
        </div>

    );
  }
}

function drawClarity(clarity) {
    let text = 'No report';
    let styling = {
        color: 'black',
        backgroundColor: 'gray',
        border: 'hidden',
        borderRadius: '5px',
        padding: '5px',
        display: 'block',
    };
    if (typeof clarity === 'number') {
        // In the "no report" case, clarity = 0
        if (clarity >= -10 && clarity < 0) {
            text = "Excellent (" + clarity + " dB)";
            styling.color = "white";
            styling.backgroundColor = "green";
        } else if (clarity >= -15) {
            text = "Good (" + clarity + " dB)";
            styling.color = "black";
            styling.backgroundColor = "yellow";
        } else if (clarity >= -20) {
            text = "Fair to Poor (" + clarity + " dB)";
            styling.color = "black";
            styling.backgroundColor = "orange";
        } else if (clarity >= -30) {
            text = "Poor signal (" + clarity + " dB)";
            styling.color = "white";
            styling.backgroundColor = "red";
        };
    }
    return <span style={styling}>
        {text}
    </span>
}

function drawPower(power) {
    let text = 'No report';
    let styling = {
        color: 'black',
        backgroundColor: 'gray',
        border: 'hidden',
        borderRadius: '5px',
        padding: '5px',
        display: 'block',
    };

    // In the "no report" case, power === null
    if (typeof power === 'number') {
         if (power >= -80 && power < 0) {
            text = "Excellent (" + power + " dBm)";
            styling.color = "white";
            styling.backgroundColor = "green";
        } else if (power >= -90) {
            text = "Good (" + power + " dBm)";
            styling.color = "black";
            styling.backgroundColor = "yellow";
        } else if (power >= -100) {
            text = "Fair to Poor (" + power + " dBm)";
            styling.color = "black";
            styling.backgroundColor = "orange";
        } else if (power >= -200) {
            text = "Poor signal (" + power + " dBm)";
            styling.color = "white";
            styling.backgroundColor = "red";
        };
    }
    return <span style={styling}>
        {text}
    </span>
}

function drawWiFi(wifi_unsigned) {
    let text = 'No report';
    let styling = {
        color: 'black',
        backgroundColor: 'gray',
        border: 'hidden',
        borderRadius: '5px',
        padding: '5px',
        display: 'block',
    };
    // RainWise.net sends as a positive integer, and Birns quit so we can't fix it there
    let wifi = Math.abs(wifi_unsigned) * -1

    // In the "no report" case, wifi === null
    if (typeof wifi === 'number') {
         if (wifi >= -67 && wifi < 0) {
            text = "Excellent (" + wifi + " dBm)";
            styling.color = "white";
            styling.backgroundColor = "green";
        } else if (wifi >= -70) {
            text = "Good (" + wifi + " dBm)";
            styling.color = "black";
            styling.backgroundColor = "yellow";
        } else if (wifi >= -80) {
            text = "Fair to Poor (" + wifi + " dBm)";
            styling.color = "black";
            styling.backgroundColor = "orange";
        } else if (wifi >= -90) {
            text = "Poor signal (" + wifi + " dBm)";
            styling.color = "white";
            styling.backgroundColor = "red";
        };
    }
    return <span style={styling}>
        {text}
    </span>
}

function drawBatteryByMode(mode) {
    let text = 'No report';
    let styling = {
        color: 'black',
        backgroundColor: 'gray',
        border: 'hidden',
        borderRadius: '5px',
        padding: '5px',
        display: 'block',
    };

    // Battery Level Update
    if (typeof mode === 'number') {
        if (mode == 0) {
            text = "Battery Charged";
            styling.backgroundColor = "green";
            styling.color = "white";
        } else if (mode == 1) {
            text = "Low Battery";
            styling.backgroundColor = "yellow";
            styling.color = "black";
        } else if (mode >= 2) {
            text = "Critically Low Battery";
            styling.backgroundColor = "red";
            styling.color = "white";
        };
    }

    return <span style={styling}>
        {text}
    </span>
}


function drawBatteryByVoltage(battery) {
    let text = 'No report';
    let styling = {
        color: 'black',
        backgroundColor: 'gray',
        border: 'hidden',
        borderRadius: '5px',
        padding: '5px',
        display: 'block',
    };

    // 11/9/2020 MS Teams Chat with Nils:
    // [11:36 AM] Adam Nickerson
    // i would say high is above 4.4ish. then ok would be between that and about 4.1 to 4.15 maybe 
    // which would be roughly 40% to 60% capacity left then anything below that would be low
        // Old values prior to this chat:
        // const batt_hi = 4.25;
        // const batt_med = 4.21;

    const batt_hi = 4.4;
    const batt_med = 4.1;
    const batt_error = 1.0;   // Intent is to trap for ridiculous values, like 0V.
    // Battery Level Update
    if (battery >= batt_hi) {
        text = "Fully Charged";
        styling.backgroundColor = "green";
        styling.color = "white";
    } else if (battery >= batt_med) {
        text = "OK";
        styling.backgroundColor = "yellow";
        styling.color = "black";
    } else if (battery >= batt_error) {
        text = "Low";
        styling.backgroundColor = "red";
        styling.color = "white";
    };

    return <span style={styling}>
        {text}
    </span>
}

CellStatus.displayName = 'CellStatus'
