import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames'

export default class AlertBanner extends PureComponent {
  static propTypes = {
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    link: PropTypes.string,
    className: PropTypes.string
  }

  render() {
    const { message, link, children, className } = this.props
    const btns = []
    if (link) {
      if (typeof link === 'string') {
        btns.push(
          <a key="link" target="_blank" href={link}>more info</a> 
        )
      } else {
        btns.push(
          <a key="link" target="_blank" href={link.link}>{link.text}</a> 
        )
      }
    }
    return (
      <div className={classNames('device-alert-banner', {
        hide: !message
      }, className)}>
        <span>{message}</span>
        <div className="btns">
          {btns}
          {children}
        </div>
      </div>
    );
  }
}



AlertBanner.displayName = 'AlertBanner'

AlertBanner.displayName = 'AlertBanner'

AlertBanner.displayName = 'AlertBanner'

AlertBanner.displayName = 'AlertBanner'

AlertBanner.displayName = 'AlertBanner'

AlertBanner.displayName = 'AlertBanner'

AlertBanner.displayName = 'AlertBanner'
