import {
  FETCH_DEVICE_BEGIN,
  FETCH_DEVICE_SUCCESS,
  FETCH_DEVICE_FAILURE,
  FETCH_DEVICE_DISMISS_ERROR
} from './constants'
import { serviceFind } from '../../../common/feathers'

export const fetchDevice = serviceFind('devices', {
  begin: FETCH_DEVICE_BEGIN,
  success: FETCH_DEVICE_SUCCESS,
  fail: FETCH_DEVICE_FAILURE
})

export function clearFetchedDevices () {
  return {
    type: FETCH_DEVICE_DISMISS_ERROR
  }
}

export function reducer (state, action) {
  switch (action.type) {
    case FETCH_DEVICE_BEGIN:
      return {
        ...state,
        fetchDevicePending: true,
        fetchDeviceError: null
      }

    case FETCH_DEVICE_SUCCESS:
      return {
        ...state,
        fetchDevicePending: false,
        fetchDeviceError: null,
        fetchedDevices: action.skipCache ? state.fetchedDevices : action.data
      }

    case FETCH_DEVICE_FAILURE:
      return {
        ...state,
        fetchDevicePending: false,
        fetchDeviceError: action.data.error
      }

    case FETCH_DEVICE_DISMISS_ERROR:
      return {
        ...state,
        fetchDeviceError: null,
        fetchedDevices: []
      }

    default:
      return state
  }
}
