import React from 'react';
// import PropTypes from 'prop-types';
import { path } from 'ramda';
import { dataHas } from '../../common/ambient'
import FormattedDataPoint from './FormattedDataPoint'

export default function GrowDaysWidget({currentDevice}) {
  // const deviceId = path(['macAddress'], currentDevice)
  const { lastData } = currentDevice
  const currentDataHas = dataHas(lastData)

  const mains = []
  // console.log('##### Entered GrowDaysWidget')
  // console.log(JSON.stringify(lastData, 0, 2))

  if (currentDataHas(['gdd'])) {
    // console.log('##### currentDataHas([gdd]) == TRUE')
    mains.push(
      <div key="gdd">
        {/* <div className="label">
          Growing Degree Days
        </div> */}
        <div className="growdays">
          <FormattedDataPoint type={'gdd'} row={lastData} />
        </div>
      </div>
    )
  }

  return (
    <div className="device-grow-days-widget">
      {mains}
    </div>
  );
};

GrowDaysWidget.propTypes = {};
GrowDaysWidget.defaultProps = {};

GrowDaysWidget.displayName = 'GrowDaysWidget'

GrowDaysWidget.displayName = 'GrowDaysWidget'

GrowDaysWidget.displayName = 'GrowDaysWidget'

GrowDaysWidget.displayName = 'GrowDaysWidget'

GrowDaysWidget.displayName = 'GrowDaysWidget'

GrowDaysWidget.displayName = 'GrowDaysWidget'

GrowDaysWidget.displayName = 'GrowDaysWidget'
