import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withRouter } from 'react-router'
import classNames from 'classnames'
import { store } from 'react-notifications-component'
import { setStorage, getStorage, getUserSetting, isLoggedIn, isCordova, userCanSocialPost, isLtBreakpoint, pathsChanged, activePosts, postsSort } from '../../common/ambient'
import bindAllActions from '../../common/bindAllActions'
import {
  MapWithForecast
} from '../../components/'
import {
  MetricChooser,
  MobileHomePage
} from './'
import {
  DevicePopup,
  OutsideBar
} from '../device'
import { getThemeObj } from '../../common/skinner'
import { path } from 'ramda'
import { isApp, isGtBreakpoint } from '../../common/ambient/utils'

class HomePage extends Component {
  static propTypes = {
    user: PropTypes.object,
    deviceActions: PropTypes.object
  }

  state = {
    currentNotificationLocation: false,
    lastNotificationTime: getStorage('lastNotificationTime')
  }

  componentDidMount () {
    const { match, history } = this.props
    if (/index\.html/.test(match.url)) {
      history.push('/')
    }
    window.addEventListener('resize', this._resize.bind(this))
    // this._socialPrompt()
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this._resize.bind(this))
  }

  componentDidUpdate (prevProps) {
    // in apps, on first open, go to dash if logged in
    if (isLoggedIn(this.props.user) && !isLoggedIn(prevProps.user) && isApp()) {
      const appStart = getUserSetting('appStart')(this.props.user) || {}
      if (!appStart.path || appStart.path === '/dashboard') {
        this.props.history.push('/dashboard')
      }
    }
    // For KestrelMet web, on first open, go to dash if logged in
    if (isLoggedIn(this.props.user) && !isLoggedIn(prevProps.user) && getThemeObj().token === 'km') {
      this.props.history.push('/dashboard')
    }
    // For KestrelMet web, go to welcome if not logged in
    if (!isLoggedIn(this.props.user) && getThemeObj().token === 'km') {
      this.props.history.push('/welcome')
    }

    if (pathsChanged(this.props, prevProps, [['common', 'mapLocation'], ['user', 'userChecked'], ['device', 'devices']])) {
      // this._socialPrompt()
    }

    // boosted post popup
    // if (pathsChanged(this.props, prevProps, [['social', 'allPosts']])) {
    //   const post = postsSort(activePosts(this.props.social.allPosts)).find(p => p.text && p.status === 'boosted')
    //   const postsNotified = (getStorage('postsNotified') || []).filter(p => p.time > Date.now() - 1000 * 60 * 60 * 24)
    //   const postIds = postsNotified.map(p => p.id)
    //   if (post && !postIds.includes(post._id)) {
    //     setStorage('postsNotified', [...postsNotified, { time: Date.now(), id: post._id }])
    //     store.addNotification(this._notificationOpts({
    //       message: <DevicePopup
    //         layerParam='social'
    //         post={post}
    //         currentDevice={{ _id: post.deviceId }}
    //         onClick={currentDevice => {
    //           // phone go to social
    //           if (isLtBreakpoint('phone-max')) {
    //             this.props.history.push('/social#' + post._id)
    //           } else {
    //             this.props.deviceActions.focusDevice(currentDevice)
    //             this.props.deviceActions.setSidebar(true)
    //           }
    //         }}
    //       />,
    //       type: 'info',
    //       dismiss: {
    //         duration: 20000,
    //         showIcon: false
    //       }
    //     }))
    //   }
    // }
  }

  _notificationOpts (opts = {}) {
    return {
      ...opts,
      insert: isGtBreakpoint('phone-max') ? 'bottom' : 'top',
      container: isGtBreakpoint('phone-max') ? 'bottom-right' : 'top-left',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut']
    }
  }

  _socialPrompt () {
    const { user, device, common, userActions } = this.props
    const { mapLocation } = common
    const { currentNotificationLocation, lastNotificationTime } = this.state

    if (userCanSocialPost(user, device)) {
      const loc = path(['location'], mapLocation)
      if (loc &&
        loc !== currentNotificationLocation &&
        (!lastNotificationTime || lastNotificationTime < Date.now() - 1000 * 60 * 60 * 24)) {
        const prompts = [
          'How about this weather we\'re having?',
          'What do you like best about today\'s weather?',
          'Take a picture of the sky and share your weather conditions 📸',
          'What activities do you like to do in this weather?',
          'What does your weekend forecast look like?',
          'How do people ask about the weather in your neck of the woods?'
        ]
        const prompt = prompts[Math.floor(Math.random() * prompts.length)]
        store.addNotification(this._notificationOpts({
          id: 'how_about_this_weather',
          message: <a
            onClick={() => {
              userActions.doModal({
                type: 'create-post',
                data: {
                  prompt
                }
              })
            }}
            >{prompt}</a>,
          type: 'default',
          dismiss: {
            duration: 15000,
            onScreen: true,
          }
        }))
        setStorage('lastNotificationTime', Date.now())
        this.setState({
          currentNotificationLocation: loc,
          lastNotificationTime: Date.now()
        })
      }
    }
  }

  _resize () {
    this.setState({
      rand: Math.random()
    })
  }

  render () {
    const { user, device } = this.props
    return (
      <div className={classNames('user-home-page', {
        'social-ok': userCanSocialPost(user, device)
      })}
      >
        {isLtBreakpoint('phone-max')
          ? <MobileHomePage />
          : <>
            <MetricChooser />
            <MapWithForecast />
            <OutsideBar />
          </>
        }
      </div>
    )
  }
}
export default bindAllActions(withRouter(HomePage))
HomePage.displayName = 'HomePage'
