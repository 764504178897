import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import classNames from 'classnames'

export default class FindLocationBtn extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    onClick: PropTypes.func
  }

  state = {
    findingLocation: false
  }

  render () {
    const { onClick, onChange } = this.props
    const { findingLocation } = this.state
    let locationButton
    if (navigator.geolocation) {
      locationButton = (
        <a
          disabled={findingLocation}
          title='Find My Location'
          className={classNames('component-find-location-btn btn-long btn btn-primary', {
            'btn-loading': findingLocation
          })}
          onClick={evt => {
            evt.stopPropagation()
            if (onClick) {
              onClick()
            }
            this.setState({ findingLocation: true })
            navigator.geolocation.getCurrentPosition(position => {
              if (onChange) {
                onChange(position)
              }
              this.setState({
                findingLocation: false
              })
            }, err => {
              if (err.code === 1) {
                window.alert('In order to use this feature you must give the site permission to find your location.')
              }
              this.setState({
                findingLocation: false
              })
            })
          }}><span className='text'>{findingLocation ? 'Triangulating...' : 'Find my location'}</span><i className='glyphicon glyphicon-map-marker' />
        </a>
      )
    }
    return locationButton
  }
}

FindLocationBtn.displayName = 'FindLocationBtn'


FindLocationBtn.displayName = 'FindLocationBtn'

FindLocationBtn.displayName = 'FindLocationBtn'
