import { map, identity, indexOf, merge, nth, find, test, lensProp, set, clone, pluck, all, any, flip, pick, equals, contains, append, curry, filter, pipe, not, path, anyPass } from 'ramda'
import { isWxw } from './wxw'
const fetch = require('isomorphic-fetch')


export const isCordova = () => !!window.cordova
export const isAndroid = () => isCordova() && device.platform === 'Android'
export const isIos = () => isCordova() && device.platform === 'iOS'
export const noMaps = () => false

// hybrid app helpers. transition to this 8.11.2023
export const isApp = () => navigator && /AWNApp/i.test(navigator.userAgent)
export const isIosApp = () => isApp() && /AWNiOSNativeApp/i.test(navigator.userAgent)
export const isAndroidApp = () => isApp() && /AWNAndroidNativeApp/i.test(navigator.userAgent)

// this function can only be used >= v4.5.0 
// i.e. you can't test for anything before that
export const appIsGteVersion = (version) => {
  if (!isApp()) {
    return null
  }
  const appVersion = navigator.userAgent.split(':')[1]
  if (!/\./.test(appVersion) && appVersion !== '405004') {
    return false
  }
  const toPre = (str) => {
    return parseInt(str.replace(/[^0-9]/g, ''))
  }
  const toNum = (str) => {
    const pieces = str.split('.')
    if (pieces.length === 2) {
      pieces.push('0')
    }
    return pieces.reduce((acc, val, i) => {
      return acc + parseInt(val) * Math.pow(1000, 2 - i)
    }, 0)
  }
  if (/-/.test(version)) {
    const main = toNum(version.split('-')[0])
    const pre = toPre(version.split('-')[1])
    const appMain = toNum(appVersion.split('-')[0])
    // both have pre-release
    if (/-/.test(appVersion)) {
      const appPre = toPre(appVersion.split('-')[1])
      if (appMain > main) {
        return true
      } else if (appMain === main && appPre >= pre) {
        return true
      }
      return false
    // app is not pre-release - desired is
    } else {
      if (appMain > main) {
        return true
      }
      return false
    }

  // app is prerelase - desired is not
  } else if (/-/.test(appVersion)) {
    const main = toNum(appVersion.split('-')[0])
    if (main >= toNum(version)) {
      return true
    }
    return false

  // neither have pre-release
  } else {
    return toNum(appVersion) >= toNum(version)
  }
}

export const isIosCordovaOrIosBrowser = () => {
  return isIos() 
  || [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document) 
}

// keep in sync with variables.less, in DESC order
const BREAKPOINTS = [
  ['tablet-max', 1229],
  ['tablet-phone-max', 1023],
  ['phone-max', 767],
  ['super-small', 320],
  ['tiny', 0]
]
export const getWindowWidth = () => {
  return Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
}
export const getCurrentBreakpoint = () => {
  const vw = getWindowWidth()
  return pipe(
    find(arr => vw > arr[1]),
    arr => arr ? arr[0] : 'tablet-max'
  )(BREAKPOINTS)
}
export const isGtBreakpoint = (breakpoint) => {
  return getWindowWidth() > nth(1, find(arr => arr[0] === breakpoint, BREAKPOINTS))
}

export const isLtBreakpoint = (breakpoint) => {
  return getWindowWidth() < nth(1, find(arr => arr[0] === breakpoint, BREAKPOINTS))
}

let initialURL = window.location.href
export const reloadApp = () => {
  if (isAndroid()) {
    window.location.href = 'file:///android_asset/www/index.html'
  } else if (isIos()) {
    window.location.href = initialURL
  } else {
    window.location.reload()
  }
}

export const getUrl = () => {
  // let url = 'http://localhost:3030' // local backend
  let url = 'https://tornado.ambientweather.net' // dev backend
  // let url = 'https://lightning.ambientweather.net' // live backend

  if (/169/.test(window.location.hostname)) {
    url = 'http://' + window.location.hostname + ':3030'
  } else if (/^dev-fe\.ambientweather/.test(window.location.hostname)) {
    // url = 'https://tornado.ambientweather.net' // dev backend
    url = 'https://lightning.ambientweather.net'
  } else if (/^beta/.test(window.location.hostname)) {
    url = 'https://lightning.ambientweather.net'
  } else if (/^pentest-fe\.ambientweather/.test(window.location.hostname)) {
    url = 'https://lightning.ambientweather.net' // pentest backend
  } else if (/^test-fe\.ambientweather/.test(window.location.hostname)) {
    url = 'https://tornado.ambientweather.net' // should always be dev backend
    // url = 'https://lightning.ambientweather.net'
  } else if (/^aw\.ambientweather/.test(window.location.hostname)) {
    // url = 'https://ambient-backend-test-server-9nipf.ondigitalocean.app'
    // url = 'https://tornado.ambientweather.net' // dev backend
    url = 'https://lightning.ambientweather.net'
  // } else if (/^km\.ambientweather/.test(window.location.hostname)) {
  //   // url = 'https://tornado.ambientweather.net' // dev backend
  //   url = 'https://lightning.ambientweather.net'
  // public website !!
  } else if (/ambientweather/.test(window.location.hostname)) {
    // url = 'https://dash2.ambientweather.net'
    url = 'https://lightning.ambientweather.net'
  } else if (isCordova() && !window.device.isVirtual) {
    // url = 'https://rt.ambientweather.net' // live backend
    url = 'https://tornado.ambientweather.net' // dev backend
  } else if (isCordova() && window.device.platform !== 'iOS') {
    // url = 'http://10.0.0.30:3030' // for testing
    url = 'https://rt.ambientweather.net' // live backend
  }
  return url
}

export const isDev = () => !isCordova() && (/localhost/.test(window.location.host) || /192/.test(window.location.host) || /tornado/.test(getUrl()))

export const isBillingDev = () => /tornado/.test(getUrl())

export function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}

export const addPropToObj = curry(function (prop, fn) {
  return function (obj) {
    return set(lensProp(prop), typeof fn === 'function' ? fn(obj) : fn, clone(obj))
  }
})
export function removeFromArrWith (pred, arr) {
  return filter(pipe(pred, not), arr)
}
export function removeFromArr (key, arr) {
  return filter(pipe(equals(key), not), arr)
}
export function toggleArr (key, arr) {
  if (contains(key, arr)) {
    return removeFromArr(key, arr)
  }
  return append(key, arr)
}
export const fcontainsAny = curry(function (haystack, needleList) {
  return any(flip(contains)(haystack))(needleList)
})
export const fcontainsAll = curry(function (haystack, needleList) {
  return all(flip(contains)(haystack))(needleList)
})
export const fcontains = flip(contains)
export const containsAll = flip(fcontainsAll)
export const containsAny = flip(fcontainsAny)
export const ucFirst = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const objsContainId = curry((objArr, objId) => {
  if (!objArr) { 
    return false
  }
  return pipe(
    pluck('_id'),
    contains(objId)
  )(objArr)
})

export const pluckClean = curry(function (prop, arr) {
  return pipe(
    pluck(prop),
    filter(isSomething)
  )(arr)
})
export const isSomething = v => v || v === 0

export const goodEmail = test(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)

export const toFixed = curry((amt, thing) => {
  if (typeof thing.toFixed === 'function') {
    return thing.toFixed(amt)
  }
  return thing
})

export const countDecimals = function (value) {
  if (Math.floor(value) !== value) {
    return value.toString().split(".")[1].length || 0
  }
  return 0
}

export const guid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = crypto.getRandomValues(new Uint8Array(1))[0]%16|0, v = c == 'x' ? r : (r&0x3|0x8)
    return v.toString(16)
  })
}

export function log (thing) {
  console.log(thing)
  return thing
}
// arr -> obj -> obj -> bool
// array containing obj fields
// are the two objects equal on those fields
export const fieldsEq = curry(function (fields, obj1, obj2) {
  return equals(pick(fields, obj1), pick(fields, obj2))
})

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const what = what => {
  console.log(what)
  return what
}

export function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : { r: 255, g: 255, b: 255 }
}

//:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
//:::                                                                         :::
//:::  This routine calculates the distance between two points (given the     :::
//:::  latitude/longitude of those points). It is being used to calculate     :::
//:::  the distance between two locations using GeoDataSource (TM) prodducts  :::
//:::                                                                         :::
//:::  Definitions:                                                           :::
//:::    South latitudes are negative, east longitudes are positive           :::
//:::                                                                         :::
//:::  Passed to function:                                                    :::
//:::    lat1, lon1 = Latitude and Longitude of point 1 (in decimal degrees)  :::
//:::    lat2, lon2 = Latitude and Longitude of point 2 (in decimal degrees)  :::
//:::    unit = the unit you desire for results                               :::
//:::           where: 'M' is statute miles (default)                         :::
//:::                  'K' is kilometers                                      :::
//:::                  'N' is nautical miles                                  :::
//:::                                                                         :::
//:::  Worldwide cities and other features databases with latitude longitude  :::
//:::  are available at https://www.geodatasource.com                         :::
//:::                                                                         :::
//:::  For enquiries, please contact sales@geodatasource.com                  :::
//:::                                                                         :::
//:::  Official Web site: https://www.geodatasource.com                       :::
//:::                                                                         :::
//:::               GeoDataSource.com (C) All Rights Reserved 2018            :::
//:::                                                                         :::
//:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

export function latLonDistance(lat1, lon1, lat2, lon2, unit) {
	if ((lat1 == lat2) && (lon1 == lon2)) {
		return 0;
	}
	else {
		var radlat1 = Math.PI * lat1/180;
		var radlat2 = Math.PI * lat2/180;
		var theta = lon1-lon2;
		var radtheta = Math.PI * theta/180;
		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) {
			dist = 1;
		}
		dist = Math.acos(dist);
		dist = dist * 180/Math.PI;
		dist = dist * 60 * 1.1515;
		if (unit=="K") { dist = dist * 1.609344 }
		if (unit=="N") { dist = dist * 0.8684 }
		return dist;
	}
}
/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject (item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep (target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

// found this here: https://stackoverflow.com/questions/2090551/parse-query-string-in-javascript
export const deparam = (function (d, x, params, p, i, j) {
  return function (qs) {
    // start bucket; can't cheat by setting it in scope declaration or it overwrites
    params = {};
    // remove preceding non-querystring, correct spaces, and split
    qs = qs.substring(qs.indexOf('?') + 1).replace(x, ' ').split('&');
    // march and parse
    for (i = qs.length; i > 0;) {
      p = qs[--i];
      // allow equals in value
      j = p.indexOf('=');
      // what if no val?
      if (j === -1) params[d(p)] = undefined;
      else params[d(p.substring(0, j))] = d(p.substring(j + 1));
    }

    return params;
  };//--  fn  deparam
})(decodeURIComponent, /\+/g);

export const hasWebcam = anyPass([path(['webcam']), path(['info', 'webcam'])])
export const hasPublicWebcam = anyPass([path(['webcam', 'public']), path(['info', 'webcam'])])

export const getWebcamArchives = currentDevice => {
  const archives = path(['webcam', 'archives'], currentDevice) || path(['info', 'archives'], currentDevice) || []
  return archives
    .map((url) => {
      const fileName = url.split('/').pop()
      const date = moment(fileName.replace('.mp4', ''), 'YYYYMMDD')
      return {
        fileName,
        url,
        date
      }
    })
    .sort((a, b) => b.date.valueOf() - a.date.valueOf())
}

export const updateArrayForObject = (arr, data) => {
  if (!arr) {
    return arr
  }
  let deviceI = indexOf(data.id, pluck('_id', arr))
  if (deviceI > -1) {
    let dev = arr[deviceI]
    arr[deviceI] = merge(dev, data.args)
  }
  return clone(arr)
}
export const dateVal = str => {
  const d = new Date(str)
  return d.getTime()
}

export const trackEvent = (cat, action, label) => {
  if (window.ga) {
    window.ga('send', 'event', cat, action, label)
  }
  if (window.gtag) {
    window.gtag('event', action, {
      event_category: cat,
      event_label: label
    })
  }
}

export const roundDecimal = curry((places, val) => {
  const m = places * 10
  return Math.floor(val * m) / m
})

export const findLocationFromGoogleRes = (comps) => {
  const locationObjs = map(slug => {
    return find(comp => {
      return comp.types.includes(slug)
    }, comps)
  }, ['locality', 'neighborhood', 'administrative_area_level_2', 'administrative_area_level_1', 'doesnotwork'])
  return pipe(
    filter(identity),
    nth(0),
    path(['long_name'])
  )(locationObjs)
}

// we are transitioning to geo format, for now put both
export const formatCoords = (obj, coords) => {
  let coordsArr = coords
  if (!Array.isArray(coords)) {
    coordsArr = [coords.lon, coords.lat]
  }
  return Object.assign({}, obj, {
    coords: {
      lat: coordsArr[1],
      lon: coordsArr[0]
    },
    geo: {
      type: 'Point',
      coordinates: [coordsArr[0], coordsArr[1]]
    }
  })
}

export const checkOldLinks = location => {
  if (!location) return
  if (/^\/help/.test(location.pathname) || /^\/product/.test(location.pathname)) {
    window.location.href = 'https://help.ambientweather.net' + location.pathname
  }
  if (/^\/share\//.test(location.pathname)) {
    window.location.href = 'https://lightning.ambientweather.net/s/' + location.pathname.replace('/share/', '')
  }
}

export const makeSureObjHasGeo = (obj = {}) => {
  if (!obj.geo && obj.coords) {
    obj.geo = {
      type: 'Point',
      coordinates: [obj.coords.lon, obj.coords.lat]
    }
  }
  return obj
}

export const coordsAreEqual = (geo1, geo2) => {
  geo1 = makeSureObjHasGeo(geo1)
  geo2 = makeSureObjHasGeo(geo2)
  const lon1 = path(['geo', 'coordinates', 0], geo1)
  const lat1 = path(['geo', 'coordinates', 1], geo1)
  const lon2 = path(['geo', 'coordinates', 0], geo2)
  const lat2 = path(['geo', 'coordinates', 1], geo2)
  return lon1 === lon2 && lat1 === lat2
}

export const errorLog = (err) => {
  const url = 'https://logs.collector.solarwinds.com/v1/log'
  fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Basic ' + btoa(':K63HjdB19EOcvP-RXpwQ2VavXxzG')
    },
    body: JSON.stringify({ browserError: err })
  });
}

export const getDeviceType = currentDevice => {
  if (isWxw(currentDevice)) {
    return 'wxw'
  } else if (path(['settings', 'external', 'rw'], currentDevice)) {
    if (/Kestrel/.test(path(['settings', 'external', 'rw', 'stationType'], currentDevice))) {
      return 'kestrel'
    } else {
      return 'rw'
    }
  } else if (path(['stationType'], currentDevice)) {
    return path(['stationType'], currentDevice)
  } else if (path(['lastData', 'stationtype'], currentDevice)) {
    if (/Kestrel/.test(path(['lastData', 'stationtype'], currentDevice))) {
      return 'kestrel'
    }
    if (/^RW_/.test(path(['lastData', 'stationtype'], currentDevice))) {
      return 'rw'
    }
  }
}

export const getDeviceTypeLabel = currentDevice => {
  const deviceType = getDeviceType(currentDevice)
  if (!deviceType) {
    return ''
  }
  if (deviceType === 'wxw') {
    return 'Weather Window'
  } else if (deviceType === 'rw') {
    return 'Rainwise'
  } else if (deviceType === 'kestrel') {
    return 'KestrelMet'
  } else if (deviceType === 'legacy') {
    return 'Legacy'
  }
  return deviceType
}

export const sleep = ms => new Promise(
  resolve => setTimeout(resolve, ms)
)
