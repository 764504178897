import React, { PureComponent } from 'react';
import { Area, Line, YAxis } from 'recharts'
import PropTypes from 'prop-types';
import { filter, identity, uniq, keys, map, flatten, pipe, not, apply, concat, pluck, contains } from 'ramda'
import { isSomething, rowsHave, toFixed, getSuffForUser, getLabel } from '../../common/ambient'
import bindDeviceActions from './redux/bindDeviceActions';
import CommonGraph from './CommonGraph'

class TempGraph extends PureComponent {
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    graphData: PropTypes.array,
    mode: PropTypes.string,
    activeGraphs: PropTypes.array,
    onClick: PropTypes.func,
    user: PropTypes.object,
    dontChangeHeight: PropTypes.bool,
  };

  render() {
    const { device, dontChangeHeight, user, width, height, graphData, mode, activeGraphs, onClick } = this.props
    let data = graphData
    const isRefined = graphData[0] && graphData[0].refined
    const lines = []
    let allVals = []
    const getSuff = getSuffForUser(user)
    const thisDataHas = rowsHave(graphData)
    let composed = false


    if (contains('feelsLike', activeGraphs)) {

      allVals = concat(allVals, pluck('feelsLike', data))
      if (thisDataHas('feelsLikeHl')) {
        allVals = concat(allVals, pipe(
          pluck('feelsLikeHl'),
          flatten
        )(data))
        composed = true
        lines.push(
          <Area
            key="feelsLikeHl"
            type="monotone"
            dataKey="feelsLikeHl"
            stroke="#d7dce0"
            strokeWidth={0}
            fill="#d7dce0"
            legendType="circle"
            connectNulls={false}
            dot={false}
            isAnimationActive={!isRefined}
            name={getLabel('feelsLike') + ' High/Low'}
            unit={getSuff('feelsLike')}
          />
        )
      }
    }
    if (contains('dewPoint', activeGraphs)) {
      allVals = concat(allVals, pluck('dewPoint', data))
      if (thisDataHas('dewPointHl')) {
        allVals = concat(allVals, pipe(
          pluck('dewPointHl'),
          flatten
        )(data))
        composed = true
        lines.push(
          <Area
            key="dewPointHl"
            type="monotone"
            dataKey="dewPointHl"
            stroke="#a7dfde"
            strokeWidth={0}
            fill="#a7dfde"
            legendType="circle"
            connectNulls={false}
            dot={false}
            isAnimationActive={!isRefined}
            name={getLabel('dewPoint') + ' High/Low'}
            unit={getSuff('dewPoint')}
          />
        )
      }
    }
    if (contains('tempf', activeGraphs)) {
      allVals = concat(allVals, pluck('tempf', data))
      if (thisDataHas('tempfHl')) {
        allVals = concat(allVals, pipe(
          pluck('tempfHl'),
          flatten
        )(data))
        composed = true
        lines.push(
          <Area
            key="tempfHl"
            type="monotone"
            dataKey="tempfHl"
            stroke="#b4dcf6"
            strokeWidth={0}
            fill="#b4dcf6"
            legendType="circle"
            connectNulls={false}
            dot={false}
            isAnimationActive={!isRefined}
            name={getLabel('tempf') + ' High/Low'}
            unit={getSuff('tempf')}
          />
        )
      }
    }
    allVals = allVals.filter(pipe(isNaN, not))
    let yAxis =
      <YAxis
        dataKey="tempf"
        orientation="left"
        type="number"
        axisLine={false}
        domain={[apply(Math.min, allVals), apply(Math.max, allVals)]}
        tickFormatter={toFixed(1)}
      />

    // temperature lines
    if (contains('feelsLike', activeGraphs)) {
      lines.push(
        <Line
          key="feelsLike"
          type="monotone"
          dataKey={thisDataHas('feelsLikeAvg') ? 'feelsLikeAvg' : 'feelsLike'}
          stroke="#b0bbc3"
          strokeWidth={2}
          fill="#b0bbc3"
          connectNulls={true}
          dot={false}
          legendType="circle"
          isAnimationActive={!isRefined}
          name={getLabel('feelsLike') + (thisDataHas('feelsLikeAvg') ? ' Avg' : '')}
          unit={getSuff('tempf')}
        />
      )
    }
    if (contains('tempf', activeGraphs)) {
      lines.push(
        <Line
          key="tempf"
          type="monotone"
          dataKey={thisDataHas('tempfAvg') ? 'tempfAvg' : 'tempf'}
          stroke="#2c9ce6"
          strokeWidth={2}
          fill="#2c9ce6"
          dot={false}
          legendType="circle"
          isAnimationActive={!isRefined}
          name={getLabel('tempf') + (thisDataHas('tempfAvg') ? ' Avg' : '')}
          unit={getSuff('tempf')}
          connectNulls={true}
        />
      )
    }
    if (contains('dewPoint', activeGraphs)) {
      lines.push(
        <Line
          dataKey={thisDataHas('dewPointAvg') ? 'dewPointAvg' : 'dewPoint'}
          key="dewPoint"
          type="monotone"
          stroke="#50bfbd"
          connectNulls={true}
          strokeWidth={2}
          fill="#50bfbd"
          dot={false}
          legendType="circle"
          isAnimationActive={!isRefined}
          name={getLabel('dewPoint') + (thisDataHas('dewPointAvg') ? ' Avg' : '')}
          unit={getSuff('tempf')}
        />
      )
    }
    if (contains('tempinf', activeGraphs)) {
      allVals = concat(allVals, pluck(thisDataHas('tempinfAvg') ? 'tempinfAvg' : 'tempinf', data))
      if (thisDataHas('tempinfHl')) {
        allVals = concat(allVals, pipe(
          pluck('tempinfHl'),
          flatten
        )(data))
        composed = true
        lines.push(
          <Area
            key="tempinfHl"
            type="monotone"
            dataKey="tempinfHl"
            stroke="#b4dcf6"
            strokeWidth={0}
            fill="#b4dcf6"
            legendType="circle"
            connectNulls={false}
            dot={false}
            isAnimationActive={!isRefined}
            name={getLabel('tempinf', device) + ' High/Low'}
            unit={getSuff('tempinf')}
          />
        )
      }
      allVals = filter(isSomething, allVals)
      yAxis =
        <YAxis
          dataKey="tempinf"
          orientation="left"
          type="number"
          axisLine={false}
          domain={[apply(Math.min, allVals), apply(Math.max, allVals)]}
          tickFormatter={toFixed(1)}
        />
      lines.push(
        <Line
          key="tempinf"
          dataKey={thisDataHas('tempinfAvg') ? 'tempinfAvg' : 'tempinf'}
          type="monotone"
          stroke="#2c9ce6"
          strokeWidth={2}
          fill="#2c9ce6"
          dot={false}
          legendType="circle"
          isAnimationActive={!isRefined}
          name={getLabel('tempinf', device) + (thisDataHas('tempinfAvg') ? ' Avg' : '')}
          unit={getSuff('tempinf')}
          connectNulls={true}
        />
      )
    }
    const message = lines.length < 1 ? <div className="hidden" /> :
      <CommonGraph
        onClick={onClick}
        width={width}
        height={height}
        mode={mode}
        graphData={data}
        id={'temp'}
        composed={composed}
        dontChangeHeight={dontChangeHeight}
      >
        {yAxis}
        {lines}
        {this.props.children}
      </CommonGraph>
    return message
  }
}

export default bindDeviceActions(TempGraph)

TempGraph.displayName = 'TempGraph'

TempGraph.displayName = 'TempGraph'

TempGraph.displayName = 'TempGraph'

TempGraph.displayName = 'TempGraph'

TempGraph.displayName = 'TempGraph'

TempGraph.displayName = 'TempGraph'

TempGraph.displayName = 'TempGraph'
