import {
  CREATE_ALERT_BEGIN,
  CREATE_ALERT_SUCCESS,
  CREATE_ALERT_FAILURE,
  CREATE_ALERT_DISMISS_ERROR,
} from './constants';
import { fetchAlerts } from './fetchAlerts'
import { app } from '../../../common/feathers'
const alertService = app.service('alerts')

export function createAlert (args) {
  return (dispatch) => {
    dispatch({
      type: CREATE_ALERT_BEGIN,
    });
    args.userId = app.get('user')._id
    return alertService.create(args)
      .then(function (res) {
        dispatch({
          type: CREATE_ALERT_SUCCESS,
          data: res,
        });
        fetchAlerts({ userId: args.userId })(dispatch)
      })
      .catch(function (err) {
        dispatch({
          type: CREATE_ALERT_FAILURE,
          data: {
            error: err,
          },
        });
      })
  };
}

export function dismissCreateAlertError() {
  return {
    type: CREATE_ALERT_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CREATE_ALERT_BEGIN:
      return {
        ...state,
        createAlertPending: true,
        createAlertError: null,
      };

    case CREATE_ALERT_SUCCESS:
      return {
        ...state,
        createAlertPending: false,
        createAlertError: null,
      };

    case CREATE_ALERT_FAILURE:
      return {
        ...state,
        createAlertPending: false,
        createAlertError: action.data.error,
      };

    case CREATE_ALERT_DISMISS_ERROR:
      return {
        ...state,
        createAlertError: null,
      };

    default:
      return state;
  }
}
