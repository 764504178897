import { useCallback } from 'react'
import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import { setStorage, makeSureObjHasGeo } from '../../../common/ambient'
import {
  COMMON_SET_MAP_LOCATION
} from './constants'

export function setMapLocation (data) {
  data = makeSureObjHasGeo(data)
  setStorage('lastLocationSearch', data)
  return {
    type: COMMON_SET_MAP_LOCATION,
    data
  }
}

export function useSetMapLocation () {
  const dispatch = useDispatch()
  const boundAction = useCallback((...params) => dispatch(setMapLocation(...params)), [dispatch])
  const st8Obj = useSelector(state => ({ mapLocation: state.common.mapLocation }), shallowEqual)
  return Object.assign({ setMapLocation: boundAction }, st8Obj)
}

export function reducer (state, action) {
  switch (action.type) {
    case COMMON_SET_MAP_LOCATION:
      return {
        ...state,
        mapLocation: action.data
      }

    default:
      return state
  }
}
