import {
  GET_POST_BEGIN,
  GET_POST_SUCCESS,
  GET_POST_FAILURE,
  GET_POST_DISMISS_ERROR,
} from './constants';
import { serviceGet } from '../../../common/feathers'

export const getPost = serviceGet('posts', {
  begin: GET_POST_BEGIN,
  success: GET_POST_SUCCESS,
  fail: GET_POST_FAILURE
})

export function dismissGetPostError() {
  return {
    type: GET_POST_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case GET_POST_BEGIN:
      return {
        ...state,
        getPostPending: true,
        getPostError: null,
      };

    case GET_POST_SUCCESS:
      return {
        ...state,
        getPostPending: false,
        getPostError: null,
      };

    case GET_POST_FAILURE:
      return {
        ...state,
        getPostPending: false,
        getPostError: action.data.error,
      };

    case GET_POST_DISMISS_ERROR:
      return {
        ...state,
        getPostError: null,
      };

    default:
      return state;
  }
}
