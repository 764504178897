import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './actions';
import mapStateToProps from '../../../common/mapStateToProps'


/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)

// this is used in Widget.js because of a bad naming convention
// because we dont want to overwrite 'device'
// olw 1.28.21
export const bindOnlyUserActions = connect(
  st8 => ({
    user: st8.user
  }),
  mapDispatchToProps
)
