import { isSomething } from './utils'
import { ambient } from '../lib/ambient-weather-common'
const HI = require('heat-index')
const pogoda = require('pogoda')

export function toCel (f) {
  return (5 / 9) * (f - 32)
}
export function toFahr (c) {
  return (c * (9 / 5)) + 32
}
export function toMps (mph) {
  return mph * 0.44704
}
export function inHg2mmHg (inHg) {
  return inHg / 0.0393700791974
}
export function inHg2hPa (inHg) {
  return inHg * 33.86389
}
export function dewPoint (tempf, humidity) {
  if (!isSomething(tempf) || !isSomething(humidity)) return
  if (humidity > 100) {
    humidity = 100
  }
  return toFahr(pogoda.dewPoint(toCel(tempf), humidity / 100))
}
export function heatIndex (tempf, humidity) {
  return HI.heatIndex({ temperature: tempf, humidity: humidity, fahrenheit: true })
}
export function windChill (tempf, wind) {
  if (!isSomething(tempf) || !isSomething(wind)) return
  return 35.74 + 0.6215 * tempf - 35.75 * Math.pow(wind, 0.16) + 0.4275 * tempf * Math.pow(wind, 0.16)
}
export function feelsLike (tempf, humidity, windspd) {
  if (tempf < 50 && isSomething(windspd) && windspd > 3) {
    return windChill(tempf, windspd)
  }
  if (tempf > 68 && humidity) {
    return heatIndex(tempf, humidity)
  }
  return tempf
}
export function feelsLikeObj (obj) {
  return feelsLike(obj.tempf, obj.humidity, obj.windspeedmph)
}
export function windDirLabel (val) {
  if (val < 11.25) {
    val = 'N'
  } else if (val < 33.75) {
    val = 'NNE'
  } else if (val < 56.25) {
    val = 'NE'
  } else if (val < 78.75) {
    val = 'ENE'
  } else if (val < 101.25) {
    val = 'E'
  } else if (val < 123.75) {
    val = 'ESE'
  } else if (val < 146.25) {
    val = 'SE'
  } else if (val < 168.75) {
    val = 'SSE'
  } else if (val < 191.25) {
    val = 'S'
  } else if (val < 213.75) {
    val = 'SSW'
  } else if (val < 236.25) {
    val = 'SW'
  } else if (val < 258.75) {
    val = 'WSW'
  } else if (val < 281.25) {
    val = 'W'
  } else if (val < 303.75) {
    val = 'WNW'
  } else if (val < 326.25) {
    val = 'NW'
  } else if (val < 348.75) {
    val = 'NNW'
  } else {
    val = 'N'
  }
  return val
}

export function windDirFromLabel (label) {
  if (label === 'N') return 0
  if (label === 'NNE') return 22.5
  if (label === 'NE') return 22.5 * 2
  if (label === 'ENE') return 22.5 * 3
  if (label === 'E') return 22.5 * 4
  if (label === 'ESE') return 22.5 * 5
  if (label === 'SE') return 22.5 * 6
  if (label === 'SSE') return 22.5 * 7
  if (label === 'S') return 22.5 * 8
  if (label === 'SSW') return 22.5 * 9
  if (label === 'SW') return 22.5 * 10
  if (label === 'WSW') return 22.5 * 11
  if (label === 'W') return 22.5 * 12
  if (label === 'WNW') return 22.5 * 13
  if (label === 'NW') return 22.5 * 14
  if (label === 'NNW') return 22.5 * 15
}

export const conversions = ambient.conversions
export const conversionsInverse = ambient.conversionsInverse

export const convertUnit = ambient.convertUnit
export const convertUnitInverse = ambient.convertUnitInverse
