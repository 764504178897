import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom'
import { isLtBreakpoint } from '../../common/ambient';
import { DashboardNav  } from "../../components/";

export default function Menu() {
  const history = useHistory()
  useEffect(() => {
    function checkSize () {
      if (!isLtBreakpoint('phone-max')) {
        history.push('/')
      }
    }
    window.addEventListener('resize', checkSize)
    checkSize()
    return _ => window.removeEventListener('resize', checkSize)
  }, [history])
  return (
    <div className='common-menu'>
      <DashboardNav />
    </div>
  );
};

Menu.propTypes = {};
Menu.defaultProps = {};

Menu.displayName = 'Menu'
