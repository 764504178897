import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import { withRouter } from 'react-router'

export class Confirm extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  };
  componentWillMount(){
    const { location } = this.props
    console.log(location)
    this.setState({
      confirming: true
    })
    const searchParams = new URLSearchParams(location.search)
    const key = searchParams.get('key')
    if (!location.search || !key) {
      this.props.history.push('/')
    } else {
      this.props.actions.confirmUser(key)
    }
  }

  render() {
    return (
      <div className="user-confirm">
        <header className="main">
          <h1>Confirmation</h1>
        </header>
        <div className="page-body">
          <div className="block">
            {this.props.user.confirmUserPending ? 'Confirming...' : (this.props.user.confirmUserSuccess ? 'Confirmed!' : 'Confirmed') }
          </div>
        </div>
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Confirm));

Confirm.displayName = 'Confirm'

Confirm.displayName = 'Confirm'

Confirm.displayName = 'Confirm'

Confirm.displayName = 'Confirm'

Confirm.displayName = 'Confirm'

Confirm.displayName = 'Confirm'

Confirm.displayName = 'Confirm'
