import React from 'react'
import ReactDOM from 'react-dom'
import Root from './Root'
import * as serviceWorker from './serviceWorker'
import './styles/index.less'
import initReactFastclick from 'react-fastclick'
import { isAndroid, isCordova, isDev } from './common/ambient'

initReactFastclick()

if (!isDev() && window.location.protocol === 'http:' && !isCordova()) {
  window.location.protocol = 'https:'
}

if ('serviceWorker' in navigator && !isCordova() && !isDev()) {
  serviceWorker.register()
}

if (isAndroid()) {
  document.addEventListener('backbutton', () => {
    const l = window.location.href
    const h = window.history
    if (l === 'file:///'
        || l === 'file:///signin'
        || h.length === 3 && l === 'file:///dashboard'
       ) {
      navigator.app.exitApp()
    } else {
      h.back()
    }
  })
}


ReactDOM.render(<Root />, document.getElementById('root'))
