import React from 'react'
import FormattedDataPoint from './FormattedDataPoint'
// import PropTypes from 'prop-types';

export default function AirDensityWidget ({ currentDevice }) {
  const { lastData } = currentDevice
  return (
    <div className='device-air-density-widget'>
      <FormattedDataPoint row={lastData} type='airdensitylbft3' />
      <div>
        <div className='label'>Relative Air Density</div>
        <FormattedDataPoint row={lastData} type='relativeairdensity' />
      </div>
    </div>
  )
};

AirDensityWidget.propTypes = {}
AirDensityWidget.defaultProps = {}

AirDensityWidget.displayName = 'AirDensityWidget'
