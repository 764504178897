import md5 from 'blueimp-md5'
import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import {
  DEVICE_DEVICE_DATA_BEGIN,
  DEVICE_DEVICE_DATA_SUCCESS,
  DEVICE_DEVICE_DATA_FAILURE,
  DEVICE_DEVICE_DATA_DISMISS_ERROR
} from './constants'
import { fetchDeviceData } from './fetchDeviceData'
import { curry, uniq } from 'ramda'

let dataKeysQueue = []

export function deviceData (dataKey) {
  return (dispatch, getState) => { // optionally you can have getState as the second argument
    const st8 = getState()
    const { deviceDataCache } = st8.device
    if (st8.device.deviceDataCompletedKeys.includes(dataKey) ||
    st8.device.deviceDataPending.includes(dataKey)) {
      return
    }
    const dayKeys = getDayKeys(dataKey)
    if (dayKeys.every(k => deviceDataCache[k])) {
      // console.log('all data is already there')
      dispatch({
        type: DEVICE_DEVICE_DATA_SUCCESS,
        data: [],
        key: dataKey
      })
      return
    }

    dispatch({
      type: DEVICE_DEVICE_DATA_BEGIN,
      key: dataKey
    })

    dataKeysQueue = uniq(dataKeysQueue.concat(dayKeys.filter(k => !deviceDataCache[k])))

    function processDayKeysInBatches () {
      const batchSize = 2
      // const filteredKeys = dayKeys.filter(k => !deviceDataCache[k])
      const filteredKeys = dataKeysQueue // a master queue
    
      function processBatch (startIndex) {
        if (startIndex >= filteredKeys.length) {
          // console.log('All batches processed', dataKey)
          return Promise.resolve()
        }

        const batch = filteredKeys.slice(startIndex, startIndex + batchSize)
        const batchPromises = batch.map(k => {
          const { passkey, start, end } = _explodeKey(k)
          const ret = {
            passkey,
            start,
            end,
            dataKey: k,
            deviceData: true,
            limit: 2000
          }
          // Uncomment and adjust the following block if you want to include the resolution logic
          // const diff = ret.end - ret.start;
          // if (diff > 1000 * 60 * 60 * 24 * 30) { // more than a month show daily 
          //   ret.res = 1440;
          // } else if (diff > 1000 * 60 * 60 * 24 * 8) { // more than a week show every 4 hrs
          //   ret.res = 240;
          // } else if (diff > 1000 * 60 * 60 * 25) { // more than 25 hours show 30 mins
          //   ret.res = 30;
          // }
          return dispatch(fetchDeviceData(ret))
        })
    
        return Promise.all(batchPromises)
          .then(() => {
            // console.log(`Processed batch ${startIndex / batchSize + 1} of ${Math.ceil(filteredKeys.length / batchSize)}`)
            return processBatch(startIndex + batchSize)
          })
      }

      return processBatch(0)
    }

    processDayKeysInBatches()
      .then(
        (res) => {
          dispatch({
            type: DEVICE_DEVICE_DATA_SUCCESS,
            data: res,
            key: dataKey
          })
        })
      .catch(
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: DEVICE_DEVICE_DATA_FAILURE,
            data: { error: err },
            key: dataKey
          })
        }
      )
  }
}

const _explodeKey = key => {
  const passkey = key.split('-')[0]
  const start = parseInt(key.split('-')[1])
  const end = parseInt(key.split('-')[2])
  return { passkey, start, end }
}
const _explodeKeyM = key => {
  const obj = _explodeKey(key)
  obj.start = moment.tz(obj.start, 'utc')
  obj.end = moment.tz(obj.end, 'utc')
  return { ...obj }
}

const _getDeviceData = curry((deviceDataCache, key) => {
  const { start, end } = _explodeKeyM(key)
  const dayKeys = getDayKeys(key.split('-')[0], start, end)
  let ret = []
  dayKeys.forEach(k => {
    ret = ret.concat(deviceDataCache[k] || [])
  })
  return ret
    .filter(d => d.dateutc >= start.valueOf() && d.dateutc <= end.valueOf())
    .sort((a, b) => a.dateutc - b.dateutc)
})

export function dismissDeviceDataError () {
  return {
    type: DEVICE_DEVICE_DATA_DISMISS_ERROR
  }
}

export function generateDeviceDataDataKey (macAddress, start, end) {
  return `${md5(macAddress)}-${start}-${end}`
}

export const dayKeyForDoc = doc => {
  return doc.PASSKEY + '-' + moment.tz(doc.dateutc, 'utc').startOf('day').valueOf() + '-' + moment.tz(doc.dateutc, 'utc').endOf('day').valueOf()
}

export const getDayKeys = (passkey, start, end) => {
  // the whole key
  if (/-/.test(passkey)) {
    const keyObj = _explodeKeyM(passkey)
    passkey = keyObj.passkey
    start = keyObj.start
    end = keyObj.end
  } else {
    start = moment.tz(start, 'utc')
    end = moment.tz(end, 'utc')
  }
  const dayKeys = []
  const currentDay = start.clone().startOf('day')
  while (currentDay.isSameOrBefore(end)) {
    dayKeys.push(passkey + '-' + currentDay.valueOf() + '-' + currentDay.clone().endOf('day').valueOf())
    currentDay.add(1, 'day')
  }
  return dayKeys
}

export function useDeviceData () {
  const dispatch = useDispatch()

  const { deviceDataCompletedKeys, deviceDataPending, deviceDataError, deviceDataCache } = useSelector(
    state => ({
      deviceDataPending: state.device.deviceDataPending,
      deviceDataError: state.device.deviceDataError,
      deviceDataCache: state.device.deviceDataCache,
      deviceDataCompletedKeys: state.device.deviceDataCompletedKeys
    }),
    shallowEqual
  )

  const getDeviceData = useCallback(_getDeviceData(deviceDataCache), [deviceDataCache, deviceDataCompletedKeys])

  const isDeviceDataPending = useCallback(key => {
    return deviceDataPending.includes(key)
  }, [deviceDataPending])

  const isDeviceDataComplete = useCallback(key => {
    const { passkey, start, end } = _explodeKey(key)

    return getDayKeys(passkey, start, end).every(k => deviceDataCache[k]) || deviceDataCompletedKeys.includes(key)
  }, [deviceDataCompletedKeys, deviceDataCache])


  const boundAction = useCallback((...args) => {
    return dispatch(deviceData(...args))
  }, [dispatch])

  const boundDismissError = useCallback(() => {
    return dispatch(dismissDeviceDataError())
  }, [dispatch])

  return {
    deviceData: boundAction,
    deviceDataPending,
    deviceDataError,
    getDeviceData,
    generateDeviceDataDataKey,
    isDeviceDataPending,
    isDeviceDataComplete,
    dismissDeviceDataError: boundDismissError
  }
}

export function reducer (state, action) {
  switch (action.type) {
    case DEVICE_DEVICE_DATA_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        deviceDataPending: [...state.deviceDataPending, action.key],
        deviceDataError: null}

    case DEVICE_DEVICE_DATA_SUCCESS:
      // The request is success
      return {
        ...state,
        deviceDataPending: state.deviceDataPending.filter(k => k !== action.key),
        deviceDataCompletedKeys: uniq([...state.deviceDataCompletedKeys, action.key]),
        deviceDataError: null
      }

    case DEVICE_DEVICE_DATA_FAILURE:
      // The request is failed
      return {
        ...state,
        deviceDataPending: state.deviceDataPending.filter(k => k !== action.key),
        deviceDataError: action.data.error
      }

    case DEVICE_DEVICE_DATA_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        deviceDataError: null
      }

    default:
      return state
  }
}
