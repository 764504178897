import {
  HOVER_DEVICE
} from './constants'

export function hoverDevice (data) {
  return {
    type: HOVER_DEVICE,
    data
  }
}

export function reducer (state, action) {
  switch (action.type) {
    case HOVER_DEVICE:
      return {
        ...state,
        hoverDevice: action.data
      }

    default:
      return state
  }
}
