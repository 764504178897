import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { Cell, ResponsiveContainer, PieChart, Pie } from 'recharts';
import PropTypes from 'prop-types';
import { curry, prop, propEq, find, pipe } from 'ramda';
import lune from '../../common/lib/lune';
import astro from '../../common/lib/astro';
import { getAstroValue, getUserUnitI, timeFormatForUser, widgetExpanded, getTheme } from '../../common/ambient';
import SunIcon from '../../assets/sun-moon/sun.inline.svg';
import { getMoonI, moons } from '../../common/ambient/moons';


const findTime = curry((arr, phen) => {
  return pipe(
    find(propEq('phen', phen)),
    prop('time')
  )(arr)
})
const getTime = curry((arr, phen) => {
  return findTime(arr, phen).valueOf()
})

export default class SunMoonWidget extends PureComponent {
  static propTypes = {
    currentDevice: PropTypes.object.isRequired,
    device: PropTypes.object,
    user: PropTypes.object.isRequired,
    now: PropTypes.number
  }
  state = {
    lastMac: false,
    height: 0,
    show: 'moon'
  }
  constructor(props){
    super(props)
  }

  _height() {
    return this.state.height - 15
  }
  _now() {
    return this.props.now ? moment(this.props.now) : moment()
  }
  _getDay(otherDay) {
    const { currentDevice, now } = this.props
    if (otherDay) return moment.tz(otherDay + ' 00:00', currentDevice.tz.name)
    return moment.tz(this._now().format('YYYY-MM-DD 00:00'), currentDevice.tz.name)
  }

  _fetchData(){
    const { currentDevice } = this.props
    const { height, lastMac } = this.state
    if (currentDevice.macAddress !== lastMac) {
      const rightNow = this._getDay()
      const yesterday = rightNow.clone().subtract(1, 'day')
      const today = new Date(rightNow.format()) 
      const phases = lune.phase_hunt(today)
      const fullMoons = lune.phase_range(today, new Date(rightNow.clone().add(60, 'days').format()), lune.PHASE_FULL)
      const startOfDay = rightNow.clone().startOf('day')
      const endOfDay = rightNow.clone().endOf('day')
      const getValue = getAstroValue(moment, currentDevice) 
      const phase = lune.phase(today)
      const ifNaN = curry((defaultVal, m) => {
        if (m.isValid()) return m
        return defaultVal
      }) 
      this.setState({
        startOfDay,
        endOfDay,
        phase,
        lastMac: currentDevice.macAddress,
        nextNewMoon: moment(phases.nextnew_date),
        nextFullMoon: moment(fullMoons[0]),
        sun: {
          rise: getValue(astro.solar.rise, rightNow),
          set: getValue(astro.solar.set, rightNow),
          transit: getValue(astro.solar.transit, rightNow),
          dusk: getValue(astro.solar.dusk, rightNow),
          dawn: getValue(astro.solar.dawn, rightNow),
        },
        moon: {
          rise: ifNaN(this._getDay().startOf('day'), getValue(astro.lunar.rise, rightNow)),
          set: ifNaN(this._getDay().endOf('day'), getValue(astro.lunar.set, rightNow)),
        },
        yesterdayMoon: {
          rise: getValue(astro.lunar.rise, yesterday),
          set: getValue(astro.lunar.set, yesterday),
        },
        yesterdaySun: {
          rise: getValue(astro.solar.rise, yesterday),
          set: getValue(astro.solar.set, yesterday),
        }
      })
    }
    if (this.ref && !height) {
      this._setHeight()
    }
  }
  _setHeight() {
    if (!this.ref) return
    this.setState({
      height: this.ref.offsetHeight - (this._expanded() ? 100 : 20 ),
      width: this.ref.offsetWidth
    })
  }
  componentDidMount(){
    this._fetchData()
    window.addEventListener("resize", this._setHeight.bind(this))
  }
  componentWillUnmount(){
    window.removeEventListener("resize", this._setHeight.bind(this))
  }
  componentDidUpdate(){
    this._fetchData()
    this._setHeight()
  }
  _moonI() {
    const { phase } = this.state
    return getMoonI(phase)
  }
  _waxingWaning() {
    const { phase } = this.state
    return phase.phase > 0.5 ? 'Waning' : 'Waxing'
  }
  _moonLength(yesterday) {
    const { moon, yesterdayMoon } = this.state
    const dayToUse = yesterday ? this._getDay().subtract(1, 'day') : this._getDay()
    const theMoon = yesterday ? yesterdayMoon : moon 
    const startOfDay = dayToUse.clone().startOf('day')
    const endOfDay = dayToUse.clone().endOf('day')
    const moonSet = theMoon.set.valueOf()
    const moonRise = theMoon.rise.valueOf()
    // moon sets first, then rises in the evening
    if (moonSet < moonRise) {
      return moment.duration(theMoon.set.diff(startOfDay)).add(moment.duration(endOfDay.diff(theMoon.rise)))
    // moon rises then sets, all normal-like
    } else {
      return moment.duration(theMoon.set.diff(theMoon.rise))
    }
  }

  _chart() {
    const { currentDevice, user } = this.props
    const { sun, moon, height } = this.state
    const today = moment.tz(this._now().valueOf(), currentDevice.tz.name)
    const startOfDay = this.state.startOfDay.valueOf()
    const endOfDay = this.state.endOfDay.valueOf()
    const dayLength = endOfDay - startOfDay

    // -(sunset - 6pm) / lengthOfDay * 360
    const pm6 = startOfDay + 1000 * 60 * 60 * 18 
    const sunStartAngle = -(sun.set.valueOf() - pm6) / dayLength * 360
    const sunData = [
      {
        name: 'Sunrise',
        value: sun.set.valueOf()- sun.rise.valueOf(),
        color: '#fcd754'
      },
      {
        name: 'Last Light',
        value: sun.dusk.valueOf() - sun.set.valueOf(), 
        color: '#f6e5b2'
      },
      {
        name: 'Night',
        value: endOfDay - sun.dusk.valueOf() + sun.dawn.valueOf() - startOfDay,
        color: '#f5f5f5'
      },{
        name: 'First Light',
        value: sun.rise.valueOf() - sun.dawn.valueOf(),
        color: '#f6e5b2'
      }
    ]
    let moonStartAngle = 0
    const moonIconArr = 'v2'
    const moonData = []
    const moonSet = moon.set.valueOf()
    const moonRise = moon.rise.valueOf()
    // moon sets first, then rises in the evening
    if (moonSet < moonRise) {
      // -(rise - 6pm) / lengthOfDay * 360
      moonStartAngle = -((moonRise - pm6) / dayLength * 360)
      const moonDownTime = moonRise - moonSet 
      moonData.push({
        name: 'Moon Down',
        value: moonDownTime,
        color: '#f5f5f5'
      })
      moonData.push({
        name: 'Moon Up',
        value: dayLength - moonDownTime,
        color: '#09a8e6'
      })
      if (today.valueOf() < moonSet || today.valueOf() > moonRise) {
        /* moonIconArr = 'color'*/
      }
      
    // moon rises first
    } else {
      const moonUpTime = moonSet - moonRise 
      moonStartAngle = -((moonSet - pm6) / dayLength * 360)
      moonData.push({
        name: 'Moon Up',
        value: moonUpTime,
        color: '#09a8e6'
      })
      moonData.push({
        name: 'Moon Down',
        value: dayLength - moonUpTime,
        color: '#f5f5f5'
      })
      if (today.valueOf() < moonSet && today.valueOf() > moonRise) {
        /* moonIconArr = 'color'*/
      }
    }
    let lineWidth = 8
    let sunLineRadius = 84
    let moonLineRadius = 57
    let exp = false
    if (this._expanded()) {
      lineWidth = 5
      sunLineRadius = 76 
      moonLineRadius = 52
      exp = true
    }
    const labelFn = (slug = 'sun') => {
      return ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        value,
        index
      }) => {
        if (index > 0) return
        const RADIAN = Math.PI / 180;
        const radius = outerRadius - ((outerRadius - innerRadius) / 2)
        const dayPercent = (today.valueOf() - startOfDay) / dayLength
        const timeAngle = 270 - dayPercent * 360 
        let hw = slug === 'moon' ? 17 : 24
        if (exp) {
          hw = slug === 'moon' ? 35 : 47
        }
        const x = cx + radius * Math.cos(-timeAngle * RADIAN) - hw / 2
        const y = cy + radius * Math.sin(-timeAngle * RADIAN) - hw / 2
        // ▲ ☀ 

        if (slug === 'moon') {
          return moons[moonIconArr][this._moonI()]({
            width: hw,
            height: hw,
            x,
            y
          })
        } 
        return <SunIcon
                 width={hw}
                 height={hw}
                 x={x}
                 y={y}
               />
      }
    }
    const stroke = getTheme(user) === 'dark' ? 'transparent' : '#fff'
    return (
      <ResponsiveContainer height={this._height()}>
        <PieChart >
          <Pie
            startAngle={sunStartAngle}
            endAngle={360 + sunStartAngle}
            data={sunData}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            innerRadius={`${sunLineRadius - lineWidth}%`}
            outerRadius={`${sunLineRadius}%`}
            fill="#FFC924"
            isAnimationActive={false}
            label={labelFn()}
            stroke={stroke}
            onMouseEnter={(data, index) => this.setState({ show: (index === 1 || index === 3) ? 'twilight' : 'sun' })}
          >
          {
            sunData.map((entry, index) => <Cell key={`cell-${index}`} fill={sunData[index].color} />)
          }
          </Pie>
          <Pie
            data={moonData}
            startAngle={moonStartAngle}
            endAngle={360 + moonStartAngle}
            isAnimationActive={false}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            innerRadius={`${moonLineRadius - lineWidth}%`}
            outerRadius={`${moonLineRadius}%`}
            fill="#09a8e6"
            stroke={stroke}
            label={labelFn('moon')}
            onMouseEnter={() => this.setState({ show: 'moon' })}
          >
          {
            moonData.map((entry, index) => <Cell key={`cell-${index}`} fill={moonData[index].color} />)
          }
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    )
  }
  _largeEnough() {
    return this.state.width > 480
  }
  _expanded() {
    return widgetExpanded('sunMoon', this.props.currentDevice) && this._largeEnough() 
  }
  _dayLength(yesterday) {
    const { sun, yesterdaySun } = this.state
    const s = yesterday ? yesterdaySun : sun
    return moment.duration(s.set.diff(s.rise))
  }
  _center(){
    const { user, currentDevice } = this.props
    const { moon, sun, phase, seasons, show, nextFullMoon, nextNewMoon } = this.state
    if (!phase) return
    let ret
    if (show === 'moon') {
      if (this._expanded()) {
        let waxWane = parseInt(phase.illuminated * 100, 10) + '% ' + this._waxingWaning() 
        const p = phase.phase
        if (p <= 0.1 || p >= 0.9) {
          waxWane = 'New Moon'
        } else if(p >= 0.2 && p <= 0.3) {
          waxWane = 'First Quarter'
        } else if (p >= 0.45 && p <= 0.55) {
          waxWane = 'Full Moon'
        } else if (p >= 0.7 && p <= 0.8) {
          waxWane = 'Third Quarter'
        }
        ret = [
          <div className="title" key="moon-exp-title">{waxWane}</div>
        ]
        if (nextFullMoon) {
          ret.push(
            <div className="label" key="moon-exp-label-1">Next Full Moon</div>
          )
          ret.push(
            <div key="moon-exp-1">{nextFullMoon.format('MMMM Do')}</div>
          )
        }
        if (nextNewMoon) {
          ret.push(
            <div className="label" key="moon-exp-label-2">Next New Moon</div>
          )
          ret.push(
            <div key="moon-exp-2">{nextNewMoon.format('MMMM Do')}</div>
          )
        }
        
      } else {
        ret = [
          <div className="label" key={"moon-label"}>Moon</div>
        ]
        ret.push(<div key={"moon-1"}>{parseInt(phase.illuminated * 100, 10)}%</div>)
        ret.push(<div key={"moon-2"}>{this._waxingWaning()}</div>)
      }

    // expanded sun && twilight are the same
    } else if (this._expanded()) {
      const twilightLength = moment.duration(sun.rise.diff(sun.dawn))
      ret = [
        <div className="label" key={"soloar-noon-label"}>Solar Noon</div>,
        <div key="solar-noon-2">{sun.transit.format(timeFormatForUser(user))}</div>,
        <div className="label" key={"twilight-label"}>Twilight</div>,
        <div key="twilight-2">{`+${twilightLength.minutes()} minutes`}</div>
      ]
      if (seasons) {
        const closest = find(s => (s.phenom === 'Equinox' || s.phenom === 'Solstice') && s.date.valueOf() > Date.now(), seasons)
        if (closest) {
          ret.push(<div className="label" key={"next-label"}>Next {closest.phenom}</div>)
          ret.push(<div key={"next"}>{closest.date.format('MMM Do')}</div>)
        }
      }
    } else if (show === 'sun') {
      const dayLength = this._dayLength()
      ret = [
        <div className="label" key={"sun-label"}>Day</div>,
        <div key="sun-1">{`${dayLength.hours()} hr`}</div>,
        <div key="sun-2">{`${dayLength.minutes()} min`}</div>
      ]
    } else if (show === 'twilight') {
      const twilightLength = moment.duration(sun.rise.diff(sun.dawn))
      ret = [
        <div className="label" key={"twilight-label"}>Twilight</div>,
        <div key="twilight-2">{`+${twilightLength.minutes()} min`}</div>
      ]
    }
    return <div style={{ height: this._height() }} className={classNames("center", show)}>
      {ret}
    </div>
  }
  _info() {
    const { user } = this.props
    const { width, sun, moon } = this.state
    const fmt = timeFormatForUser(user) 
    let hw = 13
    let moonI = this._moonI()
    if ([0, 1, 15].includes(moonI)) {
      moonI = 8
    }
    if (this._expanded()) {
      const dayDiff = this._dayLength().subtract(this._dayLength(true))
      const moonDiff = this._moonLength().subtract(this._moonLength(true))
      const moonIcon = moons.v2[moonI]({
        height: hw,
        width: hw
      })
      return <div className="info"><table>
        <tbody>
          <tr className="headings">
            <td></td>
            <td>Rise</td>
            <td></td>
            <td>Set</td>
            <td>Duration</td>
            <td>From Yesterday</td>
            <td></td>
          </tr>
          <tr>
            <td><div className="sun"></div></td>
            <td>{sun.rise.format(fmt)}</td>
            <td><span className="hyphen"></span></td>
            <td>{sun.set.format(fmt)}</td>
            <td><span>{this._dayLength().hours()} hr, {this._dayLength().minutes()} min</span></td>
            <td>
              <span className={classNames('arrow', { neg: dayDiff.seconds() < 0 })}>{Math.abs(dayDiff.minutes())} min, {Math.abs(dayDiff.seconds())} sec</span>
            </td>
            <td><div className="sun"></div></td>
          </tr>
          <tr>
            <td>{moonIcon}</td>
            <td>{moon.rise.format(fmt)}</td>
            <td><span className="hyphen"></span></td>
            <td>{moon.set.format(fmt)}</td>
            <td><span>{this._moonLength().hours()} hr, {this._moonLength().minutes()} min</span></td>
            <td>
              <span className={classNames('arrow', { neg: moonDiff.seconds() < 0 })}>{Math.abs(moonDiff.minutes())} min, {Math.abs(moonDiff.seconds())} sec</span>
            </td>
            <td>{moonIcon}</td>
          </tr>
        </tbody>
      </table></div>
    }
    hw = 11 
    return <div className="info">
      <div className="rise">
        <div className="label">
          Rise
        </div>
        <div className="sun">
          {sun.rise.format(fmt)}
        </div>
        <div className="moon">
          {moons.v2[moonI]({
             height: hw,
             width: hw
           })}
          {moon.rise.format(fmt)}
        </div>
      </div>
      <div className="set">
        <div className="label">
          Set 
        </div>
        <div className="sun">
          {sun.set.format(fmt)}
        </div>
        <div className="moon">
          {moon.set.format(fmt)}
          {moons.v2[moonI]({
             height: hw,
             width: hw
           })}
        </div>
      </div>
    </div>
  }


  render() {
    const { width } = this.state
    if (!this.state.sun) return null

    return (
      <div ref={ref => {
        this.ref = ref
      }} className={classNames("device-sun-moon-widget", { exp: this._expanded(), lg: width > 410 })}>
        <div className={classNames("wrap", { hour24: getUserUnitI('hour24', this.props.user) === 1 })}>
          {this._chart()}
          {this._info()}
          {this._center()}
        </div>
      </div>
    )
  }
}

SunMoonWidget.displayName = 'SunMoonWidget'
