import React, { useEffect } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { isLoggedIn } from '../../common/ambient'
import Loader from '../../components/Loader'
import md5 from 'blueimp-md5'
import bindUserActions from '../user/redux/bindUserActions'
import {
  Avatar,
  Signup,
  Signin
} from './'
import { goodEmail } from '../../common/ambient/utils';
import { path } from 'ramda';
import { rawFeathersRequest } from '../../common/feathers';
// import PropTypes from 'prop-types';

const INTERESTS = {
  garden: {
    title: 'Gardening & Lawn Care',
    url: 'https://www.ambientweather.com/awn/gardening-lawn-care'
  },
  pool: {
    title: 'Pools & Spa',
    url: 'https://www.ambientweather.com/awn/pools-spa'
  },
  indoor: {
    title: 'Indoor Monitoring',
    url: 'https://www.ambientweather.com/awn/indoor-monitoring'
  },
  outdoor: {
    title: 'Outdoor Monitoring',
    url: 'https://www.ambientweather.com/awn/outdoor-monitoring'
  },
  weather: {
    title: 'Weather Watching',
    url: 'https://www.ambientweather.com/awn/weather-watching'
  },
  education: {
    title: 'Education & Research',
    url: 'https://www.ambientweather.com/awn/education-research'
  },
  farm: {
    title: 'Agriculture & Farming',
    url: 'https://www.ambientweather.com/awn/agriculture-farming'
  }
}

function GetStarted ({ user, actions }) {
  const [step, setStep] = React.useState(1);
  const [email, setEmail] = React.useState('');
  const [signupOn, setSignupOn] = React.useState(true)
  const [checking, setChecking] = React.useState(false)
  const [hasCode, setHasCode] = React.useState(false)
  const [interests, setInterests] = React.useState(path(['info', 'settings', 'marketing', 'interests'], user) || [])
  const [checkedUser, setCheckedUser] = React.useState(false)
  useEffect(() => {
    if (checkedUser) return
    if (!user.userChecked) return
    setInterests(path(['info', 'settings', 'marketing', 'interests'], user) || [])
    setHasCode(path(['info', 'settings', 'marketing', 'code'], user))
    setCheckedUser(true)
  }, [user, setInterests, checkedUser, setCheckedUser, setHasCode])
  let show = (
    <div className='step-1'>
      <h1>Congratulations</h1>
      <h2>on your new Ambient Weather station</h2>
      <h3>Welcome! Let's help you to:</h3>
      <ul>
        <li>
          <i className='glyphicon glyphicon-ok' />
          <span>
            Setup your new Ambient Weather station device
          </span>
        </li>
        <li>
          <i className='glyphicon glyphicon-tag' />
          <span>
            Claim your <b>10% discount coupon</b> for all accessories
          </span>
        </li>
        <li>
          <Avatar />
          <span>
            Create your account and gain <b>unlimited FREE</b> access to AWN
          </span>
        </li>
      </ul>
      <h4>Start here with your email</h4>
      <span className='email-wrap'>
        {checking
          ? (
            <Loader />
            )
          : (
            <>
              <input type='email' placeholder='Enter your email' value={email} onChange={evt => setEmail(evt.target.value)} />
              <button
                className='btn btn-primary btn-lg'
                disabled={!goodEmail(email) && !isLoggedIn(user)}
                onClick={() => {
                  if (isLoggedIn(user)) {
                    setStep(3)
                  } else {
                    setChecking(true)
                    rawFeathersRequest('/misc/email', {
                      method: 'POST',
                      body: JSON.stringify({ email, secret: md5(email + 'EM@!L') })
                    })
                      .then(res => {
                        setSignupOn(!res.exists)
                        setChecking(false)
                        setStep(2)
                      })
                  }}}
                >Continue</button>
            </>
          )
        }
      </span>
    </div>
  )
  if (step === 2) {
    show = (
      <div className='step-2'>
        <h3>Set your name and password</h3>
        <Signup email={email} onSuccess={() => {
          setCheckedUser(false)
          setStep(3)
        }}>
          <p className='gray'>Sign in and claim your dicount coupon</p>
        </Signup>
        <p>Already have an account? <a onClick={() => setSignupOn(false)}>Log in here</a></p>
      </div>
    )
    if (!signupOn) {
      show = (
        <div className='step-2'>
          <Signin email={email} onSuccess={() => {
            setCheckedUser(false)
            setStep(3)
          }} />
          <p>Don't have an account? <a onClick={() => setSignupOn(true)}>Sign up here</a></p>
        </div>
      )
    }
  }
  if (step === 3) {
    show = (
      <div className='step-3'>
        <h1>Claim your 10% Discount for all accessories</h1>
        <h3>What are you interested in?</h3>
        <p>(You can select multiple interests)</p>
        <div className='interests'>
          {Object.keys(INTERESTS).map(key => {
            return (
              <a
                key={key}
                className={classNames('interest', key, { active: interests.includes(key) })}
                onClick={() => {
                  if (interests.includes(key)) {
                    setInterests(interests.filter(i => i !== key))
                  } else {
                    setInterests([...interests, key])
                  }
                }}
              >
                <div>
                  <i className='icon' />
                  <span>{INTERESTS[key].title}</span>
                </div>
                {/* <a className='more' href={INTERESTS[key].url} target='_blank'>more</a> */}
              </a>
            )
          })}
        </div>
        <div className='btn-wrap'>
          <button className='btn btn-primary btn-lg' onClick={() => {
            actions.updateSetting('marketing', { interests })
            setStep(4)
          }}>Claim & Setup device</button>
          <Link to='/devices?open=true'>Skip and setup my station</Link>
        </div>
      </div>
    )
  }
  if (step === 4) {
    let message = (
      <>
        <h3>We sent you your 10% off coupon on your email</h3>
        <p>Didn’t receive an email? <a href='mailto:support@ambietweather.com' target='_blank'>Contact support</a></p>
      </>
    )
    if (hasCode) {
      message = (
        <h3>Your discount code: {hasCode}</h3>
      )
    }
    show = (
      <div className='step-4'>
        <i className='sent' />
        {message}
        <Link to='/devices?open=true' className='btn btn-primary setup'>
          <i className='glyphicon glyphicon-plus' />
          Setup new device
        </Link>
        <a href='https://ambientweather.com/support/getting-started/' target='_blank' className='btn btn-white manuals'>
          <i className='glyphicon glyphicon-file' />
          User Manuals
        </a>
        <a target='_blank' href='https://ambientweather.com/support' className='btn btn-white help'>
          <i className='glyphicon glyphicon-question-sign' />
          Help Center
        </a>
        <Link to='/dashboard'>Done</Link>
      </div>
    )
  }
  return (
    <div className={classNames('user-get-started', 's-' + step)}>
      <header>
        <a className='back' onClick={() => setStep(step - 1)}>&lt; Back</a>
        <div className='logo' />
      </header>
      {show}
    </div>
  )
}

GetStarted.propTypes = {};
GetStarted.defaultProps = {};
export default bindUserActions(GetStarted);

GetStarted.displayName = 'GetStarted'
