import {
  CREATE_USER_BEGIN,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  CREATE_USER_DISMISS_ERROR,
  FETCH_USER_BEGIN
} from './constants'
import { app } from '../../../common/feathers'
import * as actions from './actions'
import { getThemeObj } from '../../../common/skinner'
import { pick } from 'ramda'
const userService = app.service('users')

export function createUser(args) {
  return (dispatch) => {
    dispatch({
      type: CREATE_USER_BEGIN,
    })
    let creds = {
      email: args.email.toLowerCase(),
      password: args.password,
      username: args.username,
      marketing: args.marketing,
      roles: ['user', 'social'],
      settings: {
        home: getThemeObj().token
      }
    }
    return userService.create(creds)
      .then((user) => {
        app.set('user', user)
        return actions.fetchUser(pick(['email', 'password'], creds))(dispatch)
          .then(() => {
            dispatch({
              type: CREATE_USER_SUCCESS,
              data: user,
            })
          })
          .then(() => {
            actions.sendConfirmation({ user: user._id, confirmKey: true })(dispatch)
            return user
          })
      })
      .catch((err) => {
        dispatch({
          type: CREATE_USER_FAILURE,
          data: {
            error: err,
          },
        })
      })
  }
}

export function dismissCreateUserError() {
  return {
    type: CREATE_USER_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CREATE_USER_BEGIN:
      return {
        ...state,
        userChecked: false,
        createUserPending: true,
        createUserError: null,
      };

    case CREATE_USER_SUCCESS:
      return {
        ...state,
        info: action.data,
        userChecked: true,
        createUserPending: false,
        createUserError: null,
      };

    case CREATE_USER_FAILURE:
      return {
        ...state,
        createUserPending: false,
        createUserError: action.data.error,
      };

    case CREATE_USER_DISMISS_ERROR:
      return {
        ...state,
        createUserError: null,
      };

    default:
      return state;
  }
}
