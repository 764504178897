import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import classNames from 'classnames'

export default class FromNow extends PureComponent {
  static propTypes = {
    dateutc: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]) 
  }

  render() {
    let { dateutc } = this.props
    if (!dateutc) return null
    if (typeof dateutc === 'string') {
      const d = new Date(dateutc)
      dateutc = d.getTime()
    }
    const diff = Date.now() - dateutc
    let short, overWeek
    if (diff < 1000 * 60) {
      short = moment.duration(diff).seconds() + 's'
    } else if (diff < 1000 * 60 * 60) {
      short = moment.duration(diff).minutes() + 'm'
    } else if (diff < 1000 * 60 * 60 * 24) {
      short = moment.duration(diff).hours() + 'h'
    } else if (diff < 1000 * 60 * 60 * 24 * 7) {
      short = moment.duration(diff).days() + 'd'
    } else {
      overWeek = true
      short = moment(dateutc).format('MMM D')
    }
    return (
      <span className={classNames('component-from-now', { 'over-week': overWeek })}>
        <span className='long'>{moment(dateutc).fromNow()}</span>
        <span className='short'>{short}</span>
        <span className='full'>{moment(dateutc).format('MMM D, YYYY h:mma')}</span>
      </span>
    )
  }
}

FromNow.displayName = 'FromNow'
