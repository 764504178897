import React, { useEffect } from 'react'
import { getUrl } from '../../common/ambient'
import { Loader } from '../../components'
const fetch = require('isomorphic-fetch')
// import PropTypes from 'prop-types';

export default function WxwPreview ({ macAddress, layout, children, layoutId, onLoading }) {
  if (!layoutId) {
    layoutId = layout.id
  }
  const imgRef = React.useRef(null)
  const [loading, setLoading] = React.useState(false)
  useEffect(() => {
    const url = `${getUrl()}/devices/wxw/${macAddress}?layout=${layoutId}`
    if (imgRef.current && imgRef.current.url === url) {
      return
    }
    const opts = {
      method: 'GET',
      headers: {
        Authorization: `${window.localStorage.getItem('feathers-jwt')}`,
      }
    }
    if (layout) {
      opts.method = 'POST'
      opts.headers['Content-Type'] = 'application/json'
      opts.body = JSON.stringify(layout)
    }
    setLoading(true)
    fetch(url, opts)
      .then(res => res.blob())
      .then(blob => {
        imgRef.current = {
          src: URL.createObjectURL(blob),
          url
        }
        setLoading(false)
      })
  }, [macAddress, layout, layoutId, setLoading, loading])
  useEffect(() => {
    console.log('layout changed')
    imgRef.current = null
    setLoading(true)
  }, [layout])
  useEffect(() => {
    if (onLoading) {
      onLoading(loading)
    }
  }, [loading, onLoading])
  const style = {}
  if (imgRef.current) {
    style.backgroundImage = `url(${imgRef.current.src})`
  }
  return (
    <div className='device-wxw-preview' style={style}>
      {!imgRef.current && <Loader />}
      {children}
    </div>
  )
};

WxwPreview.propTypes = {}
WxwPreview.defaultProps = {}

WxwPreview.displayName = 'WxwPreview'
