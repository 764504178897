import React, { Component } from 'react';
import { withRouter } from 'react-router'
import PropTypes from 'prop-types';
import { identity, split, length, path, test, prop, filter, uniq, pipe, pluck, splitEvery, toUpper, join } from 'ramda'
import bindAllActions from '../../common/bindAllActions'
import { pathsChanged, debounce, fcontains, isLoggedIn, isAdmin, getUrl } from '../../common/ambient'
import SearchBar from '../device/SearchBar'
import UserCard from './UserCard'
import Loader from '../../components/Loader'
import AmbRadio from '../../components/AmbRadio'
import AlertBanner from '../device/AlertBanner'
import { app } from '../../common/feathers'
import { getThemeObj } from '../../common/skinner'
const fetch = require('isomorphic-fetch')

class Admin extends Component {
  static propTypes = {
    alert: PropTypes.object.isRequired,
    alertActions: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    userActions: PropTypes.object.isRequired,
    deviceActions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    device: PropTypes.object.isRequired,
  };
  constructor(props) {
    super(props)
    this.state = {
      rand: 'hi',
      q: '',
      whitelist: '',
      stats: {},
      alertObj: {}
    }
    this.alertObjFetched = false
    this.fetchDevices = ::this.fetchDevices
    this.downloadCsv = ::this.downloadCsv
    this.appendWhitelist = ::this.appendWhitelist
    this.doSearch = debounce(this.doSearch.bind(this), 300)
    this.doAlertSave = debounce(this.doAlertSave.bind(this), 500)
    this.autoColon = ::this.autoColon
  }
  componentDidMount() {
    this.fetchUsers()
    return fetch(getUrl() + '/misc/admin/stats')
    .then((res) => {
      return res.json()
    })
    .then((stats) => {
      this.setState({ stats })
    })
  }
  componentDidUpdate(prevProps) {
    const { user, history } = this.props
    if (pathsChanged(this.props, prevProps, [['user', 'userChecked']])) {
      if (!isLoggedIn(user) && !isAdmin(user)) {
        history.replace('/dashboard')
      }
    }
    this.fetchUsers()
  }
  doAlertSave() {
    const { alertObj } = this.state
    app.service('siteStats').patch(this.alertObjFetched, { body: alertObj })
  }
  autoColon(q) {
    if (q.length === 12 && !/[^0-9a-fA-F]+$/.test(q)) {
      const addColons = pipe(
        toUpper,
        splitEvery(2),
        join(':')
      )(q)
      return addColons
    }
    return q
  }
  doSearch(p) {
    const { userActions, deviceActions } = this.props
    if (p.length < 3) return
    userActions.clearUsers()
    // device
    let q = this.autoColon(p)
    if (test(/:/, q)) {
      deviceActions.fetchAllDevices({
        macAddress: { $regex: `^${q.toUpperCase()}` },
        $limit: 1000
      })
      .then(() => {
        const devices = this.props.device.allDevices
        if (devices && devices.length > 0) {
          userActions.fetchUsers({
            _id: { $in: pluck('userId', devices) },
            $limit: 1000
          })
        }
      })
      
    // user
    } else {
      userActions.fetchUsers({
        email: { $regex: q.toLowerCase() },
        $limit: 1000
      })
      .then(() => {
        const users = this.props.user.users
        if (users && users.length > 0) {
          deviceActions.fetchAllDevices({
            userId: { $in: pluck('_id', users) },
            $limit: 1000
          })
        }
      })
    }
  }
  appendWhitelist() {
    const { deviceActions } = this.props
    const list = pipe(
      split("\n"),
      filter(identity)
    )(this.state.whitelist)
    deviceActions.updateDeviceData('whitelist', list, {})
    .then(() => this.setState({ whitelist: '' }))
  }
  fetchDevices() {
    const { deviceActions } = this.props
    return (args) => {
      deviceActions.fetchDevices(args)
      .then(() => this.setState({ rand: Math.random() }))
    }
  }
  fetchUsers() {
    const { deviceActions, userActions, user, history, alert, device, alertActions } = this.props
    const { fetchUsersPending, users } = user
    const { fetchIftttAlertsPending, iftttAlerts } = alert
    const { fetchAllDevicesPending, allDevices } = device
    if (isLoggedIn(user) && !isAdmin(user)) {
      history.replace('/dashboard')
    }
    if (isLoggedIn(user) && !this.alertObjFetched) {
      this.alertObjFetched = true
      app.service('siteStats').find({ query : { key: getThemeObj().token === 'aw' ? 'alert' : `alert-${getThemeObj().token}` } })
      .then((ssRes) => {
        this.alertObjFetched = ssRes.data[0]._id
        this.setState({ alertObj: ssRes.data[0].body || {} })
      })
    }
    // if (!fetchIftttAlertsPending && !iftttAlerts) {
    //   alertActions.fetchIftttAlerts({
    //     ifttt: { $ne: null }
    //   })
    // }
  }
  downloadCsv() {
    const { user } = this.props
    const { users } = user
    const csv = 'data:text/csv;charset=utf-8,' + pluck('email', users).join("\n")
    const link = document.createElement('a')
    link.setAttribute('href', encodeURI(csv))
    link.setAttribute('download', 'ambient-weather-net-users.csv')
    document.body.appendChild(link)
    link.click()
  }

  render() {
    const { alertObj, q, whitelist, stats } = this.state
    const { user, device, alert } = this.props
    const { fetchUsersPending, users } = user
    const { fetchAllDevicesPending, allDevices } = device
    const { iftttAlerts } = alert
    let ifttt = ''
    if (iftttAlerts) {
      const iftttUsers = pipe(
        pluck('userId'),
        uniq
      )(iftttAlerts)

      ifttt =
        <div>
          <i className="ifttt" />
          {iftttUsers.length} users / {iftttAlerts.length} applets
        </div>
    }
    let alexa = 0
    let ga = 0
    if (users) {
      alexa = pipe(
        filter(path(['oauth', 'alexa'])),
        length
      )(users)
      ga = pipe(
        filter(path(['oauth', 'google-assistant'])),
        length
      )(users)
    }
    /* const userEmailEquals = pipe(prop('email'), test(new RegExp(q, 'i')))
     * const matchingDeviceUserIds = pipe(
     *   filter(pipe(prop('macAddress'), test(new RegExp(q, 'i')))),
     *   pluck('userId')
     * )(allDevices || [])
     * const userIdInDevices = pipe(prop('_id'), fcontains(matchingDeviceUserIds))
     * const theFilter = test(/:/, q) ? userIdInDevices : userEmailEquals
     */
    return (
      <div className="user-admin">
        <header className="main">
          <h1>
            Admin
          </h1>
          <div className="children">
            <SearchBar
              onChange={this.doSearch}
              placeholder={'Email or MAC Address'}
              open={true}
            />
          </div>
        </header>
        <div className="banner-wrap">
          <AlertBanner {...alertObj} />
        </div>
        <div className="page-body">
          <div className="block">
            <div className="top-bar">
              <h3>
                Users
              </h3>
              <div>
                Total: 
                {
                  users && users.length > 0 ?
                    ' ' + users.length + ' of '
                  : ''
                }
                {stats.users}
              </div>
              <div>
                Devices: {stats.devices}
              </div>
              {ifttt}
              <div>
                <i className="alexa" />
                {stats.al}
              </div>
              <div>
                <i className="google-assistant" />
                {stats.ga}
              </div>
              {/* <a
              className="csv"
              onClick={this.downloadCsv}
              title="Download CSV"
              tabIndex={-1}
              /> */}
            </div>
            {fetchAllDevicesPending || fetchUsersPending ? <Loader /> : 
              users.length > 0 ? users.map((u, i) =>
                <UserCard
                  key={i}
                  u={u}
                />
              )
              : ''}
          </div>
          <div className="alert-banner block">
            <h3>Alert Banner</h3>
            <input
              type="text"
              value={alertObj.message || ''}
              placeholder="message"
              onChange={(evt) => {
                alertObj.message = evt.target.value
                this.setState({ alertObj }, this.doAlertSave)
              }}
            />
            <input
              type="text"
              value={alertObj.link || ''}
              placeholder="more info link - http://..."
              onChange={(evt) => {
                alertObj.link = evt.target.value
                this.setState({ alertObj }, this.doAlertSave)
              }}
            />
            <AmbRadio
              selected={alertObj.active}
              onClick={() => {
                alertObj.active = !alertObj.active
                this.setState({ alertObj }, this.doAlertSave)
              }}
              label="Live on the site?"
            />
          </div>
          <div className="block whitelist">
            <h3>
              Whitelist
              <a className="btn btn-primary" disabled={whitelist === ''} onClick={this.appendWhitelist}>Append</a>
            </h3>
            <textarea
              onChange={(evt) => {
                this.setState({
                  whitelist: evt.target.value
                })
              }}
              value={whitelist}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default bindAllActions(withRouter(Admin))

Admin.displayName = 'Admin'

Admin.displayName = 'Admin'

Admin.displayName = 'Admin'

Admin.displayName = 'Admin'

Admin.displayName = 'Admin'

Admin.displayName = 'Admin'

Admin.displayName = 'Admin'
