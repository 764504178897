import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { AllPayments, CAM_TIERS, deviceCamTierObj, userHasCardOnFile, deviceHasThisSub, deviceSubscriptionStatus } from '.'
import { billingSubscribe } from './billingAccess'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'
import { path, range } from 'ramda'
import DisplayCard from './DisplayCard'
import { Loader } from '../../components';
import Switch from 'react-switch'

export default function EnhancedCamModal ({ user, currentDevice, userActions }) {
  const myTier = deviceCamTierObj(user, currentDevice.macAddress)
  const history = useHistory()
  const [step, setStep] = useState(1)
  const [picked, setPicked] = useState(0)
  const [paid, setPaid] = useState(false)
  const [hasPaymentMethod, setHasPaymentMethod] = useState(userHasCardOnFile(user))
  const [loading, setLoading] = useState(false)
  const [annual, setAnnual] = useState(true)
  const userId = path(['info', '_id'], user)
  const deviceId = path(['_id'], currentDevice)
  const macAddress = path(['macAddress'], currentDevice)
  // console.log("Cam billing says: user=" + userId + "   device=" + deviceId)

  const termType = <Switch
    onChange = {() => setAnnual(prevAnnual => !annual)}
    checked={annual}
  />
  
  // ----- Build the plans step
  const term = annual ? 'yearly' : 'monthly'
  const showPlans = (
    <>
      <header>
        <h2>Choose the camera subscription that best fits your needs.</h2>
        <p>Your current subscription is <strong>{myTier.label} Camera</strong></p>
        <div className='term'>{termType} <span>Save up to 17% with an annual subscription!</span></div>
      </header>
      <div className='product-tiers'>
        {CAM_TIERS.map((tier, i) => {
          if ((tier.term !== term) && (tier.term !== 'both')) return
          const active = i === myTier.tier || (term === 'yearly' && i === myTier.tier + 1) || (term === 'monthly' && i === myTier.tier - 1)

          // button stuff
          let disabled = false
          let btnLabel = 'Upgrade'
          let btnClick = () => {
            setStep(2)
            setPicked(i)
            // console.log("Picked: " + picked)
          }
          if (active) {
            disabled = true
            btnLabel = 'Current'
          } else if (i < myTier.tier) {
            btnLabel = 'Downgrade'
            if (i === 0) {
              btnClick = () => {
                history.push('/account#subscriptions')
                userActions.doModal(null)
              }
            }
          }

          const specialPrice = i !== 0 && annual
          return (
            <div className={classNames(`tier tier-${i} step-${step}`, { active, annual })} key={i}>
              <div className='top'>
                <h3>{tier.label} Camera</h3>
                <h2>{tier.price}{specialPrice ? <sup>*</sup> : null}</h2>
                {specialPrice ? <p><sup>*</sup> when billed annually</p> : null}
                <button
                  className='btn btn-primary'
                  disabled={disabled}
                  onClick={btnClick}
                >{btnLabel}</button>
              </div>
              <div className='bottom'>
                <strong>Features</strong>
                <div className='group'>
                  <div className='line'>
                    <div className='label'>Time lapse resolution:</div>
                    <div className='val'>{tier.timelapseRes}</div>
                  </div>
                  <div className='line'>
                    <div className='label'>Regeneration rate:</div>
                    <div className='val'>{tier.regenRate}</div>
                  </div>
                  <div className='line'>
                    <div className='label'>Weather banner:</div>
                    <div className='val'>{tier.banner}</div>
                  </div>
                  <div className='line'>
                    <div className='label'>Sampling:</div>
                    <div className='val'>{tier.sampling}</div>
                  </div>
                  <div className='line'>
                    <div className='label'>Time lapse storage:</div>
                    <div className='val'>{tier.storage}</div>
                  </div>
                  <div className='line'>
                    <div className='label'>Map Icon:</div>
                    <div className='val'>{tier.mapIcon}</div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )

  // ----- Build the payment step
  const sleep = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  let subscribed = false
  async function btnBuyHandler () {
    // console.log('==================> Entered btnBuyHandler')
    if (!deviceHasThisSub(user, macAddress, CAM_TIERS[picked].productName)) {
      let productName = CAM_TIERS[picked].productName
      // handle upgrades/downgrades with existing subscriptions
      const existingSubI = range(1, CAM_TIERS.length).find(i => deviceHasThisSub(user, macAddress, CAM_TIERS[i].productName))
      const billingSubOpts = {}
      if (existingSubI && CAM_TIERS[existingSubI].productName !== productName) {
        productName = CAM_TIERS[existingSubI].productName
        billingSubOpts.body = { new_product_name: CAM_TIERS[picked].productName }
        billingSubOpts.method = 'PUT'
      }
      setLoading(true)
      subscribed = await billingSubscribe(userId, macAddress, productName, billingSubOpts)
      await sleep(7000)
      await userActions.refreshUser()
      setLoading(false)
      setPaid(subscribed)
      if (subscribed) {
        setStep(3)
      }
    }
  }

  const showCard = (
    <>
      <h1>Choose payment method for your {CAM_TIERS[picked].label} plan.</h1>
      {/* <div style={{ border: 'solid', borderColor: 'green' }}> */}
      <div>
        <DisplayCard user={user} />
        {/* <AllPayments userId={userId} mode="file" /> */}
        <AllPayments
          userId={userId}
          deviceId={deviceId}
          mode='file'
          onSuccess={setHasPaymentMethod}
        />
      </div>
      <div className="buyBtn">
        <button
          className='btn btn-primary buy-plan'
          disabled={!hasPaymentMethod || loading}
          onClick={btnBuyHandler}
          >Buy Plan - ${CAM_TIERS[picked].buyPrice}</button>
          {annual && <p>annual saving of 17% is equivalent to {CAM_TIERS[picked].price}</p>}
        
        {loading ? <><br /><p>Please wait</p><Loader /></> : ''}
      </div>
    </>
  )

  // ----- Build confirmation step
  const subStatus = deviceSubscriptionStatus(user, macAddress, CAM_TIERS[picked].productName) 
  let showConfirm = ''
  switch (subStatus) {
    case "active":
      showConfirm = <>
          <h1>You have successfully subscribed to the {CAM_TIERS[picked].label} plan.</h1>
          <p>You can cancel your subscription renewal at any time under your Accounts page.</p>
          <p>There may be a delay for payment processing before the features are available.</p>
        </>
      break    
    case "incomplete":
      showConfirm = <>
          <h1>There has been a delay in confirming your payment method for the {CAM_TIERS[picked].label} plan.</h1>
          <p>We apologize for the inconvenience.  Please check the status of your subscription later on your Account page.</p>
        </>
      break
    default:
      showConfirm = <>
          <h1>An error occured while attempting to subscribe to the {CAM_TIERS[picked].label} plan.</h1>
          <p>We are sorry for the inconvenience.  Please try again later.</p>
        </>

  }

  // ----- Choose the step to show
  let show = ''
  switch (step) {
    case 1:
      show = showPlans
      break
    case 2:
      show = showCard
      break
    case 3:
      show = showConfirm
      break
    default:
      show = <h1>Error processing.  Please try again later.</h1>
  }

  return (
    <div className='payment-enhanced-cam-modal'>
      {show}
    </div>
  )
}

EnhancedCamModal.propTypes = {
  currentDevice: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  userActions: PropTypes.object.isRequired
}
EnhancedCamModal.defaultProps = {}



EnhancedCamModal.displayName = 'EnhancedCamModal'
