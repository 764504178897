import {
  FETCH_USER_ACTIONS_BEGIN,
  FETCH_USER_ACTIONS_SUCCESS,
  FETCH_USER_ACTIONS_FAILURE,
  FETCH_USER_ACTIONS_DISMISS_ERROR
} from './constants'
import { serviceFind } from '../../../common/feathers'
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useCallback } from 'react';
import { sort, concat, pipe, prop, uniqBy } from 'ramda'

export const fetchUserActions = serviceFind('userActions', {
  begin: FETCH_USER_ACTIONS_BEGIN,
  success: FETCH_USER_ACTIONS_SUCCESS,
  fail: FETCH_USER_ACTIONS_FAILURE
})

export function dismissFetchUserActionsError () {
  return {
    type: FETCH_USER_ACTIONS_DISMISS_ERROR
  }
}

export function useFetchUserActions() {
  const dispatch = useDispatch();

  const { fetchUserActionsPending, fetchUserActionsError } = useSelector(
    state => ({
      fetchUserActionsPending: state.social.fetchUserActionsPending,
      fetchUserActionsError: state.social.fetchUserActionsError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(fetchUserActions(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissFetchUserActionsError());
  }, [dispatch]);

  return {
    fetchUserActions: boundAction,
    fetchUserActionsPending,
    fetchUserActionsError,
    dismissFetchUserActionsError: boundDismissError,
  };
}

export function reducer (state, action) {
  let st8
  switch (action.type) {
    case FETCH_USER_ACTIONS_BEGIN:
      st8 = {
        ...state,
        fetchUserActionsPending: true,
        fetchUserActionsError: null
      }
      if (action.dataKey) {
        st8.fetchUserActionsPendingDataKeys.push(action.dataKey)
      }
      return st8

    case FETCH_USER_ACTIONS_SUCCESS:
      st8 = {
        ...state,
        fetchUserActionsPending: false,
        fetchUserActionsError: null,
        fetchUserActionsPendingDataKeys: action.dataKey ? state.fetchUserActionsPendingDataKeys.filter(key => key !== action.dataKey) : state.fetchUserActionsPendingDataKeys,
      }
      if (action.dataKey) {
        st8[action.dataKey] = action.data
      }
      return st8

    case FETCH_USER_ACTIONS_FAILURE:
      return {
        ...state,
        fetchUserActionsPending: false,
        fetchUserActionsError: action.data.error,
        fetchUserActionsPendingDataKeys: action.dataKey ? state.fetchUserActionsPendingDataKeys.filter(key => key !== action.dataKey) : state.fetchUserPendingActionsDataKeys,
      }

    case FETCH_USER_ACTIONS_DISMISS_ERROR:
      return {
        ...state,
        fetchUserActionsError: null
      }

    default:
      return state
  }
}
