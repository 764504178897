import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as actions from './actions'
import mapStateToProps from '../../../common/mapStateToProps'

/* istanbul ignore next */
function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)
