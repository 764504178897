import {
  SEND_CONFIRMATION_BEGIN,
  SEND_CONFIRMATION_SUCCESS,
  SEND_CONFIRMATION_FAILURE,
  SEND_CONFIRMATION_DISMISS_ERROR,
} from './constants';
import { app } from '../../../common/feathers'
const confirmService = app.service('confirm')

export function sendConfirmation (args) {
  return (dispatch) => {
    dispatch({
      type: SEND_CONFIRMATION_BEGIN,
    });
    return confirmService.update(args.user, args)
      .then((res) => {
        dispatch({
          type: SEND_CONFIRMATION_SUCCESS,
          data: {},
        });
      })
      .catch((err) => {
        dispatch({
          type: SEND_CONFIRMATION_FAILURE,
          data: {
            error: err,
          },
        });
      })
  };
}

export function dismissSendConfirmationError() {
  return {
    type: SEND_CONFIRMATION_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case SEND_CONFIRMATION_BEGIN:
      return {
        ...state,
        sendConfirmationPending: true,
        sendConfirmationError: null,
        sendConfirmationSucceeded: false
      };

    case SEND_CONFIRMATION_SUCCESS:
      return {
        ...state,
        sendConfirmationPending: false,
        sendConfirmationError: null,
        sendConfirmationSucceeded: true 
      };

    case SEND_CONFIRMATION_FAILURE:
      return {
        ...state,
        sendConfirmationPending: false,
        sendConfirmationError: action.data.error,
      };

    case SEND_CONFIRMATION_DISMISS_ERROR:
      return {
        ...state,
        sendConfirmationError: null,
      };

    default:
      return state;
  }
}
