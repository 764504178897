import {
  DEVICE_TEST_ACTION,
} from './constants';

export function deviceTestAction() {
  return {
    type: DEVICE_TEST_ACTION,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case DEVICE_TEST_ACTION:
      return {
        ...state,
      };

    default:
      return state;
  }
}
