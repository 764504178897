import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames'
import { CopyToClipboard } from 'react-copy-to-clipboard'

export default class CopyLink extends PureComponent {
  static propTypes = {
    text: PropTypes.string.isRequired,
    linkTitle: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string
  }
  state = {}

  render() {
    const { inputCopied } = this.state
    const { onClick, text, linkTitle, className } = this.props
    const fieldCopied = (field) => {
      return () => {
        const s = {}
        s[field] = true
        this.setState(s)
        setTimeout(() => {
          s[field] = false
          this.setState(s)
        }, 2000)
      }
    }
    return (
      <CopyToClipboard
        text={text}
        onCopy={fieldCopied('inputCopied')}
      >
        <a onClick={onClick} className={classNames("component-copy-link", className)} >{inputCopied ? 'Copied!' : linkTitle || 'Copy'}</a>
      </CopyToClipboard>
    );
  }
}

CopyLink.displayName = 'CopyLink'
