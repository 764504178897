import { isCordova, deviceIsMine, getStorage, setStorage, isApp, isIosApp, isAndroidApp, appIsGteVersion } from '../../../common/ambient'
import { getDevice, saveCurrent, manualRefresh, fetchDevices, setThing, clearForecast } from './actions'
import { setOnline } from '../../common/redux/actions'
import { refreshUser } from '../../user/redux/refreshUser'
import { path } from 'ramda'

export default function (store) {
  let user
  const fetchProperDevices = () => {
    const { device } = store.getState()
    const { dashboardDevice } = device
    if (dashboardDevice && !deviceIsMine(device, dashboardDevice._id)) {
      getDevice(dashboardDevice._id)(store.dispatch)
    }
    fetchDevices()(store.dispatch)
  }
  let _int = false
  function doInterval () {
    manualRefresh()
    clearInterval(_int)
    _int = setInterval(fetchProperDevices, 1000 * 60)
  }
  store.subscribe(function () {
    const { device } = store.getState()
    // the first time as a logged in user
    if (!user && store.getState().user.info._id) {
      user = store.getState().user.info
      if (device.current.macAddress) {
        saveCurrent(device.current)(store.dispatch)
      }
      fetchProperDevices()
      doInterval()
    }
  })
  if (isApp()) {
    window.handleAppState = function (key, data) {
      const { user } = store.getState()
      const obj = typeof data === 'string' ? JSON.parse(decodeURIComponent(data)) : data
      console.log('handleAppState', key, obj)
      if (key === 'app-paused') {
        store.dispatch(setThing('appPaused', true))
        store.dispatch(clearForecast())
        clearInterval(_int)
      } else if (key === 'app-resumed') {
        store.dispatch(setThing('appPaused', false))
        fetchProperDevices()
        store.dispatch(refreshUser())
        doInterval()

        const whitelistedEmails = [
          'kstillman@nkhome.com',
          'nsteffensen@nkhome.com',
          'yoan.petrushinov@musala.com',
          'kristiyantanev@gmail.com',
          'martin.cvetkov@musala.com',
          'zdravko.zdravkin@musala.com'
        ]
        const userEmail = path(['info', 'email'], user)
        if (appIsGteVersion('4.5.1') && userEmail && whitelistedEmails.includes(userEmail)) {
          // prompt to review
          const HOW_LONG_TO_WAIT = 1000 * 5 // 5 seconds
          const HOW_MANY_OPENS_A_WEEK = 4
          const previousOpens = getStorage('howManyTimesOpened') || []
          const userPrompted = getStorage('userPrompted')
          const opensThisWeek = previousOpens.filter(o => o.time > Date.now() - 1000 * 60 * 60 * 24 * 7)
          opensThisWeek.push({ time: Date.now() })
          if (opensThisWeek.length >= HOW_MANY_OPENS_A_WEEK) {
            if (!userPrompted) {
              setTimeout(() => {
                setStorage('userPrompted', true)
                console.log('prompting')
                if (isIosApp()) {
                  window.webkit.messageHandlers.showRateMe.postMessage({})
                } else if (isAndroidApp()) {
                  window.ANDROID && window.ANDROID.showRateMe && window.ANDROID.showRateMe()
                }
              }, HOW_LONG_TO_WAIT)
            }
          } else {
            setStorage('howManyTimesOpened', opensThisWeek)
          }
        }
      } else if (key === 'product-details') {
        store.dispatch(setThing('appProductDetails', obj))
      } else if (key === 'purchase-flow-canceled') {
        store.dispatch(setThing('appPurchaseFlowCanceled', obj || true))
      } else if (key === 'payment-complete') {
        store.dispatch(setThing('paymentComplete', true))
        setTimeout(() => {
          store.dispatch(refreshUser())
          setTimeout(() => {
            store.dispatch(refreshUser())
          }, 1500)
        }, 1500)
      }
    }
  }
  if (isCordova()) {
    if (window.innerHeight <= 736 || window.innerWidth <= 736) {
      window.screen.orientation.lock('portrait')
    }
    document.addEventListener('pause', () => {
    })
    document.addEventListener('resume', () => {
    })
    document.addEventListener('offline', () => {
      store.dispatch(setOnline(false))
    }, false)
    document.addEventListener('online', () => {
      store.dispatch(setOnline(true))
    }, false)
  } else {
    window.addEventListener('offline', () => {
      store.dispatch(setOnline(false))
    }, false)
    window.addEventListener('online', () => {
      store.dispatch(setOnline(true))
    }, false)
  }
}
