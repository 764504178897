import React, { PureComponent } from 'react';
import { Line, YAxis } from 'recharts'
import PropTypes from 'prop-types';
import { contains } from 'ramda'
import CommonGraph from './CommonGraph'
import { rowsHave, windDirLabel, getSuffForUser, getLabel } from '../../common/ambient'

export default class WindDirectionGraph extends PureComponent {
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    graphData: PropTypes.array,
    mode: PropTypes.string,
    activeGraphs: PropTypes.array,
    user: PropTypes.object,
  };

  render() {
    const { user, width, height, graphData, mode, activeGraphs } = this.props
    const isRefined = graphData[0] && graphData[0].refined
    const lines = []
    const getSuff = getSuffForUser(user)
    const hasAvg = rowsHave(graphData, 'winddirAvg')
    if (contains('winddir', activeGraphs)) {
      lines.push(
        <Line
          key="winddir"
          type="monotone"
          dataKey={"winddir" + (hasAvg ? 'Avg' : '')}
          stroke="#2c9ce6"
          strokeWidth={0}
          fill="#2c9ce6"
          isAnimationActive={false}
          name={(hasAvg ? 'Dominant ' : '') + getLabel('winddir')}
          unit={getSuff('winddir')}
          legendType="circle"
        />
      )
    }
    return ( lines.length < 1 ? <div className="hidden" /> :
      <CommonGraph
        width={width}
        height={height}
        mode={mode}
        onClick={this.props.onClick}
        graphData={graphData}
        id={'winddir'}
      >
        <YAxis
          dataKey="y"
          orientation="left"
          type="number"
          axisLine={false}
          domain={[0, 360]}
          tickFormatter={windDirLabel}
        />
        {lines}
        {this.props.children}
      </CommonGraph>
    )
  }
}

WindDirectionGraph.displayName = 'WindDirectionGraph'

WindDirectionGraph.displayName = 'WindDirectionGraph'

WindDirectionGraph.displayName = 'WindDirectionGraph'

WindDirectionGraph.displayName = 'WindDirectionGraph'

WindDirectionGraph.displayName = 'WindDirectionGraph'

WindDirectionGraph.displayName = 'WindDirectionGraph'

WindDirectionGraph.displayName = 'WindDirectionGraph'
