import {
  CONFIRM_USER_BEGIN,
  CONFIRM_USER_SUCCESS,
  CONFIRM_USER_FAILURE,
  CONFIRM_USER_DISMISS_ERROR,
} from './constants';
import { app } from '../../../common/feathers'

export function confirmUser(key) {
  return (dispatch) => {
    dispatch({
      type: CONFIRM_USER_BEGIN,
    });
    const promise = new Promise((resolve, reject) => {
      const confirmService = app.service('confirm')
      return confirmService.create({
        key : key
      })
      .then(function (res){
        console.log(res);
        dispatch({
          type: CONFIRM_USER_SUCCESS,
          data: {},
        });
      })
      .catch(function (err){
        dispatch({
          type: CONFIRM_USER_FAILURE,
          data: {
            error: 'some error',
          },
        });
      })
    });

    return promise;
  };
}

export function dismissConfirmUserError() {
  return {
    type: CONFIRM_USER_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CONFIRM_USER_BEGIN:
      return {
        ...state,
        confirmUserPending: true,
        confirmUserError: null,
      };

    case CONFIRM_USER_SUCCESS:
      return {
        ...state,
        confirmUserPending: false,
        confirmUserError: null,
        confirmUserSuccess : true
      };

    case CONFIRM_USER_FAILURE:
      return {
        ...state,
        confirmUserPending: false,
        confirmUserError: action.data.error,
      };

    case CONFIRM_USER_DISMISS_ERROR:
      return {
        ...state,
        confirmUserError: null,
      };

    default:
      return state;
  }
}
