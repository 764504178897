import {
  PATCH_POST_BEGIN,
  PATCH_POST_SUCCESS,
  PATCH_POST_FAILURE,
  PATCH_POST_DISMISS_ERROR,
} from './constants';
import { servicePatch } from '../../../common/feathers'
import { updateArrayForObject } from '../../../common/ambient';


export const patchPost = servicePatch('posts', {
  begin: PATCH_POST_BEGIN,
  success: PATCH_POST_SUCCESS,
  fail: PATCH_POST_FAILURE
})


export function dismissPatchPostError() {
  return {
    type: PATCH_POST_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case PATCH_POST_BEGIN:
      const { data } = action
      state.allPosts = updateArrayForObject(state.allPosts, data)
      return {
        ...state,
        patchPostPending: true,
        patchPostError: null,
      };

    case PATCH_POST_SUCCESS:
      return {
        ...state,
        patchPostPending: false,
        patchPostError: null,
      };

    case PATCH_POST_FAILURE:
      return {
        ...state,
        patchPostPending: false,
        patchPostError: action.data.error,
      };

    case PATCH_POST_DISMISS_ERROR:
      return {
        ...state,
        patchPostError: null,
      };

    default:
      return state;
  }
}
