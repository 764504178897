import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import bindDeviceActions from './redux/bindDeviceActions'

class ShareButton extends PureComponent {
  static propTypes = {
    currentDevice: PropTypes.object,
    device: PropTypes.object,
    actions: PropTypes.object,
  }

  render() {
    const { actions, currentDevice } = this.props
    return (
      <div className="device-share-button">
        <a className="btn btn-green" onClick={() => actions.shareDevice(currentDevice)}><span>Share </span><i className="glyphicon glyphicon-share-alt"></i></a>
      </div>
    );
  }
}

export default bindDeviceActions(ShareButton)

ShareButton.displayName = 'ShareButton'

ShareButton.displayName = 'ShareButton'

ShareButton.displayName = 'ShareButton'

ShareButton.displayName = 'ShareButton'

ShareButton.displayName = 'ShareButton'

ShareButton.displayName = 'ShareButton'

ShareButton.displayName = 'ShareButton'
