/* istanbul ignore next */
export default function mapStateToProps (state) {
  return {
    device: state.device,
    user: state.user,
    alert: state.alert,
    social: state.social,
    common: state.common
  }
}
