import React, { PureComponent } from 'react'
import bindDeviceActions from './redux/bindDeviceActions'
import SummaryTable from './SummaryTable'
import { summaryDocsForDates, getSummaryDocDate, getTheDevice } from '../../common/ambient'
import PropTypes from 'prop-types'
import { reverse, keys, sort, prop, groupBy, pipe, prepend } from 'ramda'

class SummariesWrap extends PureComponent {
  static propTypes = {
    dates: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array
    ])
  }

  state = {
    pageI: 0,
    momentTz: moment
  }

  componentDidUpdate (prevProps) {
    if (prevProps.dates !== this.props.dates) {
      this.setState({ pageI: 0 })
    }
  }

  _pagination (months) {
    const { momentTz, pageI } = this.state
    if (months.length > 1) {
      return (
        <div className='pagination'>
          <span>View Month: </span>
          <select className='months' value={pageI} onChange={evt => this.setState({ pageI: evt.target.value })}>
            {
              reverse(months).map((m, i) => <option key={m} value={i}>{momentTz(m).format('MMM YYYY')}</option>)
            }
          </select>
        </div>
      )
    }
  }

  render () {
    const { activeFilters, dates, device } = this.props
    const { deviceSummaries } = device
    const { pageI, momentTz } = this.state
    if (!deviceSummaries || !dates) return <div />

    let allDocs = deviceSummaries
    const theDevice = getTheDevice(device)
    if (theDevice.lastData && theDevice.lastData.hl) {
      allDocs = prepend(theDevice.lastData.hl, allDocs)
    }
    const docs = summaryDocsForDates(momentTz, device, dates, allDocs)
    const groupedByMonth = groupBy(pipe(
      prop('dateutc'),
      getSummaryDocDate(momentTz, device),
      d => d.format('YYYY-MM-01')
    ))(docs)
    const months = pipe(
      keys,
      sort((a, b) => momentTz(a).valueOf() - momentTz(b).valueOf())
    )(groupedByMonth)
    const handleDateClick = (d) => {
      this.setState({
        pageI: months.length - 1 - months.indexOf(d.format('YYYY-MM-01'))
      })
    }

    const show = []
    if (docs.length < 1) {
      show.push(<div key={2}>No data for this date range</div>)
    } else if (docs.length === 1) {
      show.push(
        <SummaryTable
          key={1}
          docs={docs}
          details
          activeFilters={activeFilters}
          onCellClick={handleDateClick}
        />
      )
    } else {
      const showPagination = docs.length > 31
      const docsToShow = showPagination ? groupedByMonth[months[months.length - 1 - pageI]] : docs
      show.push(
        <SummaryTable
          key='summary'
          docs={docs}
          activeFilters={activeFilters}
          onCellClick={handleDateClick}
        />,
        <SummaryTable
          key='details'
          docs={docsToShow}
          allDocs={docs}
          activeFilters={activeFilters}
          details
        >{showPagination ? this._pagination(months) : null}
        </SummaryTable>
      )
    }

    return (
      <div className='device-summaries-wrap'>
        {show}
      </div>
    )
  }
}

export default bindDeviceActions(SummariesWrap)

SummariesWrap.displayName = 'SummariesWrap'
