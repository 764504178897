import React, { PureComponent } from 'react';
import { withRouter } from 'react-router'
import bindUserActions from './redux/bindUserActions';
import PropTypes from 'prop-types';
import R from 'ramda'
import HandlesProps from '../../common/handlesProps'
import { getUrlQueryParam, pathsChanged, debounce, log, goodEmail } from '../../common/ambient'
import TermsOfUse from '../../components/TermsOfUse'
import PasswordConfirmInputs from '../../components/PasswordConfirmInputs'
import Loader from '../../components/Loader'
import classNames from 'classnames'
import { getToken } from '../../common/skinner';


class Signup extends HandlesProps(PureComponent) {
  static propTypes = {
    email: PropTypes.string,
    onSuccess: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      email: props.email || '',
      password: '',
      username: '',
      usernameOk: false,
      termsOpen: false,
      termsApproved: false,
      plus13: true,
      marketing: true 
    }
    this.submit = ::this.submit
    this.handlePassword = ::this.handlePassword
    this.toggleTerms = ::this.toggleTerms
    this._checkUnique = debounce(this._checkUnique.bind(this), 600)
  }
  _email() {
    return getUrlQueryParam(this.props.location, 'email')
  }
  componentDidMount() {
    if (this._email()) {
      this.setState({
        email: this._email()
      })
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (pathsChanged(this.state, prevState, [['username']])) {
      this._checkUnique()
    }
  }
  toggleTerms(){
    this.setState({
      termsOpen: !this.state.termsOpen
    })
  }

  handlePassword(info){
    const { password } = info
    this.setState({
      password
    })
  }

  submit(e){
    e.preventDefault()
    let text = 'I agree to Ambient Weather\'s Terms of Use'
    if (!this.state.termsApproved && !window.confirm(text)) {
      return
    }
    this.state.submitted = true
    this.props.actions.createUser(this.state)
      .then(what => {
        if (what) {
          if (this.props.onSuccess) {
            this.props.onSuccess(this.state)
          } else {
            this.props.history.push('/where-to' + this.props.location.search)
          }
        }
      })
  }


  isOk(){
    const noEmpties = [
      'email',
      'password',
      'confirmPassword'
    ]
    const stateHasEmptyProp = R.pipe(
      R.flip(R.prop)(this.state),
      R.isEmpty
    )
    if (R.any(stateHasEmptyProp)(noEmpties)) return false
    if (!this.state.password) return false
    if (!goodEmail(this.state.email)) return false
    if (this.props.user.createUserPending) return false
    if (!this.state.usernameOk) return false
    if (!this.state.plus13) return false
    return true
  }
  _checkUnique() {
    const { actions } = this.props
    const { username } = this.state
    if (username.length < 5) {
      return this.setState({
        usernameOk: false,
        usernameMessage: 'Too short.'
      })
    }
    this.setState({
      loading: true,
      usernameOk: false
    })
    actions.fetchUsers({ 
      $select: ['username'],
      username,
      $limit: 1
    })
      .then(res => {
        const ok = !res[0].exists
        const st8 = {
          loading: false,
          usernameOk: ok
        }
        if (ok) {
          st8.usernameMessage = 'It\'s available!'
        } else {
          st8.usernameOk = false
          st8.usernameMessage = 'Sorry, that\'s taken.'
        }
        this.setState(st8)
      })
  }

  render() {
    const { user, children } = this.props
    const { usernameMessage, submitted, username, password, plus13, marketing } = this.state
    const { fetchUsersPending } = user
    let error, message, terms = ''
    if (submitted && user.createUserError) {
      switch (user.createUserError.name) {
        case 'Conflict':
          message = 'Email already exists'
          break;
      }
      if (message) {
        error = <div className="error">{message}</div>
      }
    }
    if(this.state.termsOpen) {
      terms =
        <div className="terms">
          <h2>Terms of Use</h2>
          <TermsOfUse />
          <div className="agree">
            <a className="btn btn-primary" onClick={() => {
              this.setState({
                termsOpen: false,
                termsApproved: true
              })
            }} >Agree</a>
            <a onClick={this.toggleTerms}>Disagree</a>
          </div>
        </div>
    }
    const val = user.createUserPending ? 'Creating Account...' : 'Create Account'
    return (
      <div className="user-signup">
        {terms}
        <form onSubmit={this.submit}>
          <h4>Email</h4>
          <input type="email" onChange={this.handleChangeProp('email')} defaultValue={this.state.email} />
          <h4>Handle <small>(min 5 characters)</small></h4>
          <div className='username-wrap'>
            <input 
              type="text" 
              onChange={evt => {
                const value = evt.target.value 
                const newUsername = value.replace(/[^A-Za-z0-9_]/g, '') 
                if (newUsername.length !== value.length) {
                  this.setState({ 
                    usernameMessage: 'Only letters, numbers and underscores are allowed'
                  })
                  this._checkUnique()
                  setTimeout(() => {
                    this.setState({
                      usernameMessage: '',
                    })
                  }, 4000)
                }
                this.setState({ 
                  username: newUsername
                })
              }}
              value={username} 
            />
            {fetchUsersPending ? <Loader /> : null}
          </div>
          {usernameMessage ? <p className={classNames('username-message',{
            ok: /avail/i.test(usernameMessage)
          })}>{usernameMessage}</p> : null}
          <PasswordConfirmInputs onChange={this.handlePassword} />
          <div className='plus-13'>
            <label>
              <input type="checkbox" checked={plus13} onChange={evt => this.setState({ plus13: evt.target.checked })} />
              <span>I certify that I am 13 years of age or older</span>
            </label>
          </div>
          <div className='plus-13'>
            <label>
              <input type="checkbox" checked={marketing} onChange={evt => this.setState({ marketing: evt.target.checked })} />
              <span>I agree to receive marketing emails from AWN</span>
            </label>
          </div>
          {error}
          {children}
          <div className='btn-wrap'>
            <input className="btn btn-primary" type="submit" disabled={!this.isOk()} value={val} />
          </div>
          <p className='terms-btn'>
            <a onClick={this.toggleTerms}>Terms and Conditions</a>
          </p>
        </form>
      </div>
    );
  }

}

export default bindUserActions(withRouter(Signup))

Signup.displayName = 'Signup'
