import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { dataHas } from '../../common/ambient'
import {
  FormattedDataPoint
} from './'

export default class DischargeRateWidget extends PureComponent {
  static propTypes = {
    currentDevice: PropTypes.object
  };

  render() {
    const { currentDevice } = this.props
    const { lastData } = currentDevice
    const data = lastData
    let widget = <div className="dischargerate-widget hidden" />
    const currentDataHas = dataHas(data)
    if(currentDataHas('dischargeft3s')) {
      widget =
      <div className="med-number dischargerate-widget">
        <FormattedDataPoint type={'dischargeft3s'} row={lastData} />
      </div>
    }
    return widget
  }
};
DischargeRateWidget.displayName = 'DischargeRateWidget'
