const initialState = {
  createAlertPending: false,
  createAlertError: null,
  fetchAlertsPending: false,
  fetchAlertsError: null,
  alerts: false,
  removeAlertPending: false,
  removeAlertError: null,
  fetchInstancesPending: false,
  fetchInstancesError: null,
  fetchInstancesPagination: null,
  alertInstances: [],
  fetchIftttAlertsPending: false,
  fetchIftttAlertsError: null,
  iftttAlerts: false,
};

export default initialState;
