import React, { PureComponent, Component } from 'react';
import { Link, withRouter } from 'react-router-dom'
import { ambient } from '../../common/lib/ambient-weather-common'
import PropTypes from 'prop-types';
import { path, range, pluck, filter, not, prop, clone, flip, pipe, sort, merge, values, keys, contains, mapObjIndexed } from 'ramda'
import { getUserSetting, getWidgetTitle, isRole, getUserUnitI, widgetExpanded, dataHasAny, getDeviceSetting, WIDGET_CONFIG, dataHas, mockData, deviceIsMine, pathsChanged, handleWidgetChange, noMaps, hasWebcam, getDeviceSharePath } from '../../common/ambient'
import bindDeviceActions from './redux/bindDeviceActions'
import { isPlus } from '../payment'
import {
  SolarWidget,
  Widget,
  TempWidget,
  WindWidget,
  RainWidget,
  PressureWidget,
  IndoorWidget,
  UvWidget,
  RelaysWidget,
  SensorsWidget,
  HumidityWidget,
  SoilWidget,
  BatteryWidget,
  QuickViewWidget,
  Air2Widget,
  Co2Widget,
  SunMoonWidget,
  MapWidget,
  ForecastWidget,
  LightningWidget,
  LeakWidget,
  WebcamWidget,
  SoilTensionWidget,
  LeafBinWidget,
  GrowDaysWidget,
  EvapoTransWidget,
  WbgtWidget,
  HeatIndexWidget,
  DischargeRateWidget,
  GaugeHeightWidget,
  WaterPhWidget,
  WaterNitratesWidget,
  CrosswindWidget,
  GddWidget,
  AirDensityWidget
} from './'
import GenericWidget from './GenericWidget';


class DeviceRealtimeDashboard extends Component {
  static propTypes = {
    currentDevice: PropTypes.object,
    device: PropTypes.object,
    user: PropTypes.object,
    actions: PropTypes.object,
    userActions: PropTypes.object,
    hideKeys: PropTypes.array
  }
  constructor(props) {
    super(props)
    this.handleWidgetChange = ::this.handleWidgetChange
    this.state = {
      mocks: false 
    }
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (pathsChanged(this.props, nextProps, [
      ['hideKeys'],
      ['currentDevice'],
      ['user', 'info'],
      ['device', 'deviceSummaries'],
      ['device', 'pressureWidgetLastHours'],
      ['device', 'yesterdayData']
    ])
      || this.state.mocks !== nextState.mocks
    ) {
      return true
    }
    return false
  }
  handleWidgetChange(change, key) {
    const { match, history, userActions, user, device, currentDevice, actions } = this.props
    // special case for user level dashboard minimizing (i.e. showing the graphs)
    if (change.dashboard) {
      userActions.updateSetting('dashboard', change.dashboard)
      actions.setThing('graphHash', key) // pass this to DeviceDataGraphWrap
      window.scrollTo(0, 0)
      history.push({ 
        pathname: getDeviceSharePath(currentDevice) + '/graphs'
      })
    }
    handleWidgetChange(userActions, user, device, currentDevice, actions, change, key)
  }
  _orderWidgets(widgets) {
    const { currentDevice } = this.props
    return pipe(
      sort((a, b) => {
        const aSetting = getDeviceSetting(a.key)(currentDevice) || {}
        const bSetting = getDeviceSetting(b.key)(currentDevice) || {}
        if (a.key === 'view' && aSetting.pinned) return -1
        if (a.key === 'view' && !aSetting.pinned) return 1
        if (b.key === 'view' && bSetting.pinned) return 1
        if (b.key === 'view' && !bSetting.pinned) return -1
        if (aSetting.expanded && !bSetting.expanded) return -1
        if (!aSetting.expanded && bSetting.expanded) return 1
        if (WIDGET_CONFIG[a.key].priority < WIDGET_CONFIG[b.key].priority) return -1
        if (WIDGET_CONFIG[a.key].priority > WIDGET_CONFIG[b.key].priority) return 1
        return 0
      })
    )(widgets)
  }
  _filterMinimizedWidgets(widgets, onlyMinimized) {
    const { currentDevice } = this.props
    return filter(d => {
      const setting = getDeviceSetting(d.key)(currentDevice) || {}
      if (onlyMinimized) return setting.minimized
      return !setting.minimized
    }, widgets)
  }

  render() {
    const { mocks } = this.state
    const { userActions, user, currentDevice, actions, device, hideKeys } = this.props

    let stats = 'There\'s no real-time data yet. Give us a sec...'
    if (currentDevice.lastData) {
      const d = new Date(currentDevice.lastData.dateutc)
      let deviceInUse = currentDevice

      // mocking data
      let mocksLabel = 'show mock data'
      if (mocks) {
        mocksLabel = 'hide mocks data'
        const mockDevice = clone(currentDevice)
        mockDevice.lastData = merge(currentDevice.lastData, mockData)
        deviceInUse = mockDevice
      }
      // end mocking data

      const currentDataHas = dataHas(deviceInUse.lastData)
      const currentDataHasAny = dataHasAny(deviceInUse.lastData)
      const isAKestrel = ambient.deviceIsAKestrel(deviceInUse)

      const dataPoints = values(mapObjIndexed((val, key) => {
        if (contains(key, keys(ambient.DATA_SPEC))) {
          return <span key={key}>{key} : {val}, </span>
        }
        return ''
      }, deviceInUse.lastData))
      let widgets = []

      if(isAKestrel) {
        widgets.push(
          <Widget hidden={!currentDataHas(['tempf'])}
            device={deviceInUse}
            onChange={this.handleWidgetChange}
            key={'tempkestrel'}
            type={'tempkestrel'}
            className='generic'
            notExpandable={true}
          >
            <GenericWidget currentDevice={deviceInUse} type={'tempf'} />
          </Widget>
        )
        widgets.push(
          <Widget hidden={!currentDataHas(['windspeedmph']) || currentDataHas(['winddir'])}
            device={deviceInUse}
            onChange={this.handleWidgetChange}
            key={'wind'}
            type={'wind'}
            className='generic'
            notExpandable={true}
          >
            <GenericWidget currentDevice={deviceInUse} type={'windspeedmph'} />
          </Widget>
        )
      // AWN only
      } else {
        widgets.push(
          <Widget hidden={!currentDataHas(['tempf'])} device={deviceInUse} onChange={this.handleWidgetChange} type={'temp'} key={'temp'}>
            <TempWidget
              fetchDeviceData={actions.fetchDeviceData}
              currentDevice={deviceInUse}
              device={device}
              user={user}
            />
          </Widget>
        )
        widgets.push(
          <Widget
            device={deviceInUse}
            onChange={this.handleWidgetChange}
            key={'view'}
            type={'view'}
            onPin={this.handleWidgetChange}
          >
            <QuickViewWidget
              currentDevice={deviceInUse}
              onChange={this.handleWidgetChange}
            />
          </Widget>
        )
        if (path(['info', 'coords', 'coords', 'lat'], deviceInUse)) {
          widgets.push(
            <Widget
            device={deviceInUse}
            onChange={this.handleWidgetChange}
            key={'sunMoon'}
            type={'sunMoon'}>
              <SunMoonWidget
                currentDevice={deviceInUse}
                device={device}
                user={user}
              />
            </Widget>
          )
          widgets.push(
            <Widget
              device={deviceInUse}
              onChange={this.handleWidgetChange}
              key={'forecast'}
              type={'forecast'}
            >
              <ForecastWidget
                currentDevice={deviceInUse}
              />
            </Widget>
          )
        }
        widgets.push(
          <Widget
            hidden={!currentDataHas(['windspeedmph', 'winddir'])}
            device={deviceInUse}
            onChange={this.handleWidgetChange}
            key={'wind'}
            type={'wind'}
            notExpandable={isAKestrel}
          >
            <WindWidget
              currentDevice={deviceInUse}
            />
          </Widget>
        )
      }

      if (isPlus(user) && 
        currentDataHasAny(['tempf', 'temp1f', 'temp2f', 'temp3f', 'temp4f', 'temp5f', 'temp6f', 'temp7f', 'temp8f']) &&
        deviceIsMine(device, deviceInUse._id)
      ) {
        widgets.push(
          <Widget
            device={deviceInUse}
            onChange={this.handleWidgetChange}
            key={'gdd2'}
            type={'gdd2'}
            extraButtons={<a className='glyphicon glyphicon-info-sign' href='https://ambientweather.com/faqs/question/view/id/6094/' target='_blank' />}
          >
            <GddWidget
              currentDevice={deviceInUse}
            />
          </Widget>
        )
        widgets.push(
          <Widget
            device={deviceInUse}
            onChange={this.handleWidgetChange}
            key={'cdd'}
            type={'cdd'}
            extraButtons={<a className='glyphicon glyphicon-info-sign' href='https://ambientweather.com/faqs/question/view/id/6097/' target='_blank' />}
          >
            <GddWidget
              currentDevice={deviceInUse}
              type='cdd'
            />
          </Widget>
        )
        widgets.push(
          <Widget
            device={deviceInUse}
            onChange={this.handleWidgetChange}
            key={'hdd'}
            type={'hdd'}
            extraButtons={<a className='glyphicon glyphicon-info-sign' href='https://ambientweather.com/faqs/question/view/id/6100/' target='_blank' />}
          >
            <GddWidget
              currentDevice={deviceInUse}
              type='hdd'
            />
          </Widget>
        )
      }



      widgets.push(
        <Widget
          hidden={!currentDataHasAny(['weeklyrainin', 'monthlyrainin', 'yearlyrainin', 'totalrainin', 'hourlyrainin', 'dailyrainin'])}
          device={deviceInUse}
          onChange={this.handleWidgetChange}
          key={'rain'}
          type={'rain'}
          notExpandable={!currentDataHasAny(['weeklyrainin', 'monthlyrainin', 'yearlyrainin', 'totalrainin'])}
        >
          <RainWidget
            currentDevice={deviceInUse}
          />
        </Widget>
      )

      widgets.push(
        <Widget
          hidden={!currentDataHas(['humidity'])}
          device={deviceInUse}
          onChange={this.handleWidgetChange}
          key={'humidity'}
          type={'humidity'}
          notExpandable={true}
        >
          <HumidityWidget
            currentDevice={deviceInUse}
            fetchDeviceData={actions.fetchDeviceData}
            device={device}
          />
        </Widget>
      )

      widgets.push(
        <Widget
          hidden={!currentDataHas(['baromrelin'])}
          device={deviceInUse}
          onChange={this.handleWidgetChange}
          key={'pressure'}
          type={'pressure'}
          notExpandable={true}
        >
          <PressureWidget
            fetchDeviceData={actions.fetchDeviceData}
            currentDevice={deviceInUse}
            device={device}
            unitI={getUserUnitI('baromrelin', user)}
          />
        </Widget>
      )

      widgets.push(
        <Widget hidden={!currentDataHas(['tempinf'])} device={deviceInUse} onChange={this.handleWidgetChange} key={'indoor'} type={'indoor'}>
          <IndoorWidget
            fetchDeviceData={actions.fetchDeviceData}
            currentDevice={deviceInUse}
            device={device}
            user={user}
          />
        </Widget>
      )

      widgets.push(
        <Widget
          hidden={!currentDataHas(['uv'])}
          device={deviceInUse}
          onChange={this.handleWidgetChange}
          key={'uv'}
          type={'uv'}
          notExpandable={true}
        >
          <UvWidget currentDevice={deviceInUse} />
        </Widget>
      )

      widgets.push(
        <Widget hidden={!currentDataHas(['solarradiation'])} device={deviceInUse} onChange={this.handleWidgetChange} key={'solar'} type={'solar'}>
          <SolarWidget
            fetchDeviceData={actions.fetchDeviceData}
            currentDevice={deviceInUse}
            device={device}
          />
        </Widget>
      )

      range(1, 9).forEach(i => {
        const leafWetParam = `leafwetness${i}`
        if (currentDataHas([leafWetParam])) {
          widgets.push(
            <Widget
              device={deviceInUse}
              onChange={this.handleWidgetChange}
              key={leafWetParam}
              type={leafWetParam}
              className='generic'
            >
              <GenericWidget currentDevice={deviceInUse} type={leafWetParam} />
            </Widget>
          )
        }
      })

      widgets.push(
        <Widget
          hidden={!currentDataHas(['wbgtf'])}
          device={deviceInUse}
          onChange={this.handleWidgetChange}
          key={'wbgt'}
          type={'wbgt'}
          notExpandable={false}
        >
          <WbgtWidget
            currentDevice={deviceInUse}
            fetchDeviceData={actions.fetchDeviceData}
            device={device}
          />
        </Widget>
      )

      widgets.push(
        <Widget
          hidden={!currentDataHas(['heatindexf'])}
          device={deviceInUse}
          onChange={this.handleWidgetChange}
          key={'heatindex'}
          type={'heatindex'}
          notExpandable={true}
        >
          <HeatIndexWidget
            currentDevice={deviceInUse}
            fetchDeviceData={actions.fetchDeviceData}
            device={device}
          />
        </Widget>
      )

      widgets.push(
        <Widget hidden={!currentDataHas(['pig'])}
          device={deviceInUse}
          onChange={this.handleWidgetChange}
          key={'pig'}
          type={'pig'}
          className='generic'
          notExpandable={true}
        >
          <GenericWidget currentDevice={deviceInUse} type={'pig'} />
        </Widget>
      )
      widgets.push(
        <Widget
          hidden={!currentDataHas(['dischargeft3s'])}
          device={deviceInUse}
          onChange={this.handleWidgetChange}
          key={'dischargerate'}
          type={'dischargerate'}
          notExpandable={true}
        >
          <DischargeRateWidget
            currentDevice={deviceInUse}
            fetchDeviceData={actions.fetchDeviceData}
            device={device}
          />
        </Widget>
      )
      widgets.push(
        <Widget
          hidden={!currentDataHas(['gaugeheightft'])}
          device={deviceInUse}
          onChange={this.handleWidgetChange}
          key={'gaugeheight'}
          type={'gaugeheight'}
          notExpandable={true}
        >
          <GaugeHeightWidget
            currentDevice={deviceInUse}
            fetchDeviceData={actions.fetchDeviceData}
            device={device}
          />
        </Widget>
      )
      widgets.push(
        <Widget
          hidden={!currentDataHas(['watermeasph'])}
          device={deviceInUse}
          onChange={this.handleWidgetChange}
          key={'waterph'}
          type={'waterph'}
          notExpandable={true}
        >
          <WaterPhWidget
            currentDevice={deviceInUse}
            fetchDeviceData={actions.fetchDeviceData}
            device={device}
          />
        </Widget>
      )
      widgets.push(
        <Widget
          hidden={!currentDataHas(['waternitratesmgl'])}
          device={deviceInUse}
          onChange={this.handleWidgetChange}
          key={'waternitrates'}
          type={'waternitrates'}
          notExpandable={true}
        >
          <WaterNitratesWidget
            currentDevice={deviceInUse}
            fetchDeviceData={actions.fetchDeviceData}
            device={device}
          />
        </Widget>
      )
      if (currentDataHas(['crosswindmph', 'headwindmph'])) {
          widgets.push(
            <Widget
              device={deviceInUse}
              onChange={this.handleWidgetChange}
              key={'crosswind'}
              type={'crosswind'}
            >
              <CrosswindWidget currentDevice={deviceInUse} />
            </Widget>
          )
      }
      if (currentDataHas(['airdensitylbft3', 'relativeairdensity'])) {
          widgets.push(
            <Widget
              device={deviceInUse}
              onChange={this.handleWidgetChange}
              key={'airdensity'}
              type={'airdensity'}
            >
              <AirDensityWidget currentDevice={deviceInUse} />
            </Widget>
          )
      }
      ambient.simpleWidgets.forEach(param => {
        if (currentDataHas([param])) {
          widgets.push(
            <Widget
              device={deviceInUse}
              onChange={this.handleWidgetChange}
              key={param}
              type={param}
              className='generic'
            >
              <GenericWidget currentDevice={deviceInUse} type={param} />
            </Widget>
          )
        }
      })

      range(1, 11).forEach((i) => {
        const tempKey = `temp${i}f`
        const humidityKey = `humidity${i}`
        const soilTempKey = `soiltemp${i}`
        const soilHumKey = `soilhum${i}`
        const soilTensKey = `soiltens${i}`
        if (currentDataHasAny([tempKey, humidityKey])) {
          widgets.push(
            <Widget
              hidden={!currentDataHasAny([tempKey, humidityKey])}
              device={deviceInUse}
              onChange={this.handleWidgetChange}
              key={tempKey}
              type={tempKey}
              className={{
                hum: currentDataHas([humidityKey])
              }}
            >
              <IndoorWidget
                fetchDeviceData={actions.fetchDeviceData}
                currentDevice={deviceInUse}
                device={device}
                user={user}
                sensor={i}
              />
            </Widget>
          )
        }
        if (currentDataHasAny([soilTempKey, soilHumKey])) {
          if (!currentDataHas([soilTensKey])) { // For KestrelAg suppress temp-only widgets
            widgets.push(
              <Widget
                device={deviceInUse}
                onChange={this.handleWidgetChange}
                key={soilTempKey}
                type={soilTempKey}
                notExpandable={true}
                className={{
                  soil: true,
                  hum: currentDataHas([soilHumKey])
                }}
              >
                <SoilWidget
                  currentDevice={deviceInUse}
                  sensor={i}
                />
              </Widget>
            )
          }
        }
        if (currentDataHas([soilTempKey, soilTensKey])) { // KestrelAg has these tied together
          widgets.push(
            <Widget
              device={deviceInUse}
              onChange={this.handleWidgetChange}
              key={soilTensKey}
              type={soilTensKey}
              notExpandable={true}
              className={{
                soil: true
              }}
            >
              <SoilTensionWidget
                currentDevice={deviceInUse}
                sensor={i}
              />
            </Widget>
          )
        }
      })

      widgets.push(
        <Widget 
          hidden={!currentDataHas(['leafwet1x'])} 
          device={deviceInUse} 
          onChange={this.handleWidgetChange} 
          key={'leafwet1x'} 
          type={'leafwet1x'}
          notExpandable={true}
          className={'leafwet1x'}
        >
          <LeafBinWidget
            fetchDeviceData={actions.fetchDeviceData}
            currentDevice={deviceInUse}
            device={device}
            notExpandable={true}
          />
        </Widget>
      )

      widgets.push(
        <Widget 
          hidden={!currentDataHas(['gdd'])} 
          device={deviceInUse} 
          onChange={this.handleWidgetChange} 
          key={'gdd'} 
          type={'gdd'}
          notExpandable={true}
          className={'gdd'}
        >
          <GrowDaysWidget
            fetchDeviceData={actions.fetchDeviceData}
            currentDevice={deviceInUse}
            device={device}
            notExpandable={true}
          />
        </Widget>
      )

      widgets.push(
        <Widget hidden={!currentDataHasAny(['etos', 'etrs'])}
         device={deviceInUse}
         onChange={this.handleWidgetChange}
         key={'etrs'}
         type={'etrs'}
         notExpandable={true}
         classname={'etrs'}
        >
          <EvapoTransWidget
            fetchDeviceData={actions.fetchDeviceData}
            currentDevice={deviceInUse}
            device={device}
            user={user}
          />
        </Widget>
      )


      widgets.push(
        <Widget hidden={!currentDataHasAny(WIDGET_CONFIG.relays.fields)} device={deviceInUse} onChange={this.handleWidgetChange} key={'relays'} type={'relays'}>
          <RelaysWidget
            currentDevice={deviceInUse}
            onChange={this.handleWidgetChange}
          />
        </Widget>
      )

      widgets.push(
        <Widget
          hidden={!currentDataHasAny(WIDGET_CONFIG.battery.fields)}
          device={deviceInUse}
          onChange={this.handleWidgetChange}
          key={'battery'}
          type={'battery'}>
          <BatteryWidget
            currentDevice={deviceInUse}
            device={device}
          />
        </Widget>
      )
      widgets.push(
        <Widget hidden={!isRole('dev', user)} device={deviceInUse} onChange={this.handleWidgetChange} key={'stats'} type={'stats'}>
          <div className="wrap">
            <Link to="/">Map</Link>
            Last updated: {d.toString()} <br />
            <a
              tabIndex="-1"
              onClick={() => {
                this.setState({
                  mocks: !this.state.mocks
                })
              }}
            >{mocksLabel}</a>
            {widgetExpanded('stats', deviceInUse) ? dataPoints : ''}
          </div>
        </Widget>
      )

      if (currentDataHasAny(['aqi_pm25_aqin'])) {
        widgets.push(
          <Widget
            device={deviceInUse}
            onChange={this.handleWidgetChange}
            key={'aqin'}
            type={'aqin'}
          >
            <Air2Widget
              currentDevice={deviceInUse}
            />
          </Widget>
        )
      }

      if (currentDataHasAny(['pm25'])) {
        widgets.push(
          <Widget
            device={deviceInUse}
            onChange={this.handleWidgetChange}
            key={'air'}
            type={'air'}
          >
            <Air2Widget
              type='air'
              currentDevice={deviceInUse}
            />
          </Widget>
        )
      }
      if (currentDataHasAny(['pm25_in'])) {
        widgets.push(
          <Widget
            device={deviceInUse}
            onChange={this.handleWidgetChange}
            key={'airin'}
            type={'airin'}
          >
            <Air2Widget
              currentDevice={deviceInUse}
              type={'airin'}
            />
          </Widget>
        )
      }

      if (currentDataHasAny(['co2_in'])) {
        widgets.push(
          <Widget
            device={deviceInUse}
            onChange={this.handleWidgetChange}
            key={'co2'}
            type={'co2'}
          >
            <Co2Widget
              type='co2_in'
              currentDevice={deviceInUse}
            />
          </Widget>
        )
      }
      if (currentDataHasAny(['co2_in_aqin'])) {
        widgets.push(
          <Widget
            device={deviceInUse}
            onChange={this.handleWidgetChange}
            key={'co2'}
            type={'co2'}
          >
            <Co2Widget
              currentDevice={deviceInUse}
            />
          </Widget>
        )
      }

      if (currentDataHasAny(['lightning_day'])) {
        widgets.push(
          <Widget
            device={deviceInUse}
            onChange={this.handleWidgetChange}
            key={'lightning'}
            type={'lightning'}
          >
            <LightningWidget
              currentDevice={deviceInUse}
            />
          </Widget>
        )
      }

      if (currentDataHasAny(WIDGET_CONFIG.leak.fields)) {
        widgets.push(
          <Widget
            device={deviceInUse}
            onChange={this.handleWidgetChange}
            key={'leak'}
            type={'leak'}
          >
            <LeakWidget
              onChange={this.handleWidgetChange}
              currentDevice={deviceInUse}
            />
          </Widget>
        )
      }


      // put in priority order
      if (hideKeys.length > 0) {
        const notInHideKeys = pipe(
          prop('key'),
          flip(contains)(hideKeys),
          not
        )
        widgets = filter(notInHideKeys, widgets)
      }
      const orderedWidgets = this._orderWidgets(widgets)
      const minimizedWidgets = this._filterMinimizedWidgets(orderedWidgets, true)
      widgets = this._filterMinimizedWidgets(orderedWidgets)
      let minimized
      if (minimizedWidgets.length > 0) {
        minimized = <div className="minimized">
          <div className="label">Add back to Dashboard: </div>
          {minimizedWidgets.map(w => <a title={getWidgetTitle(deviceInUse, w.key)} key={w.key + '-a'} onClick={() => {
            this.handleWidgetChange({ minimized: false }, w.key)
          }}>{w}</a>)}
        </div>
      }
      const flipKey = widgets.map(w => w.key).join('-')

      stats = <div>
        <div className="board clearfix compact">
          {widgets}
        </div>
        {minimized}
      </div>
    }
    return (
      <div className="device-device-realtime-dashboard">
        {stats}
      </div>
    )
  }
}

export default bindDeviceActions(withRouter(DeviceRealtimeDashboard))

DeviceRealtimeDashboard.displayName = 'DeviceRealtimeDashboard'
