import { useCallback } from 'react'
import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import {
  COMMON_SET_ONLINE
} from './constants'

export function setOnline (data) {
  return {
    type: COMMON_SET_ONLINE,
    data
  }
}

export function useSetOnline () {
  const dispatch = useDispatch()
  const boundAction = useCallback((...params) => dispatch(setOnline(...params)), [dispatch])
  const st8Obj = useSelector(state => ({ online: state.common.online }), shallowEqual)
  return Object.assign({ setOnline: boundAction }, st8Obj)
}

export function reducer (state, action) {
  switch (action.type) {
    case COMMON_SET_ONLINE:
      return {
        ...state,
        online: action.data
      }

    default:
      return state
  }
}
