import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { path } from 'ramda' 
import { getDeviceLabel } from '../../common/lib/ambient-weather-common/ambient'
import { coordsAreDifferent, makeSureObjHasGeo, getUserUnitI, latLonDistance, METERS_TO_FEET } from '../../common/ambient'
import bindDeviceActions from './redux/bindDeviceActions'
import bindUserActions from '../user/redux/bindUserActions'
import FormattedDataPoint from './FormattedDataPoint'
import {
  Loader, QuestionMark
} from '../../components'

const hasElevation = path(['info', 'coords', 'elevation'])

function BarometerInfo ({ currentDevice, actions, user }) {
  const coords = path(['info', 'coords'], currentDevice)
  const [theStation, setTheStation] = useState(null)
  const localStations = useRef([])
  const lastCoords = useRef(null)
  useEffect(() => {
    if (!coords) {
      return
    }
    if (coordsAreDifferent(lastCoords.current, coords)) {
      lastCoords.current = coords
      const args = {
        $publicNear: {
          coords: makeSureObjHasGeo(coords).geo.coordinates
        },
        $limit: 15,
        skipCache: true,
        rank: 2
      }
      actions.fetchDevice(args)
        .then(res => {
          localStations.current = res.data.filter(d => /noaa/.test(d.macAddress) && path(['lastData', 'baromrelin'], d))
          setTheStation(localStations.current[0])
        })
    }
  }, [coords, lastCoords, actions])
  useEffect(() => {
    if (!theStation) {
      return
    }
    console.log(theStation)
  }, [theStation])
  if (!coords) {
    return null
  }
  let show = <Loader />
  if (theStation) {
    const dist = getUserUnitI('metric', user) === 1 ? latLonDistance(coords.coords.lat, coords.coords.lon, theStation.info.coords.coords.lat, theStation.info.coords.coords.lon, 'K').toFixed(1) + ' km' : latLonDistance(coords.coords.lat, coords.coords.lon, theStation.info.coords.coords.lat, theStation.info.coords.coords.lon).toFixed(1) + ' mi'
    let elev = null
    if (hasElevation(theStation) && hasElevation(currentDevice)) {
      elev = <> and a difference in elevation of {Math.floor(Math.abs(hasElevation(theStation) - hasElevation(currentDevice) * METERS_TO_FEET))} feet</>
    }
    show = (
      <p>
        We found an official station "{getDeviceLabel(theStation)}" {dist} away{elev}. You can use its relative pressure: <FormattedDataPoint row={theStation.lastData} type='baromrelin' skipConvert /> to configure yours.
        &nbsp;&nbsp;
        <a onClick={() => {
          const i = localStations.current.indexOf(theStation)
          let newI = i + 1
          if (newI === localStations.current.length) {
            newI = 0
          }
          setTheStation(localStations.current[newI])
        }}>Try another</a>
      </p>
    )
  }
  return (
    <div className='device-barometer-info item'>
      <label>Barometer Calibration <QuestionMark link='https://ambientweather.com/faqs/question/view/id/1593/' /></label>
      {show}
    </div>
  )
}

BarometerInfo.propTypes = {
  currentDevice: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired, // deviceActions
}
BarometerInfo.defaultProps = {}

export default bindUserActions(bindDeviceActions(BarometerInfo))

BarometerInfo.displayName = 'BarometerInfo'

