import React from 'react'
// import PropTypes from 'prop-types';

export default function Tags ({ tags, onRemove }) {
  return (
    <span className='common-tags'>
      {tags.map((tag, i) => (
        <span key={i} className='tag'>
          {typeof tag === 'string' ? tag : tag.label}
          <a className='x' onClick={() => onRemove(typeof tag === 'string' ? tag : tag.value)}>
            x
          </a>
        </span>
      ))}
    </span>
  )
};

Tags.propTypes = {}
Tags.defaultProps = {}

Tags.displayName = 'Tags'
