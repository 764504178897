export const USER_TEST_ACTION = 'USER_TEST_ACTION';
export const FETCH_USER_BEGIN = 'FETCH_USER_BEGIN';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';
export const FETCH_USER_DISMISS_ERROR = 'FETCH_USER_DISMISS_ERROR';
export const CREATE_USER_BEGIN = 'CREATE_USER_BEGIN';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';
export const CREATE_USER_DISMISS_ERROR = 'CREATE_USER_DISMISS_ERROR';
export const LOGOUT_USER_BEGIN = 'LOGOUT_USER_BEGIN';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_FAILURE = 'LOGOUT_USER_FAILURE';
export const LOGOUT_USER_DISMISS_ERROR = 'LOGOUT_USER_DISMISS_ERROR';
export const CONFIRM_USER_BEGIN = 'CONFIRM_USER_BEGIN';
export const CONFIRM_USER_SUCCESS = 'CONFIRM_USER_SUCCESS';
export const CONFIRM_USER_FAILURE = 'CONFIRM_USER_FAILURE';
export const CONFIRM_USER_DISMISS_ERROR = 'CONFIRM_USER_DISMISS_ERROR';
export const UPDATE_SETTING = 'UPDATE_SETTING';
export const SEND_CONFIRMATION_BEGIN = 'SEND_CONFIRMATION_BEGIN';
export const SEND_CONFIRMATION_SUCCESS = 'SEND_CONFIRMATION_SUCCESS';
export const SEND_CONFIRMATION_FAILURE = 'SEND_CONFIRMATION_FAILURE';
export const SEND_CONFIRMATION_DISMISS_ERROR = 'SEND_CONFIRMATION_DISMISS_ERROR';
export const PATCH_BEGIN = 'USER_PATCH_BEGIN';
export const PATCH_SUCCESS = 'USER_PATCH_SUCCESS';
export const PATCH_FAILURE = 'USER_PATCH_FAILURE';
export const PATCH_DISMISS_ERROR = 'USER_PATCH_DISMISS_ERROR';
export const FETCH_USERS_BEGIN = 'FETCH_USERS_BEGIN';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';
export const FETCH_USERS_DISMISS_ERROR = 'FETCH_USERS_DISMISS_ERROR';
export const BECOME_USER = 'BECOME_USER';
export const FETCH_ALEXA_DATA_BEGIN = 'FETCH_ALEXA_DATA_BEGIN';
export const FETCH_ALEXA_DATA_SUCCESS = 'FETCH_ALEXA_DATA_SUCCESS';
export const FETCH_ALEXA_DATA_FAILURE = 'FETCH_ALEXA_DATA_FAILURE';
export const FETCH_ALEXA_DATA_DISMISS_ERROR = 'FETCH_ALEXA_DATA_DISMISS_ERROR';
export const CLEAR_USERS = 'CLEAR_USERS';
export const FETCH_INFO_BEGIN = 'FETCH_INFO_BEGIN';
export const FETCH_INFO_SUCCESS = 'FETCH_INFO_SUCCESS';
export const FETCH_INFO_FAILURE = 'FETCH_INFO_FAILURE';
export const FETCH_INFO_DISMISS_ERROR = 'FETCH_INFO_DISMISS_ERROR';
export const DO_MODAL = 'DO_MODAL';
export const SET_UNITS = 'SET_UNITS';
export const CREATE_SHORT_URL_BEGIN = 'CREATE_SHORT_URL_BEGIN';
export const CREATE_SHORT_URL_SUCCESS = 'CREATE_SHORT_URL_SUCCESS';
export const CREATE_SHORT_URL_FAILURE = 'CREATE_SHORT_URL_FAILURE';
export const CREATE_SHORT_URL_DISMISS_ERROR = 'CREATE_SHORT_URL_DISMISS_ERROR';
export const USER_REFRESH_USER_BEGIN = 'USER_REFRESH_USER_BEGIN';
export const USER_REFRESH_USER_SUCCESS = 'USER_REFRESH_USER_SUCCESS';
export const USER_REFRESH_USER_FAILURE = 'USER_REFRESH_USER_FAILURE';
export const USER_REFRESH_USER_DISMISS_ERROR = 'USER_REFRESH_USER_DISMISS_ERROR';
export const USER_SET_APP_DEVICE = 'USER_SET_APP_DEVICE';
