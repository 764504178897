import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {
  FormattedDataPoint
} from "./"

export default class Co2Widget extends PureComponent {
  static propTypes = {
    currentDevice: PropTypes.object.isRequired,

  }

  render() {
    const { currentDevice, type = 'co2_in_aqin' } = this.props
    const co2Bands = [{
      cutoff: [0, 600],
      label: 'Healthy',
      distance: [0, 18.6]
    }, {
      cutoff: [601, 1000],
      label: 'Moderate',
      distance: [18.6, 31]
    },{
      cutoff: [1001, 1500],
      label: 'Unhealthy for Sensitive Groups',
      distance: [32, 47.5]
    },{
      cutoff: [1501, 2000],
      label: 'Unhealthy',
      distance: [47.5, 64]
    }, {
      cutoff: [2001, 2500],
      label: 'Very Unhealthy',
      distance: [64, 80]
    }, {
      cutoff: [2501, 5000],
      label: 'Hazardous',
      distance: [80, 96]
    }]
    const stuffForPpm = value => {
      let v = value > 5000 ? 5000 : value
      const co2Band = co2Bands.find(band => v <= band.cutoff[1])
      const distance = co2Band.distance[0] + (v - co2Band.cutoff[0]) * (co2Band.distance[1] - co2Band.distance[0]) / (co2Band.cutoff[1] - co2Band.cutoff[0])
      return {
        ...co2Band,
        distance,
        v
      }
    }
    const stuff = stuffForPpm(currentDevice.lastData[type])
    let bottoms = [
      <div key='temp'>
        Temp
        <FormattedDataPoint type='pm_in_temp_aqin' row={currentDevice.lastData} suff="°" />
      </div>,
      <div key='humidity'>
        Humidity
        <FormattedDataPoint type='pm_in_humidity_aqin' row={currentDevice.lastData} />
      </div>
    ]
    if (type === 'co2_in') {
      bottoms = <div>
        24h 
        <FormattedDataPoint type='co2_in_24h' row={currentDevice.lastData} />
      </div>
    }
    return (
      <div className="device-co-2-widget">
        <div className='value'>
          CO2
          <FormattedDataPoint type={type} row={currentDevice.lastData} />
        </div>
        <div className='gauge'>
          <div className='dot' style={{ marginLeft: `${stuff.distance}%` }} />
        </div>
        <div className={classNames('label', stuff.label.toLowerCase().replace(/ /g, '-'))}>
          {stuff.label}
        </div>
        <div className='bottom'>
          {bottoms}
        </div>
      </div>
    )
  }
}

Co2Widget.displayName = 'Co2Widget'

Co2Widget.displayName = 'Co2Widget'

Co2Widget.displayName = 'Co2Widget'

Co2Widget.displayName = 'Co2Widget'

Co2Widget.displayName = 'Co2Widget'

Co2Widget.displayName = 'Co2Widget'

Co2Widget.displayName = 'Co2Widget'
