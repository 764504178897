import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import bindDeviceActions from './redux/bindDeviceActions'
import Modal from 'react-modal'
import { generalModalStyle, isIos, getDeviceLabel, getDeviceShareLink, toggleDevicePublic, getDeviceEmbedLink } from '../../common/ambient'
import AmbRadio from '../../components/AmbRadio'
import md5 from 'blueimp-md5'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import classNames from 'classnames'
import {
  ToggleDevicePublicBtn
} from './'

class ShareDeviceModal extends PureComponent {
  static propTypes = {
    device: PropTypes.object,
    actions: PropTypes.object,
    bodyOnly: PropTypes.bool,
    currentDevice: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.state = {
      inputCopied: false,
      textareaCopied: false,
      width: 500,
      height: 400
    }
  }

  _getDevice () {
    const { currentDevice, device } = this.props
    return currentDevice || device.shareDevice
  }

  updateDevicePublic (shareDevice) {
    const { currentDevice, actions } = this.props
    actions.patch(shareDevice._id, { public: shareDevice.public })
    if (!currentDevice) actions.shareDevice(shareDevice)
  }

  render () {
    const { bodyOnly, actions } = this.props
    const shareDevice = this._getDevice()
    const { width, height, inputCopied, textareaCopied } = this.state
    const closeModal = () => actions.shareDevice(false)

    let modal = ''
    let body
    if (shareDevice) {
      body = (
        <div>
          <p className='first'>&nbsp;</p>
          <p>
            Your sensor data is currently private. Click below to allow others to view your sensor dashboard.
          </p>
          <p>
            For your own privacy, you can choose to share your outdoor conditions only. 
          </p>
          <ToggleDevicePublicBtn currentDevice={this._getDevice()} />
        </div>
      )
      const fieldCopied = (field) => {
        return () => {
          const s = {}
          s[field] = true
          this.setState(s)
          setTimeout(() => {
            s[field] = false
            this.setState(s)
          }, 2000)
        }
      }
      if (shareDevice.public) {
        const link = getDeviceShareLink(shareDevice)
        const iframeCode = `<iframe width="${width}" height="${height}" frameborder="0" src="${getDeviceEmbedLink(shareDevice)}"></iframe><p>powered by <a target="_blank" href="https://ambientweather.net">Ambient Weather</a></p>`
        const selectAll = evt => evt.target.select()
        body = (
          <div>
            <p className="first">&nbsp;</p>
            <p>
              Your device dashboard is <span className="public">public</span>.
            </p>
            <p>
              {shareDevice.public.indoorPublic ? <span>All data for this device is <span className="public">public</span>.<br/></span> : 'Only your Outdoor Data is shared from your dashboard. To share your indoor and other sensor data as well ' } 
              <a onClick={() => {
                shareDevice.public.indoorPublic = !shareDevice.public.indoorPublic
                this.updateDevicePublic(shareDevice)
              }}> {shareDevice.public.indoorPublic ? 'Click here to make your indoor and other sensor data private' : 'click here' }</a>
            </p>
            <ToggleDevicePublicBtn currentDevice={this._getDevice()} />
            <label>Public Dashboard Link</label>
            <div className="line">
              <input type="text" className="form-control" readOnly={true} value={link} onFocus={selectAll} />
              <CopyToClipboard
                text={link}
                onCopy={fieldCopied('inputCopied')}
              >
                <a className="btn btn-primary" >{inputCopied ? 'Copied!' : 'Copy'}</a>
              </CopyToClipboard>
            </div>
            <label>Iframe Embed Code</label>
            <textarea onFocus={selectAll} readOnly={true} value={iframeCode} />
            <div className="line">
              <div className="same">
                <label>Iframe Width (pixels)</label>
                <input type="text" onChange={(evt) => this.setState({ width: evt.target.value})} value={width} />
              </div>
              <div className="same">
                <label>Iframe Height (pixels)</label>
                <input type="text" onChange={(evt) => this.setState({ height: evt.target.value})} value={height} />
              </div>
            </div>
            <CopyToClipboard
              text={iframeCode}
              onCopy={fieldCopied('textareaCopied')}
            >
              <a className="btn btn-primary w100" >{textareaCopied ? 'Copied!' : 'Copy Embed Code'}</a>
            </CopyToClipboard>
          </div>
        )
      }
      modal = (
        <Modal
          portalClassName={classNames(
            'device-share-device-modal',
            'general-modal',
            {
              ios: isIos()
            }
          )}
          contentLabel={'Share Device Modal'}
          isOpen={!!shareDevice}
          onRequestClose={closeModal}
          style={generalModalStyle}
        >
          <a className="close" onClick={closeModal}></a>
          <h2>
            Share <em>{shareDevice.info.name || shareDevice.macAddress}</em>
          </h2>
          <div className="body">
            {body}
          </div>
        </Modal>
      )
    }
    return (
      <div className='device-share-device-modal'>
        {bodyOnly ? body : modal}
      </div>
    )
  }
}

export default bindDeviceActions(ShareDeviceModal)

ShareDeviceModal.displayName = 'ShareDeviceModal'
