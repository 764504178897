import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import bindUserActions from './redux/bindUserActions'
import { getUrlQueryParam, isLoggedIn } from '../../common/ambient'
import {
  TermsOfUse,
  HelpLinks
} from '../../components/'
import {
  Signup
} from './'
import { getThemeObj } from '../../common/skinner'

export class DefaultPage extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired
  }

  state = {
    terms: false,
    view: 'home'
  }

  _email () {
    return getUrlQueryParam(this.props.location, 'email')
  }

  _ref () {
    return getUrlQueryParam(this.props.location, 'ref')
  }

  _code () {
    return getUrlQueryParam(this.props.location, 'code')
  }

  _macAddress () {
    return getUrlQueryParam(this.props.location, 'macAddress') || getUrlQueryParam(this.props.location, 'mac')
  }

  _checkLoggedIn () {
    const { user, actions } = this.props
    const { fetchUserPending } = user
    if (!fetchUserPending && isLoggedIn(user)) {
      let goTo = '/dashboard'
      if (this._macAddress() || this._ref()) {
        goTo = '/devices?'
        if (this._macAddress()) {
          goTo += `macAddress=${this._macAddress()}`
        }
        if (this._ref()) {
          goTo += `&ref=${this._ref()}`
        }
      } else if (this._code()) {
        actions.doModal({
          type: 'plus-subscription',
          data: {
            code: this._code(),
            redeemCode: user.plus
          }
        })
      }
      this.props.history.replace(goTo)
      return true
    }
  }

  componentDidMount () {
    if (!this._checkLoggedIn() && this._email()) {
      this.setState({ view: 'signup' })
    }
  }

  componentDidUpdate (nextProps) {
    this._checkLoggedIn()
  }

  render () {
    const { view, terms } = this.state
    let t = <a className='open-terms' onClick={() => this.setState({ terms: true })}>Terms and Conditions</a>
    if (terms) {
      t = (
        <div className='terms'>
          <a className='close' onClick={() => this.setState({ terms: false })} />
          <h2>Terms Of Use</h2>
          <TermsOfUse />
        </div>
      )
    }
    let createLink = <Link className='btn btn-primary btn-lg white' to='/devices'>Create Your Account</Link>
    if (!/add-device/.test(window.location.href)) {
      createLink = <a onClick={() => this.setState({ view: 'signup' })} className='btn btn-primary btn-lg white'>Create Your Account</a>
    }
    let show = (
      <div className='jumbotron'>
        <h1>{getThemeObj().sitename}</h1>
        <p>{getThemeObj().tagline2}</p>
        <p>{createLink}</p>
        <p><Link className='btn btn-primary btn-lg' to={`/signin${window.location.search}`}>Login</Link></p>
        {t}
      </div>
    )
    if (view === 'signup') {
      show = <Signup />
    }
    return (
      <div className='user-default-page'>
        {show}
        <HelpLinks />
      </div>
    )
  }
}

export default bindUserActions(withRouter(DefaultPage))

DefaultPage.displayName = 'DefaultPage'
