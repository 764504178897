import {
  FETCH_FORECAST_BEGIN,
  FETCH_FORECAST_CLEAR,
  FETCH_FORECAST_SUCCESS,
  FETCH_FORECAST_FAILURE,
  FETCH_FORECAST_DISMISS_ERROR
} from './constants';
import { coordString, isDev } from '../../../common/ambient'
import { path } from 'ramda'

let outstandingRequest = []
export function fetchForecast (args) {
  return (dispatch, getState) => {
    const { lat, lon, force } = args
    const data = {
      lat,
      lon
    }
    // check cache
    const { forecastCache } = getState().device
    const forecast = forecastCache[coordString(lat, lon)]
    if (forecast && !force && forecast.lat === lat && forecast.lon === lon) {
      // check time on it
      const time = path(['currently', 'time'], forecast)
      if (time && Date.now() - time * 1000 < 1000 * 60 * 15) { // 15 minutes
        return Promise.resolve(forecast)
      }
    }

    const queryParams = []
    if (isDev()) {
      queryParams.push('ios=K6NGQaZTI3d6JabLcSGX25Y41BuBQPkVcJmeNHibVNhJ4k')
    }
    if (args.hourlyPressure) {
      queryParams.push('hourlyPressure=true')
    }
    const url = `https://lightning.ambientweather.net/forecast/${lat}/${lon}${queryParams.length ? '?' + queryParams.join('&') : ''}`
    // const url = `http://localhost:3030/forecast/${lat}/${lon}${queryParams.length ? '?' + queryParams.join('&') : ''}`
    if (outstandingRequest.includes(url)) {
      return
    }
    outstandingRequest.push(url)

    dispatch({
      type: FETCH_FORECAST_BEGIN,
      data
    })
    return fetch(url)
      .then(res => res.json())
      .then(forecast => {
        forecast.lat = lat
        forecast.lon = lon
        outstandingRequest = outstandingRequest.filter(u => u !== url)
        dispatch({
          type: FETCH_FORECAST_SUCCESS,
          data: forecast
        })
        return forecast
      })
      .catch(err => {
        outstandingRequest = outstandingRequest.filter(u => u !== url)
        dispatch({
          type: FETCH_FORECAST_FAILURE,
          data: {
            error: err
          }
        })
      })
  }
}

export function clearForecast () {
  return {
    type: FETCH_FORECAST_CLEAR
  }
}

export function dismissFetchForecastError() {
  return {
    type: FETCH_FORECAST_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FETCH_FORECAST_BEGIN:
      return {
        ...state,
        fetchForecastPending: true,
        fetchForecastError: null,
      };

    case FETCH_FORECAST_CLEAR:
      return {
        ...state,
        forecastCache: {}
      }

    case FETCH_FORECAST_SUCCESS:
      const newForecast = state.forecastCache
      newForecast[coordString(action.data.lat, action.data.lon)] = action.data
      return {
        ...state,
        fetchForecastPending: false,
        fetchForecastError: null,
        forecastCache: newForecast
      };

    case FETCH_FORECAST_FAILURE:
      return {
        ...state,
        fetchForecastPending: false,
        fetchForecastError: action.data.error,
      };

    case FETCH_FORECAST_DISMISS_ERROR:
      return {
        ...state,
        fetchForecastError: null,
      };

    default:
      return state;
  }
}
