import {
  SHARE_DEVICE,
} from './constants';

export function shareDevice(data) {
  return {
    type: SHARE_DEVICE,
    data
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case SHARE_DEVICE:
      return {
        ...state,
        shareDevice: action.data
      };

    default:
      return state;
  }
}
