import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { useToggleAppClass, useSetForecastOpen } from './redux/hooks'
import { useDoModal } from '../user/redux/doModal'
// import PropTypes from 'prop-types';

export default function MobileNav () {
  const location = useLocation()
  const { toggleAppClass, appClass } = useToggleAppClass()
  const { setForecastOpen, forecastOpen } = useSetForecastOpen()
  const { doModal } = useDoModal()
  const notificationsOpen = appClass.includes('notifications-open')
  const activePath = regex => {
    return regex.test(location.pathname) && !notificationsOpen
  }
  const removeNotifications = () => {
    toggleAppClass('notifications-open', false)
    setForecastOpen(false)
  }
  const forecastOnClick = () => {
    removeNotifications()
    setForecastOpen(true)
  }
  const awnPlusClick = () => {
    removeNotifications()
    doModal({ type: 'plus-subscription' })
  }
  return (
    <div className='common-mobile-nav'>
      {/* <Link onClick={removeNotifications} to='/account' className={classNames('account', { active: activePath(/^\/account/) })}>
        <Avatar />
      </Link> */}
      <Link onClick={forecastOnClick} to='/' className={classNames('forecast', { active: activePath(/^\/$/) && forecastOpen })} />
      <Link onClick={removeNotifications} to='/' className={classNames('map', { active: activePath(/^\/$/) && !forecastOpen })} />
      <Link onClick={removeNotifications} to='/dashboard' className={classNames('dashboard', { active: activePath(/^\/dashboard/) })} />
      <a className={classNames('alerts', { active: notificationsOpen })} />
      <Link onClick={removeNotifications} to='/social' className={classNames('social', { active: activePath(/^\/social/) })} />
      <a className='awn-plus' onClick={awnPlusClick} />
      <Link onClick={removeNotifications} to='/common/menu' className={classNames('menu', { active: activePath(/menu/) })} />
      <div className='login-wrap'>
        <Link className='login btn btn-primary btn-long' to='/welcome'>Login</Link>
      </div>
    </div>
  )
};

MobileNav.propTypes = {}
MobileNav.defaultProps = {}

MobileNav.displayName = 'MobileNav'
