import React, { Component } from 'react';
import { ambient } from '../../common/lib/ambient-weather-common'
import PropTypes from 'prop-types';
import { addIndex, map, path, pluck } from 'ramda'
import { getUserUnitI, getUserSetting, rowsHave } from '../../common/ambient'
import AmbRadio from '../../components/AmbRadio'
import bindAllActions from '../../common/bindAllActions';

/**
 * So you want to add a new unit do ya?
 * 1. add the slug and units to the unitsObj ~25
 * 2. add a label for it and it's slug ~67
 * 3. in common/ambient add slug to getMasterUnitParam
 */
class Settings extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    userActions: PropTypes.object.isRequired,
  };

  render () {
    const { device, user, userActions } = this.props
    const { devices = [] } = device
    const unitsLabels = [
      ['Temperature', 'tempf'],
      ['Barometer', 'baromrelin'],
      ['Wind Speed', 'windspeedmph'],
      ['Rainfall', 'hourlyrainin'],
      ['Solar Radiation', 'solarradiation'],
      ['Soil Moisture', 'soilhum1'],
      ['Time Format', 'hour24'],
      ['Distance', 'metric'],
    ]

    if (path(['info', 'settings', 'home'], user) === 'km') {
      unitsLabels.push(['Soil Tension', 'soiltens1'])
      unitsLabels.push(['Evapotranspiration', 'etos'])
    }

    const myDevicesHave = rowsHave(pluck('lastData', devices))
    if (myDevicesHave(['airflowcfm'])) {
      unitsLabels.push(['Volume Airflow', 'airflowcfm'])
    }
    if (myDevicesHave(['humidityratiogplb'])) {
      unitsLabels.push(['Moisture Content', 'humidityratiogplb'])
    }

    const lines = map((arr) => {
      const label = arr[0]
      const key = arr[1]
      const userSetting = getUserSetting(key)(user) || {}
      const selected = getUserUnitI(key, user)
      const unitsObj = Object.assign({
        hour24: {
          units: ['12hr', '24hr']
        },
        metric: {
          units: ['imperial', 'metric']
        }
      }, ambient.DATA_SPEC)
    
      const options = addIndex(map)((unit, i) => {
        return (
          <AmbRadio
            key={i}
            selected={selected === i}
            onClick={() => {
              userSetting.unit = i
              userActions.updateSetting(key, userSetting)
            }}
            label={unit === ' ' ? 'index' : unit}
          />
        )
      })(unitsObj[key].units)
      return (
        <div
          className="line"
          key={key}
        >
          <div>{label}</div>
          <div className="options">
            {options}
          </div>
        </div>
      )
    }
    )(unitsLabels)
    return (
      <div className="user-settings">
        <div className="block">
          <h3>Units</h3>
          {lines}
        </div>
      </div>
    );
  }
}
export default bindAllActions(Settings)

Settings.displayName = 'Settings'
