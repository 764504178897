import React from 'react';
// import PropTypes from 'prop-types';
import { dataHas } from '../../common/ambient'
import FormattedDataPoint from './FormattedDataPoint'
import classNames from 'classnames'

export default function EvapoTransWidget({currentDevice}) {
  const { lastData } = currentDevice
  const currentDataHas = dataHas(lastData)
  let single = true 
  const mains = []

  if (currentDataHas(['etos'])) {
    mains.push(
      <div key="etos">
        <div className="label">
          ETos (Short crops)
        </div>
          <FormattedDataPoint type={'etos'} row={lastData} />
      </div>
    )
  }  if (currentDataHas(['etrs'])) {
    single = false
    mains.push(
      <div key="etrs">
        <br />
        <div className="label">
          ETrs (Tall crops)
        </div>
          <FormattedDataPoint type={'etrs'} row={lastData} />
      </div>
    )
  }

  return (
    <div className={classNames('device-evapo-trans-widget', { single })}>
      <div className="top">
        <div className="wrap">
          {mains}
        </div>
      </div>
    </div>
  );
};

EvapoTransWidget.propTypes = {};
EvapoTransWidget.defaultProps = {};

EvapoTransWidget.displayName = 'EvapoTransWidget'

EvapoTransWidget.displayName = 'EvapoTransWidget'

EvapoTransWidget.displayName = 'EvapoTransWidget'

EvapoTransWidget.displayName = 'EvapoTransWidget'

EvapoTransWidget.displayName = 'EvapoTransWidget'

EvapoTransWidget.displayName = 'EvapoTransWidget'

EvapoTransWidget.displayName = 'EvapoTransWidget'
