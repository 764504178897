import { useCallback } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import {
  COMMON_TOGGLE_APP_CLASS
} from './constants'

export function toggleAppClass (clss, add) {
  return {
    type: COMMON_TOGGLE_APP_CLASS,
    data: {
      clss, add
    }
  }
}

export function useToggleAppClass () {
  const dispatch = useDispatch()
  const boundAction = useCallback((...params) => dispatch(toggleAppClass(...params)), [dispatch])
  const st8Obj = useSelector(state => ({ appClass: state.common.appClass }), shallowEqual);
  return Object.assign({ toggleAppClass: boundAction }, st8Obj);
}

export function reducer (state, action) {
  switch (action.type) {
    case COMMON_TOGGLE_APP_CLASS:
      const st8 = { ...state }
      if (action.data.add && !st8.appClass.includes(action.data.clss)) {
        st8.appClass = [...st8.appClass, action.data.clss]
      } else if (action.data.add === false && st8.appClass.includes(action.data.clss)) {
        st8.appClass = st8.appClass.filter(clss => clss !== action.data.clss)
      }
      return st8

    default:
      return state
  }
}
