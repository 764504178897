import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { Line, YAxis } from 'recharts'
import { getUserUnitI, toFixed, getSuffForUser, getLabel } from '../../common/ambient'
import CommonGraph from './CommonGraph'

export default class PressureGraph extends PureComponent {
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    graphData: PropTypes.array,
    onClick: PropTypes.func,
    mode: PropTypes.string,
    user: PropTypes.object
  }

  render () {
    const { user, width, height, graphData, mode, onClick } = this.props
    const isRefined = graphData[0] && graphData[0].refined
    const key = 'baromrelin'
    const getSuff = getSuffForUser(user)
    const userUnitI = getUserUnitI(key, user)

    return (
      <CommonGraph
        width={width}
        height={height}
        mode={mode}
        onClick={onClick}
        graphData={graphData}
        id='pressure'
      >
        <YAxis
          dataKey={key}
          orientation='left'
          type='number'
          axisLine={false}
          domain={['dataMin', 'dataMax']}
          tickFormatter={(val) => {
            if (userUnitI === 0) {
              return toFixed(2, val)
            }
            return toFixed(0, val)
          }}
        />
        <Line
          type='monotone'
          dataKey={key}
          stroke='#2c9ce6'
          strokeWidth={2}
          fill='#2c9ce6'
          isAnimationActive={!isRefined}
          dot={false}
          name={getLabel(key)}
          legendType='circle'
          unit={getSuff('baromrelin')}
          connectNulls
        />
        {this.props.children}
      </CommonGraph>
    )
  }
}

PressureGraph.displayName = 'PressureGraph'
