import React from 'react'
// import PropTypes from 'prop-types';
import classNames from 'classnames'
import { isSomething, getDisplayKeys, getLabelForDevice } from '../../common/ambient'
import { path, test } from 'ramda'
import DatePicker from 'react-datepicker'
import bindAllActions from '../../common/bindAllActions'

const GddForm = ({ currentDevice, onCancel, onComplete, type }) => {
  const [formData, setFormData] = React.useState({
    label: '',
    param: '',
    threshold: '',
    startDate: moment(),
    endDate: null
  })
  const [calOpen, setCalOpen] = React.useState(false)
  const theKeys = getDisplayKeys([currentDevice.lastData])
    .filter(test(/^temp/))
  const disabled = !((formData.label || type !== 'gdd2') && formData.threshold && formData.startDate)
  let heading = 'Create a Growing Degree Day'
  let btnLabel = 'Create GDD'
  if (type === 'hdd') {
    heading = 'Configure Heating Degree Days'
    btnLabel = 'Save'
  } else if (type === 'cdd') {
    heading = 'Configure Cooling Degree Days'
    btnLabel = 'Save'
  }
  return (
    <div className={classNames('gdd-form', `type-${type}`)}>
      <h2>{heading}</h2>
      <div className='line crop'>
        <label>Crop</label>
        <input
          className='form-control'
          type='text'
          value={formData.label}
          onChange={evt => setFormData({ ...formData, label: evt.target.value })}
        />
      </div>
      <div className='line'>
        <label>Temperature Sensor</label>
        <select
          className='form-control'
          onChange={evt => setFormData({ ...formData, param: evt.target.value })}
          value={formData.param}
        >
          {theKeys.map(key => <option key={key} value={key}>{getLabelForDevice(key, currentDevice)}</option>)}
        </select>
      </div>
      <div className='line'>
        <label>Threshold</label>
        <input
          type='number'
          placeholder='°F'
          onChange={evt => setFormData({ ...formData, threshold: evt.target.value })}
          className='form-control'
          value={formData.threshold}
        />
      </div>
      <div className='line'>
        <label>Start Date</label>
        <div>
          <span className='start-date'>{formData.startDate.format('YYYY-MM-DD')}</span>
          <a className='btn btn-round cal' onClick={() => setCalOpen(!calOpen)} />
        </div>
        {calOpen && (
          <DatePicker
            inline
            selected={new Date(moment(formData.startDate.format('YYYY-MM-DD') + ' 00:00:00').valueOf())}
            onChange={d => {
              setFormData({
                ...formData,
                startDate: moment(d)
              })
              setCalOpen(false)
            }}
          />
        )}
      </div>
      <div className='line'>
        <a
          disabled={disabled}
          className='btn btn-primary'
          onClick={() => {
            if (disabled) return
            const ret = {
              ...formData,
              startDate: formData.startDate.format('YYYY-MM-DD')
            }
            if (!ret.param) {
              ret.param = theKeys[0]
            }
            onComplete && onComplete(ret)
          }}>{btnLabel}</a>
        <a
          onClick={onCancel}
          className='btn btn-default'
        >Cancel</a>
      </div>
    </div>
  )
}

function GddWidget ({ currentDevice, deviceActions, device, userActions, type = 'gdd2' }) {
  const { deviceSummaries = [] } = device
  const gdds = path(['settings', type, 'gdds'], currentDevice) || []
  const [flipped, setFlipped] = React.useState([])
  let createLabel = 'Add GDD'
  let whatLink = 'https://ambientweather.com/faqs/question/view/id/6094/'
  if (type === 'hdd') {
    createLabel = 'Configure HDD'
    whatLink = 'https://ambientweather.com/faqs/question/view/id/6100/'
  } else if (type === 'cdd') {
    createLabel = 'Configure CDD'
    whatLink = 'https://ambientweather.com/faqs/question/view/id/6097/'
  }
  const gddForm = (
    <GddForm
      type={type}
      currentDevice={currentDevice}
      onComplete={gdd => {
        const settings = path(['settings'], currentDevice) || {}
        settings[type] = settings[type] || {}
        settings[type].gdds = settings[type].gdds || []
        settings[type].gdds.push(gdd)
        deviceActions.patch(currentDevice._id, { settings })
        userActions.doModal(null)
      }}
      onCancel={() => {
        userActions.doModal(null)
      }}
    />
  )
  const NA = 'N/A'
  return (
    <div className='device-gdd-widget'>
      <div className={classNames('gdd-widget-wrap', {
        'gdds-zero': gdds.length === 0,
        'gdds-one': gdds.length === 1,
        'gdd-gte-one': gdds.length > 1
      }, `type-${type}`)}
      >
        <div className={classNames('gdd-list')}>
          {gdds.map((gdd, i) => {
            const days = deviceSummaries.filter(ds => ds.dateutc >= moment(gdd.startDate + ' 00:00:00').valueOf())
            let value = NA
            if (days.length) {
              value = parseInt(days.reduce((acc, ds) => {
                if (!isSomething(ds[gdd.param])) return acc
                const min = type === 'hdd' ? ds[gdd.param].l : Math.max(gdd.threshold, ds[gdd.param].l)
                const avg = (ds[gdd.param].h + min) / 2
                if (type === 'hdd') {
                  if (avg <= gdd.threshold) {
                    const diff = gdd.threshold - avg
                    return acc + diff
                  }
                } else {
                  if (avg >= gdd.threshold) {
                    return acc + avg - gdd.threshold
                  }
                }
                return acc
              }, 0), 10)
            }
            return (
              <a
                key={i}
                className={classNames('gdd-wrap', { flipped: flipped.includes(i) })}
                onClick={() => {
                  if (flipped.includes(i)) {
                    setFlipped(flipped.filter(f => f !== i))
                  } else {
                    setFlipped([...flipped, i])
                  }
                }}
              >
                <div className={classNames('gdd')}>
                  <div className='front'>
                    <header>{gdd.label}</header>
                    <div className='gdd-value fdp'>{value}</div>
                    {value !== NA && <div className='since'>since {gdd.startDate}</div>}
                  </div>
                  <div className='back'>
                    <footer>
                      <div>
                        {gdd.label}
                      </div>
                      <div>
                        <span>Start Date: </span>
                        <span>{gdd.startDate}</span>
                      </div>
                      <div>
                        <span>{getLabelForDevice(gdd.param, currentDevice)}</span>
                      </div>
                      <div className='bottom'>
                        <div>
                          <span>{gdd.threshold}°F</span>
                        </div>
                        <a
                          className='trash glyphicon glyphicon-trash'
                          onClick={() => {
                            let label = 'this GDD'
                            if (type === 'hdd') {
                              label = 'your HDD'
                            } else if (type === 'cdd') {
                              label = 'your CDD'
                            }
                            if (window.confirm(`Are you sure you want to delete ${label}?`)) {
                              const settings = path(['settings'], currentDevice) || {}
                              settings[type].gdds = settings[type].gdds.filter((_, j) => i !== j)
                              deviceActions.patch(currentDevice._id, { settings })
                            }
                          }}
                        />
                      </div>
                    </footer>
                  </div>
                </div>
              </a>
            )
          })}
          <a
            onClick={() => {
              userActions.doModal({
                type: 'component',
                data: gddForm
              })
            }}
            className='btn btn-primary create-gdd'
          >
            {createLabel}
          </a>
          <a className='what' href={whatLink} target='_blank'>What's this?</a>
        </div>
      </div>
    </div>
  )
};

GddWidget.propTypes = {}
GddWidget.defaultProps = {}

export default bindAllActions(GddWidget)
GddWidget.displayName = 'GddWidget'
