import {
  SAVE_CURRENT_BEGIN,
  SAVE_CURRENT_SUCCESS,
  SAVE_CURRENT_FAILURE,
  SAVE_CURRENT_DISMISS_ERROR,
} from './constants';
import { app } from '../../../common/feathers'
const deviceService = app.service('devices')

export function saveCurrent(args) {
  return (dispatch) => {
    dispatch({
      type: SAVE_CURRENT_BEGIN,
    });
    args.userId = app.get('user')._id
    let firstStep = !args._id ? deviceService.create(args) : deviceService.update(args._id, args)
    return firstStep
    .then(function (res){
      dispatch({
        type: SAVE_CURRENT_SUCCESS,
        data: res,
      });
      return res
    })
    .catch(function (err){
      dispatch({
        type: SAVE_CURRENT_FAILURE,
        data: {
          error: err,
        },
      });
    })
  };
}

export function dismissSaveCurrentError() {
  return {
    type: SAVE_CURRENT_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case SAVE_CURRENT_BEGIN:
      return {
        ...state,
        saveCurrentPending: true,
        saveCurrentError: null,
      };

    case SAVE_CURRENT_SUCCESS:
    console.log(action.data);
      return {
        ...state,
        current : action.data,
        recentlyCreated: action.data,
        saveCurrentPending: false,
        saveCurrentError: null,
      };

    case SAVE_CURRENT_FAILURE:
      return {
        ...state,
        saveCurrentPending: false,
        saveCurrentError: action.data.error,
      };

    case SAVE_CURRENT_DISMISS_ERROR:
      return {
        ...state,
        recentlyCreated: null,
        saveCurrentError: null,
      };

    default:
      return state;
  }
}
