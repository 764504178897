import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import classNames from 'classnames'

export default class PoleGraph extends PureComponent {
  static propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    avg: PropTypes.number,
    statsMax: PropTypes.number,
    statsMin: PropTypes.number,
    label: PropTypes.string
  }

  render () {
    const { min, max, avg, statsMin, statsMax, label } = this.props

    // group fixed values
    const groupWidth = statsMax - statsMin
    const pad = (groupWidth / 10)
    const start = statsMin - pad
    const end = statsMax + pad

    // This parameter values
    let thisMinPercent = ((min - start) / (end - start)) * 100
    let thisWidthPercent = ((max - min) / (end - start)) * 100
    const thisAvgPercent = ((avg - min) / (max - min)) * 100

    const minPercent = 5
    if (thisWidthPercent < minPercent) {
      thisMinPercent = thisMinPercent - ((minPercent - thisWidthPercent) / 2)
      thisWidthPercent = minPercent
    }

    const style = {
      left: thisMinPercent + '%',
      width: thisWidthPercent + '%'
    }
    const avgStyle = {
      left: thisAvgPercent + '%',
      display: thisWidthPercent > 10 ? 'block' : 'none'
    }
    return (
      <div className={classNames('line-wrap component-pole-graph', label.toLowerCase())}>
        <div className='label'>{label}</div>
        <div className='line-bg'>
          <div style={style} className='line'>
            <div className='avg' style={avgStyle}>{avg}</div>
            <div className='min'>
              {min}
            </div>
            <div className='max'>
              {max}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

PoleGraph.displayName = 'PoleGraph'
