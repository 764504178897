import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import bindUserActions from '../features/user/redux/bindUserActions'
import classNames from 'classnames'
import { isLoggedIn } from '../common/ambient'

class ProtectedLink extends PureComponent {
  static propTypes = {
    user: PropTypes.object,
    actions: PropTypes.object,
    to: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string
  }

  _loginModal () {
    this.props.actions.doModal('login')
  }

  render () {
    const { children, to, onClick, className, user } = this.props
    const cls = classNames('component-protected-link', className)
    if (!isLoggedIn(user)) {
      return <a className={cls} onClick={this._loginModal.bind(this)}>{children}</a>
    }
    if (to) {
      return <Link onClick={onClick} className={cls} to={to}>{children}</Link>
    }
    return <a className={cls} onClick={onClick}>{children}</a>
  }
}
export default bindUserActions(ProtectedLink)

ProtectedLink.displayName = 'ProtectedLink'
