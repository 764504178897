import { path } from 'ramda';
import React from 'react';
import { FormattedDataPoint } from '../device';
import { FromNow } from '../../components'
import { isSomething } from '../../common/lib/ambient-weather-common/ambient';
import { MAP_LEGENDS } from '../../common/ambient/map';
import { getUserUnitI } from '../../common/ambient';
// import PropTypes from 'prop-types';

const valueForFns = (fns, data) => {
  return fns.reduce((acc, fn) => {
    return acc || fn(data)
  }, null)
}

const stormCategoryLabel = key => {
  const levels = {
    TD: 'Tropical Depression',
    TS: 'Tropical Storm',
    H1: 'Category 1 Hurricane',
    H2: 'Category 2 Hurricane',
    H3: 'Category 3 Hurricane',
    H4: 'Category 4 Hurricane',
    H5: 'Category 5 Hurricane',
    TY: 'Typhoon',
    STY: 'Super Typhoon'
  }
  return levels[key]
}

export default function DataPopup ({ data, user }) {
  const locationPaths = [
    path(['profile', 'waterbody']),
    d => {
      const state = path(['place', 'state'], d)
      const country = path(['place', 'country'], d)
      if (!state || !country) return null
      return <span className='state'>{`${state}${country && `, ${country}`}`}</span>
    },
    d => {
      const basinOrigin = path(['profile', 'basinCurrent'], d)
      if (!basinOrigin) return null
      const origins = {
        AL: 'Atlantic',
        EP: 'Eastern Pacific',
        CP: 'Central Pacific',
        WP: 'Western Pacific',
        IO: 'Indian Ocean',
        SH: 'Southern Hemisphere'
      }
      return origins[basinOrigin]
    }
  ]
  const labelPaths = [
    path(['place', 'name']),
    path(['position', 'details', 'stormName']),
    path(['profile', 'name']),
  ]
  let deviceLabelPieces = valueForFns(labelPaths, data)
  const parens = path(['report', 'name'], data)
  if (parens) {
    deviceLabelPieces += ` (${parens})`
  }
  const displayLocation = valueForFns(locationPaths, data)
  console.log('data', data);
  const agoPaths = [
    path(['ob', 'timestamp']), // river
    path(['report', 'timestamp']), // fire
    path(['position', 'timestamp']), // storm
    path(['periods', 0, 'timestamp']) // aqi
  ]
  const ago = valueForFns(agoPaths, data)
  const simpleFdp = (pth, type) => {
    return data => {
      const v = path(pth, data)
      return v && <FormattedDataPoint value={v} type={type} />
    }
  }
  const fields = {
    AQI: data => {
      const v = path(['periods', 0, 'aqi'], data)
      return v && <FormattedDataPoint value={v} type='aqin' />
    },
    Category: data => {
      const v = path(['periods', 0, 'aqi'], data)
      if (!v) return null
      const level = MAP_LEGENDS.air.items.find(item => item.range[0] <= v && item.range[1] >= v)
      if (!level) return null
      return <span>{level.label} <span style={{ color: level.color, top: -2, position: 'relative' }}>⬤</span></span>
    },
    Started: data => {
      const starteds = [
        path(['report', 'startTimestamp']),
        path(['profile', 'lifespan', 'startTimestamp'])
      ]
      const v = valueForFns(starteds, data)
      return v && <FormattedDataPoint value={v * 1000} type='dateutc' />
    },
    Ended: data => {
      const endeds = [
        path(['profile', 'lifespan', 'endTimestamp']),
      ]
      const v = valueForFns(endeds, data)
      return v && <FormattedDataPoint value={v * 1000} type='dateutc' />
    },
    'Snow Depth': data => {
      const v = path(['periods', 0, 'snowDepthIN'], data)
      return v && <FormattedDataPoint value={v} type='hourlyrainin' suff={getUserUnitI('hourlyrainin', user) ? 'mm' : 'in'} />
    },
    'Snow Rate': simpleFdp(['periods', 0, 'snowRateIN'], 'hourlyrainin'),
    Visibility: simpleFdp(['periods', 0, 'visibilityMI'], 'lightning_distance'),
    Temperature: simpleFdp(['periods', 0, 'tempF'], 'tempf'),
    'Wind Direction': simpleFdp(['periods', 0, 'windDirDEG'], 'winddir'),
    Precipitation: simpleFdp(['periods', 0, 'precipIN'], 'hourlyrainin'),
    'Max Strength': simpleFdp(['profile', 'maxStormCat'], 'stormcat'),
    'Max Wind Speed': simpleFdp(['profile', 'windSpeed', 'maxMPH'], 'windspeedmph'),
    'Min Pressure': simpleFdp(['profile', 'pressure', 'minIN'], 'baromrelin'),
    'Current Strength': data => {
      const v = path(['position', 'details', 'stormCat'], data)
      return v && stormCategoryLabel(v)
    },
    'Current Direction': simpleFdp(['position', 'details', 'movement', 'directionDEG'], 'winddir'),
    'Current Movement Speed': simpleFdp(['position', 'details', 'movement', 'speedMPH'], 'windspeedmph'),
    'Current Wind Speed': data => {
      const v = path(['position', 'details', 'windSpeedMPH'], data) || path(['periods', 0, 'windSpeedMPH'], data)
      return v && <FormattedDataPoint value={v} type='windspeedmph' />
    },
    'Current Max Gust': data => {
      const v = path(['position', 'details', 'gustSpeedMPH'], data)
      return v && <FormattedDataPoint value={v} type='windspeedmph' />
    },
    'Current Pressure': data => {
      const v = path(['position', 'details', 'pressureIN'], data) || path(['periods', 0, 'pressureIN'], data)
      return v && <FormattedDataPoint value={v} type='baromrelin' />
    },
    'Expected Containment': data => {
      const v = path(['report', 'expContainedTimestamp'], data)
      return v && <FormattedDataPoint value={v * 1000} type='dateutc' />
    },
    Area: data => {
      const v = path(['report', 'areaMI'], data)
      if (!v) return null
      const unitI = getUserUnitI('lightning_distance', user)
      const val = unitI === 1 ? (v * 1.609344).toFixed(1) : v
      return <FormattedDataPoint value={val} suff={unitI === 1 ? 'km' : 'mi'} after={<sup>2</sup>} />
    },
    Contained: data => {
      const v = path(['report', 'perContained'], data)
      return v && <FormattedDataPoint value={v} after='%' />
    },
    Cause: data => {
      const v = path(['report', 'cause'], data)
      return v && <FormattedDataPoint value={v} />
    },
    Confidence: data => {
      const v = path(['report', 'conf'], data)
      return v && <FormattedDataPoint value={v} after='%' />
    },
    // river
    'Water Height': simpleFdp(['ob', 'heightFT'], 'altitudeft'),
    'Water Flow': data => {
      const v = path(['ob', 'flowCFS'], data)
      if (!v) return null
      return <FormattedDataPoint value={v} type='altitudeft' after={<><sup>3</sup>/sec</>} />
    },
    Status: data => {
      const v = path(['ob', 'statusCode'], data)
      if (!v) return null
      const level = MAP_LEGENDS.river.items.find(item => item.statusCodes.includes(v))
      if (!level) return null
      return <span>{level.label} <span style={{ color: level.color, top: -2, position: 'relative' }}>⬤</span></span>
    }
  }
  return (
    <div className='common-data-popup'>
      <div className='top'>
        <div className='meta'>
          <div className='device-name-wrap'>
            {deviceLabelPieces && <span>{deviceLabelPieces}</span>}
            {displayLocation && <b>{displayLocation}</b>}
          </div>
        </div>
        {ago && (
          <div className='ago'>
            <FromNow dateutc={ago * 1000} />
          </div>
        )}

      </div>
      <div className='content'>
        {Object.keys(fields).map((key, i) => {
          const v = fields[key](data)
          if (!isSomething(v)) return null
          return (
            <div className='field' key={i}>
              <div className='label'>{key}</div>
              <div className='value'>{v}</div>
            </div>
          )
        })}
      </div>
    </div>
  );
};

DataPopup.propTypes = {};
DataPopup.defaultProps = {};

DataPopup.displayName = 'DataPopup'
