import React, { useState } from 'react'
// import PropTypes from 'prop-types';
import {
  Location
} from '../../components'
import {
  BarometerInfo
} from '../device'
import { Link } from 'react-router-dom'

export default function BarometerCalibration () {
  const [currentDevice, setCurrentDevice] = useState({ info: {} })
  return (
    <div className='user-barometer-calibration'>
      <Link to='/' className='navbar-brand' />
      <label>Where is your device located?</label>
      <Location
        location={Object.assign({}, currentDevice.info.coords)}
        onChange={location => {
          const cd = Object.assign({}, currentDevice)
          if (location.coords) {
            cd.info.coords = location
          } else {
            cd.info.coords = null
          }
          setCurrentDevice(cd)
        }}
      />
      <BarometerInfo currentDevice={currentDevice} />
    </div>
  )
}

BarometerCalibration.propTypes = {}
BarometerCalibration.defaultProps = {}

BarometerCalibration.displayName = 'BarometerCalibration'
