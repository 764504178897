import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import bindUserActions from './redux/bindUserActions'
import { getUserSetting } from '../../common/ambient'
import AmbRadio from '../../components/AmbRadio'

class SettingRadio extends PureComponent {
  static propTypes = {
    user: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    setting: PropTypes.string.isRequired,
    settingKey: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    value: PropTypes.any.isRequired,
    label: PropTypes.any,
    defaultValue: PropTypes.any
  };

  render() {
    const { onClick, defaultValue, user, actions, setting, settingKey, value, label } = this.props
    const thisSetting = getUserSetting(setting)(user) || {}
    return (
      <AmbRadio
        label={label}
        selected={thisSetting[settingKey] === value || (defaultValue && !thisSetting[settingKey] && defaultValue === value)}
        onClick={evt => {
          if (onClick) return onClick(evt)

          thisSetting[settingKey] = value
          actions.updateSetting(setting, thisSetting)
        }}
      />
    );
  }
}
export default bindUserActions(SettingRadio)

SettingRadio.displayName = 'SettingRadio'
