import React, { useEffect, useState } from 'react'
import bindAllActions from '../../common/bindAllActions'
import { getUrl, isAdmin, isLoggedIn } from '../../common/ambient'
import DatePicker from '../../components/DatePicker'
import { on } from 'ramda';
// import PropTypes from 'prop-types';


const StateSelector = ({ usState = '', onChange }) => {

  // An array of U.S. states for the dropdown options.
  const usStates = [
    'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California',
    'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia',
    'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas',
    'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts',
    'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana',
    'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico',
    'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma',
    'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina',
    'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont',
    'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
  ];

  // Event handler for when a state is selected from the dropdown.
  const handleStateChange = (event) => {
    onChange && onChange(event.target.value)
  };

  return (
    <select value={usState} onChange={handleStateChange}>
      <option value="">-- Select a state --</option>
      {usStates.map((state, index) => (
        <option key={index} value={state}>
          {state}
        </option>
      ))}
    </select>
  );
};


const CountrySelector = ({ country = '', onChange }) => {

  // An array of countries for the dropdown options.
  const countries = [
    'United States', 'Canada', 'United Kingdom', 'Australia', 'Germany',
    'France', 'Japan', 'India', 'Brazil', 'China',
    'Mexico', 'South Korea', 'Russia', 'Spain', 'Italy',
    'Netherlands', 'Sweden', 'Switzerland', 'Norway', 'Denmark',
    'South Africa', 'Argentina', 'New Zealand', 'Singapore', 'Ireland',
    'Austria', 'Belgium', 'Finland', 'Portugal', 'Chile',
    'Greece', 'Poland', 'Turkey', 'Israel', 'Egypt',
    'Thailand', 'Vietnam', 'Malaysia', 'Indonesia', 'Philippines'
  ];

  // Event handler for when a country is selected from the dropdown.
  const handleCountryChange = (event) => {
    onChange && onChange(event.target.value)
  };

  return (
    <select value={country} onChange={handleCountryChange}>
      <option value="">-- Select a country --</option>
      {countries.map((country, index) => (
        <option key={index} value={country}>
          {country}
        </option>
      ))}
    </select>
  );
};


function AdminDataReports ({ user, history, deviceActions }) {
  const [usState, setUsState] = useState('')
  const [country, setCountry] = useState('')
  const [email, setEmail] = useState('')
  const [allTime, setAllTime] = useState(true)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [deviceCount, setDeviceCount] = useState(0)
  useEffect(() => {
    if (user.userChecked && !isLoggedIn(user) && !isAdmin(user)) {
      history.replace('/dashboard')
    }
  }, [user, history])

  const query = {
    'info.coords.address_components': { $elemMatch: { long_name: country || usState } },
    public: { $ne: null },
    macAddress: { $regex: /:/ }
  }
  let isOk = usState || country
  let buttonTitle = 'Find Devices'
  let step2 = null
  let btnAction = async () => {
    const res = await deviceActions.fetchDevice(query)
    setDeviceCount(res.total)
  }
  if (deviceCount > 0) {
    step2 = (
      <div>
        <h2>Timeframe</h2>
        {!allTime && (
          <>
            <label>Specific Date Range</label>
            <DatePicker 
              getDate={d => moment(d)}
            />
          </>
        )}
        <label>
          All Time
          &nbsp;
          &nbsp;
          <input type='checkbox' checked={allTime} onChange={() => setAllTime(!allTime)} />
        </label>
        <label>Email Report To</label>
        <input type='text' value={email} onChange={evt => setEmail(evt.target.value)} />
      </div>
    )
    isOk = isOk && email !== ''
    btnAction = async () => {
      const url = `${getUrl()}/devices/data-sales/`
      const opts = {
        method: 'POST',
        headers: {
          Authorization: `${window.localStorage.getItem('feathers-jwt')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          query,
          email,
          dates: allTime ? true : []
        })
      }
      setLoading(true)
      fetch(url, opts)
        .then(res => res.json())
        .then(ret => {
          setSuccess(true)
          setLoading(false)
        })
    }
  }
  let show = (
    <>
      <h2>Location</h2>
      <label>US State</label>
      <StateSelector
        usState={usState}
        onChange={setUsState}
      />
      <label>Country</label>
      <CountrySelector
        country={country}
        onChange={setCountry}
      />
      {step2}
      <div>
        <button
          className='btn btn-primary btn-long'
          onClick={btnAction}
          disabled={!isOk}
        >{buttonTitle}</button>
      </div>
      {deviceCount > 0 ? <div> {deviceCount} devices found</div> : ''}
    </>
  )
  if (success) {
    show = (
      <p>
        Report will be sent to {email}<br/><br/>
        <button className='btn btn-primary btn-long' onClick={() => {
          setUsState('')
          setCountry('')
          setAllTime(true)
          setEmail('')
          setSuccess(false)
          setDeviceCount(0)
        }}>Do Another?</button>
      </p>
    )
  }
  return (
    <div className='device-admin-data-reports'>
      <header className='main'>
        <h1>
          Data Sales
        </h1>
      </header>
      <div className='page-body'>
        <div className='block'>
          {show}
        </div>
      </div>
    </div>
  )
};
export default bindAllActions(AdminDataReports)

AdminDataReports.propTypes = {}
AdminDataReports.defaultProps = {}

AdminDataReports.displayName = 'AdminDataReports'
