import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import bindDeviceActions from './redux/bindDeviceActions'
import classNames from 'classnames'
import { toggleDevicePublic } from '../../common/ambient'

class ToggleDevicePublicBtn extends PureComponent {
  static propTypes = {
    currentDevice: PropTypes.object,
    onClick: PropTypes.func
  }

  constructor(props){
    super(props)
    this.togglePublic = ::this.togglePublic
  }
  togglePublic() {
    const { currentDevice, onClick, actions } = this.props
    if (currentDevice.public && !window.confirm('Note: The public link and embed code for this device will stop working. Proceed?')) {
      return
    }
    const shareDevice = toggleDevicePublic(currentDevice) 
    actions.patch(shareDevice._id, { public: shareDevice.public })
    if (onClick) {
      onClick(shareDevice)
    }
  }
  render() {
    const { currentDevice } = this.props
    return <a className={classNames("btn", {
      'btn-gray': currentDevice.public,
      'btn-primary': !currentDevice.public
    })} onClick={this.togglePublic} >{currentDevice.public ? 'Make Private' : 'Make Public'}</a>
  }
}

export default bindDeviceActions(ToggleDevicePublicBtn)
ToggleDevicePublicBtn.displayName = 'ToggleDevicePublicBtn'

ToggleDevicePublicBtn.displayName = 'ToggleDevicePublicBtn'

ToggleDevicePublicBtn.displayName = 'ToggleDevicePublicBtn'

ToggleDevicePublicBtn.displayName = 'ToggleDevicePublicBtn'

ToggleDevicePublicBtn.displayName = 'ToggleDevicePublicBtn'

ToggleDevicePublicBtn.displayName = 'ToggleDevicePublicBtn'

ToggleDevicePublicBtn.displayName = 'ToggleDevicePublicBtn'
