import React, { useState, useRef, useMemo } from 'react'
import SwipeableBottomSheet from 'react-swipeable-bottom-sheet'
import classNames from 'classnames'
import { isIosCordovaOrIosBrowser } from '../../common/ambient/utils'
import bindAllActions from '../../common/bindAllActions'
import { isSomething, isCordova, debounce, getMapLocationLabel, userCanSocialPost, getLayerParam, isAdmin } from '../../common/ambient'
import { nth, not, path, range } from 'ramda'

import { useSetMapLocation } from './redux/hooks'
import {
  FetchesForecast,
  ImageUploadLink,
  SwipeHandler
} from '../../components/'
import {
  Avatar
} from '../user/'
import {
  DevicePopup,
  FormattedDataPoint,
} from '../device/'
import {
  ForecastCard,
  LocationAutocomplete
} from '../common/'
// import PropTypes from 'prop-types';

/**
 * Ios had a strange bug when it comes to fullscreen swipeable views that scroll internally.
 * This component usess a custom implementation of the swipeable bottom sheet component
 * only for Ios devices
 */

function BottomSheet ({ onShowSettingsChange, onChange, open, update, coords, forecastUpdate, device, user, deviceActions, userActions, onPlaceSelected }) {
  const { sidebar, focusDevice, mapLayer } = device
  const { mapLocation, setMapLocation } = useSetMapLocation()
  const [scrollPos, setScrollPos] = useState(0)
  let forecastCardPeakHeight = 250
  if (isCordova()) {
    forecastCardPeakHeight += 40
  }
  const debouncedScrollHandler = useMemo(() => evt => {
    if (!isIosCordovaOrIosBrowser()) {
      return
    }
    setScrollPos(evt.target.scrollTop)
  }, [setScrollPos])
  let forecastLabel = getMapLocationLabel(mapLocation)
  let findMyLocation, currentConditions
  const layerParam = getLayerParam(mapLayer)
  if (focusDevice) {
    forecastLabel = <DevicePopup
      currentDevice={focusDevice}
      layerParam={layerParam}
    />
    // findMyLocation = <LocationAutocomplete className='blue' onPlaceSelected={onPlaceSelected} />
    let value = path(['lastData', layerParam], focusDevice)
    if (isSomething(value)) {
      const fdpProps = {}
      if (layerParam === 'tempf') {
        fdpProps.valueTransform = Math.round
        fdpProps.after = '°'
      } else if (layerParam === 'hourlyrainin') {
        if (value < 0.01) {
          value = value.toFixed(1)
        } else if (value < 1) {
          value = value.toFixed(2).replace(/^0/, '')
        }
      }
      currentConditions = <FormattedDataPoint type={layerParam} value={value} {...fdpProps} />
    }
  }
  const common = (
    <div className={classNames('bottom-card', {
      'has-device-detail': !!focusDevice
    })}
    >
      <div className='settings-nav'>
        <a className='show-settings' onClick={() => onShowSettingsChange && onShowSettingsChange(true)} />
      </div>
      <div
        onScroll={debouncedScrollHandler}
        className={classNames('forecast-first', {
          'hyper-local': focusDevice,
          local: !focusDevice
        })}
      >
        <a className='forecast-open' onClick={() => onChange(true)} />
        <LocationAutocomplete className='open' onPlaceSelected={onPlaceSelected} />
        {findMyLocation}
        <FetchesForecast
          coords={coords}
          label={forecastLabel}
          update={update}
          currentConditions={currentConditions}
        >
          <ForecastCard user={user} />
        </FetchesForecast>
      </div>
    </div>
  )
  if (isIosCordovaOrIosBrowser()) {
    const style = {
      bottom: `calc(-100vh + ${forecastCardPeakHeight}px)`
    }
    return (
      <div className={classNames('ios-bottom-sheet', { open })}>
        <SwipeHandler
          threshold={100}
          onSwipeDown={evt => {
            if (scrollPos < 10) {
              onChange(false)
            }
          }}
          onSwipeUp={() => {
            if (!open) {
              onChange(true)
            }
          }}
        >
          <div style={style} className='mobile-post'>
            {common}
          </div>
        </SwipeHandler>
      </div>
    )
  }
  return (
    <SwipeableBottomSheet
      shadowTip={false}
      overlay={false}
      overflowHeight={forecastCardPeakHeight}
      open={open}
      fullScreen
      onChange={onChange}
      swipeableViewsProps={{
        // disabled: !!focusDevice
      }}
    >
      <div className='mobile-post'>
        {common}
      </div>
    </SwipeableBottomSheet>
  )
};

export default bindAllActions(BottomSheet)

BottomSheet.propTypes = {}
BottomSheet.defaultProps = {}

BottomSheet.displayName = 'BottomSheet'
