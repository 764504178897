// This is the root reducer of the feature. It is used for:
//   1. Load reducers from each action in the feature and process them one by one.
//      Note that this part of code is mainly maintained by Rekit, you usually don't need to edit them.
//   2. Write cross-topic reducers. If a reducer is not bound to some specific action.
//      Then it could be written here.
// Learn more from the introduction of this approach:
// https://medium.com/@nate_wang/a-new-approach-for-managing-redux-actions-91c26ce8b5da.

import initialState from './initialState'
import { reducer as setMapLocationReducer } from './setMapLocation'
import { reducer as toggleAppClassReducer } from './toggleAppClass'
import { FETCH_INFO_SUCCESS } from '../../user/redux/constants'
import { setStorage } from '../../../common/ambient'
import { reducer as setOnlineReducer } from './setOnline';
import { reducer as setForecastOpenReducer } from './setForecastOpen';
import { FOCUS_DEVICE } from '../../device/redux/constants'
import { path } from 'ramda'

const reducers = [
  setMapLocationReducer,
  toggleAppClassReducer,
  setOnlineReducer,
  setForecastOpenReducer
]

export default function reducer (state = initialState, action) {
  let newState
  switch (action.type) {
    // Handle cross-topic actions here

    // IP location
    case FETCH_INFO_SUCCESS:
      if (state.mapLocation) {
        newState = state
      } else {
        const loc = action.data
        const mapLocation = {
          location: `${loc.city}`,
          label: `${loc.city}, ${loc.state}`,
          coords: {
            lon: loc.longitude,
            lat: loc.latitude
          },
          geo: {
            type: 'Point',
            coordinates: [loc.longitude, loc.latitude]
          }
        }
        setStorage('lastLocationSearch', mapLocation)
        newState = {
          ...state,
          mapLocation
        }
      }
      break

    // focus device
    case FOCUS_DEVICE:
      const deviceLoc = path(['info', 'coords'], action.data)
      if (!deviceLoc) {
        newState = state
      } else {
        newState = {
          ...state,
          mapLocation: Object.assign({}, deviceLoc)
        }
      }
      break
    default:
      newState = state
      break
  }
  /* istanbul ignore next */
  return reducers.reduce((s, r) => r(s, action), newState)
}
