import {
  EDIT_SUMMARY_CELL,
} from './constants';

export function editSummaryCell(cellId) {
  return {
    type: EDIT_SUMMARY_CELL,
    data: cellId
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case EDIT_SUMMARY_CELL:
      return {
        ...state,
        editingSummaryCell: action.data
      }

    default:
      return state;
  }
}
