import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { contains, sum, mean, isEmpty, reduce, max, min } from 'ramda'
import { pluckClean, containsAll, getDisplayKeys } from '../../common/ambient'
import FormattedDataPoint from './FormattedDataPoint'

export default class ComparisonTable extends PureComponent {
  static propTypes = {
    data: PropTypes.array
  }

  render() {
    const { data } = this.props
    const graphData = data
    let table = <div className="hidden device-comparison-table" />
    const readings = {}
    const averages = {}
    const displayKeys = getDisplayKeys(graphData)
    if (contains('hourlyrainin', displayKeys)) {
      readings.rain =
        <div>
          <FormattedDataPoint type={'hourlyrainin'} value={sum(pluckClean('hourlyrainin', graphData))} />
          <h6>Rainfall Total</h6>
        </div>
    }
    if (contains('humidity', displayKeys)) {
      averages.humidity =
        <div>
          <FormattedDataPoint type={'humidity'} value={Math.round(mean(pluckClean('humidity', graphData)))} />
          <h6>Humidity</h6>
        </div>
    }
    if (containsAll(['tempf', 'humidity'], displayKeys)) {
      averages.dewPoint =
      <div>
        <FormattedDataPoint type={'tempf'} value={mean(pluckClean('dewPoint', graphData))} />
        <h6>Dew Point</h6>
      </div>
    }
    if (contains('tempf', displayKeys)) {
      const temps = pluckClean('tempf', graphData)
      readings.high =
      <div>
        <FormattedDataPoint type={'tempf'} value={reduce(max, -1000, temps)} />
        <h6>High</h6>
      </div>
      readings.low =
      <div>
        <FormattedDataPoint type={'tempf'} value={reduce(min, 1000, temps)} />
        <h6>Low</h6>
      </div>
      averages.temp =
      <div>
        <FormattedDataPoint type={'tempf'} value={mean(temps)} />
        <h6>Temperature</h6>
      </div>
    }
    if (!isEmpty(readings)) {
      table =
        <div className="device-comparison-table-wrap">
          <div key={'stats'} className="device-comparison-table">
            <div>
              <h4>Readings</h4>
              <div className="flex">
                {readings.high}
                {readings.low}
                {readings.rain}
              </div>
            </div>
            <div>
              <h4>Averages</h4>
              <div className="flex">
                {averages.temp}
                {averages.humidity}
                {averages.dewPoint}
              </div>
            </div>
          </div>
        </div>
    }
    return (table)
  }
}

ComparisonTable.displayName = 'ComparisonTable'

ComparisonTable.displayName = 'ComparisonTable'

ComparisonTable.displayName = 'ComparisonTable'

ComparisonTable.displayName = 'ComparisonTable'

ComparisonTable.displayName = 'ComparisonTable'

ComparisonTable.displayName = 'ComparisonTable'

ComparisonTable.displayName = 'ComparisonTable'
