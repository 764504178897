export const ALERT_TEST_ACTION = 'ALERT_TEST_ACTION';
export const CREATE_ALERT_BEGIN = 'CREATE_ALERT_BEGIN';
export const CREATE_ALERT_SUCCESS = 'CREATE_ALERT_SUCCESS';
export const CREATE_ALERT_FAILURE = 'CREATE_ALERT_FAILURE';
export const CREATE_ALERT_DISMISS_ERROR = 'CREATE_ALERT_DISMISS_ERROR';
export const FETCH_ALERTS_BEGIN = 'FETCH_ALERTS_BEGIN';
export const FETCH_ALERTS_SUCCESS = 'FETCH_ALERTS_SUCCESS';
export const FETCH_ALERTS_FAILURE = 'FETCH_ALERTS_FAILURE';
export const FETCH_ALERTS_DISMISS_ERROR = 'FETCH_ALERTS_DISMISS_ERROR';
export const REMOVE_ALERT_BEGIN = 'REMOVE_ALERT_BEGIN';
export const REMOVE_ALERT_SUCCESS = 'REMOVE_ALERT_SUCCESS';
export const REMOVE_ALERT_FAILURE = 'REMOVE_ALERT_FAILURE';
export const REMOVE_ALERT_DISMISS_ERROR = 'REMOVE_ALERT_DISMISS_ERROR';
export const FETCH_INSTANCES_BEGIN = 'FETCH_INSTANCES_BEGIN';
export const FETCH_INSTANCES_SUCCESS = 'FETCH_INSTANCES_SUCCESS';
export const FETCH_INSTANCES_PAGINATION = 'FETCH_INSTANCES_PAGINATION';
export const FETCH_INSTANCES_FAILURE = 'FETCH_INSTANCES_FAILURE';
export const FETCH_INSTANCES_DISMISS_ERROR = 'FETCH_INSTANCES_DISMISS_ERROR';
export const FETCH_IFTTT_ALERTS_BEGIN = 'FETCH_IFTTT_ALERTS_BEGIN';
export const FETCH_IFTTT_ALERTS_SUCCESS = 'FETCH_IFTTT_ALERTS_SUCCESS';
export const FETCH_IFTTT_ALERTS_FAILURE = 'FETCH_IFTTT_ALERTS_FAILURE';
export const FETCH_IFTTT_ALERTS_DISMISS_ERROR = 'FETCH_IFTTT_ALERTS_DISMISS_ERROR';
