import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { ambient } from '../../common/lib/ambient-weather-common'
import PropTypes from 'prop-types';
import { contains, path } from 'ramda'
import { windDirLabel, getMasterUnitParam, convertUnit, getUserUnitI, getSuff, fetchStartEndRes, isSomething, pathsChanged } from '../../common/ambient'
import mapStateToProps from '../../common/mapStateToProps'
import classNames from 'classnames'


class FormattedDataPoint extends PureComponent {
  static propTypes = {
    type: PropTypes.string,
    row: PropTypes.object,
    mode: PropTypes.string,
    value: PropTypes.any,
    valueTransform: PropTypes.func,
    suff: PropTypes.string,
    after: PropTypes.string,
    before: PropTypes.string,
    skipConvert: PropTypes.bool,
    skipWindLabel: PropTypes.bool,
    user: PropTypes.object.isRequired,
    className: PropTypes.string,
  }
  // shouldComponentUpdate(nextProps) {
  //   if (pathsChanged(this.props, nextProps, [
  //     'row',
  //     'type',
  //     'value',
  //     ['user', 'info'],
  //     ['user', 'userUnits'],
  //   ])) {
  //     return true
  //   }
  //   return false
  // }

  render() {
    const { valueTransform, before, after, suff, value, row, type, mode, user, skipConvert, skipWindLabel, className } = this.props
    let val = value
    if (!isSomething(val)) {
      if (!row) {
        return <span />
      }
      val = row[type]

    }
    let skipSuff = false
    let suffInternalOverride = false
    const unitParam = getMasterUnitParam(type)
    const userUnitI = getUserUnitI(type, user)
    
    if (!isSomething(val)) {
      val = '- -'
      skipSuff = true
    // convert degress to compass
    } else if (contains(type, ['winddir', 'windgustdir', 'winddir_avg2m', 'winddir_avg10m']) && !skipWindLabel) {
      skipSuff = true
      val = windDirLabel(val)
    } else if (type === 'dateutc') {
      skipSuff = true
      let format = 'MMM D h:mma'
      let dateutc = val
      if (mode) {
        const res = fetchStartEndRes(mode).res
        dateutc = ambient.roundDownMins(res, dateutc)
        if (contains(mode, ['monthly', 'yearly'])) {
          format = 'MMM D'
        }
      }
      const d = moment(dateutc, 'x')
      val = d.format(format)
    } else if (unitParam) {
      if (!skipConvert) {
        val = convertUnit(unitParam, userUnitI, val)
      }
      if (userUnitI > 0) {
        const origSuff = getSuff(unitParam)
        suffInternalOverride = origSuff.replace(ambient.DATA_SPEC[unitParam].units[0], ambient.DATA_SPEC[unitParam].units[userUnitI])
      }
    }

    let suffElem = ''
    if (suff || suffInternalOverride || (!skipSuff && getSuff(type))) {
      let suffContent = suff || suffInternalOverride || getSuff(type)
      const exponentRegEx = /\^(\d)/
      if (exponentRegEx.test(suffContent)) {
        const match = suffContent.match(exponentRegEx)
        suffContent = <span>{suffContent.replace(exponentRegEx, '')}<sup>{match[1]}</sup></span>
      }
      suffElem = <span className="suff">{suffContent}</span>
    }
    let afterElem = ''
    if (after) {
      afterElem = <span className="after">{after}</span>
    }
    let beforeElem = ''
    if (before) {
      beforeElem = <span className="before">{before}</span>
    }
    if (valueTransform) {
      val = valueTransform(val)
    }

    let theClassName = ''
    if (className) {
      theClassName = classNames(className)
    }

    const cls = 'device-formatted-data-point fdp ' + type + ' ' + theClassName

    return (
      <span className={cls}>{beforeElem}<span className="fdp-val">{val}</span>{suffElem}{afterElem}</span>
    )
  }
}

export default connect(mapStateToProps)(FormattedDataPoint)

FormattedDataPoint.displayName = 'FormattedDataPoint'
