import { mergeDeep } from '../../../common/ambient'
import { clone } from 'ramda'
import initialState from './initialState';
import { reducer as userTestAction } from './userTestAction';
import { reducer as fetchUser } from './fetchUser';
import { reducer as createUser } from './createUser';
import { reducer as logoutUser } from './logoutUser';
import { reducer as confirmUser } from './confirmUser';
import { reducer as updateSetting } from './updateSetting';
import { reducer as sendConfirmation } from './sendConfirmation';
import { reducer as patch } from './patch';
import { reducer as fetchUsers } from './fetchUsers';
import { reducer as becomeUser } from './becomeUser';
import { reducer as fetchAlexaData } from './fetchAlexaData';
import { reducer as clearUsers } from './clearUsers';
import { reducer as fetchInfo } from './fetchInfo';
import { reducer as doModal } from './doModal';
import { reducer as setUnits } from './setUnits';
import { reducer as createShortUrl } from './createShortUrl';
import { reducer as refreshUserReducer } from './refreshUser';
import { reducer as setAppDeviceReducer } from './setAppDevice';

const reducers = [
  userTestAction,
  fetchUser,
  createUser,
  logoutUser,
  confirmUser,
  updateSetting,
  sendConfirmation,
  patch,
  fetchUsers,
  becomeUser,
  fetchAlexaData,
  clearUsers,
  fetchInfo,
  doModal,
  setUnits,
  createShortUrl,
  refreshUserReducer,
  setAppDeviceReducer,
];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    case 'CLEAR_USER_DEVICES':
      newState = { ...state }
      if (state.users) {
        newState.users = state.users.map((u) => {
          if (u._id === action.userId) {
            u.devices = []
          }
          return clone(u)
        })
      }
      break
    case 'ADD_DEVICES_TO_USER':
      newState = { ...state }
      if (state.users) {
        newState.users = state.users.map((u) => {
          u.devices = u.devices ? u.devices.slice(0) : []
          action.data.forEach((d) => {
            if (u._id === d.userId) {
              u.devices.push(d)
            }
          })
          return clone(u)
        })
      }
      break
    case 'GLOBAL_USER_SET':
      newState = mergeDeep(state, action.data)
      break
    default:
      newState = state;
      break;
  }
  return reducers.reduce((s, r) => r(s, action), newState);
}
