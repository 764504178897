import React, { PureComponent } from 'react';
import { isSomething, soilHumidityLabel, dataHas, ucFirst, widgetExpanded, feelsLike, dewPoint, containsAny, displayProps, getDisplayKeys } from '../../common/ambient'
import bindAllActions from '../../common/bindAllActions'
import classNames from 'classnames'
import {
  FormattedDataPoint,
  FromYesterday,
  HighLowGraphs,
  EditableWidgetTitleInput
} from './'
import PropTypes from 'prop-types';
import { flatten, clone, intersection, toPairs } from 'ramda'

const config = {
  "temp": [
    'tempf',
    'dewPoint',
    'yesterday-tempf',
    'feelsLike'
  ],
  "Wind": [
    'windspeedmph',
    'windgustmph',
    'maxdailygust',
    'winddir'
  ],
  "Rain": [
    'hourlyrainin',
    'dailyrainin',
    'weeklyrainin'
  ],
  "Pressure": [
    'baromrelin',
    'pressureWidgetLastHour'
  ],
  "Humidity": [
    'humidity',
    'yesterday-humidity'
  ],
  "Indoor": [
    'tempinf',
    'humidityin',
    'indoor-dewPoint',
    'indoor-feelsLike'
  ],
  "Solar": [
    'uv',
    'solarradiation'
  ],
  "Air": [
    'pm25',
    'pm25_24h'
  ],
  "Airin": [
    'pm25_in',
    'pm25_in_24h' 
  ],
  "Lightning": [
    'lightning_hour',
    'lightning_day',
    'lightning_distance'
  ],
  "temp1f": [
    'temp1f',
    'humidity1',
    's-dewPoint',
    's-feelsLike'
  ],
  "temp2f": [
    'temp2f',
    'humidity2',
    's-dewPoint',
    's-feelsLike'
  ],
  "temp3f": [
    'temp3f',
    'humidity3',
    's-dewPoint',
    's-feelsLike'
  ],
  "temp4f": [
    'temp4f',
    'humidity4',
    's-dewPoint',
    's-feelsLike'
  ],
  "temp5f": [
    'temp5f',
    'humidity5',
    's-dewPoint',
    's-feelsLike'
  ],
  "temp6f": [
    'temp6f',
    'humidity6',
    's-dewPoint',
    's-feelsLike'
  ],
  "temp7f": [
    'temp7f',
    'humidity7',
    's-dewPoint',
    's-feelsLike'
  ],
  "temp8f": [
    'temp8f',
    'humidity8',
    's-dewPoint',
    's-feelsLike'
  ],
  "temp9f": [
    'temp9f',
    'humidity9',
    's-dewPoint',
    's-feelsLike'
  ],
  "temp10f": [
    'temp10f',
    'humidity10',
    's-dewPoint',
    's-feelsLike'
  ],
  "soiltemp1": [
    'soiltemp1',
    'soilhum1',
    'conditions',
  ],
  "soiltemp2": [
    'soiltemp2',
    'soilhum2',
    'conditions',
  ],
  "soiltemp3": [
    'soiltemp3',
    'soilhum3',
    'conditions',
  ],
  "soiltemp4": [
    'soiltemp4',
    'soilhum4',
    'conditions',
  ],
  "soiltemp5": [
    'soiltemp5',
    'soilhum5',
    'conditions',
  ],
  "soiltemp6": [
    'soiltemp6',
    'soilhum6',
    'conditions',
  ],
  "soiltemp7": [
    'soiltemp7',
    'soilhum7',
    'conditions',
  ],
  "soiltemp8": [
    'soiltemp8',
    'soilhum8',
    'conditions',
  ],
  "soiltemp9": [
    'soiltemp9',
    'soilhum9',
    'conditions',
  ],
  "soiltemp10": [
    'soiltemp10',
    'soilhum10',
    'conditions',
  ],
}

class QuickViewWidget extends PureComponent {
  static propTypes = {
    currentDevice: PropTypes.object,
    onChange: PropTypes.func
  };

  getConfig() {
    const { currentDevice } = this.props
    const ret = clone(config)
    if (widgetExpanded('view', currentDevice)) {
      ret.Wind.push('windspdmph_avg2m')
      ret.Wind.push('winddir_avg2m')
      ret.Wind.push('windspdmph_avg10m')
      ret.Wind.push('winddir_avg10m')
      ret.Rain.push('eventrainin')
      ret.Rain.push('monthlyrainin')
      ret.Rain.push('yearlyrainin')
      ret.Rain.push('totalrainin')
      ret.temp.push('hl-tempf')
      ret.Indoor.push('hl-tempinf')

    }
    return toPairs(ret) 
  }
  keyLine(key, title) {
    const { onChange, currentDevice, device } = this.props
    const { lastData } = currentDevice
    const displayKeys = getDisplayKeys([lastData])
    const isYesterdayKey = /yesterday/.test(key)
    const isIndoorKey = /indoor/.test(key)
    const isSensorKey = /^temp\d/.test(title)
    const isSoilKey = /soil/.test(title)
    const isPressureChange = /pressure/.test(key)
    if ((!displayKeys.includes(key)
        && !isSensorKey
        && !isYesterdayKey
        && !isIndoorKey
        && !isSoilKey
        && !isPressureChange)
        || (isPressureChange && !(device[key] && device[key][0] && device[key][0].baromrelin))
      ) {
      return ''
    }
    let fdp, label
    if (key === 'conditions') {
      const d = title.match(/temp(\d+)$/)[1]
      const humidityKey = 'soilhum' + d 
      if (!dataHas(lastData, humidityKey)){
        return ''
      }
      label = 'Conditions'
      fdp = <span className="fdp">{soilHumidityLabel(lastData[humidityKey])}</span>
    }  else if (isSensorKey && ['s-feelsLike', 's-dewPoint'].includes(key)) {
      const d = title.match(/\d+/)[0]
      const tempKey = 'temp' + d + 'f' 
      const humidityKey = 'humidity' + d 
      if (!dataHas(lastData, [tempKey, humidityKey])) {
        return ''
      }
      if (key === 's-dewPoint') {
        const dp = dewPoint(lastData[tempKey], lastData[humidityKey]) 
        if (!isSomething(dp)) {
          return ''
        }
        label = 'Dew Point'
        fdp = <FormattedDataPoint type={'tempf'} value={dp}/>
      } 
      else {
        const fl = feelsLike(lastData[tempKey], lastData[humidityKey]) 
        if (!isSomething(fl)) {
          return ''
        }
        label = 'Feels Like'
        fdp = <FormattedDataPoint type={'tempf'} value={fl}/>
      }
      
    } else if (isIndoorKey) {
      const k = key.replace('indoor-', '')
      if (k === 'dewPoint') {
        const dp = dewPoint(lastData.tempinf, lastData.humidityin) 
        if (!dataHas(lastData, ['tempinf', 'humidityin']) || !isSomething(dp)) {
          return ''
        }
        label = 'Dew Point'
        fdp = <FormattedDataPoint type={'tempf'} value={dp}/>
      } else {
        const fl = feelsLike(lastData.tempinf, lastData.humidityin) 
        if (!dataHas(lastData, ['tempinf', 'humidityin']) || !isSomething(fl)) {
          return ''
        }
        label = 'Feels Like'
        fdp = <FormattedDataPoint type={'tempf'} value={fl}/>
      }
    } else if (isPressureChange) {
      label = 'Rate'
      const diff = lastData.baromrelin - device.pressureWidgetLastHour[0].baromrelin
      fdp = <FormattedDataPoint type={'baromrelin'} before={(diff > 0 ? '+' : '')} value={diff} after={'/hr'} />
      
    } else if (isYesterdayKey) {
      fdp = <FromYesterday showNegative type={key.replace('yesterday-', '')} currentDevice={currentDevice} />
      label = ''
    } else {
      if (!isSomething(currentDevice.lastData[key])) {
        return ''
      }
      fdp = <FormattedDataPoint type={key} row={currentDevice.lastData} skipWindLabel />
      label = displayProps[key].label.replace(title, '').replace(/ \d+/, '')
      if (key === 'tempf') {
        label = 'Temperature'
      } else if (key === 'humidity') {
        label = 'Humidity'
      }
    }
    if (key === 'hourlyrainin') {
      label = 'Rate'
    } 
    if (key === 'pm25') {
      label = 'Current'
    }
    if (key === 'pm25_24h') {
      label = '24 hour average'
    }
    if (key === 'pm25_in') {
      label = 'Current'
    }
    if (key === 'pm25_in_24h') {
      label = '24 hour average'
    }

    let theLabel = <div className="label">{label}</div>
    if (['uv', 'solarradiation'].includes(key)) {
      theLabel = <EditableWidgetTitleInput 
                    type={key === 'uv' ? key : 'solar'}
                    currentDevice={currentDevice}
                    onChange={onChange}
                  />
    }
    return (
      <div key={key} className={classNames("line", {
          yesterday: isYesterdayKey
        })}>
      {theLabel}
      {fdp}
      </div>
    )
  }
  hlLines (key, title) {
    const { device, user, currentDevice, deviceActions } = this.props
    const { fetchDeviceData } = deviceActions
    const k = key.replace('hl-', '')
    function format(obj) {
      const add = ['today', 'yesterday'].includes(obj.label) ? '\'s' : 'ly'
      if (!obj.stat.max || !obj.stat.min) {
        return <div key={obj.key} className="hidden" />
      }
      return <div key={obj.key} className="line">
        <div className="label">{ucFirst(obj.label) + add} High/Low</div>
        <div className="high-lows">
          <FormattedDataPoint type={'tempf'} value={obj.stat.max}/>
          <span className="slash"> / </span>
          <FormattedDataPoint type={'tempf'} value={obj.stat.min}/>
        </div>
      </div>
    }
    return <HighLowGraphs
      device={device}
      user={user}
      currentDevice={currentDevice}
      fetchDeviceData={fetchDeviceData}
      type={k}
      key={key}
      format={format}
    />
  }
  render() {
    const { onChange, currentDevice } = this.props
    const displayKeys = getDisplayKeys([currentDevice.lastData])
    return (
      <div className="device-quick-view-widget small-flex-lines">
        <div className="wrap">
          {this.getConfig().map((pair) => {
             const title = pair[0]
             const keys = pair[1]
             if (!containsAny(keys, displayKeys)) {
               return <div className="hidden" key={title} />
             }
             const t = title === 'Solar' ? <h5>{title}</h5> : <EditableWidgetTitleInput
                                                     type={title.toLowerCase()}
                                                     currentDevice={currentDevice}
                                                     onChange={onChange}
                                                     />
             return <div className="group" key={title}>
                {t}
                {flatten(keys.map(key => {
                   return /hl-/.test(key) ? this.hlLines(key, title) : this.keyLine(key, title)
                 }))}
             </div>
          })}
        </div>
      </div>
    );
  }
}

export default bindAllActions(QuickViewWidget)

QuickViewWidget.displayName = 'QuickViewWidget'

QuickViewWidget.displayName = 'QuickViewWidget'

QuickViewWidget.displayName = 'QuickViewWidget'

QuickViewWidget.displayName = 'QuickViewWidget'

QuickViewWidget.displayName = 'QuickViewWidget'

QuickViewWidget.displayName = 'QuickViewWidget'

QuickViewWidget.displayName = 'QuickViewWidget'
