import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { identity, filter, last, contains } from 'ramda'
import bindDeviceActions from './redux/bindDeviceActions'
import { fmtDate } from '../../common/ambient'

class WipeData extends PureComponent {
  static propTypes = {
    d: PropTypes.object.isRequired,
    device: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };
  constructor(props) {
    super(props)
    this.state = {
      enabled: false,
      lastDate: '',
      waiting: false
    }
  }
  componentDidUpdate() {
    const { waiting, enabled } = this.state
    const { d, actions, device } = this.props
    const limit = 50
    if (enabled
     && !waiting
     && !contains('wipeData', device.fetchDeviceDataKeysPending)
     && !device.removeDeviceDataPending
    ) {
      if (device.wipeData && device.wipeData.length > 0) {
        const toRemove = filter(identity, device.wipeData)
        const l = last(toRemove)
        if (l) {
          actions.removeDeviceData(toRemove).then(() => actions.clearDataKey('wipeData'))
          this.setState({ lastDate: fmtDate('MMM D h:mma', l.dateutc) })
        // they are all null, wait a bit
        } else {
          this.setState({ waiting: true })
          setTimeout(() => {
            actions.clearDataKey('wipeData')
            this.setState({ waiting: false })
          }, 7000)
        }
      } else {
        const data = {
          dataKey: 'wipeData',
          macAddress: d.macAddress,
          start: 1,
          end: Date.now(),
          asc: true,
          limit
        }
        actions.clearFetchDeviceDataCache(data)
        actions.fetchDeviceData(data)
      }
    }
  }

  render() {
    const { waiting, enabled, lastDate } = this.state
    return (
      <div className="device-wipe-data">
        <a
          className="btn btn-circle"
          onClick={() => {
            if (enabled) {
              return this.setState({
                enabled: false,
                waiting: false,
                lastDate: ''
              })
            }
            if (window.confirm('Are you sure?')) {
              this.setState({ enabled: true })
            }
          }}
        >{enabled ? 'Stop' : 'wipe data'}</a>
        {lastDate !== '' ? 'Currently on: ' + lastDate : ''}
        {waiting ? '... waiting...' : ''}
      </div>
    );
  }
}

export default bindDeviceActions(WipeData)

WipeData.displayName = 'WipeData'

WipeData.displayName = 'WipeData'

WipeData.displayName = 'WipeData'

WipeData.displayName = 'WipeData'

WipeData.displayName = 'WipeData'

WipeData.displayName = 'WipeData'

WipeData.displayName = 'WipeData'
