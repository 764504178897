import React from 'react';

export default function PageNotFound() {
  return <div className="common-page-not-found">Page not found.</div>;
}

PageNotFound.displayName = 'PageNotFound'

PageNotFound.displayName = 'PageNotFound'

PageNotFound.displayName = 'PageNotFound'

PageNotFound.displayName = 'PageNotFound'

PageNotFound.displayName = 'PageNotFound'

PageNotFound.displayName = 'PageNotFound'

PageNotFound.displayName = 'PageNotFound'
