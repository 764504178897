import React, { useState, useEffect } from 'react'
import bindAllActions from '../../common/bindAllActions'
import { getUrlQueryParam, isLoggedIn } from '../../common/ambient'
import { Loader } from '../../components'
import { getThemeObj } from '../../common/skinner'
// import PropTypes from 'prop-types';

function UaPage ({ match, location, history, user, social, socialActions, userActions }) {
  const { id } = match.params
  const { userChecked } = user
  const { getUserActionPending, patchUserActionPending, addUserActionPending } = social
  const [ua, setUa] = useState(null)
  const code = getUrlQueryParam(location, 'code')
  const [codeInput, setCodeInput] = useState(code)
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [secondsLeft, setSecondsLeft] = useState(false)
  useEffect(() => {
    if (secondsLeft === false) return
    if (secondsLeft > 1) {
      setTimeout(() => {
        setSecondsLeft(secondsLeft - 1)
      }, 1000)
    } else {
      userActions.logoutUser()
    }
  }, [secondsLeft, userActions])
  // "init" - after checking login
  useEffect(() => {
    if (code && userChecked && (!getUserActionPending && (!ua || ua._id !== id))) {
      socialActions.getUserAction(id)
        .then(res => {
          if (!res) throw new Error('no auth')
          // delete-account must be logged in
          if (res.type === 'delete-account' && !isLoggedIn(user)) {
            history.push('/')
          }
          if (res.type === 'multi-sms-link' || res.type === 'multi-sms-verification') {
            if (res.data.code !== parseInt(code)) {
              history.push('/')
            }
            setCodeInput('')
          }
          setUa(res)
        })
        .catch(err => {
          console.log(err)
          history.push('/')
        })
    }
  }, [userChecked, user, history, code, socialActions, getUserActionPending, ua, setUa, id])
  const btnOrLoader = (btn) => {
    if (getUserActionPending || patchUserActionPending || addUserActionPending) {
      return <Loader />
    }
    return btn
  }
  let title = '...'
  let body = <Loader />
  if (ua) {
    title = 'Verify Account Deletion'
    body = (
      <div className='verify-form'>
        <p>Please enter the code from your email to delete your account.</p>
        <input type='text' value={codeInput} onChange={evt => setCodeInput(evt.target.value)} /> <button className='btn btn-primary' onClick={() => {
          if (window.confirm('Are you sure?')) {
            socialActions.patchUserAction(id, { data: Object.assign({}, ua.data, { verifyCode: codeInput }) })
            setSecondsLeft(3)
          }
        }}>Delete</button>
      </div>
    )
    if (ua.type === 'multi-sms-link') {
      title = 'Verify your SMS number'
      body = (
        <div className='verify-form'>
          <p>An AWN user wants to add you to their account. Please use the form below to verify the phone number you would like to use to recieve SMS alerts.</p>
          <label>Your Name</label>
          <input type='text' value={name} onChange={evt => setName(evt.target.value)} />
          <label>Phone Number</label>
          <input type='text' value={phone} onChange={evt => setPhone(evt.target.value)} /><br/>
          {btnOrLoader(
            <button className='btn btn-primary' disabled={!(name && phone.length >= 10)} onClick={() => {
              const data = {
                userId: ua.userId,
                type: 'multi-sms-verification',
                data: {
                  name,
                  phone: phone.replace(/[^0-9]/g, ''),
                  code: Math.floor(Math.random() * 10000),
                  verifyCode: Math.floor(Math.random() * 10000),
                  userActionId: ua._id
                }
              }
              socialActions.addUserAction(data)
                .then(res => {
                  history.push(`/common/ua/${res._id}?code=${data.data.code}`)
                })
            }}>Send SMS Code</button>
          )}
        </div>
      )
    } else if (ua.type === 'multi-sms-verification') {
      title = 'Verify your SMS number'
      body = (
        <div className='verify-form'>
          <p>Please enter the code from your SMS to verify your phone number.</p>
          <input type='text' value={codeInput} onChange={evt => setCodeInput(evt.target.value)} /> 
          {btnOrLoader(
            <button className='btn btn-primary' onClick={() => {
              socialActions.patchUserAction(id, { data: Object.assign({}, ua.data, { verifiedCode: codeInput }) })
                .then(res => {
                  // we're going to use setName since it's notbeing used here
                  if (res.result.ok) {
                    setName(true)
                  } else {
                    setName('fail')
                  }
                })
                .catch(err => {
                  setName('fail')
                })
            }}
            >Verify</button>
          )}
        </div>
      )
      if (name === true) {
        body = (
          <div className='verify-form'>
            <p>Success! Your phone number has been verified.</p>
          </div>
        )
      } else if (name === 'fail') {
        body = (
          <div className='verify-form'>
            <p>Sorry, that code was incorrect. Please try again.</p>
          </div>
        )
      }
    }
    if (secondsLeft > 0) {
      body = (
        <div className='verify-form'>
          <p>Thanks for using {getThemeObj().sitename}!</p>
          <p>We'll <a onClick={userActions.logoutUser}>log you out</a> in {secondsLeft}.</p>
        </div>
      )
    }
  }
  return (
    <div className='common-ua-page'>
      <header className='main'>
        <h1>
          {title}
        </h1>
      </header>
      <div className='page-body'>
        {body}
      </div>
    </div>
  )
};

export default bindAllActions(UaPage)

UaPage.propTypes = {};
UaPage.defaultProps = {};

UaPage.displayName = 'UaPage'
