import {
  GET_DEVICE_BEGIN,
  GET_DEVICE_SUCCESS,
  GET_DEVICE_FAILURE,
  GET_DEVICE_DISMISS_ERROR
} from './constants'
import { serviceGet } from '../../../common/feathers'
import { append } from 'ramda'
import { hookMaker, removeFromArr } from '../../../common/ambient'

export const getDevice = serviceGet('devices', {
  begin: GET_DEVICE_BEGIN,
  success: GET_DEVICE_SUCCESS,
  fail: GET_DEVICE_FAILURE
})

export const useGetDevice = hookMaker('getDevice', getDevice)

export function dismissGetDeviceError () {
  return {
    type: GET_DEVICE_DISMISS_ERROR
  }
}

export function reducer (state, action) {
  switch (action.type) {
    case GET_DEVICE_BEGIN:
      return {
        ...state,
        getDevicePending: true,
        getDeviceError: null,
        getDeviceIdsPending: append(action.data, state.getDeviceIdsPending)
      }

    case GET_DEVICE_SUCCESS:
      const st8 = Object.assign({}, state)
      const device = action.data
      const deviceCache = Object.assign({}, state.deviceCache)
      device.fetched = Date.now()
      deviceCache[device._id] = device
      // make sure dashboard device is always the latest
      if (st8.dashboardDevice && st8.dashboardDevice._id === device._id) {
        st8.dashboardDevice = device
      }
      return {
        ...st8,
        getDevicePending: false,
        getDeviceError: null,
        deviceCache,
        getDeviceIdsPending: removeFromArr(device._id, state.getDeviceIdsPending)
      }

    case GET_DEVICE_FAILURE:
      return {
        ...state,
        getDevicePending: false,
        getDeviceError: action.data.error,
        getDeviceIdsPending: removeFromArr(action.data.id, state.getDeviceIdsPending),
        getDeviceFailures: append(action.data.id, state.getDeviceFailures)
      }

    case GET_DEVICE_DISMISS_ERROR:
      return {
        ...state,
        getDeviceError: null
      }

    default:
      return state
  }
}
