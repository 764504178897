import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { dataHas } from '../../common/ambient'
import {
  FormattedDataPoint
} from './'

export default class WaterNitratesWidget extends PureComponent {
  static propTypes = {
    currentDevice: PropTypes.object
  };

  render() {
    const { currentDevice } = this.props
    const { lastData } = currentDevice
    const data = lastData
    let widget = <div className="waternitrates-widget hidden" />
    const currentDataHas = dataHas(data)
    if(currentDataHas('waternitratesmgl')) {
      widget =
      <div className="med-number waternitrates-widget">
        <FormattedDataPoint type={'waternitratesmgl'} row={lastData} />
      </div>
    }
    return widget
  }
};
WaterNitratesWidget.displayName = 'WaterNitratesWidget'
