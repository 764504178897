import {
  FETCH_DEVICES_BEGIN,
  FETCH_DEVICES_SUCCESS,
  FETCH_DEVICES_FAILURE,
  FETCH_DEVICES_DISMISS_ERROR,
  GET_DEVICE_SUCCESS
} from './constants';
import { app } from '../../../common/feathers'
import { dateVal, isCordova, reloadApp } from '../../../common/ambient'
const deviceService = app.service('devices')

export function fetchDevices (args = {}) {
  return (dispatch) => {
    const userId = args.userId || (app.get('user') && app.get('user')._id)
    if (!userId) {
      return new Promise((resolve, reject) => {
        resolve(false)
      })
    }
    dispatch({
      type: FETCH_DEVICES_BEGIN,
      userId
    })
    dispatch({
      type: 'CLEAR_USER_DEVICES',
      userId
    })
    const find = () => {
      return deviceService.find({
        query: {
          userId,
          $limit: 200
        }
      })
    }
    // we'll want to move this up to common/feathers
    let connectThenFind = false
    // if (!app.io.connected) {
    //   connectThenFind = () => {
    //     return new Promise((resolve, reject) => {
    //       app.io.connect()
    //       setTimeout(() => {
    //         return app.authenticate(() => find.then(resolve))
    //       }, 500)
    //     })
    //   }
    // }
    const prom = connectThenFind || find
    return prom()
      .then(function (res) {
        const data = res.data.sort((a, b) => dateVal(b.createdAt) - dateVal(a.createdAt))
        if (args.userId) {
          dispatch({
            type: 'ADD_DEVICES_TO_USER',
            data
          })
          dispatch({
            type: FETCH_DEVICES_SUCCESS,
          })
        } else {
          dispatch({
            type: FETCH_DEVICES_SUCCESS,
            data
          })
        }
      })
      .catch(function (err) {
        dispatch({
          type: FETCH_DEVICES_FAILURE,
          data: {
            error: err
          }
        });
        if (/Authentication token missing/.test(err) && !isCordova()) {
          // reloadApp()
        }
        return { data: [] }
      })
  }
}

export function dismissFetchDevicesError() {
  return {
    type: FETCH_DEVICES_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FETCH_DEVICES_BEGIN:
      return {
        ...state,
        fetchDevicesPending: true,
        fetchDevicesError: null,
      };

    case FETCH_DEVICES_SUCCESS:
      const st8 = {
        ...state,
        fetchDevicesPending: false,
        fetchDevicesError: null,
        fetchDevicesFetched: true
      }
      if (action.data) {
        st8.devices = action.data

        // cache these devices
        const deviceCache = Object.assign({}, state.deviceCache)
        action.data.forEach(d => {
          d.fetched = Date.now()
          deviceCache[d._id] = d
          if (st8.dashboardDevice && st8.dashboardDevice._id === d._id) {
            st8.dashboardDevice = d
          }
        })
        st8.deviceCache = deviceCache
      }
      return st8

    case FETCH_DEVICES_FAILURE:
      return {
        ...state,
        fetchDevicesPending: false,
        fetchDevicesFetched: true,
        fetchDevicesError: action.data.error,
      };

    case FETCH_DEVICES_DISMISS_ERROR:
      return {
        ...state,
        fetchDevicesError: null,
      };

    default:
      return state;
  }
}
