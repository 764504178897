import React, { PureComponent } from 'react';
import classNames from 'classnames'
import PropTypes from 'prop-types';
import { flip, path, identity, apply, map, slice, filter, pipe } from 'ramda'
import { widgetExpanded, dataHas } from '../../common/ambient'
import FormattedDataPoint from './FormattedDataPoint'

export default class RainWidget extends PureComponent {
  static propTypes = {
    currentDevice: PropTypes.object.isRequired,
    now: PropTypes.number
  }
  constructor(props) {
    super(props)
    this.state = {}
    this.rand = ::this.rand
  }

  componentDidMount() {
    window.addEventListener('resize', this.rand)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.rand)
  }
  rand() {
    this.setState({
      rand: Math.random()
    })
  }
  render() {
    const { height } = this.state
    const { currentDevice, now } = this.props
    const { lastData } = currentDevice
    const data = lastData
    let widget = <div className="rain-widget hidden" />
    const currentDataHas = dataHas(data)
    const config = [
      ['Rate', 'hourlyrainin'],
      ['Day', 'dailyrainin'],
      ['Event', 'eventrainin'],
      ['Week', 'weeklyrainin'],
      ['Month', 'monthlyrainin'],
      ['Year', 'yearlyrainin'],
      ['Total', 'totalrainin']
    ]
    const filterDataWeHave = filter(pipe(
      slice(1, 2),
      currentDataHas,
    ))
    const barsWereUsing = pipe(
      filterDataWeHave,
      (widgetExpanded('rain', currentDevice) ? identity : slice(0, 3)),
    )(config)
    const dataWereUsing = map(pipe(
      slice(1, 2),
      flip(path)(data)
    ))(barsWereUsing)
    const max = apply(Math.max)(dataWereUsing)
    const lineCreator = (val, k) => {
      const h = height ? height * (data[k] / max) : 0
      const rainStyle = {
        height: h < 22 ? 22 : h || 22
      }
      let rainClass = 'rain-wrap'
      if (data[k] === 0) {
        rainClass += ' zero'
      }
      return (
        <div className={rainClass} key={k}>
          <div className="rain-tube">
            <div
              ref={(el) => {
                if (el) {
                  this.setState({
                    height: el.offsetHeight
                  })
                }
              }}
              className="bg"
            >
              <div className="rain" style={rainStyle} />
            </div>
          </div>
          <div className="wrap">
            <FormattedDataPoint type={k} row={data} />
            <div className={classNames('label', val.toLowerCase())}>{val}</div>
          </div>
        </div>
      )
    }
    const mains = map(apply(lineCreator), barsWereUsing)
    if (mains.length > 0) {
      let cls = 'rain-widget'
      let add = ''
      if (widgetExpanded('rain', currentDevice) && currentDataHas('lastRain')) {
        cls += ' has-last-rain'
        const t = moment(new Date(data.lastRain))
        const n = now || Date.now()
        const daysDiff = Math.floor(Math.abs(moment.duration(t.diff(n)).asDays()))
        const lastRainMessage = daysDiff > 1 ? daysDiff + ' days ago' : t.from(n)
        add = <div className="label last-rain">Last Rainfall: {lastRainMessage} </div>
      }
      widget =
        <div className={cls}>
          <div className="outer-wrap">
            {mains}
          </div>
          {add}
        </div>
    }
    return widget
  }
}

RainWidget.displayName = 'RainWidget'

RainWidget.displayName = 'RainWidget'

RainWidget.displayName = 'RainWidget'

RainWidget.displayName = 'RainWidget'

RainWidget.displayName = 'RainWidget'

RainWidget.displayName = 'RainWidget'

RainWidget.displayName = 'RainWidget'
