import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { path } from 'ramda';
import { useStripe, useElements } from "@stripe/react-stripe-js";
// import Autocomplete from 'react-google-autocomplete'

import { billingSubscribe, billingMakeCardDefault } from './billingAccess';
import CheckoutError from './CheckoutError';
import {PaymentElement} from '@stripe/react-stripe-js';
import bindUserActions from '../user/redux/bindUserActions';

const AddCard = ({userId, deviceId, subscribe, onSuccess, reset, user, actions}) => {
  const [isProcessing, setProcessingTo] = useState(false);
  const [checkoutError, setCheckoutError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  // const [billLine1, setBillLine1] = useState("21 Creek Circle");
  // const [billCity, setBillCity] = useState("Boothwyn");
  // const [billState, setBillState] = useState("PA");
  // const [billFirstName, setBillFirstName] = useState("John");
  // const [billLastName, setBillLastName] = useState("Smith");
  const [billLine1, setBillLine1] = useState("");
  const [billLine2, setBillLine2] = useState("");
  const [billCity, setBillCity] = useState("");
  const [billState, setBillState] = useState("");
  const [billFirstName, setBillFirstName] = useState("");
  const [billLastName, setBillLastName] = useState("");
  const defaultPaymentMethod = path(['info', 'payments', 'defaultPaymentMethod'], user)
  // const deviceId = path(['_id'], currentDevice)
  const stripe = useStripe();
  const elements = useElements();

  const sleep = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  // dont show anything
  if (defaultPaymentMethod) return null
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    
    const trimBillFirstName = billFirstName.trim()
    const trimBillLastName = billLastName.trim()
    const trimBillLine1 = billLine1.trim()
    const trimBillLine2 = billLine2.trim()
    const trimBillCity = billCity.trim()
    const trimBillState = billState.trim()

    if (!trimBillFirstName || !trimBillLastName || !trimBillLine1 || !trimBillCity) { // Some coutries don't have States
      alert("Please enter name, address and card information.")
      return
    }
    setProcessingTo(true)

    const {setupIntent, error} = await stripe.confirmSetup({
      //"Elements" instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: 'http://ambientweather.net/devices',
        // return_url: 'http://localhost:3100/devices',
        payment_method_data: {
          billing_details: {
            name: trimBillFirstName + " " + trimBillLastName,
            address: {
              line1: trimBillLine1,
              line2: trimBillLine2,
              city: trimBillCity,
              state: trimBillState,
            },
          }
        }
      },
      redirect: 'if_required'
    });
    // await actions.refreshUser()
    
    // console.log(">>>>> Beginning Stripe confirmSetup")
    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Error can be shown directly to customer.
      console.log("  There was an error: " + error.message)
      setErrorMessage(error.message);
      setCheckoutError(true)
    } else if (setupIntent && setupIntent.payment_method) {
      const paymentId = setupIntent.payment_method
      const successDefault = await billingMakeCardDefault(userId, paymentId)
      await sleep(1000)  // Not sure this is necessary, but giving BillingServer time to update user.info.payments
      await actions.refreshUser()
      if (successDefault && onSuccess) {
        onSuccess(true)
      }
      reset(true)
      // if (subscribe.length > 0) {
      //   const success = await billingSubscribe(userId, deviceId, subscribe)
      //   if (success) {
      //     onSuccess({
      //       status: 'subscribed'
      //     })
      //   } else {
      //     console.log("¯\\_(ツ)_/¯  AddCard: Subscription failed")
      //   }
      // }
    }
    setProcessingTo(false)
  };

  const iframeStyles = {
    base: {
      color: "#000",
      fontSize: "16px",
      iconColor: "#fff",
      "::placeholder": {
        color: "black"
      }
    },
    invalid: {
      iconColor: "red",
      color: "red"
    },
    complete: {
      iconColor: "#cbf4c9"
    }
  };

  const cardElementOpts = {
    iconStyle: "solid",
    style: iframeStyles,
    hidePostalCode: true
  };

  return(
    <div className="payment-add-card">
      <form onSubmit={e => {handleSubmit(e)}}>
        <div className='aw-fields'>
          <div className='item two'>
            <label>First Name</label>
            <input 
              name='billFirstName' 
              type='text' 
              value={billFirstName}
              onChange={e => setBillFirstName(e.target.value)}
              required
            />
          </div>
          <div className='item two'>
            <label>Last Name</label>
            <input 
              name='billLastName' 
              type='text' 
              value={billLastName}
              onChange={e => setBillLastName(e.target.value)}
              required
            />
          </div>
        </div>

        <div className='aw-fields'>




          <div className='item'>
            <label>Address</label>
            <input 
              name='billLine1' 
              type='text' 
              value={billLine1}
              // defaultValue='21 Creek Circle'
              onChange={e => setBillLine1(e.target.value)}
              required
            />
          </div>
          <div className='item'>
            <label>Address 2</label>
            <input 
              name='billLine2' 
              type='text' 
              value={billLine2}
              onChange={e => setBillLine2(e.target.value)}
            />
          </div>
          <div className='item'>
            <label>City</label>
            <input 
              name='billCity' 
              type='text' 
              value={billCity}
              // defaultValue='Boothwyn'
              onChange={e => setBillCity(e.target.value)}
              required
            />
          </div>
          <div className='item'>
            <label>State</label>
            <input 
              name='billState' 
              type='text' 
              value={billState}
              // defaultValue='PA'
              onChange={e => setBillState(e.target.value)}
            />
          </div>
        </div>
        <PaymentElement 
          options={cardElementOpts}
        />

        <div className="row">
          {checkoutError && <CheckoutError>{errorMessage}</CheckoutError>}
        </div>        

        {<button className="btn-long btn btn-primary" disabled={isProcessing} >
        Register Card
        </button>}

      </form>
    </div>
  )
};

AddCard.propTypes = {};
AddCard.defaultProps = {};

export default bindUserActions(AddCard)


AddCard.displayName = 'AddCard'
