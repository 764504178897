import { deviceTestAction } from './deviceTestAction';
import { updateCurrent } from './updateCurrent';
import { saveCurrent, dismissSaveCurrentError } from './saveCurrent';
import { fetchDevices, dismissFetchDevicesError } from './fetchDevices';
import { removeDevice, dismissRemoveDeviceError } from './removeDevice';
import { checkMacAddress, dismissCheckMacAddressError } from './checkMacAddress';
import { clearFetchDeviceDataCache, fetchDeviceData, dismissFetchDeviceDataError, clearHiresData, clearDataKey } from './fetchDeviceData';
import { removeDeviceData, dismissRemoveDeviceDataError } from './removeDeviceData';
import { patch, dismissPatchError } from './patch';
import { setDeviceI } from './setDeviceI';
import { clearCurrent } from './clearCurrent';
import { fetchAllDevices, dismissFetchAllDevicesError } from './fetchAllDevices';
import { updateDeviceData, dismissUpdateDeviceDataError } from './updateDeviceData';
import { fetchDevice, clearFetchedDevices } from './fetchDevice';
import { shareDevice } from './shareDevice';
import { manualRefresh } from './manualRefresh';
import { editSummaryCell } from './editSummaryCell';
import { getDevice, dismissGetDeviceError } from './getDevice';
import { setDashboardDevice } from './setDashboardDevice';
import { fetchForecast, dismissFetchForecastError, clearForecast } from './fetchForecast';
import { setMapVisibleDevices } from './setMapVisibleDevices';
import { setSidebar } from './setSidebar';
import { focusDevice } from './focusDevice';
import { hoverDevice } from './hoverDevice';
import { setThing } from './setThing';

export {
  deviceTestAction,
  updateCurrent,
  saveCurrent,
  dismissSaveCurrentError,
  fetchDevices,
  dismissFetchDevicesError,
  removeDevice,
  dismissRemoveDeviceError,
  checkMacAddress,
  dismissCheckMacAddressError,
  clearFetchDeviceDataCache,
  fetchDeviceData,
  dismissFetchDeviceDataError,
  clearHiresData,
  clearDataKey,
  removeDeviceData,
  dismissRemoveDeviceDataError,
  patch,
  dismissPatchError,
  setDeviceI,
  clearCurrent,
  fetchAllDevices,
  dismissFetchAllDevicesError,
  updateDeviceData,
  dismissUpdateDeviceDataError,
  fetchDevice,
  clearFetchedDevices,
  shareDevice,
  manualRefresh,
  editSummaryCell,
  getDevice,
  dismissGetDeviceError,
  setDashboardDevice,
  fetchForecast,
  dismissFetchForecastError,
  clearForecast,
  setMapVisibleDevices,
  setSidebar,
  focusDevice,
  hoverDevice,
  setThing,
};
export { deviceData, dismissDeviceDataError } from './deviceData';
