import {
  MyDevices,
  Public,
} from './index';

export default {
  path: 'devices',
  childRoutes: [
    { path: 'my-devices', component: MyDevices, isIndex: true },
    { path: 'public/:key', component: Public },
  ],
};
