import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import DeviceChooser from '../../components/DeviceChooser'
import { isRole, getTheDevice, getDeviceLabel } from '../../common/ambient'
import { LastUpdated } from '.'

export default class DevicesHeader extends PureComponent {
  static propTypes = {
    device: PropTypes.object,
    user: PropTypes.object,
    children: PropTypes.node,
    onChange: PropTypes.func
  }

  render() {
    const { device, user, children, onChange } = this.props
    const  { fetchedDevices, dashboardDevice, devices, deviceI } = device
    let label
    let header, dev
    const countdown = []
    // v2
    if (!/devices\/public/.test(window.location.href)) {
      if (dashboardDevice) {
        dev = getTheDevice(device) 
      }
      header = <DeviceChooser
        onChange={theDevice => {
          if (onChange) {
            onChange(theDevice)
          }
        }}
        />
    // v1 - used for public
    } else if (dev = getTheDevice(device)) {
      label = getDeviceLabel(dev)
      header = <h1>{label}</h1>

      if (devices && devices.length > 1 && !fetchedDevices[0]) {
        const options = devices.map((dev, i) => {
          const l = dev.info ? dev.info.name : dev.macAddress
          return (<option key={i} value={i}>{l}</option>)
        })
        header =
          <select
            value={deviceI}
            onChange={(evt) => {
              if (onChange) {
                onChange(parseInt(evt.target.value, 10))
              }
            }}
          >
            {options}
          </select>
      }
    }
    return (
      <header className="main device-devices-header">
        {header}
        <LastUpdated currentDevice={dev} />
        <div className="children">
          {children}
        </div>
      </header>
    )
  }
}

DevicesHeader.displayName = 'DevicesHeader'

DevicesHeader.displayName = 'DevicesHeader'

DevicesHeader.displayName = 'DevicesHeader'

DevicesHeader.displayName = 'DevicesHeader'

DevicesHeader.displayName = 'DevicesHeader'

DevicesHeader.displayName = 'DevicesHeader'

DevicesHeader.displayName = 'DevicesHeader'
