import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, clone, values, map, keys, mapObjIndexed } from 'ramda'
import bindUserActions from './redux/bindUserActions';
import { guid, debounce } from '../../common/ambient'

class ApiKeys extends PureComponent {
  static propTypes = {
    user: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    u: PropTypes.object
  }
  constructor(props) {
    super(props)
    const { user, u } = this.props
    this.handleName = ::this.handleName
    this.getUser = ::this.getUser
    this.createApiKey = ::this.createApiKey
    this.doSave = debounce(this.doSave.bind(this), 500)
    const theUser  = u || user.info
    this.state = {
      api: theUser.api || {},
      showDevKeyBox: false,
      description: ''
    }
  }
  componentDidUpdate() {
    const { api } = this.state
    const { user } = this.props
    if ((!api || isEmpty(api)) && !isEmpty(this.getUser().api)) {
      this.setState({
        api: this.getUser().api
      })
    }
  }
  getUser() {
    const { user, u } = this.props
    return u || user.info
  }
  doSave() {
    const { api } = this.state
    const { actions } = this.props
    const u = this.getUser()
    actions.patch(u._id, { api })
    .then(() => {
      this.setState({
        api: map(val => {
          delete val.description
          return val
        }, api)
      })
    })
  }
  handleName (apiKey) {
    return ((evt) => {
      const { api } = this.state
      api[apiKey].name = evt.target.value
      this.setState({
        api: clone(api)
      }, this.doSave)
    })
  }
  createApiKey(type='user', description) {
    const { api = {} } = this.state
    const key = (guid() + guid()).replace(/-/g, '')
    api[key] = {
      type,
      name: '',
      description
    }
    this.setState({
      api: clone(api)
    }, this.doSave)
  }
  _createAppKey() {
    this.createApiKey('app', this.state.description)
    this.setState({
      showDevKeyBox: false,
      description: ''
    })
  }

  render() {
    const { actions, u } = this.props
    const { description, api, showDevKeyBox } = this.state
    let table = ''
    let devKeyBox = ''
    if (keys(api).length > 0) {
      table =
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Label</th>
              <th>API Key</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {values(mapObjIndexed((obj, apiKey) => {
               return (
                 <tr key={apiKey}>
                   <td>
                      <input
                        key={apiKey}
                        type="text"
                        placeholder="Your Label"
                        value={obj.name}
                        className={'editable-title'}
                        onChange={this.handleName(apiKey)}
                      />
                      {obj.type === 'app' ? <em>(application key)</em> : ''}
                   </td>
                   <td>{apiKey}</td>
                   <td>
                     {obj.type === 'app' && !u ? '' :
                        <a className="" onClick={() => {
                          if (window.confirm('Are you sure?')) {
                            delete api[apiKey]
                            this.setState({
                              api: clone(api)
                            }, this.doSave)
                          }
                        }}><i className="glyphicon glyphicon-trash"></i></a>
                     }
                   </td>
                 </tr>
               )
             }, api))}
          </tbody>
        </table>
    }
    if (showDevKeyBox) {
      devKeyBox = <div className="app-key-form">
          <h3>Application Key</h3>
          <p>
            Please tell us a about the application you're building.
          </p>
          <textarea
            value={description}
            onChange={evt => this.setState({ description: evt.target.value })}
          >
            
          </textarea>
          <a className="btn btn-primary" disabled={description === ''} onClick={this._createAppKey.bind(this)}>Create Application Key</a>
          <a className="btn nm"  onClick={() => this.setState({ showDevKeyBox: false, description: '' })}>Nevermind</a>
      </div>
    }
    return (
      <div className="user-api-keys block">
        <h3>
          API Keys
          <a className="pull-right btn btn-primary" onClick={() => { this.createApiKey() } }>Create API Key</a>
          {u ? <a className="pull-right btn btn-primary" onClick={() => { this.createApiKey('app') }}>Create Developer API Key</a> : ''}
        </h3>
        {u ? '' : <p>An API Key is used to securely connect your weather station to applications created by outside developers. We've listed a few of them <a href="https://ambientweather.com/faqs/question/view/id/1779/">here</a>.</p>}
        {table}
        {u ? '' : <p className="gray"><b>Developers:</b> An Application Key is also required for each application that you develop. <a onClick={() => this.setState({ showDevKeyBox: true })}>Click here</a> to create one.</p>}
        {devKeyBox}
      </div>
    );
  }
}

export default bindUserActions(ApiKeys)

ApiKeys.displayName = 'ApiKeys'

ApiKeys.displayName = 'ApiKeys'

ApiKeys.displayName = 'ApiKeys'

ApiKeys.displayName = 'ApiKeys'

ApiKeys.displayName = 'ApiKeys'

ApiKeys.displayName = 'ApiKeys'

ApiKeys.displayName = 'ApiKeys'
