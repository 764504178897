import { mergeDeep } from '../../../common/ambient'
import initialState from './initialState';
import { reducer as deviceTestAction } from './deviceTestAction';
import { reducer as updateCurrent } from './updateCurrent';
import { reducer as saveCurrent } from './saveCurrent';
import { reducer as fetchDevices } from './fetchDevices';
import { reducer as removeDevice } from './removeDevice';
import { reducer as checkMacAddress } from './checkMacAddress';
import { reducer as fetchDeviceData } from './fetchDeviceData';
import { reducer as removeDeviceData } from './removeDeviceData';
import { reducer as patch } from './patch';
import { reducer as setDeviceI } from './setDeviceI';
import { reducer as clearCurrent } from './clearCurrent';
import { reducer as fetchAllDevices } from './fetchAllDevices';
import { reducer as updateDeviceData } from './updateDeviceData';
import { reducer as fetchDevice } from './fetchDevice';
import { reducer as shareDevice } from './shareDevice';
import { reducer as manualRefresh } from './manualRefresh';
import { reducer as editSummaryCell } from './editSummaryCell';
import { reducer as getDevice } from './getDevice';
import { reducer as setDashboardDevice } from './setDashboardDevice';
import { reducer as fetchForecast } from './fetchForecast';
import { reducer as setMapVisibleDevices } from './setMapVisibleDevices';
import { reducer as setSidebar } from './setSidebar';
import { reducer as focusDevice } from './focusDevice';
import { reducer as hoverDevice } from './hoverDevice';
import { reducer as setThing } from './setThing';
import { UPDATE_SETTING } from '../../user/redux/constants';
import { reducer as deviceDataReducer } from './deviceData';

const reducers = [
  deviceTestAction,
  updateCurrent,
  saveCurrent,
  fetchDevices,
  removeDevice,
  checkMacAddress,
  fetchDeviceData,
  removeDeviceData,
  patch,
  setDeviceI,
  clearCurrent,
  fetchAllDevices,
  updateDeviceData,
  fetchDevice,
  shareDevice,
  manualRefresh,
  editSummaryCell,
  getDevice,
  setDashboardDevice,
  fetchForecast,
  setMapVisibleDevices,
  setSidebar,
  focusDevice,
  hoverDevice,
  setThing,
  deviceDataReducer,
];

export default function reducer (state = initialState, action) {
  let newState
  switch (action.type) {
    case 'GLOBAL_DEVICE_SET':
      newState = mergeDeep(state, action.data)
      break
    case 'RESET_DEVICE' :
      newState = initialState
      break
      // Handle cross-topic actions here
    case UPDATE_SETTING:
      newState = state
      if (action.data.key === 'forecast') {
        newState = {
          ...state,
          forecastCache: {}
        }
      }
      break
    default:
      newState = state
      break
  }
  return reducers.reduce((s, r) => r(s, action), newState)
}
