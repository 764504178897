import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { contains } from 'ramda'
import { getLabel } from '../common/ambient'

export default class CheckboxList extends PureComponent {
  static propTypes = {
    allFields: PropTypes.array,
    activeFields: PropTypes.array,
    onChange: PropTypes.func,
    device: PropTypes.object
  };

  render() {
    const { allFields, activeFields, onChange, device } = this.props
    if (!allFields || allFields.length < 1) {
      return <div className="checkbox-list" />
    }
    return (
      <div className="checkbox-list">
        <div className="btn-group">
          <button type="button" className="btn btn-circle btn-blue" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Filter <span className="caret"></span>
          </button>
        </div>
        <ul className="dropdown-menu filter">
          {allFields.map((key) => {
            return (
              <li key={key}>
                <label>
                  <span>{getLabel(key, device)}</span>
                  <input
                    type="checkbox"
                    onChange={() => {
                      if (onChange) {
                        onChange(key)
                      }
                    }}
                    checked={contains(key, activeFields)}
                  />
                </label>
              </li>
            )
          })}
        </ul>
      </div>
    );
  }
}

CheckboxList.displayName = 'CheckboxList'
