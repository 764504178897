import { useCallback } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import {
  USER_REFRESH_USER_BEGIN,
  USER_REFRESH_USER_SUCCESS,
  USER_REFRESH_USER_FAILURE,
  USER_REFRESH_USER_DISMISS_ERROR
} from './constants'
import { app } from '../../../common/feathers'
import { path } from 'ramda'
import { isPlus } from '../../payment'

// be careful, if you pass this in it will replace the entire user.info object
export function refreshUser (newUser) {
  return (dispatch, getState) => { // optionally you can have getState as the second argument
    if (newUser) {
      dispatch({
        type: USER_REFRESH_USER_SUCCESS,
        data: newUser
      })
      return Promise.resolve(newUser)
    }
    dispatch({
      type: USER_REFRESH_USER_BEGIN
    })
    const { info } = getState().user
    if (!path(['_id'], info)) return

    const userService = app.service('users')
    return userService.get(info._id)
      .then(data => {
        const userData = (data && data.data && Array.isArray(data.data)) ? data.data[0] : data
        if (userData) {
          dispatch({
            type: USER_REFRESH_USER_SUCCESS,
            data: userData
          })
        }
      })
  }
}


export function dismissRefreshUserError () {
  return {
    type: USER_REFRESH_USER_DISMISS_ERROR
  };
}

export function useRefreshUser () {
  const dispatch = useDispatch()

  const { refreshUserPending, refreshUserError } = useSelector(
    state => ({
      refreshUserPending: state.user.refreshUserPending,
      refreshUserError: state.user.refreshUserError
    }),
    shallowEqual
  );

  const boundAction = useCallback((...args) => {
    return dispatch(refreshUser(...args))
  }, [dispatch])

  const boundDismissError = useCallback(() => {
    return dispatch(dismissRefreshUserError())
  }, [dispatch])

  return {
    refreshUser: boundAction,
    refreshUserPending,
    refreshUserError,
    dismissRefreshUserError: boundDismissError
  };
}

export function reducer (state, action) {
  switch (action.type) {
    case USER_REFRESH_USER_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        refreshUserPending: true,
        refreshUserError: null
      };

    case USER_REFRESH_USER_SUCCESS:
      // The request is success
      return {
        ...state,
        info: action.data,
        plus: isPlus(action.data),
        refreshUserPending: false,
        refreshUserError: null
      };

    case USER_REFRESH_USER_FAILURE:
      // The request is failed
      return {
        ...state,
        refreshUserPending: false,
        refreshUserError: action.data.error
      };

    case USER_REFRESH_USER_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        refreshUserError: null
      };

    default:
      return state
  }
}
