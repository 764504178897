import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { WebcamImage } from '.'

export default class WebcamWidget extends PureComponent {
  static propTypes = {
    playVideo: PropTypes.bool,
    currentDevice: PropTypes.object,
    userActions: PropTypes.object,
    onTimestamp: PropTypes.func
  }

  render() {
    const { playVideo, currentDevice, userActions, onTimestamp } = this.props
    return (
      <div className="device-webcam-widget">
        <a onClick={evt => {
          evt.preventDefault()
          userActions.doModal({ type: 'webcam', data: currentDevice })
        }} >
          <WebcamImage 
            playVideo={playVideo}
            onTimestamp={onTimestamp} 
            currentDevice={currentDevice} 
          />
        </a>
      </div>
    )
  }
}

WebcamWidget.displayName = 'WebcamWidget'

WebcamWidget.displayName = 'WebcamWidget'

WebcamWidget.displayName = 'WebcamWidget'

WebcamWidget.displayName = 'WebcamWidget'

WebcamWidget.displayName = 'WebcamWidget'

WebcamWidget.displayName = 'WebcamWidget'

WebcamWidget.displayName = 'WebcamWidget'
