import React, { PureComponent } from 'react';
import Modal from 'react-modal'
import { Link, withRouter } from 'react-router-dom'
import bindUserActions from '../features/user/redux/bindUserActions'
import PropTypes from 'prop-types';
import { path } from 'ramda'
import classNames from 'classnames'
import { pathsChanged, userCanSocialPost, generalModalStyle, isIos, getTheme, hasVideo, isLoggedIn } from '../common/ambient'
import {
  ExportData, 
  WebcamImage,
  ToggleDevicePublicBtn,
  DeviceWidgetChooser,
  WxwTileGallery
} from '../features/device/'
import {
  CreatePost
} from '../features/social/'
import ExternalShareModal from './ExternalShareModal'
import { 
  CreateUsernameModal
} from '../features/user/'
import {
  EnhancedCamModal,
  CellPlanModal,
  PlusSubscriptionModal
} from '../features/payment'
import {
  AdvancedGraphModal
} from '../features/common'

class SitewideModals extends PureComponent {
  static propTypes = {
    userActions: PropTypes.object,
    device: PropTypes.object,
    user: PropTypes.object
  }
  constructor(props) {
    super(props)
    this._closeModal = ::this._closeModal
  }
  componentDidUpdate(prevProps) {
    const { location } = this.props
    const graphModalChecked = localStorage.getItem('graphModalChecked')

    if (!graphModalChecked && isLoggedIn(this.props.user) && !/get-started/.test(location.pathname)) {
      this.props.userActions.doModal({
        type: 'adgraph'
      })
      localStorage.setItem('graphModalChecked', true)
    }
  }
  _modalWrap(children, opts = {}) {
    const { user } = this.props
    return <Modal
      portalClassName={classNames(
        'app',
        'component-sitewide-modals',
        'login-modal',
        'general-modal',
        getTheme(user),
        opts.className,
        this._modalType(),
        {
          ios: isIos()
        },

      )}
      contentLabel={'Share Device Modal'}
      isOpen={true}
      onRequestClose={this._closeModal}
      style={generalModalStyle}
    >
      <a className="close" onClick={this._closeModal}></a>
      {children}
    </Modal>
  }
  _modalType() {
    return path(['modal', 'type'], this.props.user)
  }
  _closeModal(evt) {
    const { location, history } = this.props
    this.props.userActions.doModal('')
    const searchParams = new URLSearchParams(location.search)
    if(searchParams.get('show') === 'webcam') {
      searchParams.delete('show')
      history.replace({
        pathname: location.pathname,
        query: searchParams.toString()
      })
    }
    if (evt && evt.stopPropagation) {
      evt.stopPropagation()
    }
  }

  render() {
    const { userActions, user, device } = this.props
    let modal = null
    const modalType = this._modalType()
    if (user.modal === 'login') {
      modal = this._modalWrap([
        <h2 key="h2">
          You'll need to login to use that feature
        </h2>,
        <div className="body" key="body">
          <Link className="btn btn-primary btn-lg white" to="/welcome" onClick={this._closeModal}>Create Your Account</Link>
          <Link className="btn btn-primary btn-lg" to="/signin" onClick={this._closeModal}>Login</Link>
        </div>
      ])
    } else if (modalType === 'adgraph') {
      modal = this._modalWrap(<AdvancedGraphModal user={user} userActions={userActions} />)
    } else if (modalType === 'export') {
      modal = <ExportData />
    } else if (modalType === 'webcam') {
      const currentDevice = user.modal.data
      modal = <div onClick={this._closeModal} className={classNames("webcam-modal", { video: hasVideo(currentDevice) })}>
        <WebcamImage currentDevice={currentDevice} playVideo />
        {userCanSocialPost(user, device) && <span className='component-share'><a className='share btn btn-primary btn-white' onClick={e => {
          e.stopPropagation()
          userActions.doModal({ 
            type: 'create-post', 
            data: {
              currentDevice: currentDevice,
              webcamSnapshot: currentDevice._id,
              video: true
            }
          })
        }}>Share</a></span>}
      </div>
    } else if (modalType === 'image') {
      modal = <div onClick={this._closeModal} className="image-modal">
        <div className='image img-contain' style={{ backgroundImage: `url(${user.modal.data.url})`}} />
      </div>
    } else if (modalType === 'plus-subscription') {
      modal = this._modalWrap(
        <PlusSubscriptionModal {...user.modal.data} user={user} userActions={userActions} />
      )    
    } else if (modalType === 'enhanced-cam') {
      modal = this._modalWrap(
        <EnhancedCamModal {...user.modal.data} user={user} userActions={userActions} />
      )    
    } else if (modalType === 'wxw-tile-gallery') {
      modal = this._modalWrap(
        <WxwTileGallery {...user.modal.data} user={user} />
      )
    } else if (modalType === 'device-widget-chooser') {
      modal = this._modalWrap(
        <DeviceWidgetChooser {...user.modal.data} user={user} />
      )
    } else if (modalType === 'cell-plan') {
      modal = this._modalWrap(
        <CellPlanModal {...user.modal.data} user={user} userActions={userActions} />
      )
    } else if (modalType === 'external-share') {
      modal = this._modalWrap(
        <ExternalShareModal {...user.modal.data} />
      )
    } else if (modalType === 'component') {
      modal = this._modalWrap(
        user.modal.data
      )
    } else if (modalType === 'toggle-public-device') {
      modal = this._modalWrap(
        <div className="device-private">
          <h4>In order to share you'll need to make your device public.</h4>
          <ToggleDevicePublicBtn {...user.modal.data} />
        </div>
      )
    } else if (modalType === 'create-post') {
      modal = this._modalWrap(
        <CreatePost 
          onSuccess={this._closeModal} 
          {...user.modal.data}
        />
      )
    } else if (modalType === 'create-username') {
      modal = this._modalWrap(<CreateUsernameModal onDone={this._closeModal} />)
    } else if (modalType === 'component') {
      modal = this._modalWrap(user.modal.data)
    }
    return modal
  }
}

export default withRouter(SitewideModals)
SitewideModals.displayName = 'SitewideModals'
