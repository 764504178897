import React, { PureComponent } from 'react';
import bindAllActions from '../../common/bindAllActions'
import PropTypes from 'prop-types';
import { pluck, uniq, filter, propEq, find, map, toPairs, values, pipe, prop, groupBy } from 'ramda'
import { getLabelForDevice, getDeviceLabel, sortByDateDesc, isLoggedIn, fmtzDate, shouldGetDevice } from '../../common/ambient'
import FormattedDataPoint from '../device/FormattedDataPoint'
import {
  Loader,
  FromNow
} from '../../components'

class Instances extends PureComponent {
  static propTypes = {
    alertActions: PropTypes.object.isRequired,
    alert: PropTypes.object.isRequired,
    device: PropTypes.object.isRequired,
    deviceActions: PropTypes.object.isRequired,
    user: PropTypes.object,
  };
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      first: false
    }
    this.nextPage = ::this.nextPage
  }
  componentWillMount() {
    const { user } = this.props
    if (isLoggedIn(user) && !this.state.first) {
      this.nextPage()
    }
  }
  componentDidUpdate(nextProps) {
    const { user } = nextProps
    if (isLoggedIn(user) && !this.state.first) {
      this.nextPage()
    }
  }
  nextPage() {
    const { page, first } = this.state
    const { deviceActions, device, user, alert, alertActions } = this.props 
    const { fetchInstancesPagination } = alert
    if (!first) {
      this.setState({
        first: true
      })
    }
    const opts = {
      userId: user.info._id,
      $sort: { createdAt: -1 }
    }
    if (page > 1) {
      opts.$skip = fetchInstancesPagination.limit * (page - 1)
    }
    alertActions.fetchInstances(opts)
      .then(() => {
        const { alertInstances } = alert
        pipe(
         pluck('deviceId'),
         uniq,
         filter(deviceId => shouldGetDevice(device, deviceId)),
         map(deviceId => deviceActions.getDevice(deviceId))
        )(alertInstances)
      })

  }

  render() {
    const { alert, device } = this.props
    const { fetchInstancesPending, alertInstances, fetchInstancesPagination } = alert
    const { deviceCache } = device
    let content = ''
    function instanceDiv(ai) {
      let closed = ''
      let theLabel = ''
      let theValue = ''
      let theDevice, deviceLine
      if (device.devices && device.devices.length > 0) {
        theDevice = find(propEq('_id', ai.deviceId), device.devices)
        // if it's not our own device
        if (!theDevice) {
          theDevice = deviceCache[ai.deviceId]
        }
        deviceLine = <div className="device">{getDeviceLabel(theDevice)}</div>
      }
      if (/^leak/.test(ai.origAlert.param)) {
        theLabel = <span>
          {ai.origAlert.threshold === 1 ? ' Leak Detected' : ' Leak Sensor Offline'}
        </span>
      } else if (ai.origAlert.param !== 'notReporting') {
        theLabel = <span>
          {ai.origAlert.condition === '>' ? ' over ' : ' under '}
          <FormattedDataPoint value={ai.origAlert.threshold} type={ai.origAlert.param} />
        </span>
        theValue = <FormattedDataPoint value={ai.data[ai.origAlert.param]} type={ai.origAlert.param} />
        if (ai.closed) {
          closed += ', '
        }
      }
      if (ai.closed) {
        closed += 'Lasted for ' + moment.duration(moment(ai.closed).diff(ai.createdAt, 'minutes'), 'minutes').humanize()
      }
      return (
        <div className="instance" key={ai._id}>
          <div className="time">
            {fmtzDate('HH:mm', ai.createdAt)}
          </div>
          <div className="info">
            {deviceLine}
            <b>
              {getLabelForDevice(ai.origAlert.param, theDevice)}
              {theLabel}
              {ai.ifttt ? <i className="ifttt" title="This alert was create on IFTTT" /> : ''}
            </b>
            <p>
              {theValue}
              {closed}
            </p>
          </div>
          <FromNow dateutc={ai.createdAt} />
        </div>
      )
    }
    const format = 'M/D/YY'
    const groupedInstances = groupBy(pipe(prop('createdAt'), fmtzDate(format)), alertInstances)
    content = pipe(
      toPairs,
      sortByDateDesc(format),
      map((arr) => {
        const date = arr[0]
        const ais = arr[1]
        return (
          <div key={date + ais.length} className="group">
            <div className="date">{date}</div>
            {ais.map(instanceDiv)}
          </div>
        )
      }),
      values
    )(groupedInstances)
    if (fetchInstancesPending) {
      content.push(<Loader key={'loader'} />)

    } else if (fetchInstancesPagination && alertInstances.length < fetchInstancesPagination.total) {
      content.push(
        <a
          key={'all'}
          className="more"
          onClick={()  => {
            this.setState({
              page: this.state.page + 1
            }, this.nextPage)
          }}
        >
          See More
          <i className="glyphicon glyphicon-chevron-down" />
        </a>
      )
    }
    if (alertInstances.length < 1) {
      return <div />
    }
    return (
      <div className="block clearfix">
        <h3>Timeline</h3>
        <div className="alert-instances">
          {content}
        </div>
      </div>
    );
  }
}
export default bindAllActions(Instances)

Instances.displayName = 'Instances'
