import {
  CHECK_MAC_ADDRESS_BEGIN,
  CHECK_MAC_ADDRESS_SUCCESS,
  CHECK_MAC_ADDRESS_FAILURE,
  CHECK_MAC_ADDRESS_DISMISS_ERROR,
} from './constants';
import { getUrl } from '../../../common/ambient/utils'
const fetch = require('isomorphic-fetch')

export function checkMacAddress(macAddress) {
  return (dispatch) => {
    dispatch({
      type: CHECK_MAC_ADDRESS_BEGIN,
    });
    return fetch(getUrl() + '/maccheck/' + macAddress)
      .then(function (res) {
        return res.json()
      })
      .then(function (res) {
        dispatch({
          type: CHECK_MAC_ADDRESS_SUCCESS,
          data: {
            macAddressExists: res.total > 0,
            checkMacAddressLastMacWxw: res.wxw,
            checkMacAddressLastMacRw: res.rw
          },
        });
      })
      .catch(function (err) {
        console.log('err');
        console.log(err);
        dispatch({
          type: CHECK_MAC_ADDRESS_FAILURE,
          data: {
            error: err
          }
        })
      })
    }
}

export function dismissCheckMacAddressError() {
  return {
    type: CHECK_MAC_ADDRESS_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CHECK_MAC_ADDRESS_BEGIN:
      return {
        ...state,
        checkMacAddressPending: true,
        checkMacAddressSuccess: false,
        checkMacAddressError: null,
        checkMacAddressLastMacWxw: null
      };

    case CHECK_MAC_ADDRESS_SUCCESS:
      return {
        ...state,
        checkMacAddressPending: false,
        checkMacAddressError: null,
        checkMacAddressSuccess: true,
        ...action.data,
      };

    case CHECK_MAC_ADDRESS_FAILURE:
      return {
        ...state,
        checkMacAddressPending: false,
        checkMacAddressSuccess: false,
        checkMacAddressError: action.data.error,
      };

    case CHECK_MAC_ADDRESS_DISMISS_ERROR:
      return {
        ...state,
        checkMacAddressError: null,
      };

    default:
      return state;
  }
}
