import {
  FETCH_ALEXA_DATA_BEGIN,
  FETCH_ALEXA_DATA_SUCCESS,
  FETCH_ALEXA_DATA_FAILURE,
  FETCH_ALEXA_DATA_DISMISS_ERROR,
} from './constants';
import { concat } from 'ramda'
import { app } from '../../../common/feathers'
const alexaService = app.service('alexa-data')

export function fetchAlexaData(args) {
  return (dispatch) => {
    dispatch({
      type: FETCH_ALEXA_DATA_BEGIN,
    });
    return alexaService.find({ query: { offset: 0 } })
    .then((res) => {
      console.log(res);
      dispatch({
        type: FETCH_ALEXA_DATA_SUCCESS,
        data: res
      });
    })
    .catch((err) => {
      dispatch({
        type: FETCH_ALEXA_DATA_FAILURE,
        data: err 
      });
    })
  };
}

export function dismissFetchAlexaDataError() {
  return {
    type: FETCH_ALEXA_DATA_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FETCH_ALEXA_DATA_BEGIN:
      return {
        ...state,
        fetchAlexaDataPending: true,
        fetchAlexaDataError: null,
      };

    case FETCH_ALEXA_DATA_SUCCESS:
      return {
        ...state,
        fetchAlexaDataPending: false,
        fetchAlexaDataError: null,
        alexaData: concat(state.alexaData, action.data)
      };

    case FETCH_ALEXA_DATA_FAILURE:
      return {
        ...state,
        fetchAlexaDataPending: false,
        fetchAlexaDataError: action.error,
      };

    case FETCH_ALEXA_DATA_DISMISS_ERROR:
      return {
        ...state,
        fetchAlexaDataError: null,
      };

    default:
      return state;
  }
}
