import React, { PureComponent } from 'react';
import bindAllActions from '../../common/bindAllActions';
import PropTypes from 'prop-types';
import { path } from 'ramda'
import { debounce, isRole } from '../../common/ambient'
import classNames from 'classnames'
import md5 from 'blueimp-md5'
import {
  ServersForm
} from './'

class DeviceList extends PureComponent {
  static propTypes = {
    device: PropTypes.object,
    deviceActions: PropTypes.object,
    user: PropTypes.object,
    userActions: PropTypes.object
  }
  constructor(props) {
    super(props)
    this.state = {
    }
    this._tout = false
    this.removeDevice = ::this.removeDevice
  }
  removeDevice(dev) {
    if (window.confirm('Are you sure?')) {
      const { removeDevice, fetchDevices, setDeviceI } = this.props.deviceActions
      removeDevice(dev).then(() => fetchDevices())
      setDeviceI(0)
    }
  }
  getInput(dev, field) {
    const { deviceActions } = this.props
    const val = dev.info ? dev.info[field] : ''
    return (
      <input
        type="text"
        value={val}
        className={'editable-title type-info-' + field}
        onChange={(evt) => {
          const info = dev.info || {}
          info[field] = evt.target.value
          deviceActions.patch(dev._id, { info })
        }}
      />
    )
  }
  publicCheckbox(dev) {
    const { user, deviceActions } = this.props
    return <td><a onClick={() => deviceActions.shareDevice(dev)}>{dev.public ? <span className="public">public</span> : <span className="private">private</span>}</a></td>
  }
  publicInfo(dev) {
    if (!dev.public) return ''
    const { deviceActions } = this.props
    return <div className="public-info">
      Url: {dev.public.slug}<br/>
      <label >
        Indoor Public:&nbsp;
          <input type="checkbox" checked={dev.public.indoorPublic} onChange={evt => {
            const pub = Object.assign(dev.public, { indoorPublic: evt.target.checked })
            deviceActions.patch(dev._id, { public: pub })
          }} />
      </label>
    </div>
  }
  render() {
    const { deviceActions, user } = this.props
    const { devices } = this.props.device
    const { info } = user
    if (!devices || devices.length < 1) {
     return <div className="hidden" /> 
    }
    const alexaCol = path(['oauth', 'alexa'], info) && devices.length > 1 && path(['alexa', 'lastDevice'], info)
    const alexaIcon = (device) => {
      const { patch } = this.props.userActions
      const lastDevice = path(['alexa', 'lastDevice'], info)
      if (device._id === lastDevice) {
        return(<td><i className="alexa" title="Alexa Default Device" /></td>)
      }
      return(
        <td>
          <a onClick={() => {
            const alexa = info.alexa || {}
            alexa.lastDevice = device._id
            patch(info._id, { alexa })
          }} title="Make Alexa Default Device">
            <i className="alexa gray" />
          </a>
        </td>
        )
    }
    return (
      <div className="block device-device-list">
        <h3>My Devices</h3>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Name</th>
              <th>Location</th>
              <th>Mac Address</th>
              <th>Share Dashboard</th>
              { alexaCol ? <th>Alexa Default</th> : ''}
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {devices.map((dev, i) => {
              let devRow = ''
              if (isRole('dev', user)) {
                devRow = <td><ServersForm theDevice={dev} service={'wu'} deviceActions={deviceActions} /></td>
              }
              return (
                <tr key={i} className={classNames({
                  })}>
                  <td>{this.getInput(dev, 'name')}</td>
                  <td>{this.getInput(dev, 'location')}</td>
                  <td>{dev.macAddress}</td>
                  {this.publicCheckbox(dev)}
                  { alexaCol ? alexaIcon(dev) : ''}
                  <td>
                    <a className="" onClick={() => this.removeDevice(dev)}><i className="glyphicon glyphicon-trash"></i></a>
                  </td>
                  {devRow}
                </tr>
               )
             })}
          </tbody>
        </table>
      </div>
    );
  }
}
export default bindAllActions(DeviceList)

DeviceList.displayName = 'DeviceList'

DeviceList.displayName = 'DeviceList'

DeviceList.displayName = 'DeviceList'

DeviceList.displayName = 'DeviceList'

DeviceList.displayName = 'DeviceList'

DeviceList.displayName = 'DeviceList'

DeviceList.displayName = 'DeviceList'
