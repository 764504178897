import React, { PureComponent } from 'react';
import { Bar, Line, YAxis } from 'recharts'
import PropTypes from 'prop-types';
import { pluck, concat, apply, filter, pipe, contains } from 'ramda'
import CommonGraph from './CommonGraph'
import { isSomething, rowsHave, toFixed, getSuffForUser, getLabel } from '../../common/ambient'

export default class WindGraph extends PureComponent {
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    graphData: PropTypes.array,
    mode: PropTypes.string,
    activeGraphs: PropTypes.array,
    user: PropTypes.object,
  };

  render() {
    const { user, width, height, graphData, mode, activeGraphs } = this.props
    const isRefined = graphData[0] && graphData[0].refined
    const lines = []
    const getSuff = getSuffForUser(user)
    let hasH, hasAvg
    if (contains('windgustmph', activeGraphs)) {
      hasH = rowsHave(graphData, 'maxdailygustH')
      lines.push(
        <Bar
          key="windgustmph"
          dataKey={hasH ? 'maxdailygustH': 'windgustmph'}
          fill="#cdd4d9"
          barSize={2}
          barGap={0}
          name={getLabel('windgustmph') + (hasH ? ' High' : '')}
          unit={getSuff('windgustmph')}
        />
      )
    }
    if (contains('windspeedmph', activeGraphs)) {
      hasAvg = rowsHave(graphData, 'windspeedmphAvg')
      lines.push(
        <Line
          key="windspeedmph"
          type="monotone"
          dataKey={"windspeedmph" + (hasAvg ? 'Avg' : '')}
          stroke="#2c9ce6"
          strokeWidth={2}
          fill="#2c9ce6"
          dot={false}
          isAnimationActive={!isRefined}
          name={getLabel('windspeedmph') + (hasAvg ? ' Average' : '')}
          unit={getSuff('windspeedmph')}
          legendType="circle"
          connectNulls={true}
        />
      )
    }
    let max = 'dataMax'
    if (hasAvg || hasH) {
      max = pipe(
        filter(isSomething),
        apply(Math.max)
      )(concat(pluck('maxdailygustH', graphData), pluck('windspeedmphAvg', graphData)))
    }
    return ( lines.length < 1 ? <div className="hidden" /> :
      <CommonGraph
        width={width}
        height={height}
        mode={mode}
        graphData={graphData}
        composed={true}
        onClick={this.props.onClick}
        id={'wind'}
      >
        <YAxis
          dataKey="windgustmph"
          orientation="left"
          type="number"
          axisLine={false}
          domain={[0, max]}
          tickFormatter={toFixed(1)}
        />
        {lines}
        {this.props.children}
      </CommonGraph>
    )
  }
}

WindGraph.displayName = 'WindGraph'

WindGraph.displayName = 'WindGraph'

WindGraph.displayName = 'WindGraph'

WindGraph.displayName = 'WindGraph'

WindGraph.displayName = 'WindGraph'

WindGraph.displayName = 'WindGraph'

WindGraph.displayName = 'WindGraph'
