import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { isSomething, feelsLike, dewPoint, widgetExpanded, dataHas } from '../../common/ambient'
import FormattedDataPoint from './FormattedDataPoint'
import HighLowGraphs from './HighLowGraphs'

export default class IndoorWidget extends PureComponent {
  static propTypes = {
    user: PropTypes.object,
    device: PropTypes.object,
    currentDevice: PropTypes.object.isRequired,
    fetchDeviceData: PropTypes.func,
    sensor: PropTypes.number
  }


  render() {
    const { fetchDeviceData, user, currentDevice, device, sensor } = this.props
    const { lastData } = currentDevice
    let widget = <div className="indoor-widget hidden" />
    let widgetClass = 'indoor-widget'
    const mains = []
    let bottom = ''
    let tempKey = 'tempinf'
    let humidityKey = 'humidityin'
    let widgetKey = 'indoor'
    if (sensor) {
      tempKey = `temp${sensor}f`
      humidityKey = `humidity${sensor}`
      widgetKey = tempKey
    }

    // lastData.co2 = 2 // test
    const currentDataHas = dataHas(lastData)
    if (currentDataHas([tempKey])) {
      mains.push(
        <div key={1}>
          <div className="label">
            Temperature
          </div>
          <FormattedDataPoint type={tempKey} row={lastData} />
        </div>
      )
    }
    if (currentDataHas([humidityKey])) {
      mains.push(
        <div key={2}>
          <div className="label">
            Humidity
          </div>
          <FormattedDataPoint type={humidityKey} row={lastData} />
        </div>
      )
    }
    if (mains.length === 2) {
      const dp = dewPoint(lastData[tempKey], lastData[humidityKey]) 
      const fl = feelsLike(lastData[tempKey], lastData[humidityKey], 0) 
      if (isSomething(dp) && isSomething(fl)) {
        bottom = <div className="bottom">
          <div>
              <div className="label">Dew Point</div>
              <div className="val">
                <FormattedDataPoint value={dp} type={'tempf'} />
              </div>
          </div>
          <div key={'feelsLike'}>
            <div className="label">Feels Like</div>
            <div className="val">
              <FormattedDataPoint value={fl} type={'tempf'} />
            </div>
          </div>
        </div>
      }
    } else {
      widgetClass += ' single '
    }
    let co2 = ''
    if (!sensor && currentDataHas(['co2'])) {
      widgetClass += ' co2'
      const highestCo2 = 2000
      let percent = (lastData.co2 / highestCo2) * 100
      let progressClass = 'progress-bar'
      if (lastData.co2 > 2000) {
        progressClass += ' high'
      } else if (lastData.co2 > 1000) {
        progressClass += ' warn'
      }
      if (percent > 100) {
        percent = 100
      }
      co2 =
        <div className="co2-progress">
          <div className="progress">
            <div className={progressClass} style={{ width: percent + '%'}} />
          </div>
          <div className="label">
            Co2
          </div>
        </div>
    }
    let expanded = ''
    if (widgetExpanded(widgetKey, currentDevice)) {
      expanded =
        <div className="expanded-wrap">
            <HighLowGraphs
              device={device}
              user={user}
              currentDevice={currentDevice}
              fetchDeviceData={fetchDeviceData}
              type={tempKey}
            />
        </div>
    }
    if (mains.length > 0) {
      widget =
        <div className={widgetClass}>
          <div className="top">
            <div className="wrap">
              {mains}
            </div>
          </div>
          {bottom}
          {expanded}
          {co2}
        </div>
    }
    return widget
  }
}

IndoorWidget.displayName = 'IndoorWidget'

IndoorWidget.displayName = 'IndoorWidget'

IndoorWidget.displayName = 'IndoorWidget'

IndoorWidget.displayName = 'IndoorWidget'

IndoorWidget.displayName = 'IndoorWidget'

IndoorWidget.displayName = 'IndoorWidget'

IndoorWidget.displayName = 'IndoorWidget'
