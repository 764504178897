import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  SOCIAL_PATCH_USER_ACTION_BEGIN,
  SOCIAL_PATCH_USER_ACTION_SUCCESS,
  SOCIAL_PATCH_USER_ACTION_FAILURE,
  SOCIAL_PATCH_USER_ACTION_DISMISS_ERROR,
} from './constants';
import { servicePatch } from '../../../common/feathers'

export const patchUserAction = servicePatch('userActions', {
  begin: SOCIAL_PATCH_USER_ACTION_BEGIN,
  success: SOCIAL_PATCH_USER_ACTION_SUCCESS,
  fail: SOCIAL_PATCH_USER_ACTION_FAILURE
})

export function dismissPatchUserActionError() {
  return {
    type: SOCIAL_PATCH_USER_ACTION_DISMISS_ERROR,
  };
}

export function usePatchUserAction() {
  const dispatch = useDispatch();

  const { patchUserActionPending, patchUserActionError } = useSelector(
    state => ({
      patchUserActionPending: state.social.patchUserActionPending,
      patchUserActionError: state.social.patchUserActionError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(patchUserAction(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissPatchUserActionError());
  }, [dispatch]);

  return {
    patchUserAction: boundAction,
    patchUserActionPending,
    patchUserActionError,
    dismissPatchUserActionError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case SOCIAL_PATCH_USER_ACTION_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        patchUserActionPending: true,
        patchUserActionError: null,
      };

    case SOCIAL_PATCH_USER_ACTION_SUCCESS:
      // The request is success
      return {
        ...state,
        patchUserActionPending: false,
        patchUserActionError: null,
      };

    case SOCIAL_PATCH_USER_ACTION_FAILURE:
      // The request is failed
      return {
        ...state,
        patchUserActionPending: false,
        patchUserActionError: action.data.error,
      };

    case SOCIAL_PATCH_USER_ACTION_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        patchUserActionError: null,
      };

    default:
      return state;
  }
}
