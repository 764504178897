import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as alertActions from '../features/alert/redux/actions'
import * as deviceActions from '../features/device/redux/actions'
import * as userActions from '../features/user/redux/actions'
import * as socialActions from '../features/social/redux/actions'
import * as commonActions from '../features/common/redux/actions'
import mapStateToProps from './mapStateToProps'

/* istanbul ignore next */
function mapDispatchToProps (dispatch) {
  return {
    alertActions: bindActionCreators({ ...alertActions }, dispatch),
    deviceActions: bindActionCreators({ ...deviceActions }, dispatch),
    userActions: bindActionCreators({ ...userActions }, dispatch),
    socialActions: bindActionCreators({ ...socialActions }, dispatch),
    commonActions: bindActionCreators({ ...commonActions }, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)
