import React, { PureComponent } from 'react'
import classNames from 'classnames'
import { withRouter } from 'react-router'
import { isAdmin, coordString, getDeviceSlug, getUserSetting, socialDevices } from '../../common/ambient'
import bindAllActions from '../../common/bindAllActions'
import { ambient } from '../../common/lib/ambient-weather-common'
import ReactPlayer from 'react-player'
import {
  CreateForecast
} from './'
import {
  QuickLocation,
  ImageUploadLink,
  Loader,
  Location,
  Share
} from '../../components'
import { EveryWidget, TempWidget } from '../device'
import PropTypes from 'prop-types'
import { head, last, lensPath, path, set } from 'ramda'
import { getUrl } from '../../common/ambient/utils'
import { circleToPolygonSafe } from '../../common/ambient/geo'

const CHARACTER_LIMIT = 300
const ALERT_HOURS = 6

const mockForecast = forecast => {
  const forecastDaysUseMillis = forecast.map(f => {
    return Object.assign({}, f, {
      time: parseInt(f.time / 1000, 10),
      int: true // used to round down in display
    })
  })
  // duplicate first day because it's used for "today" and
  // we need it to show up in otherDays
  const data = [forecastDaysUseMillis[0], ...forecastDaysUseMillis]
  return {
    daily: { data },
    hourly: [],
    currently: { }
  }
}

class CreatePost extends PureComponent {
  static propTypes = {
    currentDevice: PropTypes.object,
    share: PropTypes.object,
    postType: PropTypes.string,
    webcamSnapshot: PropTypes.string, // snapshot of latest.jpg
    prompt: PropTypes.string, // prompt to show
    video: PropTypes.bool, // depends on webcamSnapshot if true, take snapshot of video instead of webcam
    onSuccess: PropTypes.func
  }

  state = {
    text: '',
    // image: 'https://cdn.filestackcontent.com/8iyUi7XcQfS04GoOaJsF',
    image: null,
    tab: 'main',
    step: 1,
    expires: 'week',
    type: 'text'
  }

  _type () {
    return this.props.postType || this.state.type
  }

  /**
   * This will be stored in the post and used on the flipside for presentation
   */
  _data () {
    const { user, device, prompt } = this.props
    const { forecast, location } = this.state
    const currentDevice = this._currentDevice()
    let data = null
    if (/widget/.test(this._type())) {
      const widgetKey = this._type().replace('widget-', '')
      data = {
        currentDevice: {
          lastData: currentDevice.lastData,
          settings: currentDevice.settings,
          tz: currentDevice.tz
        }
      }
      // put extra share paths in data
      const socialPaths = path(['WIDGET_CONFIG', widgetKey, 'share', 'paths'], ambient)
      if (socialPaths) {
        socialPaths.forEach(pth => {
          const lens = lensPath(pth)
          data = set(lens, path(pth, this.props), data)
        })
      }
      if (/forecast/.test(this._type())) {
        // user generated
        if (forecast) {
          data.forecast = mockForecast(forecast)
          data.currentDevice.settings['my-forecast'] = { title: `Forecast created by ${path(['info', 'name'], currentDevice)}` }
        // sharing their current forcast
        } else {
          data.forecast = device.forecastCache[coordString(currentDevice.info.coords.coords.lat, currentDevice.info.coords.coords.lon)]
          data.currentDevice.settings['my-forecast'] = { title: 'Official Forecast' }
        }
        data.currentDevice.lastData.dateutc = Date.now() // set timestamp to now just in case device hasnt updated recently
        data.user = {
          settings: {
            hour24: getUserSetting('hour24')(user)
          }
        }
      }
    }
    // remote location label
    if (location) {
      data = data || {}
      data.location = location
    }
    if (prompt) {
      data = data || {}
      data.prompt = prompt
    }
    return data
  }

  _currentDevice () {
    const { user, device, currentDevice } = this.props
    // ----------------------- V - for now we take the first, eventually they'll choose
    return currentDevice || head(socialDevices(user, device))
  }

  _create () {
    const { type, expires, text, image, coords, video } = this.state
    const { social, device, history, onSuccess, socialActions, postType } = this.props
    const { createPostPending } = social
    if (createPostPending) return
    const currentDevice = this._currentDevice()
    socialActions.createPost({
      text,
      image,
      video,
      type: this._type(),
      // geo: coords.geo && circleToPolygonSafe(coords.geo.coordinates, 100), // turn on for new geography
      geo: coords.geo,
      deviceId: currentDevice._id,
      status: 'published',
      expiresAt: this._expiration(),
      data: {
        ...this._data(),
        centerGeo: coords.geo
      }
    })
      .then(post => {
        if (onSuccess) {
          onSuccess(post)
        }
        history.push(`/dashboard/${getDeviceSlug(currentDevice)}/social`)
      })
  }

  _expiration () {
    const { type, expires, forecast } = this.state
    let ret = this._expirations()[expires]
    if (type === 'alert') {
      ret = moment().add(ALERT_HOURS, 'hours').valueOf()
    } else if (forecast) {
      ret = moment(last(forecast).time).endOf('day')
    }
    return ret
  }

  _expirations () {
    return {
      month: moment().add('1', 'month').valueOf(),
      week: moment().add('1', 'week').valueOf(),
      tomorrow: moment().add(1, 'day').valueOf(),
      tonight: moment().endOf('day').valueOf()
    }
  }

  _createForecast () {
    const { type, createForecast } = this.state
    if (type !== 'text') return
    if (createForecast) {
      return (
        <CreateForecast 
          key='create-forecast'
          onFinished={forecast => {
            const st8 = {
              createForecast: null 
            }
            if (forecast) {
              st8.forecast = forecast
              st8.type = 'widget-my-forecast'
            }
            this.setState(st8)
          }}
        />
      )
    }
    return <a onClick={() => this.setState({ createForecast: true })} className='create-forecast-link'>Create Your Own Forecast</a>
  }

  _location () {
    const { remote } = this.props
    const { coords } = this.state
    if (!coords || !remote) return null
    return (
      <div className='location'>
        <div>
          Posting from: {coords.location}
        </div>
        <Location location={coords} onChange={newCoords => {
          // add geo
          newCoords.geo = {
            type: 'Point',
            coordinates: [newCoords.coords.lon, newCoords.coords.lat]
          }
          this.setState({
            coords: newCoords,
            location: newCoords.location
          })
        }} />
      </div>
    )
  }

  componentDidMount () {
    const { webcamSnapshot, image, video } = this.props
    const currentDevice = this._currentDevice()
    const coords = this.props.coords || path(['info', 'coords'], currentDevice)
    const origState = {
      coords
    }
    if (webcamSnapshot) {
      origState.loadingImage = true
      fetch(`${getUrl()}/images/snapshot`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ deviceId: webcamSnapshot, video })
      })
        .then(res => res.json())
        .then(res => {
          const st8 = {
            loadingImage: false
          }
          if (res.ok) {
            st8.image = res.url
            st8.video = res.video
          }
          this.setState(st8)
        })
    }
    if (image) {
      origState.image = image
    }
    this.setState(origState)
  }

  // v2 - multistep process
  render () {
    const { type, text, loadingImage, image, step, createForecast, forecast, video, forecastChanged } = this.state
    const { prompt, share, currentDevice, social } = this.props
    const { createPostPending } = social
    const widget = /widget/.test(this._type())
    const nonMyForecastWidget = widget && !/my-forecast/.test(this._type())
    let alertCheckbox = null
    if ((text !== '' || image) && !createForecast && !widget && !video) {
      alertCheckbox = (
        <label>
          <input type='checkbox' checked={type === 'alert'} onChange={evt => this.setState({ type: evt.target.checked ? 'alert' : 'text' })} />
          My post is a weather alert
        </label>
      )
    }
    const show = [
      <div key='text' className={classNames('text-wrap', { 'not-empty': text !== '', empty: text === '' })}>
        {step === 2 
          ? <div className='textarea'>{text}<a className='edit' onClick={() => this.setState({ step: 1, createForecast: !!forecast })}>Edit</a></div> 
          : <textarea 
            className={classNames({ filled: text !== '' })} 
            placeholder={prompt || 'How is the weather affecting your day?'}
            value={text} 
            onChange={evt => this.setState({ text: evt.target.value })} 
          />
        }
        <div className={classNames('chars-left', {
          error: CHARACTER_LIMIT - text.length < 6
        })}>{CHARACTER_LIMIT - text.length}</div>
      </div>,
      <div key={'is-alert' + type} className={classNames('is-alert', { 'type-alert': type === 'alert' }) }>
        {alertCheckbox}
      </div>
    ]
    if (loadingImage) {
      show.push(<Loader className='image-loading' key='loading-image' />)
    } else if (createForecast && step === 1) {
      show.push(<CreateForecast
        key='create-forecast'
        onChange={forecast => {
          const st8 = {}
          st8.forecast = forecast
          st8.type = 'widget-my-forecast'
          if (this.state.forecast) {
            st8.forecastChanged = true
          }
          this.setState(st8)
        }}
      />)
    } else if (video) {
      show.push(<div className='video-container' key={video}>
        <ReactPlayer
          className='video'
          url={video}
          light={image}
          controls
          muted
          loop
          width='100%'
          height='100%'
        />
        <a className='close' onClick={() => this.setState({ video: null, image: null })} />
                </div>)
    } else if (image) {
      show.push(<div className='img-wrap' key={image}>
        <div className='img img-contain' style={{ backgroundImage: `url(${image})` }} />
        <ImageUploadLink
          onSuccess={image => {
            this.setState({
              image
            })
          }}
        >
          Edit
        </ImageUploadLink>
        <a className='close' onClick={() => this.setState({ image: null })} />
                </div>)
    } else if (step === 1) {
      show.push(
        <ImageUploadLink
          key='image-upload-link'
          onSuccess={image => {
            this.setState({
              image
            })
          }}
        >
          <div>
            <div className='camera-btn'>
              <div className='camera' />
            </div>
            <span>Add photo</span>
          </div>
        </ImageUploadLink>
      )
    }
    if (widget && !createForecast) {
      const widgetType = this._type().replace('widget-', '')
      const data = this._data() || {}
      show.push(<EveryWidget
        key='every-widget'
        snapshot
        type={widgetType}
        {...this._data()}
                />)
    }
    if (step === 2) {
      let location = (
        <QuickLocation 
          key='quick-location' 
          currentDevice={currentDevice} 
          onChange={loc => {
            this.setState({
              location: loc.location.location || loc.label,
              coords: loc.location
            })
          }}
        />
      )
      // mimic QuickLocation structure
      if (widget) {
        location = (
          <div key='location' className='component-quick-location fake'>
            <b>{currentDevice.info.name}</b>
            <span>{currentDevice.info.coords.location}</span>
          </div>
        )
      }
      show.push(location)
      show.push(this._expiresAfter())
    }
    let btn = null
    if (step === 1 && (nonMyForecastWidget || text !== '' || image || (createForecast && forecast && forecastChanged))) {
      btn = <a className='btn btn-primary' onClick={() => this.setState({ step: 2, createForecast: false })}>Next</a>
    } else if (step === 2) {
      btn = <a className={classNames('btn btn-primary', { loading: createPostPending })} onClick={this._create.bind(this)}>{createPostPending ? <Loader /> : 'Post'}</a>
    }
    return (
      <div className={classNames(this._classNames(), 'multi-step', `step-${step}`)}>
        <div className='top'>{btn}</div>
        <div className='rect-tabs'>
          <a 
            className={classNames('tab', { active: !createForecast })} 
            onClick={() => this.setState({ 
              createForecast: false, 
              forecast: null,
              type: 'text' 
            })}
          >Create Post</a>
          {nonMyForecastWidget ? null :
            <a 
              className={classNames('tab', { active: createForecast })} 
              onClick={() => this.setState({ 
                type: 'text',
                createForecast: true, 
                image: null 
              })}
            >Create Forecast</a>
          }
        </div>
        <div className='tab-content'>
          {show}
          {btn && <div className='bottom-btn'>{btn}</div>}
          {share && step === 1 ? <div className='share-wrap'>
            <h4>or share outside of Ambient Weather</h4>
            <div>
              <div className='fb' />
              <div className='twitter' />
              <div className='email' />
            </div>
            <Share {...share} />
          </div> : null}
        </div>
      </div>
    )
  }

  _classNames () {
    const { user, remote } = this.props
    const { image, createForecast } = this.state
    const widget = /widget/.test(this._type())
    return classNames('social-create-post', {
      image: !!image,
      widget,
      remote,
      admin: isAdmin(user),
      'create-forecast': createForecast
    }, this._type())
  }

  _expiresAfter () {
    const { expires, forecast, type } = this.state
    let expiresAt = (
      <select
        className='form-control carrot-gray'
        value={expires}
        onChange={evt => this.setState({ expires: evt.target.value })}
        >
        {Object.keys(this._expirations()).map(e => <option value={e} key={e}>{e}</option>)}
      </select>
    )
    if (type === 'alert') {
      expiresAt = <span>{ALERT_HOURS} hours</span>
    } else if (forecast) {
      const l = forecast.length
      expiresAt = <span>{l} day{l > 1 ? 's' : null}</span>
    }
    return (
      <div key='expires-after' className='expires'>
        <label>Expires after: </label>
        {expiresAt}
      </div>
    )
  }
}

export default bindAllActions(withRouter(CreatePost))
CreatePost.displayName = 'CreatePost'
