import {
  FETCH_POSTS_BEGIN,
  FETCH_POSTS_SUCCESS,
  FETCH_POSTS_FAILURE,
  FETCH_POSTS_DISMISS_ERROR
} from './constants'
import { serviceFind } from '../../../common/feathers'
import { sort, concat, pipe, prop, uniqBy } from 'ramda'

export const fetchPosts = serviceFind('posts', {
  begin: FETCH_POSTS_BEGIN,
  success: FETCH_POSTS_SUCCESS,
  fail: FETCH_POSTS_FAILURE
})

export function dismissFetchPostsError () {
  return {
    type: FETCH_POSTS_DISMISS_ERROR
  }
}

export function reducer (state, action) {
  let st8
  switch (action.type) {
    case FETCH_POSTS_BEGIN:
      st8 = {
        ...state,
        fetchPostsPending: true,
        fetchPostsError: null
      }
      if (action.dataKey === 'boosted') {
        st8.fetchedBoosted = true
      }
      return st8

    case FETCH_POSTS_SUCCESS:
      st8 = {
        ...state,
        fetchPostsPending: false,
        fetchPostsError: null,
        allPosts: pipe(
          concat(action.data || []),
          uniqBy(prop('_id')),
          sort((a, b) => {
            return b.expiresAt - a.expiresAt
          })
        )(state.allPosts)
      }
      if (action.dataKey === 'boosted') {
        st8.fetchedBoosted = true
      }
      return st8

    case FETCH_POSTS_FAILURE:
      return {
        ...state,
        fetchPostsPending: false,
        fetchPostsError: action.data.error
      }

    case FETCH_POSTS_DISMISS_ERROR:
      return {
        ...state,
        fetchPostsError: null
      }

    default:
      return state
  }
}
