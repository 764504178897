import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import bindUserActions from './redux/bindUserActions';
import classNames from 'classnames'

class MetricChooser extends PureComponent {
  static propTypes = {
    user: PropTypes.object,
    actions: PropTypes.object
  }
  _onClick(metric) {
    const { actions } = this.props
    return () => {
      actions.setUnits(metric)
    }
  }

  render() {
    const { userUnits } = this.props.user
    return (
      <div className="user-metric-chooser">
        <a className={classNames({
          active: !userUnits
        })} onClick={this._onClick(false)}>F°</a>
        <a className={classNames({
          active: userUnits
        })} onClick={this._onClick('metric')}>C°</a>
      </div>
    )
  }
}

export default bindUserActions(MetricChooser)
MetricChooser.displayName = 'MetricChooser'

MetricChooser.displayName = 'MetricChooser'

MetricChooser.displayName = 'MetricChooser'

MetricChooser.displayName = 'MetricChooser'

MetricChooser.displayName = 'MetricChooser'

MetricChooser.displayName = 'MetricChooser'

MetricChooser.displayName = 'MetricChooser'
