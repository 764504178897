import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { contains, last } from 'ramda'
import { dataHas, convertUnitForUser, pathsChanged } from '../../common/ambient'
import FormattedDataPoint from './FormattedDataPoint'
import md5 from 'blueimp-md5'
import bindAllActions from '../../common/bindAllActions'

class FromYesterday extends PureComponent {
  static propTypes = {
    device: PropTypes.object,
    user: PropTypes.object,
    currentDevice: PropTypes.object,
    deviceActions: PropTypes.object,
    showNegative: PropTypes.bool,
    type: PropTypes.string
  }

  constructor (props) {
    super(props)
    this.state = {
      lastUpdate: false
    }
  }

  _yesterdayData () {
    const { device } = this.props
    const { yesterdayData } = device
    if (yesterdayData && yesterdayData[0]) {
      if (yesterdayData.length > 1) {
        return last(yesterdayData)
      }
      return yesterdayData[0]
    }
  }

  _shouldUpdate () {
    const { lastUpdate } = this.state
    const { currentDevice, device } = this.props
    const { fetchDeviceDataKeysPending } = device
    const yesterdayData = this._yesterdayData()
    if (contains('yesterdayData', fetchDeviceDataKeysPending)) return false
    if (!lastUpdate) return true
    if (yesterdayData && yesterdayData.PASSKEY !== md5(currentDevice.macAddress)) return true
    if ((Date.now() - lastUpdate) > 1000 * 60 * 10) return true
  }

  componentDidUpdate (prevProps) {
    const { deviceActions, currentDevice } = this.props
    const { fetchDeviceData } = deviceActions
    const data = currentDevice.lastData
    const yesterday = moment(data.dateutc).subtract(1, 'days')
    if (this._shouldUpdate()) {
      this.setState({ lastUpdate: Date.now() }, () => {
        fetchDeviceData({
          macAddress: currentDevice.macAddress,
          dataKey: 'yesterdayData',
          start: yesterday.valueOf() - 1000 * 60 * 60,
          end: yesterday.valueOf(),
          limit: 1,
          asc: true
        })
      })
    }
  }

  render () {
    const { currentDevice, type, user, showNegative } = this.props
    const { lastData } = currentDevice
    let from = ''
    const yesterdayData = this._yesterdayData()
    if (yesterdayData &&
      dataHas(yesterdayData, type) &&
      lastData.dateutc - yesterdayData.dateutc > 1000 * 60 * 23 // make sure it's really from yesterday
    ) {
      const convert = convertUnitForUser(user, type)
      let diff = convert(lastData[type]) - convert(yesterdayData[type])
      if (type === 'tempf') {
        diff = diff.toFixed(1)
      }
      let fromClass = 'from '
      if (diff === 0) {
        fromClass += 'zero'
      } else if (diff < 0) {
        fromClass += 'down'
      }
      const value = showNegative ? (diff < 0 ? '' : '+') + diff : Math.abs(diff)
      from = (
        <div className={fromClass}>
          <div className='label'>
            From Yesterday
          </div>
          <FormattedDataPoint type={type} value={value} skipConvert />
        </div>
      )}
    return (
      <div className='device-from-yesterday'>
        {from}
      </div>
    )
  }
}

export default bindAllActions(FromYesterday)

FromYesterday.displayName = 'FromYesterday'
