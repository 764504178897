import React, { PureComponent } from 'react';
import bindAllActions from '../../common/bindAllActions';
import PropTypes from 'prop-types';
import { append, uniqBy, prop, concat, path, reverse } from 'ramda'
import classNames from 'classnames'
import Linkify from 'linkifyjs/react'
import { dateVal, isAdmin, isLoggedIn, isRole, trackEvent } from '../../common/ambient';
import { ProtectedLink, Loader } from '../../components';
import { 
  UsernameEdit
} from '../user'

const nl2br = function(str) {
  const newlineRegex = /(\r\n|\r|\n)/g;
  if (typeof str !== 'string') {
    return str;
  }

  return str.split(newlineRegex).map(function(line, index) {
    if (line.match(newlineRegex)) {
      return <br key={index} />
    }
    return line;
  })
}

const PER_PAGE = 100
class Comments extends PureComponent {
  static propTypes = {
    post: PropTypes.object

  };
  state = {
    comment: '',
    loading: false,
    expanded: false,
    page: 1,
    showOlder: false,
    usernamePrompt: false
  }
  constructor(props) {
    super(props)
    this._addComment = ::this._addComment
    this._showMore = ::this._showMore
    this._deleteComment = ::this._deleteComment
  }
  _showMore() {
    const { socialActions, post } = this.props
    const { comments, page } = this.state
    this.setState({
      expanded: true,
      loading: true
    })

    socialActions.fetchUserActions({
      type: 'comment',
      'to._id': post._id,
      $limit: PER_PAGE,
      $skip: (page - 1) * PER_PAGE,
      $sort: {
        createdAt: -1
      }
    })
      .then(res => {
        const newComments = uniqBy(prop('_id'), concat(comments || [], res.data))
        this.setState({ 
          comments: newComments,
          loading: false,
          showOlder: res.total > newComments.length && res.data.length > 0 
        })
      })
  }
  _addComment(evt) {
    if (evt) {
      evt.preventDefault()
    }
    const { comment, comments } = this.state
    const { socialActions, post, user } = this.props
    if (comment === '') return
    this.setState({
      comment: '',
      loading: true
    })
    socialActions.addUserAction({
      to: {
        _id: post._id,
        userId: post.userId,
        type: 'post'
      },
      type: 'comment',
      text: comment,
      data: {
        username: user.info.username
      }
    })
      .then(c => {
        const st8 = {
          loading: false
        }
        // user clicked "show all" so we're using internal state
        // for all the comments
        if (comments) {
          st8.comments = append(c, comments)
        }
        this.setState(st8)
      })
  }
  _deleteComment(comment) {
    const { socialActions, post } = this.props
    const { comments } = this.state
    const uaId = comment._id
    if (window.confirm('Are you sure?')) {
      socialActions.removeUserAction(uaId)
      trackEvent('social', 'post', 'comment-delete')
      this.setState({
        comments: (comments || post.recentComments).filter(c => c._id !== uaId)
      })
    }
  }

  render() {
    const { usernamePrompt, page, showOlder, expanded, comment, loading, comments } = this.state
    const { post, user, userActions } = this.props
    if (!post) return null
    const commentSort = (a, b) => {
      if (!a.createdAt && b.createdAt) return 1
      if (!b.createdAt && a.createdAt) return -1
      if (!a.createdAt && !b.createdAt) return 0
      return dateVal(a.createdAt) - dateVal(b.createdAt)
    } 
    const username = path(['info', 'username'], user)
    const form = <form onSubmit={this._addComment}>
      {username ? <span className='username'>{username}</span> : null}
      <input className='form-control' type="text" value={comment} onChange={evt => this.setState({ comment: evt.target.value })} placeholder="Add a comment..." />
      <button className="a" onSubmit={this._addComment}>Add</button>
    </form>
    let showMore, showShowOlder, showUsernameEdit
    const commentsToShow = comments || post.recentComments || []
    if (post.comments > 2 && !expanded) {
      showMore = <a onClick={this._showMore} className='show-more'>View all {comments ? '' : post.comments} comments</a>
    }
    if (showOlder) {
      showShowOlder = <a onClick={() => {
        this.setState({
          page: page + 1
        }, this._showMore)
      }}>Show older</a>
    }
    if (!username) {
      showUsernameEdit = <ProtectedLink onClick={() => userActions.doModal({
        type: 'create-username'
      }) } />
    }
    let showAddComment = form
    if (loading) {
      showAddComment = <Loader />
    } else if (!username && usernamePrompt) {
      showAddComment = <div className='username-edit'>
        <span className='label'>Before you comment please...</span>
        <UsernameEdit />
      </div> 
    } else if (isLoggedIn(user) && !isRole('social', user)) {
      showAddComment = null
    }
    return (
      <div className="social-comments">
        <div className={classNames('comments', { empty: !post.recentComments || post.recentComments.length < 1 })}>
          {showShowOlder}
          {commentsToShow.sort(commentSort).map(c => {
            const username = path(['data', 'username'], c) || 'anonymous'
            const canDelete = isAdmin(user) // admin
              || path(['info', '_id'], user) === c.userId // my comment
              || path(['info', '_id'], user) === post.userId // post owner
            return <div className='comment' key={c._id}>
              <span className='username'>{username}</span>
              {c.text ? <span className='body'><Linkify options={{ nl2br: true, target: { url: '_blank' } }}>{c.text}</Linkify></span> : null}
              {canDelete ? <a onClick={() => this._deleteComment(c)} className='delete'>delete</a> : null}
            </div>
          })}
          {showMore}
        </div>
        {showAddComment ?
          <div className='add-comment'>
            {showUsernameEdit}
            {showAddComment}
          </div> : null }
      </div>
    )
  }
}

export default bindAllActions(Comments)
Comments.displayName = 'Comments'

Comments.displayName = 'Comments'

Comments.displayName = 'Comments'

Comments.displayName = 'Comments'

Comments.displayName = 'Comments'

Comments.displayName = 'Comments'

Comments.displayName = 'Comments'
