import {
  CLEAR_CURRENT,
} from './constants';

export function clearCurrent() {
  return {
    type: CLEAR_CURRENT,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CLEAR_CURRENT:
      return {
        ...state,
        current: {}
      };

    default:
      return state;
  }
}
