import {
  SET_SIDEBAR
} from './constants'

export function setSidebar (data) {
  return {
    type: SET_SIDEBAR,
    data
  }
}

export function reducer (state, action) {
  switch (action.type) {
    case SET_SIDEBAR:
      return {
        ...state,
        sidebar: action.data
      }

    default:
      return state
  }
}
