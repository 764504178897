import {
  CREATE_POST_BEGIN,
  CREATE_POST_SUCCESS,
  CREATE_POST_FAILURE,
  CREATE_POST_DISMISS_ERROR
} from './constants'
import { serviceCreate } from '../../../common/feathers'
import { prepend } from 'ramda'
import { trackEvent } from '../../../common/ambient'

export const createPost = serviceCreate('posts', {
  begin: CREATE_POST_BEGIN,
  success: CREATE_POST_SUCCESS,
  fail: CREATE_POST_FAILURE
})

export function dismissCreatePostError () {
  return {
    type: CREATE_POST_DISMISS_ERROR
  }
}

export function reducer (state, action) {
  switch (action.type) {
    case CREATE_POST_BEGIN:
      return {
        ...state,
        createPostPending: true,
        createPostError: null
      }

    case CREATE_POST_SUCCESS:
      trackEvent('social', 'post', 'created')
      return {
        ...state,
        createPostPending: false,
        createPostError: null,
        allPosts: prepend(action.data, state.allPosts)
      }

    case CREATE_POST_FAILURE:
      return {
        ...state,
        createPostPending: false,
        createPostError: action.data.error
      }

    case CREATE_POST_DISMISS_ERROR:
      return {
        ...state,
        createPostError: null
      }

    default:
      return state
  }
}
