import { dateVal } from './utils'
import { hasSocial, deviceIsMine } from './'
import { path, curry, sort, filter, uniq, pluck, pipe, not, length, lt } from 'ramda'
import Base64 from '../lib/base64'

export const postIsExpired = post => dateVal(post.expiresAt) <= Date.now()
export const postIsActive = pipe(postIsExpired, not)
export const activePosts = filter(postIsActive)
export const deviceIdsWithActivePosts = pipe(
  activePosts,
  pluck('deviceId'),
  uniq
)

export const mapShowSocial = mapLayer => mapLayer === ''

export const postsSort = sort((a, b) => {
  if (a.type === 'alert' && b.type !== 'alert') return -1
  if (a.type !== 'alert' && b.type === 'alert') return 1
  return dateVal(b.createdAt) - dateVal(a.createdAt)
})

export const postIsMine = curry((user, post) => {
  const userId = path(['info', '_id'], user)
  return userId && userId === post.userId
})

export const canSocialPost = curry((user, device, theDevice) => {
  return hasSocial(user) && path(['lastData'], theDevice) && path(['info', 'coords'], theDevice) && deviceIsMine(device, theDevice._id) && path(['public'], theDevice)
})

export const socialDevices = (user, device) => {
  return filter(canSocialPost(user, device), device.devices || [])
}

export const userCanSocialPost = pipe(socialDevices, length, lt(0))

// for use with ssis service
export const ssisShareLink = (data, meta = {}) => {
  return 'https://share.ambientweather.net/share/' + data + '/' + window.encodeURIComponent(Base64.encode(JSON.stringify(meta)))
}
