import PropTypes from 'prop-types'
import React, { Component } from 'react'
import bindAllActions from '../../common/bindAllActions'
import { isLoggedIn } from '../../common/ambient'
import classNames from 'classnames'
import {
  // DefaultPage,
  DeviceListRows,
  CreateDevice
} from './'
import {
  DefaultPage
} from '../user'

export class MyDevices extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired
  };

  componentDidMount () {
    this.props.deviceActions.dismissSaveCurrentError() // this clears out 'recentlyCreated'
  }

  render () {
    const { device, deviceActions, user, userActions } = this.props
    const show = [<DeviceListRows key={2} />]
    let v3 = false
    if (isLoggedIn(user)) {
      v3 = true
      show.unshift(
        <CreateDevice
          user={user}
          device={device}
          deviceActions={deviceActions}
          userActions={userActions}
          key={3}
        />
      )
    } else {
      show.push(
        <div key={1} className='block'>
          <DefaultPage />
        </div>
      )
    }
    return (
      <div className={classNames('device-my-devices v2', { v3 })}>
        <header className='main'>
          <h1>
            My Devices
          </h1>
        </header>
        <div className='page-body'>
          {show}
        </div>
      </div>
    )
  }
}
export default bindAllActions(MyDevices)

MyDevices.displayName = 'MyDevices'
