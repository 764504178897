import {
  FETCH_USER_BEGIN,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  FETCH_USER_DISMISS_ERROR
} from './constants'
import { app } from '../../../common/feathers'
import { path } from 'ramda'
import { logoutUser } from './logoutUser'
import { fetchUserActions } from '../../social/redux/fetchUserActions'
import { isAdmin } from '../../../common/ambient'
import { isPlus } from '../../payment'

export function fetchUser (args) {
  return (dispatch) => {
    dispatch({
      type: FETCH_USER_BEGIN
    })
    if (args) {
      args.type = 'local'
    }

    return app.ambientLogin(args).then(function (result) {
      // ghost user
      const email = path(['data', 'email'], result)
      if (/^ghost_user/.test(email) && /ambientweather\.net/.test(email)) {
        logoutUser()(dispatch)
        return
      }
      // apps need to know the user logged in
      if (path(['webkit', 'messageHandlers', 'setAppDevice'], window)) {
        window.webkit.messageHandlers.setAppDevice.postMessage({});
      } else if (window.ANDROID) {
        window.ANDROID.setAppDeviceNotification();
      }
      dispatch({
        type: FETCH_USER_SUCCESS,
        data: result.data
      })
      // fetch favs
      if (result.data._id) {
        fetchUserActions({
          userId: result.data._id,
          'to.type': 'device',
          dataKey: 'favs',
          $select: ['to'],
          $limit: 1000,
          $sort: { createdAt: -1 }
        })(dispatch)
      }
      return result
    }).catch((error) => {
      dispatch({
        type: FETCH_USER_FAILURE,
        data: {
          error: error
        }
      })
    })
  }
}

export function dismissFetchUserError () {
  return {
    type: FETCH_USER_DISMISS_ERROR
  }
}


export function reducer (state, action) {
  // alter as you see fit
  switch (action.type) {
    case FETCH_USER_BEGIN:
      return {
        ...state,
        fetchUserPending: true,
        fetchUserError: null
      }

    case FETCH_USER_SUCCESS:
      action.data.settings = action.data.settings || {}
      // const pro = action.data.roles.includes('admin')
      return {
        ...state,
        info: action.data,
        plus: isPlus(action.data),
        userChecked: true,
        fetchUserPending: false,
        fetchUserError: null
      }

    case FETCH_USER_FAILURE:
      return {
        ...state,
        fetchUserPending: false,
        userChecked: true,
        fetchUserError: action.data.error
      }

    case FETCH_USER_DISMISS_ERROR:
      return {
        ...state,
        fetchUserError: null
      }

    default:
      return state
  }
}
