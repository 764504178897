import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { path, pipe, toPairs, filter } from 'ramda'
import bindAllActions from '../../common/bindAllActions'
import SettingRadio from '../user/SettingRadio'
import SettingSelect from '../user/SettingSelect'
import PhoneProcess from '../user/PhoneProcess'
import AlertCrud from './Crud'
import Instances from './Instances'
import QuestionMark from '../../components/QuestionMark'
import AmbRadio from '../../components/AmbRadio'
import { isUserPhoneConfirmed, getUserSetting, isCordova, isRole, isApp } from '../../common/ambient'
import { CopyLink, Loader } from '../../components'
import { isPlus } from '../payment'
import { isDev } from '../../common/ambient/utils'

class DefaultPage extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    alertActions: PropTypes.object.isRequired,
    userActions: PropTypes.object.isRequired,
    alert: PropTypes.object.isRequired,
    device: PropTypes.object.isRequired
  };

  state = {
    multiSmsChecked: false,
    alertRecipientEmailInfo: {}
  }

  componentDidMount () {
    this._checkMultiSmsLink()
  }

  componentDidUpdate () {
    this._checkMultiSmsLink()
  }

  _checkMultiSmsLink () {
    const { user } = this.props
    const { multiSmsChecked } = this.state
    const { userChecked } = user
    if (userChecked && this._hasMultiSms() && !multiSmsChecked) {
      this.setState({ multiSmsChecked: true })
      this._fetchMultiSmsLink()
    }
  }

  _fetchMultiSmsLink () {
    this.setState({ multiSmsLinkUaFetched: false })
    this.props.socialActions.fetchUserActions({
      userId: this.props.user.info._id,
      type: 'multi-sms-link'
    })
      .then(res => {
        this.setState({
          multiSmsLinkUa: res.data[0],
          multiSmsLinkUaFetched: true
        })
      })
  }

  _socialPrefs () {
    const { user } = this.props
    const alertSettings = getUserSetting('alerts')(user) || {}
    const settingKey = 'social'
    const socialPrefs = [
      ['Email & Website', 'all'],
      [`${isCordova() ? 'App' : 'Website'} Only`, 'app']
    ]
    const radios = socialPrefs.map((arr) => {
      const label = arr[0]
      const key = arr[1]
      return (
        <SettingRadio
          defaultValue='all'
          key={key}
          setting='alerts'
          settingKey={settingKey}
          value={key}
          label={label}
        />
      )
    })
    return (
      <div className='block social-comm'>
          <h3>Social Feed Notifications</h3>
          <div className='prefs'>
            {radios}
          </div>
          {!alertSettings[settingKey] || alertSettings[settingKey] === 'all' ? <div className='message'>{this._emailMessage()}</div> : ''}
          </div>
    )
  }

  _emailMessage () {
    const { user, userActions } = this.props
    let message
    if (path(['info', 'confirmKey'], user)) {
      const link = user.sendConfirmationSucceeded
        ? <b>Confirmation Sent!</b>
        : <a
            className='btn btn-primary'
            onClick={() => {
              userActions.sendConfirmation({ user: user.info._id, confirmKey: true })
          }}
          >Resend</a>
      message = (
        <p className="warning">
          <span>Alerts will not be sent until your email address ({user.info.email}) is confirmed. </span>
          {link}
        </p>
      )} else {
      message = <p>We'll send emails to {user.info.email}.</p>
    }
    return message
  }

  _hasMultiSms () {
    return isRole('multi-sms', this.props.user) || this.props.user.pro
  }

  render () {
    const { socialActions, userActions, user } = this.props
    const { multiSmsLinkUa, multiSmsLinkUaFetched, alertRecipientEmailInfo, appAlertExplain } = this.state
    const { fetchUserPending } = user
    const alertSettings = getUserSetting('alerts')(user) || {}
    const alertChannel = [
      [<span>No&nbsp;notifications</span>, 'none'],
      ['Email', 'email'],
      ['App', 'app'],
      ['SMS', 'sms']
    ]
    const alertFreqs = [
      ['10 minutes', '10min'],
      ['30 minutes', '30min'],
      ['1 hour', 'hour'],
      ['1 day', 'day']
    ]
    let radios = alertChannel.map((arr) => {
      const label = arr[0]
      const key = arr[1]
      return (
        <SettingRadio
          key={key}
          setting='alerts'
          settingKey='type'
          value={key}
          label={label}
        />
      )
    })
    let alertTypes = []
    if (alertSettings.type) {
      alertTypes = typeof alertSettings.type === 'string' ? [alertSettings.type] : alertSettings.type
    }
    const hasAlertType = type => alertTypes.includes(type)

    const hasAppDevice = path(['info', 'appDevices'], user)

    radios = alertChannel.map((arr) => {
      const label = arr[0]
      const key = arr[1]
      return (
        <AmbRadio
          key={key}
          label={label}
          className={key === 'sms' && 'plus'}
          selected={key === 'none' ? alertTypes.length === 0 : hasAlertType(key)}
          onClick={() => {
            if (key === 'sms' && !isPlus(user)) {
              userActions.doModal({
                type: 'plus-subscription',
                data: {
                  message: 'SMS alerts are only available to AWN+ subscribers.'
                }
              })
              // dont let them add it
              if (!hasAlertType(key)) {
                return
              }
            }
            if (key === 'app' && !hasAppDevice) {
              this.setState({ appAlertExplain: true })
              return
            }
            let newAlertSettings
            if (key === 'none') {
              newAlertSettings = Object.assign({}, alertSettings, { type: [] })
            } else {
              newAlertSettings = Object.assign({}, alertSettings, { type: hasAlertType(key) ? alertTypes.filter((type) => type !== key) : alertTypes.concat(key) })
            }
            userActions.updateSetting('alerts', newAlertSettings)
          }}
        />
      )
    })
    const messages = []
    if (hasAlertType('email')) {
      messages.push(<div key='email'>{this._emailMessage()}</div>)
    }
    if (hasAlertType('sms')) {
      const yesPhone = (
        <p>
          We'll send texts to {user.info.phone}
          <small>
            <a className='glyphicon glyphicon-trash' onClick={() => {
              if (window.confirm('Are you sure?')) {
                userActions.patch(user.info._id, { phone: '', phoneConfirmKey: false })
              }
            }}
            />
          </small>
          <span className='pull-right'>
            SMS messages remaining: {path(['info', 'smsBankUpdated'], user) ? (user.info.smsBank || 0) : 10}
          </span>
        </p>
      )
      messages.push(<div key='sms'>{isUserPhoneConfirmed(user) ? yesPhone : <PhoneProcess />}</div>)
    }
    if (hasAlertType('app')) {
      const devicesWithPermission = pipe(
        path(['info', 'appDevices']),
        toPairs,
        filter(([key, obj]) => obj.notificationsEnabled)
      )(user)
      if (!fetchUserPending && isApp() && devicesWithPermission.length < 1) {
        messages.push(
          <p key='app'>
            You have no devices with permission to receive push notifications.
            {isApp() && <span> Please go to Settings &gt; Notifcations &gt; Ambient Weather to enable push notifications</span>}
          </p>
        )
      }
    }
    let freq = ''
    if (alertTypes.length > 0) {
      freq = (
        <SettingSelect
          setting='alerts'
          settingKey='freq'
          values={alertFreqs}
          label='Notification Time Window'
        />
      )
    }
    let multiSms = null
    if (this._hasMultiSms()) {
      let smsBody = <Loader />
      let recipients = null
      if (multiSmsLinkUaFetched) {
        if (multiSmsLinkUa) {
          const shareLink = `https://${window.location.host}/common/ua/${multiSmsLinkUa._id}?code=${multiSmsLinkUa.data.code}`
          smsBody = (
            <div className='alert-recipient-forms'>
              <p>To invite an <b>SMS</b> alert recipient share this link:</p>
              <p>
                <input type='text' className='sms-share-input' value={shareLink} onFocus={e => e.currentTarget.select()} />
                &nbsp;<CopyLink text={shareLink} className='btn btn-primary' />
                &nbsp;&nbsp;
                <a
                  onClick={() => {
                    if (window.confirm('The current share link will no longer work. Continue?')) {
                      socialActions.patchUserAction(multiSmsLinkUa._id, { data: { code: Math.floor(Math.random() * 1000000) } })
                        .then(res => {
                          console.log('patched', res)
                          this.setState({
                            multiSmsLinkUa: null,
                            multiSmsLinkUaFetched: false,
                            multiSmsChecked: false
                          })
                        })
                    }
                  }}
                  className='small'
                >revoke</a>
              </p>
              <div className='email'>
                <p>To add an <b>email</b> based recipient add their email address below:</p>
                <div>
                  <div>
                    <label>Name</label>
                    <input value={alertRecipientEmailInfo.name} onChange={evt => this.setState({ alertRecipientEmailInfo: Object.assign({}, alertRecipientEmailInfo, { name: evt.target.value }) })} type='text' className='form-control' />
                  </div>
                  <div>
                    <label>Email</label>
                    <input value={alertRecipientEmailInfo.email} onChange={evt => this.setState({ alertRecipientEmailInfo: Object.assign({}, alertRecipientEmailInfo, { email: evt.target.value }) })} type='email' className='form-control' />
                  </div>
                  <button className='btn btn-primary' onClick={() => {
                    const settings = Object.assign({}, alertSettings)
                    settings.smsRecipients = settings.smsRecipients || []
                    settings.smsRecipients.push(alertRecipientEmailInfo)
                    userActions.updateSetting('alerts', settings)
                    this.setState({ alertRecipientEmailInfo: { name: '', email: '' } })
                  }} disabled={!alertRecipientEmailInfo.name || !alertRecipientEmailInfo.email}>Add Email Recipient</button>
                </div>
              </div>
            </div>
          )
        } else {
          smsBody = (
            <div className='message'>
              <button
                className='btn btn-primary'
                onClick={() => {
                  socialActions.addUserAction({
                    userId: user.info._id,
                    type: 'multi-sms-link',
                    data: {
                      code: Math.floor(Math.random() * 1000000)
                    }
                  })
                    .then(this._fetchMultiSmsLink.bind(this))
                }}
              >
                Invite alert subscribers
              </button>
            </div>
          )
        }
      }
      if (alertSettings.smsRecipients && alertSettings.smsRecipients.length) {
        recipients = (
          <div className='sms-recipients'>
            <table className='table table-striped'>
              <thead>
                <th>Name</th>
                <th>Type</th>
                <th>Contact</th>
                <th>Enabled?</th>
                <th>Delete</th>
              </thead>
              <tbody>
                {alertSettings.smsRecipients.map((recipient, i) => {
                  return (
                    <tr key={i}>
                      <td>{recipient.name}</td>
                      <td>{recipient.phone ? 'SMS' : 'Email'}</td>
                      <td>{recipient.phone || recipient.email}</td>
                      <td>
                        <input
                          type='checkbox'
                          checked={!recipient.disabled}
                          onChange={() => {
                            const settings = Object.assign({}, alertSettings)
                            settings.smsRecipients[i].disabled = !settings.smsRecipients[i].disabled
                            userActions.updateSetting('alerts', settings)
                          }}
                        />
                      </td>
                      <td>
                        <small>
                          <a
                            className='glyphicon glyphicon-trash'
                            onClick={() => {
                              if (window.confirm(`Stop sending alerts to ${recipient.name}?`)) {
                                const settings = Object.assign({}, alertSettings)
                                settings.smsRecipients = settings.smsRecipients.filter((r, j) => j !== i)
                                userActions.updateSetting('alerts', settings)
                              }
                            }}
                          />
                        </small>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        )
      }
      multiSms = (
        <div className='multi-sms'>
          <h3>Alert Recipients</h3>
          {recipients}
          {smsBody}
        </div>
      )
    }
    return (
      <div className='alert-default-page'>
        <header className='main'>
          <h1>
            Alerts
          </h1>
        </header>
        <div className='page-body'>
          {this._socialPrefs()}
          <div className='block'>
            <h3>Device Alerts<QuestionMark link="https://ambientweather.com/faqs/question/view/id/1793/" /></h3>
            {appAlertExplain && (
              <p className='warning'>
                App notifications are only available to users who have installed the Ambient Weather ios or android apps. <a href='https://ambientweather.com/sms-update' target='_blank' rel='noreferrer'>Learn more</a>.
              </p>
            )}
            <div className='prefs'>
              {radios}
              {freq}
            </div>
            <div className='message'>
              {messages}
            </div>
            {multiSms}
          </div>
          <div className='block'>
            <h3>Customizations</h3>
            <AlertCrud />
          </div>
          <Instances />
        </div>
      </div>
    )
  }
}
export default bindAllActions(DefaultPage)

DefaultPage.displayName = 'DefaultPage'
