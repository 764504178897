import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom'
import classNames from 'classnames'
import PropTypes from 'prop-types';
import { path, pluck, find } from 'ramda'
import {
  Map,
  DeviceChooser,
  Forecast,
  ForecastLongTerm,
  FetchesForecast
} from './'
import {
  ForecastWidget
} from '../features/device'
import { pathsChanged, debounce, FORECAST_ZOOM, getStorage, setStorage, isLoggedIn, getDeviceSharePath, activePosts } from '../common/ambient'
import bindAllActions from '../common/bindAllActions'

class MapWithForecast extends Component {
  static propTypes = {
    isDashboard: PropTypes.bool,
    className: PropTypes.string,
    user: PropTypes.object,
    deviceActions: PropTypes.object,
    userActions: PropTypes.object,
    mapOpts: PropTypes.object,
    onDeviceChange: PropTypes.func
  }
  state = {
    show5Day: false,
    currentDeviceHasLocation: true,
    searched: false,
    firstTime: false
  }
  constructor(props) {
    super(props)
    this.state.minimized = getStorage('forecastMinimized')
    this._userMovedMap = ::this._userMovedMap
    this.onMapLoads = []
  }
  _mapSetDevices(searchDevices, fitBounds, coords) {
    if (this.map) {
      this.map.setDevices(searchDevices, fitBounds, coords)
    } else {
      this.onMapLoads.push(() => {
        this.map.setDevices(searchDevices, fitBounds, coords)
      })
    }

  }
  componentDidCatch(error, info) {
    console.log(error, info)
  }
  componentDidMount() {
    if (this.props.isDashboard) {
      this._setCoords()
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { deviceActions, social, device } = this.props
    const { dashboardDevice, focusDevice, mapVisibleDevices } = device
    const { mapVisiblePosts } = social
    const { searched, firstTime } = this.state
    if (!searched && focusDevice) {
      this.setState({
        searched: true
      })
    }
    if (pathsChanged(this.props, prevProps, [['device', 'dashboardDevice', '_id'], ['isDashboard']])) {
      this._setCoords()
    }
    // if it's the first time here and there are social posts in view
    // open the sidebar
    // const posts = activePosts(mapVisiblePosts)
    // const socialDeviceIds = pluck('deviceId', posts)
    // const firstSocialDevice = find(d => socialDeviceIds.includes(d._id), mapVisibleDevices)
    // if(searched && !firstTime && firstSocialDevice) {
    //   this.setState({
    //     firstTime: true
    //   }, () => {
    //     deviceActions.focusDevice(firstSocialDevice)
    //     deviceActions.setSidebar(true)
    //   })
    // }
  }
  _setCoords() {
      const coords = path(['device', 'dashboardDevice', 'info', 'coords', 'geo', 'coordinates'], this.props)
      if (coords) {
        this.setState({ coords })
      }
  }
  _onSearch(searchDevices, evt) {
    const { coords } = this.state
    this._mapSetDevices(searchDevices, true, coords)
    this.onMoveEnd = () => setTimeout(this._userMovedMap, 500) // find some devices around them
    if (evt.searched) {
      this.setState({
        searched: evt.searched
      })
    }
  }
  _onLocationChange(loc) {
    this.setState(loc)
  }
  _toggleShow5Day() {
    const { userActions } = this.props
    const show5Day = !this.state.show5Day
    userActions.doModal(show5Day && { type: 'forecast-modal' })
    this.setState({
      show5Day
    })
  }
  _userMovedMap() {
    this.map && this.map.searchMap()
  }
  _onMapLoad(map) {
    const { onMapLoad } = this.props
    map.on('moveend', debounce(() => {
      this.setState({
        zoom: this.map ? this.map.map.getZoom() : null
      })
      if (this.onMoveEnd) {
        this.onMoveEnd()
        this.onMoveEnd = null
      }
    }, 400))
    this.onMapLoads.forEach(fn => fn())
    if (onMapLoad) {
      onMapLoad(map)
    }
  }
  _onDeviceClick(theDevice, evt){
    const { isDashboard, onDeviceChange } = this.props
    if (onDeviceChange) {
      onDeviceChange(theDevice, evt)
    }
  }
  /**
   * the map responding to the device chooser on the dashboard 
   * 
   * @param {object} theDevice 
   */
  _onChooserChange(theDevice) {
    if (!this.props.isDashboard) return
    const { onDeviceChange } = this.props
    const coords = path(['info', 'coords', 'geo', 'coordinates'], theDevice)
    if (coords) {
      this._mapSetDevices([theDevice])
      this.onMoveEnd = this._userMovedMap // find some devices around them
      this.setState({ 
        coords,
        currentDeviceHasLocation: true
      })
    } else {
      this.setState({
        currentDeviceHasLocation: false
      })
    }
    if (onDeviceChange) {
      onDeviceChange(theDevice)
    }
  }
  _onViewDashboardClick(theDevice) {
    const { deviceActions, history, isDashboard, user } = this.props
    deviceActions.setDashboardDevice(theDevice)
    if (isLoggedIn(user)) {
      if (!isDashboard) {
        // hacky but we need to wait until this action is saved
        setTimeout(() => {
          history.push('/dashboard')
        }, 500)
      }
    } else {
      history.push(getDeviceSharePath(theDevice))
    }
  }
  _showForecast() {
    const { isDashboard } = this.props
    const { zoom, searched } = this.state
    if (!zoom) return false
    if (zoom < FORECAST_ZOOM) return false
    if (!isDashboard && !searched) return false // the very first time they come to the site
    return true
  }
  _onFindLocation(loc) {
  }

  render() {
    const { socialActions, deviceActions, device, className, isDashboard, user, mapOpts = {} } = this.props
    const { searched, currentDeviceHasLocation, minimized, show5Day, zoom, address, coords, timelineTime } = this.state
    const { sidebar } = device
    let forecast
    const button = []
    if (!isDashboard) {
      button.push(<a 
        key='sidebar-btn'
        onClick={() => deviceActions.setSidebar(!sidebar)}
        className={classNames('sidebar-btn main-btn', {
          active: sidebar
        })}
      />)
    }
    if (this._showForecast()) {
      const forecastChildren = [
        <a key='close' className="close" onClick={() => {
          const m = !minimized
          setStorage('forecastMinimized', m)
          this.setState({
            minimized: m
          })
        }}></a>,
        <a key='show-24' onClick={this._toggleShow5Day.bind(this)} className="show-24">View 24 hour forecast</a>
      ]
      forecast = <FetchesForecast 
        coords={coords} 
      >
        <Forecast chilrens={forecastChildren} />
        </FetchesForecast>
      button.push(<a key='forecast-btn' onClick={this._toggleShow5Day.bind(this)} className={classNames('forecast-btn main-btn', { active: show5Day })}><span>24h</span><span>7d</span></a>)
    }
    const finalClassName = classNames('map-with-forecast', { 
      minimized,
      searched,
      'not-searched': !searched,
      sidebar,
      'no-loc': !currentDeviceHasLocation
      }, className)
    let map
    if (!/no-loc/.test(finalClassName)) {
      map = <Map 
        onRef={ref => this.map = ref} 
        timelineTime={timelineTime}
        coords={coords}
        onMapLoad={this._onMapLoad.bind(this)}
        onDeviceClick={this._onDeviceClick.bind(this)}
        onViewDashboardClick={this._onViewDashboardClick.bind(this)}
        buttons={button}
        user={user}
        mapOpts={Object.assign({}, {
          hidePopupValue: isDashboard,
          noScrollZoom: isDashboard,
          paddingTop: isDashboard ? 100 : null
        }, mapOpts)}
      />        
    }
    return (
      <div className={finalClassName}>
        <Link key="expand" to="/" className="expand" />
        {forecast}
        {show5Day ? 
          <div onClick={this._toggleShow5Day.bind(this)} className="forecast-long-term-wrap expanded">
            <FetchesForecast coords={coords}><ForecastLongTerm onClose={this._toggleShow5Day.bind(this)} expanded /></FetchesForecast>
          </div> : null }
        {map}
        <DeviceChooser 
          onRef={ref => this.deviceChooser = ref}
          onSearch={this._onSearch.bind(this)} 
          onLocationChange={this._onLocationChange.bind(this)}
          onChange={this._onChooserChange.bind(this)}
          hideSearch={!isDashboard}
          onFindLocation={this._onFindLocation.bind(this)}
          open={!isDashboard}
          />
          {this.props.children}
      </div>
    )
  }
}

export default bindAllActions(withRouter(MapWithForecast))
MapWithForecast.displayName = 'MapWithForecast'
