import React, { PureComponent } from 'react';
import { widgetExpanded, dataHasAny, dataHas, WIDGET_CONFIG } from '../../common/ambient'
import PropTypes from 'prop-types';
import { zip, splitAt, take, map, filter } from 'ramda'
import FormattedDataPoint from './FormattedDataPoint'
import EditableWidgetTitleInput from './EditableWidgetTitleInput'

export default class SensorsWidget extends PureComponent {
  static propTypes = {
    currentDevice: PropTypes.object.isRequired,
    onChange: PropTypes.func,
  };

  render() {
    const { currentDevice, onChange } = this.props
    const { lastData } = currentDevice
    const fields = WIDGET_CONFIG.sensors.fields
    const currentDataHasAny = dataHasAny(lastData)
    const splitFields = splitAt(fields.length / 2, fields)
    const zippedFields = zip(splitFields[0], splitFields[1])

    let currentSensors = filter(currentDataHasAny, zippedFields)

    let widget = <div className="sensors-widget hidden" />
    const createLine = fields => {
      const temp = fields[0]
      const hum = fields[1]
      return <div key={temp} className="line clearfix">
        <EditableWidgetTitleInput
          type={temp}
          currentDevice={currentDevice}
          onChange={onChange}
        />
        <FormattedDataPoint
          type={temp}
          row={lastData}
        />
        <FormattedDataPoint
          type={hum}
          row={lastData}
        />
      </div>
    }

    if (currentSensors.length > 0) {
      const cutoff = 5
      if (!widgetExpanded('sensors', currentDevice)) {
        currentSensors = take(cutoff, currentSensors)
      }
      const lines = map(createLine, currentSensors)
      widget =
        <div className="sensors-widget table-widget">
          <div className="lines">
            {lines}
          </div>
        </div>
    }
    return widget
  }
}

SensorsWidget.displayName = 'SensorsWidget'

SensorsWidget.displayName = 'SensorsWidget'

SensorsWidget.displayName = 'SensorsWidget'

SensorsWidget.displayName = 'SensorsWidget'

SensorsWidget.displayName = 'SensorsWidget'

SensorsWidget.displayName = 'SensorsWidget'

SensorsWidget.displayName = 'SensorsWidget'
