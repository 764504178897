export const SOCIAL_TEST_ACTION = 'SOCIAL_TEST_ACTION';
export const CREATE_POST_BEGIN = 'CREATE_POST_BEGIN';
export const CREATE_POST_SUCCESS = 'CREATE_POST_SUCCESS';
export const CREATE_POST_FAILURE = 'CREATE_POST_FAILURE';
export const CREATE_POST_DISMISS_ERROR = 'CREATE_POST_DISMISS_ERROR';
export const FETCH_POSTS_BEGIN = 'FETCH_POSTS_BEGIN';
export const FETCH_POSTS_SUCCESS = 'FETCH_POSTS_SUCCESS';
export const FETCH_POSTS_FAILURE = 'FETCH_POSTS_FAILURE';
export const FETCH_POSTS_DISMISS_ERROR = 'FETCH_POSTS_DISMISS_ERROR';
export const PATCH_POST_BEGIN = 'PATCH_POST_BEGIN';
export const PATCH_POST_SUCCESS = 'PATCH_POST_SUCCESS';
export const PATCH_POST_FAILURE = 'PATCH_POST_FAILURE';
export const PATCH_POST_DISMISS_ERROR = 'PATCH_POST_DISMISS_ERROR';
export const ADD_LIKE_BEGIN = 'ADD_LIKE_BEGIN';
export const ADD_LIKE_SUCCESS = 'ADD_LIKE_SUCCESS';
export const ADD_LIKE_FAILURE = 'ADD_LIKE_FAILURE';
export const ADD_LIKE_DISMISS_ERROR = 'ADD_LIKE_DISMISS_ERROR';
export const ADD_USER_ACTION_BEGIN = 'ADD_USER_ACTION_BEGIN';
export const ADD_USER_ACTION_SUCCESS = 'ADD_USER_ACTION_SUCCESS';
export const ADD_USER_ACTION_FAILURE = 'ADD_USER_ACTION_FAILURE';
export const ADD_USER_ACTION_DISMISS_ERROR = 'ADD_USER_ACTION_DISMISS_ERROR';
export const REMOVE_USER_ACTION_BEGIN = 'REMOVE_USER_ACTION_BEGIN';
export const REMOVE_USER_ACTION_SUCCESS = 'REMOVE_USER_ACTION_SUCCESS';
export const REMOVE_USER_ACTION_FAILURE = 'REMOVE_USER_ACTION_FAILURE';
export const REMOVE_USER_ACTION_DISMISS_ERROR = 'REMOVE_USER_ACTION_DISMISS_ERROR';
export const FETCH_USER_ACTIONS_BEGIN = 'FETCH_USER_ACTIONS_BEGIN';
export const FETCH_USER_ACTIONS_SUCCESS = 'FETCH_USER_ACTIONS_SUCCESS';
export const FETCH_USER_ACTIONS_FAILURE = 'FETCH_USER_ACTIONS_FAILURE';
export const FETCH_USER_ACTIONS_DISMISS_ERROR = 'FETCH_USER_ACTIONS_DISMISS_ERROR';
export const SET_MAP_VISIBLE_POSTS = 'SET_MAP_VISIBLE_POSTS';
export const GET_POST_BEGIN = 'GET_POST_BEGIN';
export const GET_POST_SUCCESS = 'GET_POST_SUCCESS';
export const GET_POST_FAILURE = 'GET_POST_FAILURE';
export const GET_POST_DISMISS_ERROR = 'GET_POST_DISMISS_ERROR';
export const SOCIAL_GET_USER_ACTION_BEGIN = 'SOCIAL_GET_USER_ACTION_BEGIN';
export const SOCIAL_GET_USER_ACTION_SUCCESS = 'SOCIAL_GET_USER_ACTION_SUCCESS';
export const SOCIAL_GET_USER_ACTION_FAILURE = 'SOCIAL_GET_USER_ACTION_FAILURE';
export const SOCIAL_GET_USER_ACTION_DISMISS_ERROR = 'SOCIAL_GET_USER_ACTION_DISMISS_ERROR';
export const SOCIAL_PATCH_USER_ACTION_BEGIN = 'SOCIAL_PATCH_USER_ACTION_BEGIN';
export const SOCIAL_PATCH_USER_ACTION_SUCCESS = 'SOCIAL_PATCH_USER_ACTION_SUCCESS';
export const SOCIAL_PATCH_USER_ACTION_FAILURE = 'SOCIAL_PATCH_USER_ACTION_FAILURE';
export const SOCIAL_PATCH_USER_ACTION_DISMISS_ERROR = 'SOCIAL_PATCH_USER_ACTION_DISMISS_ERROR';
