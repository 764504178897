import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { serviceGet } from '../../../common/feathers'
import {
  SOCIAL_GET_USER_ACTION_BEGIN,
  SOCIAL_GET_USER_ACTION_SUCCESS,
  SOCIAL_GET_USER_ACTION_FAILURE,
  SOCIAL_GET_USER_ACTION_DISMISS_ERROR,
} from './constants';

export const getUserAction = serviceGet('userActions', {
  begin: SOCIAL_GET_USER_ACTION_BEGIN,
  success: SOCIAL_GET_USER_ACTION_SUCCESS,
  fail: SOCIAL_GET_USER_ACTION_FAILURE
})

export function dismissGetUserActionError() {
  return {
    type: SOCIAL_GET_USER_ACTION_DISMISS_ERROR,
  };
}

export function useGetUserAction() {
  const dispatch = useDispatch();

  const { getUserActionPending, getUserActionError } = useSelector(
    state => ({
      getUserActionPending: state.social.getUserActionPending,
      getUserActionError: state.social.getUserActionError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(getUserAction(...args));
  }, [dispatch]);

  const boundDismissError = useCallback(() => {
    return dispatch(dismissGetUserActionError());
  }, [dispatch]);

  return {
    getUserAction: boundAction,
    getUserActionPending,
    getUserActionError,
    dismissGetUserActionError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case SOCIAL_GET_USER_ACTION_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        getUserActionPending: true,
        getUserActionError: null,
      };

    case SOCIAL_GET_USER_ACTION_SUCCESS:
      // The request is success
      return {
        ...state,
        getUserActionPending: false,
        getUserActionError: null,
      };

    case SOCIAL_GET_USER_ACTION_FAILURE:
      // The request is failed
      return {
        ...state,
        getUserActionPending: false,
        getUserActionError: action.data.error,
      };

    case SOCIAL_GET_USER_ACTION_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        getUserActionError: null,
      };

    default:
      return state;
  }
}
