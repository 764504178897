import { omit, range } from 'ramda'
import { WXW_WIDGETS, canWxwWidgetGoHere, getAvailWidgets, isSomething, wxwWidgetCount } from '../../common/ambient'
import { MapWithForecast } from '../../components'
import React from 'react'
import { owise1 } from '../../common/lib/ambient-weather-common'
import WxwPreview from './WxwPreview'
import classNames from 'classnames'
import { useGetDevice } from './redux/getDevice'
import { useDoModal } from '../user/redux/doModal'
// import PropTypes from 'prop-types';

/**
 * 
 * potential wxw layouts json
 * {
 *  user: { settings: {} },
 *  forecastLocation: {},
 *  theme: 'light',
 *  layout: 0,
 *  devices: [{
 *    macAddress: '00:00:00:00:00:00',
 *    lastData: { tempf: 70 },
 *    location: 'Oakland'
  * }],
 *  widgets: [
 *  {
 *    type: 'tempf',
 *    title: 'Temperature',
 *    device: '00:00:00:00:00:00',
 *  }
 * ]
 * }
 */

export default function WxwEdit ({ onChange, layout = {}, macAddress, onCreate, device }) {
  const { doModal } = useDoModal()
  const { getDevice } = useGetDevice()
  const [config, setConfig] = React.useState(Object.assign({
    id: owise1.guid(),
    title: 'My Layout',
    widgets: []
  }, layout))
  const [defaultDevice, setDefaultDevice] = React.useState(device.dashboardDevice)
  const [loading, setLoading] = React.useState(false)
  // React.useEffect(() => {
  //   if (onChange && config.widgets.length > 0) {
  //     onChange(config)
  //   }
  // }, [onChange, config])

  const getDefaultWidgets = d => {
    const wids = []
    const availWidgets = getAvailWidgets(d)
    let availWidgetKeys = Object.keys(availWidgets)
    const totalWidgets = wxwWidgetCount(config.layout) - 1
    if (config.layout === 3) {
      if (availWidgetKeys.includes('forecast')) {
        wids[0] = {
          type: 'forecast',
          title: WXW_WIDGETS.forecast.title,
          device: d.macAddress
        }
      }
      if (availWidgetKeys.includes('rain')) {
        wids[2] = {
          type: 'rain',
          title: WXW_WIDGETS.rain.title,
          device: d.macAddress
        }
      }
    }
    range(0, totalWidgets).forEach(i => {
      if (!wids[i]) {
        const type = availWidgetKeys.find(type => {
          return canWxwWidgetGoHere(config.layout, type, i)
        })
        if (type) {
          wids[i] = {
            type,
            title: WXW_WIDGETS[type].title,
            device: d.macAddress
          }
          availWidgetKeys = availWidgetKeys.filter(k => k !== type)
        }
      }
    })
    return wids
  }

  let title = 'Select a Layout'
  let btn = null
  let show = (
    <div className='wxw-line'>
      <div className='wxw-layouts'>
        {range(0, 4).map(i => (
          <div key={i}>
            <a onClick={() => setConfig({ ...config, layout: i })} className='btn btn-primary'>Choose</a>
            <div className={`wxw-layout wxw-layout-${i}`} />
          </div>
        ))}
      </div>
    </div>
  )
  if (config.widgets.length > 0) {
    title = 'Click on a tile to customize it'
    btn = (
      <a className='btn white' onClick={() => setConfig({ ...config, widgets: [] })}>Back</a>
    )
    if (layout.id && !layout.inactive) {
      btn = (
        <a className='close-x-blue' onClick={() => onChange()} />
      )
    }
    show = (
      <div className='wxw-example'>
        <input type='text' className='editable-title' value={config.title} onChange={evt => setConfig({ ...config, title: evt.target.value })} />
        <WxwPreview
          macAddress={macAddress}
          layout={config}
          onLoading={setLoading}
        >
          <div className={classNames('wxw-widgets', `layout-${config.layout}`)}>
            {range(0, wxwWidgetCount(config.layout)).map(i => {
              return (
                <a
                  key={`layout-${config.id}-${i}`}
                  className={classNames('wxw-widget', { blank: !config.widgets[i] })}
                  onClick={() => {
                    doModal({
                      type: 'device-widget-chooser',
                      data: {
                        widget: config.widgets[i] || { device: config.defaultDevice },
                        i,
                        layout: config.layout,
                        onSave: (widgetConfig) => {
                          let widgets = [...config.widgets]
                          if (i <= widgets.length - 1) {
                            widgets[i] = widgetConfig
                          } else {
                            // fill with empty widgets
                            widgets = [...widgets, ...range(widgets.length, i).map(() => ({ type: 'text' })), widgetConfig]
                          }
                          setConfig({ ...config, widgets })
                          doModal(null)
                        },
                        onCancel: () => {
                          doModal(null)
                        }
                      }
                    })
                  }}
                />
              )
            })}
          </div>
        </WxwPreview>
        <div className='btn-wrap'>
          <a className='btn btn-primary' onClick={() => {
            if (onChange) {
              delete config.inactive
              onChange(config)
            }
          }}>Save</a>
          {config.defaultDevice && <a
            onClick={async () => {
              if (window.confirm('Restoring the default layout will reset all your changes. Proceed?')) {
                const d = await getDevice(config.defaultDevice)
                const wids = getDefaultWidgets(d)
                if (wids.length === 0) {
                  return window.alert('No tiles available for this device')
                }
                const newConfig = {
                  ...config,
                  widgets: wids
                }
                setConfig(newConfig)
              }
            }}
            >Restore Default</a>
          }

        </div>
      </div>
    )
  } else if (isSomething(config.layout)) {
    title = 'Click the default station for your Weather Window'
    btn = (
      <a className='btn white' onClick={() => setConfig(omit(['layout'], config))}>Back</a>
    )
    show = (
      <>
        <MapWithForecast
          isDashboard
          mapOpts={{
            noScrollZoom: false
          }}
          onDeviceChange={currentDevice => {
            setDefaultDevice(currentDevice)
          }}
        />
        {defaultDevice && (
          <div className='btn-wrap'>
            <a
              className='btn btn-primary'
              disabled={!defaultDevice}
              onClick={() => {
                const wids = getDefaultWidgets(defaultDevice)
                if (wids.length === 0) {
                  return window.alert('No tiles available for this device')
                }
                const newConfig = {
                  ...config,
                  inactive: true,
                  widgets: wids,
                  defaultDevice: defaultDevice.macAddress
                }
                onCreate(newConfig)
                  .then(() => {
                    setConfig(newConfig)
                  })
              }}>Next</a>
          </div>
        )}
      </>
    )
  }
  return (
    <div className={classNames('device-wxw-edit', { editing: layout.id, loading })}>
      <h2>{title} {btn}</h2>
      {show}
    </div>
  )
};

WxwEdit.propTypes = {}
WxwEdit.defaultProps = {}

WxwEdit.displayName = 'WxwEdit'
