import {
  FETCH_INSTANCES_BEGIN,
  FETCH_INSTANCES_SUCCESS,
  FETCH_INSTANCES_PAGINATION,
  FETCH_INSTANCES_FAILURE,
  FETCH_INSTANCES_DISMISS_ERROR,
} from './constants';
import { serviceFind } from '../../../common/feathers'
import { uniqBy, prop, concat } from 'ramda'

export const fetchInstances = serviceFind('alertInstances', {
  begin: FETCH_INSTANCES_BEGIN,
  success: FETCH_INSTANCES_SUCCESS,
  fail: FETCH_INSTANCES_FAILURE,
  pagination: FETCH_INSTANCES_PAGINATION
})

export function dismissFetchInstancesError() {
  return {
    type: FETCH_INSTANCES_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FETCH_INSTANCES_BEGIN:
      return {
        ...state,
        fetchInstancesPending: true,
        fetchInstancesError: null
      };

    case FETCH_INSTANCES_SUCCESS:
      return {
        ...state,
        fetchInstancesPending: false,
        fetchInstancesError: null,
        alertInstances: action.skipCache ? state.alertInstances : uniqBy(prop('_id'), concat(state.alertInstances, action.data))
      };

    case FETCH_INSTANCES_FAILURE:
      return {
        ...state,
        fetchInstancesPending: false,
        fetchInstancesError: action.data.error,
      };

    case FETCH_INSTANCES_PAGINATION:
      return {
        ...state,
        fetchInstancesPagination: action.data,
      };
    case FETCH_INSTANCES_DISMISS_ERROR:
      return {
        ...state,
        fetchInstancesError: null,
      };

    default:
      return state;
  }
}
