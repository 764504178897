import {
  FETCH_ALL_DEVICES_BEGIN,
  FETCH_ALL_DEVICES_SUCCESS,
  FETCH_ALL_DEVICES_FAILURE,
  FETCH_ALL_DEVICES_DISMISS_ERROR,
} from './constants';
import { serviceFind } from '../../../common/feathers'

export const fetchAllDevices = serviceFind('devices', {
  begin: FETCH_ALL_DEVICES_BEGIN,
  success: FETCH_ALL_DEVICES_SUCCESS,
  fail: FETCH_ALL_DEVICES_FAILURE
})

export function dismissFetchAllDevicesError() {
  return {
    type: FETCH_ALL_DEVICES_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FETCH_ALL_DEVICES_BEGIN:
      return {
        ...state,
        fetchAllDevicesPending: true,
        fetchAllDevicesError: null,
        allDevices: []
      };

    case FETCH_ALL_DEVICES_SUCCESS:
      return {
        ...state,
        fetchAllDevicesPending: false,
        fetchAllDevicesError: null,
        allDevices: action.data
      };

    case FETCH_ALL_DEVICES_FAILURE:
      return {
        ...state,
        fetchAllDevicesPending: false,
        fetchAllDevicesError: action.data.error,
        allDevices: null
      };

    case FETCH_ALL_DEVICES_DISMISS_ERROR:
      return {
        ...state,
        fetchAllDevicesError: null,
      };

    default:
      return state;
  }
}
