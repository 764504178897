import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { 
  UsernameEdit
} from './'
import bindUserActions from './redux/bindUserActions';

class CreateUsernameModal extends PureComponent {
  static propTypes = {
    onDone: PropTypes.func
  }
  state = {
    terms: false,
    username: '',
    usernameOk: false
  }

  render() {
    const { onDone, user, actions } = this.props
    const { username, terms, usernameOk } = this.state
    return (
      <div className='user-create-username-modal'>
        <h2>Join the conversation,<br/>create your handle.</h2>
        <h4>Handle <small>(min 5 characters)</small></h4>
        <UsernameEdit 
          hideSave 
          onChange={({ username, ok }) => {
            this.setState({
              username,
              usernameOk: ok
            })
          }}
        />
        <label><input checked={terms} onChange={evt => this.setState({ terms: evt.target.checked })} type="checkbox" /> <a href='https://help.ambientweather.net/help/image-and-text-guidelines/' target='_blank'> &nbsp;I agree to the community guidelines</a></label>
        <button 
          disabled={!terms || !usernameOk} 
          className='btn btn-primary'
          onClick={() => {
            actions.patch(user.info._id, { username })
            if (onDone) {
              onDone()
            }
          }}
        >Create Handle</button>
        <p className='small'>Don't worry. You can change your handle later in your settings.</p>
      </div>
    )
  }
}
export default bindUserActions(CreateUsernameModal) 

CreateUsernameModal.displayName = 'CreateUsernameModal'

CreateUsernameModal.displayName = 'CreateUsernameModal'

CreateUsernameModal.displayName = 'CreateUsernameModal'

CreateUsernameModal.displayName = 'CreateUsernameModal'

CreateUsernameModal.displayName = 'CreateUsernameModal'

CreateUsernameModal.displayName = 'CreateUsernameModal'

CreateUsernameModal.displayName = 'CreateUsernameModal'
