import {
  SET_THING
} from './constants'

export function setThing (key, val) {
  const data = {}
  data[key] = val
  return {
    type: SET_THING,
    data
  }
}

export function reducer (state, action) {
  switch (action.type) {
    case SET_THING:
      return Object.assign({
        ...state
      }, action.data)

    default:
      return state
  }
}
