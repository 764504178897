import { createBrowserHistory, createHashHistory } from 'history'
import { isCordova, checkOldLinks } from './ambient'

// A singleton history object for easy API navigation
const history = isCordova() ? createHashHistory() : createBrowserHistory()

const checkOrientation = (location) => {
  if (!location) location = window.location

  // Check if the Screen Orientation API is supported
  if (window.screen.orientation && window.screen.orientation.lock) {
    if (/graph/.test(location.pathname)) {
      window.screen.orientation.unlock()
    } else {
      // Lock the screen orientation to portrait
      window.screen.orientation.lock('portrait')
        .then(() => {
          console.log('Orientation locked to portrait')
        })
        .catch((error) => {
          console.log('Failed to lock orientation:', error)
        })
    }
  }
}

let lastPathName = false
history.listen(location => {
  checkOldLinks(location)
  if (typeof ga !== 'undefined' && lastPathName !== location.pathname) {
    ga('send', 'pageview', location.pathname)
    lastPathName = location.pathname
  }
  checkOrientation(location)
})
checkOrientation()
export default history
