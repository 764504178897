import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames'
import { soilHumidityLabel, dataHas } from '../../common/ambient'
import FormattedDataPoint from './FormattedDataPoint'

export default class SoilWidget extends PureComponent {
  static propTypes = {
    currentDevice: PropTypes.object.isRequired,
    sensor: PropTypes.number.isRequired
  };

  render() {
    const { currentDevice, sensor } = this.props
    const { lastData } = currentDevice
    const currentDataHas = dataHas(lastData)
    const tempKey = `soiltemp${sensor}`
    const humidityKey = `soilhum${sensor}`
    const mains = []
    let add = ''
    let single = true 
    if (currentDataHas([tempKey])) {
      mains.push(
        <div key={1}>
          <div className="label">
            Temperature
          </div>
          <FormattedDataPoint type={tempKey} row={lastData} />
        </div>
      )
    }
    if (currentDataHas([humidityKey])) {
      single = false
      mains.push(
        <div key={2}>
          <div className="label">
            Moisture 
          </div>
          <FormattedDataPoint type={humidityKey} row={lastData} />
        </div>
      )
      add = <div className="bottom">
          <div className="label">Conditions Are</div>
          <div className="value">
            {soilHumidityLabel(lastData[humidityKey])}
          </div>
      </div>
    }
    return (
      <div className={classNames('soil-widget', { single })}>
        <div className="top">
          <div className="wrap">
            {mains}
          </div>
        </div>
        {add}
      </div>
    );
  }
}

SoilWidget.displayName = 'SoilWidget'

SoilWidget.displayName = 'SoilWidget'

SoilWidget.displayName = 'SoilWidget'

SoilWidget.displayName = 'SoilWidget'

SoilWidget.displayName = 'SoilWidget'

SoilWidget.displayName = 'SoilWidget'

SoilWidget.displayName = 'SoilWidget'
