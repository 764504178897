import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames'

export default class Loader extends PureComponent {
  static propTypes = {
    className: PropTypes.string

  };

  render() {
    return (
      <div className={classNames("component-loader", this.props.className)}>
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
      </div>
    );
  }
}

Loader.displayName = 'Loader'
