import React, { PureComponent } from 'react';
import { widgetExpanded, dataHas, WIDGET_CONFIG } from '../../common/ambient'
import PropTypes from 'prop-types';
import { take, map, filter } from 'ramda'
import EditableWidgetTitleInput from './EditableWidgetTitleInput'

export default class RelaysWidget extends PureComponent {
  static propTypes = {
    currentDevice: PropTypes.object.isRequired,
    onChange: PropTypes.func,
  };

  render() {
    const { currentDevice, onChange } = this.props
    const { lastData } = currentDevice
    const relayFields = WIDGET_CONFIG.relays.fields
    const currentDataHas = dataHas(lastData)
    let currentRelays = filter(currentDataHas, relayFields)
    let widget = <div className="relays-widget hidden" />
    const createLine = field =>
      <div key={field} className="line clearfix">
        <i className={'dot ' + (lastData[field] === 1 ? 'on' : '')} />
        <EditableWidgetTitleInput
          type={field}
          currentDevice={currentDevice}
          onChange={onChange}
        />
      </div>

    if (currentRelays.length > 0) {
      const cutoff = 5
      if (!widgetExpanded('relays', currentDevice)) {
        currentRelays = take(cutoff, currentRelays)
      }
      const lines = map(createLine, currentRelays)
      widget =
        <div className="relays-widget table-widget">
          {lines}
        </div>
    }
    return widget
  }
}

RelaysWidget.displayName = 'RelaysWidget'

RelaysWidget.displayName = 'RelaysWidget'

RelaysWidget.displayName = 'RelaysWidget'

RelaysWidget.displayName = 'RelaysWidget'

RelaysWidget.displayName = 'RelaysWidget'

RelaysWidget.displayName = 'RelaysWidget'

RelaysWidget.displayName = 'RelaysWidget'
