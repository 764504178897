import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { dataHas } from '../../common/ambient'
import { FormattedDataPoint, FromYesterday } from './'
import { deviceIsAKestrel } from '../../common/lib/ambient-weather-common/ambient'

export default class HumidityWidget extends PureComponent {
  static propTypes = {
    currentDevice: PropTypes.object
  };
  render() {
    const { currentDevice } = this.props
    const { lastData } = currentDevice
    const data = lastData
    let widget = <div className="humidity-widget hidden" />
    const currentDataHas = dataHas(data)
    if (currentDataHas('humidity')) {
      widget =
        <div className="big-number humidity-widget">
          <FormattedDataPoint type={'humidity'} row={lastData} />
          {deviceIsAKestrel(currentDevice) ?
            <div>
              <div className="label">Dew Point</div>
              <div className="val">
                <FormattedDataPoint type={'dewPoint'} row={lastData} />
              </div>
            </div> :
            <FromYesterday type={'humidity'} currentDevice={currentDevice} />}
        </div>
    }
    return widget
  }
}

HumidityWidget.displayName = 'HumidityWidget'

HumidityWidget.displayName = 'HumidityWidget'

HumidityWidget.displayName = 'HumidityWidget'

HumidityWidget.displayName = 'HumidityWidget'

HumidityWidget.displayName = 'HumidityWidget'

HumidityWidget.displayName = 'HumidityWidget'

HumidityWidget.displayName = 'HumidityWidget'
