import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { pipe, map, toPairs } from 'ramda'

export default class TimeModeNav extends PureComponent {
  static propTypes = {
    active: PropTypes.string,
    onChange: PropTypes.func,
    navs: PropTypes.object
  };

  render() {
    const { active, onChange, navs } = this.props
    const defaultNavs = {
      daily: 'Day',
      weekly: 'Week',
      monthly: 'Month',
      yearly: 'Year'
    }
    const lis = pipe(
      toPairs,
      map((pair) => {
        return <li className={active === pair[0] ? 'active' : ''} key={pair[0]}>
          <a
            onClick={() => {
              if (onChange) {
                onChange(pair[0])
              }
            }}
          >
            {pair[1]}
          </a>
        </li>
      })
    )(navs || defaultNavs)
    return (
      <ul className="component-time-mode-nav pagination">
        {lis}
      </ul>
    );
  }
}

TimeModeNav.displayName = 'TimeModeNav'

TimeModeNav.displayName = 'TimeModeNav'

TimeModeNav.displayName = 'TimeModeNav'

TimeModeNav.displayName = 'TimeModeNav'

TimeModeNav.displayName = 'TimeModeNav'

TimeModeNav.displayName = 'TimeModeNav'

TimeModeNav.displayName = 'TimeModeNav'
