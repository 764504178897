import React, { useRef, useEffect, useState } from 'react';
import { getDeviceType } from '../../common/ambient';
import classNames from 'classnames';
import { uniq, path } from 'ramda';
// import PropTypes from 'prop-types';

const accessories = [{
  title: 'Pool Float',
  sku: 'WH-31PF',
  url: 'https://ambientweather.com/wh31pf-wireless-waterproof-floating-thermometer',
  compat: ['WS-5000', 'WS-2000', 'WS-4000', 'OBSERVERIP2', 'WS-2902', 'WS-1965'],
  cat: 'temp'
}, {
  title: 'Waterproof Probe',
  sku: 'WH-31P',
  url: 'https://ambientweather.com/wh31p-water-proof-probe-temperature-sensor',
  compat: ['WS-5000', 'WS-2000', 'WS-4000', 'OBSERVERIP2', 'WS-2902', 'WS-1965'],
  cat: 'temp'
}, {
  title: 'Thermo/Hygro',
  sku: 'WH-31E',
  url: 'https://ambientweather.com/wh31e-wireless-temperature-humidity-sensor',
  compat: ['WS-5000', 'WS-2000', 'WS-4000', 'OBSERVERIP2', 'WS-2902', 'WS-1965'],
  cat: 'temp'
}, {
  title: 'Refrigerator /Freezer',
  sku: 'WH-51RF',
  url: 'https://ambientweather.com/wh51rf-wireless-refrigerator-freezer-temperature-humidity-sensor',
  compat: ['WS-5000', 'WS-2000', 'WS-4000', 'OBSERVERIP2', 'WS-2902', 'WS-1965'],
  cat: 'temp'
}, {
  title: 'Thermo/Hygro/Baro',
  sku: 'WH-32B',
  url: 'https://ambientweather.com/wh32b-indoor-thermometer-barometer-hygrometer',
  compat: ['WS-5000', 'WS-2000', 'WS-4000', 'OBERSERVERIP2'],
  cat: 'tempIndoor'
}, {
  title: 'Lightning Detector',
  sku: 'WH-31L',
  url: 'https://ambientweather.com/wh31l-lightning-detector',
  compat: ['WS-5000', 'WS-2000', 'WS-4000', 'OBSERVERIP2', 'WS-1965'],
  cat: 'lightning'
}, {
  title: 'Particulate Monitor',
  sku: 'PM25',
  url: 'https://ambientweather.com/pm25-wireless-outdoor-particulate-monitor',
  compat: ['WS-5000', 'WS-2000', 'WS-4000', 'OBSERVERIP2', 'WS-2902', 'WS-1965'],
  cat: 'outdoor-air'
}, {
  title: 'Indoor Particulate Monitor',
  sku: 'PM25IN',
  url: 'https://ambientweather.com/pm25in-wireless-indoor-particulate-monitor',
  compat: ['WS-5000', 'WS-2000', 'WS-4000', 'OBSERVERIP2', 'WS-2902', 'WS-1965'],
  cat: 'indoor-air'
}, {
  title: 'Air Quality Monitor',
  sku: 'AQIN',
  url: 'https://ambientweather.com/indoor-wireless-air-quality-monitor-aqin',
  compat: ['WS-5000', 'WS-2000', 'WS-4000', 'OBSERVERIP2', 'WS-1965'],
  cat: 'indoor-air'
}, {
  title: 'Leak Detector',
  sku: 'WH-31LA',
  url: 'https://ambientweather.com/wh31la-leak-detector',
  compat: ['WS-5000', 'WS-2000', 'WS-4000', 'OBSERVERIP2', 'WS-1965'],
  cat: 'leak'
}, {
  title: 'Soil Moisture Sensor',
  sku: 'WH-31SM',
  url: 'https://ambientweather.com/wh31sm-soil-moisture-sensor',
  compat: ['WS-5000', 'WS-2000', 'WS-4000', 'OBSERVERIP2', 'WS-1965'],
  cat: 'soil'
}, {
  title: 'Leaf Wetness Sensor',
  sku: 'WH51LW',
  url: 'https://ambientweather.com/leaf-wetness-sensor-wh51lw',
  compat: ['WS-5000', 'WS-2000', 'WS-4000', 'WS-1938', 'OBSERVERIP2', 'WS-1965'],
  cat: 'leafwetness'
}, {
  title: 'Soil Sensor Kit',
  sku: '0672',
  url: 'https://ambientweather.com/soil-sensor-array-kit',
  compat: ['KM-6000'],
  cat: 'kestrel'
}, {
  title: 'Soil Irradiance Kit',
  sku: '0671',
  url: 'https://ambientweather.com/solar-irradiance-sensor-kit',
  compat: ['KM-6000'],
  cat: 'kestrel'
}, {
  title: 'Leaf Wetness Kit',
  sku: '0670',
  url: 'https://ambientweather.com/leaf-wetness-sensor-kit',
  compat: ['KM-6000'],
  cat: 'kestrel'
}]
const cats = {
  temp: {
    title: 'Temperature Sensors',
    count: 8,
    paramTest: /temp\d+f/
  },
  tempIndoor: {
    title: 'Indoor Sensors',
    count: 1,
    paramTest: /tempinf/
  },
  lightning: {
    title: 'Lightning',
    count: 1,
    paramTest: /lightning_day/
  },
  'outdoor-air': {
    title: 'Outdoor Air Quality',
    count: 1,
    paramTest: /^pm25$/
  },
  'indoor-air': {
    title: 'Indoor Air Quality',
    count: 1,
    paramTest: /^(pm25_in$|pm25_in_aqin)/
  },
  leak: {
    title: 'Leak',
    count: 4,
    paramTest: /^leak\d$/
  },
  leafwetness: {
    title: 'Leaf Wetness',
    count: 8,
    paramTest: /leafwetness/
  },
  soil: {
    title: 'Soil Moisture',
    count: 8,
    paramTest: /soilhum/
  },
  kestrel: {
    title: 'Kestrel Accessories',
    count: 3,
    paramTest: /km6000/
  }
}

function howManyTheyGot (currentDevice, cat) {
  if (!currentDevice.lastData) return null
  const category = cats[cat]
  return Object.keys(currentDevice.lastData)
    .filter((param) => {
      return category.paramTest.test(param)
    })
    .length
}

function howManyMore (currentDevice, cat) {
  if (!currentDevice.lastData) return null
  const category = cats[cat]
  const theyHave = howManyTheyGot(currentDevice, cat)
  let total = category.count - theyHave
  return total < 0 ? 0 : total 
}

function SensorStatus ({ currentDevice, cat }) {
  if (!currentDevice.lastData) return null
  const category = cats[cat]
  const theyHave = howManyTheyGot(currentDevice, cat)
  let show = `connect ${category.count - theyHave} more`
  let full = false
  if (theyHave === category.count) {
    full = true
    show = 'All connected'
  }
  return (
    <div className={classNames('sensor-status', { full })}>
      {show}
    </div>
  )
}

export default function Accessories ({ currentDevice, deviceActions }) {
  const deviceType = getDeviceType(currentDevice)
  const [isOverflowing, setIsOverflowing] = useState(false)
  const childElementRef = useRef(null)
  useEffect(() => {
    const childElement = childElementRef.current

    const checkOverflow = () => {
      if (childElement && childElement.offsetWidth < childElement.scrollWidth) {
        setIsOverflowing(true);
      } else {
        setIsOverflowing(false);
      }
    };

    window.addEventListener('resize', checkOverflow);
    checkOverflow();

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, []); // Empty dependency array ensures that the effect runs only once on mount


  if (!deviceType) return null
  if (deviceType === 'wxw') return null
  const hasAccessories = accessories.filter((accessory) => accessory.compat.includes(deviceType))
  if (!hasAccessories.length) return null
  const toggleOpen = () => {
    const settings = currentDevice.settings || {}
    settings.accessoriesClosed = !settings.accessoriesClosed
    deviceActions.patch(currentDevice._id, { settings })
  }
  let moreToShow = ''
  const totalSensorsRemaining = uniq(hasAccessories.map(acc => acc.cat)).reduce((acc, cat) => {
    return acc + howManyMore(currentDevice, cat)
  }, 0)
  if (totalSensorsRemaining) {
    moreToShow = `connect ${totalSensorsRemaining} more sensors`
  }
  return (
    <div className={classNames('device-accessories', { open: !path(['settings', 'accessoriesClosed'], currentDevice) })}>
      <header>
        <h3>Compatible Sensors</h3>
        {moreToShow && <a onClick={toggleOpen} className='more-to-show'>{moreToShow}</a>}
        <a className='caret' onClick={toggleOpen} />
      </header>
      <div className={classNames('cat-wrap', { overflow: isOverflowing })}>
        <div className='cat-inner' ref={childElementRef} id='accesories_inner'>
          {Object.keys(cats).map((cat) => {
            const catAccs = accessories
              .filter((accessory) => accessory.cat === cat)
              .filter((accessory) => accessory.compat.includes(deviceType))
            if (!catAccs.length) return null
            return (
              <div className='cat' key={cat}>
                <h4>{cats[cat].title}</h4>
                <SensorStatus currentDevice={currentDevice} cat={cat} />
                <div className='sensors'>
                  {catAccs.map((accessory) => (
                    <a
                      key={accessory.sku}
                      href={accessory.url + '?ref=aw'}
                      target='_blank'
                      rel='noopener noreferrer'
                      className={classNames('accessory', 'sensor-' + accessory.sku.toLowerCase())}
                    >
                      <span className='title'>{accessory.title}</span>
                      <span className='btn btn-primary'>Purchase</span>
                    </a>
                  ))}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

Accessories.propTypes = {};
Accessories.defaultProps = {};

Accessories.displayName = 'Accessories'
